/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import Message from "./Message";

const ErrorMessage = ({ message, ...props }) => (
  <Message message={message} variant={"danger"} {...props} />
);

ErrorMessage.propTypes = { message: PropTypes.string };

export default ErrorMessage;
