import React, { useCallback } from "react";
import { Box, Button, Flex, Text, Textarea, Heading } from "theme-ui";
import CartItem from "../../../components/CartItem";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import WithLoader from "../../../components/WithLoader";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../contexts/ModalContext";
import { getFormatedYukaPoints } from "../../../utils/helpers";

const LabelValueList = styled(Flex)`
  margin-bottom: 1rem;
  align-items: center;

  .label {
    flex: 1 1 50%;
  }

  .value {
    flex: 1 1 50%;
    text-align: right;
  }
`;

const ConfirmationModal = styled(Box)`
  text-align: center;
  max-width: 24rem;

  .modal-title {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;

    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      height: 1.5rem;
      width: 1.5rem;
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 0.5rem;
    }
  }

  .modal-body {
    margin-bottom: 2rem;
  }

  .modal-footer {
    justify-content: center;
    align-items: center;
    button {
      min-width: 10rem;
      margin: 0 1rem;
    }
  }
`;

const YPCart = ({
  available_points,
  processing_points,
  cart,
  updateItem,
  removeItem,
  onOrderNoteChange,
  onCheckout,
  isSubmitting = false,
}) => {
  const { t } = useTranslation();
  const { showModal } = useModal();

  const { items, total_points, remaining_points, order_note } = cart;

  const confirmCheckout = useCallback(() => {
    return showModal(({ hideModal }) => (
      <ConfirmationModal>
        <Heading className="modal-title">{t("confirm")}</Heading>
        <Box className="modal-body">
          <Text>{t("do_you_want_to_confirm_your_order")}</Text>
        </Box>
        <Flex className="modal-footer">
          <Button
            variant={"secondary"}
            onClick={() => {
              onCheckout();
              hideModal();
            }}
            sx={{ minWidth: "10rem", textTransform: "uppercase" }}
          >
            {t("yes")}
          </Button>
          <Button
            variant={"primary"}
            sx={{ minWidth: "10rem", textTransform: "uppercase" }}
            onClick={() => {
              hideModal();
            }}
          >
            {t("go_back")}
          </Button>
        </Flex>
      </ConfirmationModal>
    ));
  }, [onCheckout, showModal, t]);

  if (items.length === 0)
    return (
      <Box>
        <Heading sx={{ marginBottom: "1.5rem" }}>
          {t("you_currently_havent_selected_any_products")}
        </Heading>
        <LabelValueList>
          <Box
            className="label"
            sx={{ fontWeight: "bold", color: "primary", fontSize: "1rem" }}
          >
            {t("available_yuka_points")}
          </Box>
          <Text
            className="value"
            sx={{ fontWeight: "bold", color: "primary", fontSize: "1rem" }}
          >
            {getFormatedYukaPoints(available_points) || "0"} YP
          </Text>
        </LabelValueList>
        <LabelValueList>
          <Box className="label">{t("points_being_processed")}</Box>
          <Text className="value">
            {getFormatedYukaPoints(processing_points) || "0"} YP
          </Text>
        </LabelValueList>
      </Box>
    );
  return (
    <>
      <Box className="cart-list-container">
        {items?.map(
          (
            {
              article_no,
              quantity,
              title,
              yukapoints,
              total_points: item_total_points,
            },
            key
          ) => {
            return (
              <CartItem
                keyForData={article_no}
                key={key}
                description={title}
                selling_price={yukapoints}
                quantity={quantity}
                max_quantity={Math.floor(
                  (remaining_points + item_total_points) / yukapoints
                )}
                onChange={(quantity) => {
                  updateItem(article_no, {
                    quantity,
                    total_points: quantity * yukapoints,
                  });
                }}
                onRemove={() => {
                  removeItem(article_no);
                }}
                type={"yp"}
              />
            );
          }
        )}
      </Box>
      <LabelValueList>
        <Box className="label">{t("total")}</Box>
        <Text
          className="value"
          sx={{ fontWeight: "bold", color: "primary", fontSize: "1rem" }}
        >
          {getFormatedYukaPoints(total_points) || "0"} YP
        </Text>
      </LabelValueList>
      <Textarea
        value={order_note}
        placeholder="Delivery Address / Notes(optional)"
        onChange={(e) => {
          onOrderNoteChange(e.target.value);
        }}
      />
      <LabelValueList>
        <Box className="label">{t("yuka_points_remaining")}</Box>
        <Text
          className="value"
          sx={{ fontWeight: "bold", color: "primary", fontSize: "1rem" }}
        >
          {getFormatedYukaPoints(remaining_points) || "0"} YP
        </Text>
      </LabelValueList>
      <Box sx={{ textAlign: "center", marginBottom: "1rem" }}>
        <WithLoader isLoading={isSubmitting}>
          <Button variant="secondary" className="btn" onClick={confirmCheckout}>
            {t("checkout")}
          </Button>
        </WithLoader>
      </Box>
      <Text sx={{ fontStyle: "italic", fontSize: "0.75rem" }}>
        *{t("amount_may_be_subjected_to_insurance_shipping_charge_and_taxes")}
      </Text>
    </>
  );
};

YPCart.propTypes = {
  available_points: PropTypes.number,
  processing_points: PropTypes.number,
  cart: PropTypes.shape({
    items: PropTypes.array.isRequired,
    total_points: PropTypes.number,
    remaining_points: PropTypes.number,
    order_note: PropTypes.string,
  }),
  updateItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  onOrderNoteChange: PropTypes.func.isRequired,
  onCheckout: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default YPCart;
