/** @jsx jsx */
import styled from "@emotion/styled";
import { Box, Button, Flex, jsx } from "theme-ui";
import WithLoader from "../../../components/WithLoader";
import Logo from "../../../assets/images/logo.png";
import { Form } from "react-final-form";
import { useAuthDispatch } from "../../../contexts/AuthContext";
import { Fragment, useCallback, useRef, useState } from "react";
import ErrorMessage from "../../../components/ErrorMessage";
import { getSliders } from "../../../apis";
import RFFInputField from "./RFFInputField";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/LanguageSelector";
import { useHistory } from "react-router-dom";
import { isRequired, isCheckedTerms } from "../../../utils/validators";
import { Field } from "react-final-form";
import DefaultImage from "../../../assets/images/default_login.jpg";
import { addDefaultSource } from "../../../utils/helpers";
import Slider from "react-slick";
import IconCheveronDown from "../../../components/icons/icon-cheveron-down";
import { transparentize } from "@theme-ui/color";
import { useQuery } from "react-query";
import YukaDigital from "../../../assets/images/yuka_digital_grey.svg";
import { Spinner } from "theme-ui";
import themes from "../../../themes";
import { Link } from "react-router-dom/cjs/react-router-dom";
import IconRightLeft from "../../../components/icons/icon-right-left";
import TradeINLogo from "../../../assets/images/tradein_logo.png";
import { submitLogin } from "../../../components/Login";
const GridWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 992px) {
    margin: auto;
  }
`;

const LoginCard = styled.div`
  height: 100vh;
  width: 100%;
  padding: 2rem;
  @media (max-width: 1080px) {
    padding: 2rem 1rem;
  }
  @media (max-height: 620px) {
    height: 100%;
  }
  background-color: #ffff;
  display: flex;
  flex-direction: column;
`;

const SliderContainer = styled.div`
  max-width: 76vw;
  min-width: 72vw;
  width: 100%;
  @media (max-width: 992px) {
    display: none;
  }
  position: relative;
  justify-content: center;
  .slick-slider {
    .slick-list {
      .slick-slide {
        > div {
          height: 100vh;
        }
        img {
          height: 100%;
          object-fit: cover;
        }
        @media (min-aspect-ratio: 21/9) {
          img {
            object-fit: contain !important;
          }
        }
      }
    }
    .slick-arrow {
      &.slick-next,
      &.slick-prev {
        position: absolute;
        bottom: 50%;
        top: inherit;
        transform: inherit;
        fill: ${(props) => props.theme.colors.white};
        cursor: pointer;
        z-index: 10;
        height: 4rem;
        width: 4rem;

        &:hover {
          background: ${(props) =>
            transparentize(props.theme.colors.white, 0.92)};
        }
      }

      &.slick-next {
        right: 0;
        transform: rotate(270deg);
      }

      &.slick-prev {
        left: 0;
        transform: rotate(90deg);
      }
    }
  }
`;

const LoginContainer = styled(Flex)`
  align-items: center;
  .input-container {
    width: 18rem;
  }

  @media screen and (max-width: 992px) {
    .input-container {
      width: 20rem;
    }
  }

  @media screen and (max-width: 1200px) and (min-width: 992px) {
    .input-container {
      width: 14rem;
    }
  }

  .login-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .title {
      margin-bottom: 1.5rem;
      color: ${(props) => props.theme.colors.primary};
    }

    .form-control {
      display: flex;
      align-items: flex-start;
      flex-wrap: no-wrap;
      width: 100%;

      label {
        flex: 0 0 8rem;
        margin: 0.75rem 0;
      }

      > * {
        flex: 1 1 auto;

        input {
          background-color: ${(props) => props.theme.colors.white};
        }
      }

      .input {
        padding: 0.75rem 1.25rem;
      }

      ${(props) => props.theme.customBreakpoints[1]} {
        flex-wrap: wrap;
        label {
          width: 100%;
          flex: 1 1 auto;
        }
      }
    }

    .login-btn {
      margin-top: 1rem;
      min-width: 10rem;
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      padding: 1rem 1rem 2rem;

      .title {
        margin-bottom: 0.5rem;
      }

      .form-control {
        label {
          margin-top: 0;
        }
      }
    }
  }
`;

const Image = styled.img`
  display: block;
  height: 2.75rem;
  width: auto;
  margin-bottom: 1.5rem;
`;

const Login = () => {
  const { t } = useTranslation();
  const authDispatch = useAuthDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const slider = useRef();

  const onSubmit = useCallback(
    (values) => {
      submitLogin(
        values,
        authDispatch,
        undefined,
        setErrorMessage,
        setIsLoading
      );
    },
    [setIsLoading, setErrorMessage]
  );

  const { isLoading: areSlidersLoading, data } = useQuery(
    "banners",
    getSliders
  );
  const bannerList = data?.data;

  const shuffeled =
    bannerList && bannerList.length
      ? bannerList?.sort(() => {
          const randomTrueOrFalse = Math.random() > 0.5;
          return randomTrueOrFalse ? 1 : -1;
        })
      : [];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <IconCheveronDown />,
    prevArrow: <IconCheveronDown />,
  };
  return (
    <LoginContainer>
      <GridWrapper>
        <SliderContainer>
          <Fragment>
            {shuffeled && shuffeled.length ? (
              <Fragment>
                <Box className="banner-image">
                  <Slider {...settings} ref={slider}>
                    {shuffeled?.map((item, key) => {
                      return (
                        <img
                          key={key}
                          src={item.file}
                          alt={"banner image "}
                          onError={addDefaultSource}
                        />
                      );
                    })}
                  </Slider>
                </Box>
              </Fragment>
            ) : areSlidersLoading ? (
              <Flex
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner></Spinner>
              </Flex>
            ) : (
              <Box className="banner-image">
                <img
                  style={{
                    height: "100vh",
                    width: "100%",
                  }}
                  src={DefaultImage}
                  alt={"banner image "}
                  onError={addDefaultSource}
                />
              </Box>
            )}
          </Fragment>
        </SliderContainer>
        <LoginCard>
          <Form
            onSubmit={onSubmit}
            validate={({ username, password, terms_and_condition }) => ({
              username: isRequired(username),
              password: isRequired(password),
              terms_and_condition: isCheckedTerms(terms_and_condition),
            })}
            render={({ handleSubmit }) => (
              <Fragment>
                <Flex>
                  <span
                    style={{ marginLeft: "auto", color: themes.colors.primary }}
                  >
                    <LanguageSelector
                      borderColor={themes.colors.lightBlack}
                      rightIconColor={themes.colors.primary}
                    />
                  </span>
                </Flex>

                <Box as="form" className="login-card" onSubmit={handleSubmit}>
                  <Flex
                    className="input-container"
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image className="brand-image" src={Logo} />
                    <ErrorMessage
                      message={errorMessage}
                      sx={{ marginBottom: 4, fontWeight: 400 }}
                    />
                    <RFFInputField
                      placeholder={t("username")}
                      label={t("username")}
                      type={"text"}
                      name={"username"}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                    />
                    <RFFInputField
                      placeholder={t("password")}
                      label={t("password")}
                      type={"password"}
                      name={"password"}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                    />
                    <Field name={"terms_and_condition"}>
                      {({ input, meta }) => {
                        return (
                          <Fragment>
                            <div className={"form-control"}>
                              <div>
                                <input
                                  type={"checkbox"}
                                  {...input}
                                  onChange={(e) => {
                                    input.onChange(e);
                                    setErrorMessage(null);
                                  }}
                                />
                                {t("we_hereby_confirm")}
                                <a
                                  href={"#!"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    history.push({
                                      pathname: "/terms-and-conditions/",
                                    })
                                  }
                                >
                                  {t("terms_and_condition")}
                                </a>
                                <small
                                  style={{
                                    display: "flex",
                                    color: "#ff3d3d",
                                    fontSize: "80%",
                                  }}
                                  className={"error"}
                                >
                                  {meta.touched &&
                                    (meta.error || meta.submitError)}
                                </small>
                              </div>
                            </div>
                          </Fragment>
                        );
                      }}
                    </Field>

                    <Box className="button-container">
                      <WithLoader isLoading={isLoading}>
                        <Button
                          sx={{
                            borderRadius: "10px",
                          }}
                          type="submit"
                          variant="secondary"
                          className="login-btn"
                        >
                          {t("login")}
                        </Button>
                      </WithLoader>
                    </Box>
                    <Flex className="link-block">
                      <span
                        className="link"
                        style={{ cursor: "pointer", marginTop: "0.5rem" }}
                        onClick={() =>
                          history.push({
                            pathname: "/forgot-password",
                          })
                        }
                      >
                        {t("forgot_password")}
                      </span>
                    </Flex>
                  </Flex>
                </Box>
              </Fragment>
            )}
          />
          <Flex
            sx={{
              margin: "2rem 4rem 1.5rem 3rem",
              flexDirection: "column",
              alignItems: "center",
              borderTop: "1px solid black",
              paddingTop: "1rem",
            }}
          >
            <Box
              sx={{
                width: "8em",
                height: "auto",
                padding: "0 0.7rem",
                margin: "1em 0 0.5em 1em",
              }}
            >
              <img
                src={TradeINLogo}
                width="100%"
                height="auto"
                alt="direct_buy"
              />
            </Box>
            <Link
              to="/home-trade-in"
              style={{
                backgroundColor: themes.colors.darkblue,
                textDecoration: "none",
                color: themes.colors.white,
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                fontSize: " 14px",
                width: "8rem",
                padding: "0.5rem",
                borderRadius: "1rem",
                marginTop: "2rem",
                "@media screen and (max-width: 325px)": {
                  fontSize: "8px",
                },
              }}
            >
              <IconRightLeft />
              {t("trade_in")}
            </Link>
          </Flex>
          <Box
            sx={{
              textAlign: "end",
              "@media screen and (max-width: 992px)": {
                textAlign: "center",
              },
              "@media screen and (max-height: 620px)": {
                marginTop: "40px",
              },
            }}
          >
            <img src={YukaDigital} width="200" />
          </Box>
        </LoginCard>
      </GridWrapper>
    </LoginContainer>
  );
};

export default Login;
