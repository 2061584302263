import React from "react";
import { Box, Flex } from "theme-ui";
import themes from "../themes";
import WithLoader from "./WithLoader";
import PropTypes from "prop-types";

const LoaderModal = ({ showState }) => {
  if (!showState) {
    return null;
  }
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: themes.colors.opaqueBlack,
        zIndex: 100,
        overflow: "hidden",
        alignItems: "center",
      }}
    >
      <Flex
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: themes.colors.transparent,
          borderRadius: "15px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: ["80%", "80%", "50%", "30%"],
          height: "20em",
          overflow: "hidden",
          zIndex: 100,
        }}
      >
        <WithLoader isLoading={showState} />
      </Flex>
    </Box>
  );
};

LoaderModal.propTypes = {
  showState: PropTypes.bool,
};

export default LoaderModal;
