import React, { useCallback, useMemo, useState } from "react";
import { Box, Flex, Button, Text } from "theme-ui";
import { Form } from "react-final-form";
import RFFInput from "../../form-fields/rff-fields/RFFInput";
import IconClose from "../../icons/icon-close";
import RFFDayPicker from "../../form-fields/rff-fields/RFFDayPicker";
import { useTranslation } from "react-i18next";
import { createProductValidate } from "../../../utils/validators";
import themes from "../../../themes";
import {
  generateInitialProductValues,
  validCurreny,
  tradeInMaxInputValue,
  directBuyMaxTolerance,
} from "../../../constants";
import {
  calculateMinDate,
  isDeliveryDateAfterWeekdays,
  getWeekdaysAfterDate,
  formatValues,
} from "../../../utils/helpers";
import DirectBuyCheckModal from "./DirectBuyCheckModal";
import PropTypes from "prop-types";
const ProductCreateModal = ({
  showState,
  setShowState,
  data,
  setData,
  randomArticleNo,
  setRandomArticleNo,
  cartProducts,
  setCartProducts,
}) => {
  const { t } = useTranslation();
  const [showDirectBuyCheckModal, setShowDirectBuyCheckModal] = useState(false);
  const [directBuyCheckModalData, setDirectBuyCheckModalData] = useState(null);
  const formInitialValues = useMemo(
    () => generateInitialProductValues(data, randomArticleNo),
    [data, randomArticleNo]
  );

  const updateCartProducts = useCallback(
    (existingProductIndex, products, formattedValues) => {
      const updatedProducts = [...products];
      if (existingProductIndex !== -1) {
        updatedProducts[existingProductIndex] = formattedValues;
      } else {
        updatedProducts.push(formattedValues);
      }
      return updatedProducts;
    },
    []
  );

  const handleShowDirectBuyCheckModal = useCallback(
    (
      formattedValues,
      cartProducts,
      setShowDirectBuyCheckModal,
      setShowState,
      setData,
      setRandomArticleNo
    ) => {
      if (
        (formattedValues?.direct_buy_price > 0 &&
          Number(formattedValues?.unit_price) >=
            Number(formattedValues?.direct_buy_price)) ||
        (formattedValues?.direct_buy_price > 0 &&
          !isDeliveryDateAfterWeekdays(
            formattedValues,
            getWeekdaysAfterDate()
          ) &&
          cartProducts)
      ) {
        setShowDirectBuyCheckModal(true);
      } else {
        setShowState((prev) => !prev);
        setData(null);
        setRandomArticleNo(null);
      }
    },
    [
      cartProducts,
      setShowDirectBuyCheckModal,
      setShowState,
      setData,
      setRandomArticleNo,
    ]
  );

  const onSubmit = useCallback(
    (values) => {
      const formattedValues = formatValues(values, formInitialValues);
      const existingProductIndex = cartProducts.findIndex(
        (product) => product.article_name === values.article_name
      );

      const updatedCartProducts = updateCartProducts(
        existingProductIndex,
        cartProducts,
        formattedValues
      );
      setCartProducts(updatedCartProducts);

      handleShowDirectBuyCheckModal(
        formattedValues,
        updatedCartProducts,
        setShowDirectBuyCheckModal,
        setShowState,
        setData,
        setRandomArticleNo
      );
    },
    [
      cartProducts,
      formInitialValues?.article_no,
      formInitialValues?.direct_buy_price,
      setCartProducts,
      setShowDirectBuyCheckModal,
      setShowState,
      setData,
      setRandomArticleNo,
    ]
  );

  const validate = useCallback(
    (values) =>
      createProductValidate(
        values,
        t("field_required"),
        formInitialValues?.new_product
      ),
    [formInitialValues?.new_product, t]
  );

  if (!showState) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: themes.colors.opaqueBlack,
        zIndex: 100,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: themes.colors.white,
          borderRadius: "4px",
          width: ["90%", "80%", "60%", "40%"],
          height: data ? "70%" : "80%",
          overflow: "auto",
          zIndex: 100,
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "70px",
            backgroundColor: themes.colors.snowWhite,
            borderBottom: `1px solid ${themes.colors.darkGray}`,
            padding: "20px",
          }}
        >
          <Text sx={{ fontWeight: "bold", fontSize: 18 }}>
            {data ? t("edit_product") : t("add_product")}
          </Text>
          <Box
            sx={{
              padding: 0,
              margin: 0,
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
            onClick={() => setShowState(false)}
          >
            <IconClose width="100%" height="100%" />
          </Box>
        </Flex>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "20px",
                height: "calc( 100% - 70px )",
              }}
              onSubmit={handleSubmit}
            >
              <Flex
                sx={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                }}
              >
                <RFFInput
                  type={"text"}
                  name={"article_name"}
                  label={t("product_description")}
                  placeholder={
                    data
                      ? formInitialValues?.article_name
                      : t("product_description")
                  }
                  defaultValue={formInitialValues?.article_name}
                  disabled={formInitialValues?.article_name}
                  maxLength={formInitialValues?.max_char_limit}
                />
                <RFFInput
                  type={"number"}
                  name={"unit_price"}
                  label={t("unit_price")}
                  placeholder={
                    data ? formInitialValues?.unit_price : t("unit_price")
                  }
                  directBuyPrice={
                    data &&
                    formInitialValues?.is_direct_buy &&
                    formInitialValues?.direct_buy_price
                  }
                  max={
                    data && formInitialValues?.is_direct_buy
                      ? formInitialValues?.direct_buy_price +
                        directBuyMaxTolerance
                      : tradeInMaxInputValue
                  }
                  defaultValue={formInitialValues?.unit_price}
                  currency={validCurreny}
                  min={formInitialValues?.min_unit_price}
                />
                <RFFInput
                  type={"number"}
                  name={"min_order_quantity"}
                  label={t("minimum_order_quantity")}
                  placeholder={
                    data
                      ? formInitialValues?.min_order_quantity
                      : t("minimum_order_quantity")
                  }
                  defaultValue={formInitialValues?.min_order_quantity}
                  max={formInitialValues?.quantity_set}
                  toolTipMessage={`${t(
                    "trade_in_min_order_quantity_input_max_value_error_message"
                  )}  ${formInitialValues?.quantity_set}`}
                />
                {(values?.min_order_quantity ===
                  formInitialValues?.quantity_set ||
                  formInitialValues?.take_all_quantity > 0 ||
                  formInitialValues?.take_all_unit_price > 0) && (
                  <>
                    <RFFInput
                      type={"number"}
                      name={"take_all_quantity"}
                      label={t("take_all_quantity")}
                      min={
                        values?.take_all_quantity !== 0 &&
                        formInitialValues?.quantity_set + 1
                      }
                      max={tradeInMaxInputValue}
                      placeholder={
                        data
                          ? formInitialValues?.take_all_quantity
                          : t("take_all_quantity")
                      }
                      defaultValue={formInitialValues?.take_all_quantity}
                    />
                    <RFFInput
                      type={"number"}
                      name={"take_all_unit_price"}
                      label={t("take_all_unit_price")}
                      max={tradeInMaxInputValue}
                      placeholder={
                        data
                          ? formInitialValues?.take_all_unit_price
                          : t("take_all_unit_price")
                      }
                      defaultValue={formInitialValues?.take_all_unit_price}
                      min={formInitialValues?.min_quantity_product}
                    />
                  </>
                )}
                <RFFDayPicker
                  name={"delivery_date"}
                  label={t("delivery_date")}
                  minimumDate={calculateMinDate()}
                  defaultValue={
                    formInitialValues?.delivery_date || calculateMinDate()
                  }
                  isDirectBuy={formInitialValues?.is_direct_buy}
                />

                {formInitialValues?.new_product && (
                  <RFFInput
                    type={"text"}
                    name={"european_article_number"}
                    label={t("european_article_number")}
                    placeholder={
                      data
                        ? formInitialValues?.european_article_number
                        : t("european_article_number")
                    }
                    defaultValue={formInitialValues?.european_article_number}
                    min={formInitialValues?.min_article_limit}
                    maxLength={formInitialValues?.max_ean_limit}
                  />
                )}
              </Flex>
              <Flex
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: ["10px", "30px", "20px", "30px"],
                  padding: "20px 0px",
                  alignSelf: "flex-end",
                  width: ["100%", "100%", "70%", "70%"],
                }}
              >
                <Button
                  sx={{
                    width: "50%",
                    height: "90%",
                    borderRadius: "4px",
                    backgroundColor: themes.colors.grey,
                    color: themes.colors.black,
                    textTransform: "capitalize",
                    "&:hover": {
                      color: themes.colors.white,
                    },
                  }}
                  type="button"
                  onClick={() => setShowState(false)}
                >
                  {t("cancel")}
                </Button>
                <Button
                  sx={{
                    width: "50%",
                    height: "90%",
                    borderRadius: "4px",
                    backgroundColor: themes.colors.primary,
                    color: themes.colors.white,
                    textTransform: "capitalize",
                    "&:hover": {
                      color: themes.colors.white,
                    },
                  }}
                  type="submit"
                  disabled={
                    values?.take_all_quantity && !values?.take_all_unit_price
                  }
                >
                  {data ? t("edit_product") : t("create")}
                </Button>
              </Flex>
            </form>
          )}
        />
      </Box>
      <DirectBuyCheckModal
        showState={showDirectBuyCheckModal}
        setShowState={setShowDirectBuyCheckModal}
        data={directBuyCheckModalData}
        setData={setDirectBuyCheckModalData}
        cartProducts={cartProducts}
        setCartProducts={setCartProducts}
        setShowProductModal={setShowState}
      />
    </Box>
  );
};
ProductCreateModal.propTypes = {
  showState: PropTypes.bool,
  setShowState: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
  randomArticleNo: PropTypes.string,
  setRandomArticleNo: PropTypes.func,
  cartProducts: PropTypes.object,
  setCartProducts: PropTypes.func,
};
export default ProductCreateModal;
