import React from "react";
import { Box, Heading } from "theme-ui";
import { TermsAndConditionContainer } from "../DataProtection";

export default function index() {
  return (
    <TermsAndConditionContainer>
      <Box className="page-header">
        <Heading className="heading">Yukatel GmbH</Heading>
      </Box>

      <Box>
        <p>Yuka-Platz 1, 63303 Dreieich</p>
        <p className="m-0">Tel: +49 (0) 69 83 83 250</p>
        <p>Fax: +49 (0) 69 83 83 25 252 </p>
        <p>
          <a title="" href="mailto:info@yukatel.de">
            info@yukatel.de
          </a>
        </p>
        <br />
      </Box>

      <Box>
        <p>
          CEO: Yusuf Karatas, Mehmet Karatas
          <br />
          <br />
          Register court: Amtsgericht Dreieich
          <br />
          <br />
          Register number: HRB 12305
          <br />
          <br />
          Sales tax identification number according to § 27 a sales tax law: VAT
          ID: DE 813392743
          <br />
          <br />
          <p className="text-primary">Disclaimer Links:</p>
          Important note on all links on this homepage: With judgment of May
          12th 1998 – 312 O 85/98 – “Liability for links” the district court
          (LG) in Hamburg decided that by placing a link the contents of the
          linked pages can be viewed Side may have to answer for. According to
          the LG, this can only be prevented by expressly distancing yourself
          from this content.
          <br />
          <br />
          <p className="text-primary">Disclaimer Information:</p>
          The information provided on this website has been carefully checked
          and is updated regularly. However, no guarantee can be given that all
          information is complete, always correct and up to date. All
          information can be changed, removed or supplemented without prior
          notice. Herewith, the YUKATEL GmbH with its route control brand
          expressly distances itself from all contents of all linked pages in
          the online offer of YUKATEL GmbH.
          <br />
          <br />
          References to some images: Fotolia und Photocase
        </p>
      </Box>
    </TermsAndConditionContainer>
  );
}
