import React from "react";

import styled from "@emotion/styled";
import { Box, Heading } from "theme-ui";

import { useTranslation } from "react-i18next";
import * as QueryString from "qs";
import { useHistory } from "react-router-dom";
import { useCartActions } from "../contexts/CartContext";
import WithLoader from "./WithLoader";
import Container from "./Container";
import ProductCard from "./ProductCard";

const SectionHeading = styled(Heading)`
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 2rem;
  text-align: center;
`;

const ProductGridContainer = styled(Box)`
  //display: grid;
  //grid-gap: 2rem;
  //grid-template-columns: repeat(5, 1fr);
  //margin-bottom: 5rem;
  //display: grid;
  display: grid;
  grid-template-columns: repeat(${(props) => props.fr * 3}, 1fr);
  gap: 2rem;
  max-width: 1500px;
  margin: 10px auto;

  .product-card-container {
    grid-column: span 3;

    ${(props) => props.theme.customBreakpoints[3]} {
      grid-column: span 4;
    }
    ${(props) => props.theme.customBreakpoints[1]} {
      grid-column: span 6;
    }
    ${(props) => props.theme.customBreakpoints[0]} {
      grid-column: span 12;
    }
  }
`;
const CrossSellList = ({ items, isLoading }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const onClickTitle = (id, title) => {
    if (id && title) {
      history.push({
        pathname: "/shop",
        search: "?" + QueryString.stringify({ article_no: id, title }),
      });
    }
  };

  const { addItem, updateItem } = useCartActions();

  return (
    <Container>
      {items?.length > 0 && (
        <>
          <SectionHeading>{t("csellHeading")}</SectionHeading>
        </>
      )}

      <WithLoader isLoading={isLoading} height={"400px"}>
        <ProductGridContainer fr={items.length}>
          {items?.map((data, index) => (
            <ProductCard
              key={index}
              data={data}
              onAddToCart={addItem}
              onUpdateToCart={updateItem}
              onClickTitle={onClickTitle}
              csell={true}
            />
          ))}
        </ProductGridContainer>
      </WithLoader>
    </Container>
  );
};

export default CrossSellList;
