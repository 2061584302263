import React, { Fragment } from "react";
import { Box, Button, Flex, Text } from "theme-ui";
import CartItem from "../../../components/CartItem";
import styled from "@emotion/styled";
import { useCartActions, useCartState } from "../../../contexts/CartContext";
import ShippingAddressSelect from "./ShippingAddressSelect";
import Money from "../../../components/Money";
import { useTranslation } from "react-i18next";
import { getFormatedYukaPoints } from "../../../utils/helpers";
import WithLoader from "../../../components/WithLoader";

import Logo from "../../../assets/images/YP.png";

const LabelValueList = styled(Flex)`
  margin-bottom: 0.5rem;
  align-items: center;

  .label {
    flex: 1 1 50%;
  }

  .value {
    flex: 1 1 50%;
    text-align: right;
  }
`;
const BoxFooterItem = styled.div`
  text-align: center;
  .title {
    font-weight: bold;
  }
`;

const BoxFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem 0;
`;

const Image = styled.img`
  height: 0.8rem;
  width: auto;
`;

const Cart = ({ onConfirmClick, onOrderEditMode, isUpdatingOrder, errors }) => {
  const { t } = useTranslation();
  const {
    items,
    total_amount,
    total_reward_points,
    shipping_address_id,
    total_devices,
  } = useCartState();

  const { updateItem, removeItem, setShippingAddressId } = useCartActions();

  if (items.length === 0)
    return (
      <Box sx={{ paddingTop: "1rem" }}>
        {t("you_currently_dont_have_any_orders_start_shopping_now")}
      </Box>
    );
  return (
    <>
      <Box className="cart-list-container">
        {items?.map(
          (
            {
              article_no,
              ean,
              description,
              selling_price,
              quantity,
              max_quantity,
              reward_points,
            },
            key
          ) => {
            return (
              <Fragment key={key}>
                <CartItem
                  keyForData={article_no}
                  article_no={article_no}
                  ean={ean}
                  description={description}
                  selling_price={selling_price}
                  quantity={Number(quantity)}
                  max_quantity={max_quantity}
                  onChange={(quantity) => {
                    updateItem(article_no, {
                      article_no,
                      description,
                      selling_price,
                      reward_points,
                      quantity,
                      max_quantity,
                      total_amount: quantity * selling_price,
                      total_reward_points: quantity * reward_points,
                    });
                  }}
                  onRemove={() => {
                    removeItem(article_no);
                  }}
                  error={errors.find((item) => item.artnr === article_no)}
                  ind={key}
                />
              </Fragment>
            );
          }
        )}
      </Box>

      <div className="footer">
        <BoxFooter>
          <BoxFooterItem>
            <h5 className="title">{t("cart_total_devices")}</h5>
            {total_devices} pcs.
          </BoxFooterItem>
          <BoxFooterItem>
            <h5 className="title"> {t("total_before_taxes")} </h5>
            <Money amount={total_amount} />
          </BoxFooterItem>
          <BoxFooterItem>
            <h5 className="title">{t("accumulated_yuka_points")} </h5>
            {getFormatedYukaPoints(total_reward_points)} <Image src={Logo} />
          </BoxFooterItem>
        </BoxFooter>

        <Box className="confirm-order-container">
          <LabelValueList>
            <Box
              className="value"
              sx={{
                marginRight: "1rem",
              }}
            >
              <ShippingAddressSelect
                value={shipping_address_id}
                hasDefaultOption={false}
                onChange={(value) => {
                  setShippingAddressId(value);
                }}
              />
            </Box>
            <WithLoader isLoading={isUpdatingOrder}>
              <Button
                variant="secondary"
                className="btn"
                onClick={onConfirmClick}
              >
                {onOrderEditMode ? t("update_order") : t("confirm_order")}
              </Button>
            </WithLoader>
          </LabelValueList>
        </Box>
        <Text sx={{ fontStyle: "italic", fontSize: "0.75rem" }}>
          *{t("amount_may_be_subjected_to_insurance_shipping_charge_and_taxes")}{" "}
          {t("cart_item_will_remain_up_to_8_hour")} {t("cart_item_temporary")}
        </Text>
      </div>
    </>
  );
};

export default Cart;
