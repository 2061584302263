import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../translations/en.json";
import de from "../translations/de.json";
import es from "../translations/es.json";
import it from "../translations/it.json";
import fr from "../translations/fr.json";
import nl from "../translations/nl.json";
import tr from "../translations/tr.json";
import pl from "../translations/pl.json";
import hr from "../translations/hr.json";
import ro from "../translations/ro.json";
import el from "../translations/el.json";
import sv from "../translations/sv.json";
import hu from "../translations/hu.json";
import cs from "../translations/cs.json";

import EnFlag from "../components/icons/icon-enFlag";
import DeFlag from "../components/icons/icon-deFlag";
import EsFlag from "../components/icons/icon-esFlag";
import ItFlag from "../components/icons/icon-itFlag";
import FrFlag from "../components/icons/icon-frFlag";
import NlFlag from "../components/icons/icon-nlFlag";
import TrFlag from "../components/icons/icon-trFlag";
import PlFlag from "../components/icons/icon-plFlag";
import HrFlag from "../components/icons/icon-hrFlag";
import RoFlag from "../components/icons/icon-roFlag";
import ElFlag from "../components/icons/icon-elFlag";
import svFlag from "../components/icons/icon-svFlag";
import huFlag from "../components/icons/icon-huFlag";
import csFlag from "../components/icons/icon-csFlag";

import { getItem, setItem } from "./storage";

const languages = {
  en: "🇬🇧 English",
  de: "🇩🇪 Deutsch",
  es: "🇪🇸 Español",
  it: "🇮🇹 Italiano",
  fr: "🇫🇷 Français",
  nl: "🇳🇱 Netderlands",
  tr: "🇹🇷 Türkçe",
  pl: "🇵🇱 Polski",
  hr: "🇭🇷 Hrvatski",
  ro: "🇷🇴 Română",
  el: "🇬🇷 Ελληνικά",
  sv: "🇸🇪 Svenska",
  hu: "🇭🇺 Magyar",
  cs: "🇨🇿 Čeština",
};

const languagesFlag = {
  en: EnFlag,
  de: DeFlag,
  es: EsFlag,
  it: ItFlag,
  fr: FrFlag,
  nl: NlFlag,
  tr: TrFlag,
  pl: PlFlag,
  hr: HrFlag,
  ro: RoFlag,
  el: ElFlag,
  sv: svFlag,
  hu: huFlag,
  cs: csFlag,
};

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  it: {
    translation: it,
  },
  fr: {
    translation: fr,
  },
  nl: {
    translation: nl,
  },
  tr: {
    translation: tr,
  },
  pl: {
    translation: pl,
  },
  hr: {
    translation: hr,
  },
  ro: {
    translation: ro,
  },
  el: {
    translation: el,
  },
  sv: {
    translation: sv,
  },
  hu: {
    translation: hu,
  },
  cs: {
    translation: cs,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getItem("language") || "de",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export const changeLanguage = (language) => {
  setItem("language", language);
  i18n.changeLanguage(language);
};

export { languages, languagesFlag };
export default i18n;
