import React, { useCallback, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Form, Field } from "react-final-form";
import RFFInputField from "../../unauthenticated/Login/RFFInputField";
import { Box, Button, Flex, Heading, Grid } from "theme-ui";
import Container from "../../../components/Container";
import {
  createRma,
  getInvoiceBySerial,
  getInvoiceByInvoice,
  getRmaDocumentPublic,
  checkSerial,
  getCustomerProfile,
} from "../../../apis";
import exceptionHandler from "../../../apis/exceptionHandler";
import {
  isRequired,
  isRequiredArray,
  isEmail,
} from "../../../utils/validators";
import WithLoader from "../../../components/WithLoader";
import { useModal } from "../../../contexts/ModalContext";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../../components/ErrorMessage";
import RFFSelectField from "./RFFSelectField";
import Input from "../../../components/form-fields/Input";
import { useToast } from "../../../contexts/AlertProvider";
import { useHistory } from "react-router-dom";
import FormControl from "../../../components/FormControl";
import { RFFCheckBoxField } from "./RFFCheckBoxField";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useQuery } from "react-query";
import { checkArrayHasDuplicatedElementByProperty } from "../../../utils/helpers";

const RmaSearchContainer = styled(Box)`
  margin: 3rem 0;

  .page-header {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }
    .svg-icon {
      margin-right: 0.5rem;
      height: 1.75rem;
      width: 1.75rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  .container {
    max-width: 40rem;
  }

  .form-control {
    display: flex;
    align-items: flex-start;
    flex-wrap: no-wrap;
    width: 100%;

    label {
      flex: 0 0 15rem;
      margin: 0.75rem 0;
    }

    // for input width
    > * {
      flex: 1 1 auto;

      input {
        background-color: ${(props) => props.theme.colors.lightGray};
      }
    }

    .input {
      padding: 0.75rem 1.25rem;
    }

    &.has-error {
      margin-bottom: 2.5rem;
    }

    ${(props) => props.theme.customBreakpoints[2]} {
      flex-wrap: wrap;
      label {
        width: 100%;
        flex: 1 1 auto;
      }
    }
  }
`;

const RmaInputContainer = styled(Box)`
  margin: 3rem 0;

  .page-header {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }
    .svg-icon {
      margin-right: 0.5rem;
      height: 1.75rem;
      width: 1.75rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  .container {
    max-width: 40rem;
  }

  .form-control {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    label {
      flex: 0 0 15rem;
      margin: 0.75rem 0;
    }

    // for input width
    > * {
      flex: 1 1 auto;

      input {
        background-color: ${(props) => props.theme.colors.lightGray};
      }
    }

    .input {
      padding: 0.75rem 1.25rem;
    }

    &.has-error {
      margin-bottom: 2.5rem;
    }

    ${(props) => props.theme.customBreakpoints[2]} {
      flex-wrap: wrap;
      label {
        width: 100%;
        flex: 1 1 auto;
      }
    }
  }
`;

const ConfirmationModal = styled(Box)`
  text-align: center;
  max-width: 24rem;

  .modal-title {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;

    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      height: 1.5rem;
      width: 1.5rem;
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 0.5rem;
    }
  }

  .modal-body {
    margin-bottom: 2rem;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .modal-footer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    button {
      min-width: 10rem;
      margin: 0 1rem;
    }
  }
`;

const ModalContent = ({ data, onArticleSelect, hideModal, form }) => {
  const { t } = useTranslation();
  return (
    <Box style={{ width: "500px" }}>
      <table
        className={"table"}
        cellPadding={5}
        cellSpacing={5}
        style={{ width: "100%", textAlign: "center" }}
      >
        <thead
          className={"table-head"}
          style={{
            backgroundColor: "#053668",
            color: "white",
            fontWeight: "bold",
          }}
        >
          <tr>
            <th>{t("article_no")}</th>
            <th>{t("product")}</th>
            <th>{t("action")}</th>
          </tr>
        </thead>
        <tbody className={"table-body"}>
          {data.map(({ article_no, description }, key) => (
            <tr key={key}>
              <td style={{ padding: "10px" }}>{article_no}</td>
              <td style={{ padding: "10px" }}>{description}</td>
              <td style={{ padding: "10px" }}>
                <Button
                  variant={"primary"}
                  onClick={() =>
                    onArticleSelect(article_no, data, hideModal, form)
                  }
                  sx={{ padding: "5px", borderRadius: "2px" }}
                >
                  {t("choose")}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

const CheckboxGroup = ({ fields, disabled = false }) => {
  const { t } = useTranslation();
  const contentGrids = [
    [
      { name: t("device"), value: "Device" },
      { name: t("headphone"), value: "Headphones" },
      { name: t("charging_cable"), value: "Charging Cable" },
      { name: t("charging_plug"), value: "Charging Plug" },
    ],
    [
      { name: t("instructions_manual"), value: "Instructions/Manual" },
      { name: t("battery"), value: "Battery" },
      { name: t("case_sleeve"), value: "Case Sleeve" },
      { name: t("original_box"), value: "Original box/packaging" },
    ],
  ];
  const toggle = (event, option) => {
    if (event.target.checked) {
      fields.push(option.value);
    } else {
      fields.remove(fields.value.findIndex((opt) => opt == option.value));
    }
  };
  return (
    <>
      {contentGrids.map((grid, ind) => (
        <Grid key={`option-grid${ind}`} columns={4}>
          {grid.map((option) => (
            <div key={option.value}>
              <input
                disabled={disabled}
                type={"checkbox"}
                onClick={(event) => toggle(event, option)}
              />
              {option.name}
            </div>
          ))}
        </Grid>
      ))}
    </>
  );
};

const CreateRma = () => {
  const { t } = useTranslation();
  const { authenticated: isAuthenticated } = useAuthContext();

  const { data: profile, isLoading: isLoadingProfile } = useQuery(
    "profile",
    getCustomerProfile,
    { enabled: isAuthenticated }
  );
  const profileData = profile?.data || {};

  useEffect(() => {
    setCustomerNumberSerial(profileData?.customer_number);
    setCustomerNumberInvoice(profileData?.customer_number);
  }, [profileData]);

  const { showModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingByInvoice, setIsLoadingByInvoice] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [createErrorMessage, setCreateErrorMessage] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [chosenArticle, setChosenArticle] = useState(null);
  const [serial, setSerial] = useState(chosenArticle?.serial);
  const [articleNumber, setArticleNumber] = useState(chosenArticle?.article_no);
  const [product, setProduct] = useState(chosenArticle?.description);
  const [searchType, setSearchType] = useState(null);
  const [customerNumberSerial, setCustomerNumberSerial] = useState(null);

  const [customerNumberInvoice, setCustomerNumberInvoice] = useState(null);

  const [rmaType, setRmaType] = useState(null);
  let history = useHistory();

  const onSearchBySerial = useCallback(async (values) => {
    try {
      setIsLoading(true);
      setInvoice(null);
      const response = await getInvoiceBySerial(values);
      setInvoice(response.data.data);
      setCustomerAddress(response.data.data.customer_address);
      setErrorMessage(null);
      setSearchType("serial");
    } catch (e) {
      setInvoice(null);
      return exceptionHandler(
        e,
        (error) => {
          setErrorMessage(error);
        },
        (errors) => {
          return errors;
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onSearchByInvoice = useCallback(async (values) => {
    try {
      setIsLoadingByInvoice(true);
      setInvoice(null);
      const response = await getInvoiceByInvoice(values);
      setInvoice(response.data.data);
      setErrorMessage(null);
      setSearchType("invoice");
    } catch (e) {
      setInvoice(null);
      return exceptionHandler(
        e,
        (error) => {
          setErrorMessage(error);
        },
        (errors) => {
          return errors;
        }
      );
    } finally {
      setIsLoadingByInvoice(false);
    }
  }, []);

  const PrintConfirmation = ({ response }) => {
    const [documentPrinted, setDocumentPrinted] = useState(false);
    const [isFetchingDocument, setIsFetchingDocument] = useState(false);
    const toast = useToast();

    const onPrintRma = async (id, type) => {
      await getRmaDocumentPublic(id, type)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/pdf",
          });
          const objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl, "_blank");
        })
        .catch((e) => toast(t("document_not_available"), { type: "error" }))
        .finally(() => {
          setIsFetchingDocument(false);
        });
    };
    return (
      <ConfirmationModal>
        <Heading as="h2" sx={{ marginBottom: "1.5rem", fontSize: "1.25rem" }}>
          {t("rma_successfully_created")}
        </Heading>
        <Flex className="modal-body">
          <Box p={1} columns={1}>
            <p>
              <span style={{ fontWeight: "bolder" }}>
                {t("your_rma_id")}
                {response.data.rma_id}
              </span>
            </p>
          </Box>
          <Box p={1} columns={1}>
            <p>{t("rma_print_message")}</p>
          </Box>
        </Flex>
        <Flex className="modal-footer">
          <WithLoader isLoading={isFetchingDocument}>
            <Button
              sx={{ lineHeight: "1rem", marginBottom: "0.5rem" }}
              variant="secondary"
              className="details-btn"
              onClick={() => {
                setDocumentPrinted(true);
                setIsFetchingDocument(true);
                onPrintRma(response.data.id, "document");
                // setIsFetchingDocument(false);
              }}
            >
              {t("receipt")}
            </Button>
          </WithLoader>
          <Button
            sx={{ lineHeight: "1rem", marginBottom: "0.5rem", height: "3rem" }}
            variant="secondary"
            className="details-btn"
            disabled={!documentPrinted}
            onClick={() => createNewRmaReload()}
          >
            {t("create_new_rma")}
          </Button>
        </Flex>
      </ConfirmationModal>
    );
  };

  const onCreateRma = async (values) => {
    try {
      values["invoice_number"] = invoice.invoice_id;
      values["invoice_date"] = invoice.invoice_date;
      values["customer_number"] = invoice.customer_id;
      values["contents"] = values["package_contents"];
      values["rma_type"] = rmaType;

      setIsCreating(true);
      if (chosenArticle.serial_number_item) {
        await checkSerial({
          serial: values.serial,
          invoice_number: invoice.invoice_id,
          article_no: values.article_no,
        });
      }
      const response = await createRma(values);
      await showModal(
        () => {
          return <PrintConfirmation response={response} />;
        },
        () => {
          createNewRmaReload();
        }
      );
    } catch (e) {
      return exceptionHandler(
        e,
        (error) => {
          setCreateErrorMessage(error);
        },
        (errors) => {
          return errors;
        }
      );
    } finally {
      setIsCreating(false);
    }
  };

  const onChooseProduct = useCallback(
    (data, form) => {
      showModal(({ hideModal }) => (
        <ModalContent
          data={data}
          onArticleSelect={onArticleSelect}
          hideModal={hideModal}
          form={form}
        />
      ));
    },
    [showModal]
  );

  const onArticleSelect = (article_no, articles, hideModal, form) => {
    const article = articles.find((item) => item.article_no === article_no);
    setChosenArticle(article);
    setSerial(article?.serial);
    setProduct(article?.description);
    setArticleNumber(article?.article_no);
    if (article) {
      form.mutators.setArticleDetails(article);
    }
    hideModal();
  };

  const handleSerialChange = (event) => {
    setSerial(event.target.value);
  };

  const handleArticleChange = (event) => {
    setArticleNumber(event.target.value);
  };

  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };

  const handleRmaTypeChange = (event) => {
    setRmaType(event.target.value);
  };

  const createNewRmaReload = () => {
    history.go(0);
  };

  const onChangeCustomerNumberSerial = (event) => {
    setCustomerNumberSerial(event.target.value);
  };

  const onChangeCustomerNumberInvoice = (event) => {
    setCustomerNumberInvoice(event.target.value);
  };

  const formInitialValues = isAuthenticated
    ? { customer_number: customerNumberSerial }
    : null;

  return (
    <WithLoader isLoading={isLoadingProfile}>
      <Container>
        <Grid width={[380, null, 380]}>
          <RmaSearchContainer className="change-password-container">
            <Container className="container">
              <Box className="page-header">
                <Heading className="heading">{t("search_by_serial")}</Heading>
              </Box>
              <Form
                initialValues={formInitialValues}
                onSubmit={onSearchBySerial}
                validate={({ customer_number, serial }) => ({
                  customer_number: isRequired(customer_number),
                  serial: isRequired(serial),
                })}
                render={({ handleSubmit, submitting }) => (
                  <Box
                    as="form"
                    className="form-container"
                    onSubmit={handleSubmit}
                  >
                    <ErrorMessage
                      message={errorMessage}
                      sx={{ marginBottom: 4, fontWeight: 400, width: "100%" }}
                    />
                    <Field name={"customer_number"}>
                      {({ input, meta }) => {
                        const { value, ...restInput } = input;
                        return (
                          <FormControl className="form-control">
                            <label>{t("customer_number")}</label>
                            <Input
                              label={t("customer_number")}
                              type={"text"}
                              error={
                                meta.touched && (meta.error || meta.submitError)
                              }
                              {...restInput}
                              onChange={(e) => {
                                input.onChange(e);
                                onChangeCustomerNumberSerial(e);
                                setErrorMessage(null);
                              }}
                              value={value}
                              disabled={Boolean(invoice)}
                            />
                          </FormControl>
                        );
                      }}
                    </Field>

                    <RFFInputField
                      placeholder={t("serial")}
                      label={t("serial")}
                      type={"text"}
                      name={"serial"}
                      disabled={Boolean(invoice)}
                      onChange={() => null}
                    />
                    <Flex
                      sx={{ justifyContent: "flex-end", marginTop: "2.5rem" }}
                    >
                      <WithLoader isLoading={isLoading}>
                        <Button
                          variant={"secondary"}
                          type={"submit"}
                          sx={{ minWidth: "10rem" }}
                          disabled={Boolean(invoice) || submitting}
                        >
                          {t("search")}
                        </Button>
                      </WithLoader>
                    </Flex>
                  </Box>
                )}
              />
            </Container>
          </RmaSearchContainer>
          <RmaSearchContainer className="change-password-container">
            <Container className="container">
              <Box className="page-header">
                <Heading className="heading">{t("search_by_invoice")}</Heading>
              </Box>
              <Form
                initialValues={formInitialValues}
                onSubmit={onSearchByInvoice}
                validate={({
                  customer_number,
                  invoice_number,
                  invoice_date,
                }) => ({
                  customer_number: isRequired(customer_number),
                  invoice_number: isRequired(invoice_number),
                  invoice_date: isRequired(invoice_date),
                })}
                render={({ handleSubmit, submitting }) => (
                  <Box
                    as="form"
                    className="form-container"
                    onSubmit={handleSubmit}
                  >
                    <ErrorMessage
                      message={errorMessage}
                      sx={{ marginBottom: 4, fontWeight: 400, width: "100%" }}
                    />
                    <Field name={"customer_number"}>
                      {({ input, meta }) => {
                        const { value, ...restInput } = input;
                        return (
                          <FormControl className="form-control">
                            <label>{t("customer_number")}</label>
                            <Input
                              label={t("customer_number")}
                              type={"text"}
                              error={
                                meta.touched && (meta.error || meta.submitError)
                              }
                              {...restInput}
                              onChange={(e) => {
                                input.onChange(e);
                                onChangeCustomerNumberInvoice(e);
                                setErrorMessage(null);
                              }}
                              value={value}
                              disabled={Boolean(invoice)}
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <RFFInputField
                      placeholder={t("invoice_number")}
                      label={t("invoice_number")}
                      type={"text"}
                      name={"invoice_number"}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                      disabled={Boolean(invoice)}
                    />
                    <RFFInputField
                      placeholder={t("invoice_date")}
                      label={t("invoice_date")}
                      type={"date"}
                      name={"invoice_date"}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                      disabled={Boolean(invoice)}
                    />
                    <Flex
                      sx={{ justifyContent: "flex-end", marginTop: "2.5rem" }}
                    >
                      <WithLoader isLoading={isLoadingByInvoice}>
                        <Button
                          variant={"secondary"}
                          type={"submit"}
                          sx={{ minWidth: "10rem" }}
                          disabled={Boolean(invoice) || submitting}
                        >
                          {t("search")}
                        </Button>
                      </WithLoader>
                    </Flex>
                  </Box>
                )}
              />
            </Container>
          </RmaSearchContainer>
        </Grid>
      </Container>
      {invoice && (
        <Container sx={{ marginBottom: "20px;" }}>
          <ErrorMessage
            message={createErrorMessage}
            sx={{ marginBottom: 4, fontWeight: 400, width: "100%" }}
          />
          <Form
            onSubmit={onCreateRma}
            mutators={{
              setArticleDetails: (args, state, utils) => {
                const toChange = args.length ? args[0] : null;
                if (toChange) {
                  utils.changeValue(
                    state,
                    "article_no",
                    () => toChange.article_no
                  );
                  utils.changeValue(state, "serial", () => toChange.serial);
                  utils.changeValue(
                    state,
                    "product_title",
                    () => toChange.description
                  );
                }
              },
              ...arrayMutators,
            }}
            validate={({
              phone,
              email,
              rma_reason,
              return_address,
              package_contents,
              serial,
              product_title,
              article_no,
              confirm_language,
              confirm_not_locked,
              confirm_not_damaged,
            }) => ({
              serial: isRequired(serial),
              product_title: isRequired(product_title),
              article_no: isRequired(article_no),
              rma_type: isRequired(rmaType),
              phone: isRequired(phone),
              email: isEmail(email),
              rma_reason: isRequired(rma_reason),
              return_address: isRequired(return_address),
              package_contents: isRequiredArray(package_contents),
              confirm_language: isRequired(confirm_language),
              confirm_not_locked: isRequired(confirm_not_locked),
              confirm_not_damaged: isRequired(confirm_not_damaged),
            })}
            render={({ form, handleSubmit, submitting }) => (
              <Box as="form" onSubmit={handleSubmit}>
                <RmaInputContainer>
                  <Box className="page-header">
                    <Heading className="heading">{t("type_of_rma")}</Heading>
                  </Box>
                  <Box className="form-container">
                    <Grid columns={2} gap={0}>
                      <Box>
                        <Field name={"rma_type"}>
                          {({ input, meta }) => {
                            // eslint-disable-next-line no-unused-vars
                            const { value, ...restInput } = input;
                            return (
                              <FormControl className="form-control">
                                <label>{t("malfunction")}</label>
                                <input
                                  type="radio"
                                  error={
                                    meta.touched &&
                                    (meta.error || meta.submitError)
                                  }
                                  value={1}
                                  {...restInput}
                                  onChange={(e) => {
                                    input.onChange(e);
                                    handleRmaTypeChange(e);
                                  }}
                                />
                                <small
                                  style={{
                                    display: "flex",
                                    color: "#ff3d3d",
                                    fontSize: "80%",
                                  }}
                                  className={"error"}
                                >
                                  {meta.touched &&
                                    (meta.error || meta.submitError)}
                                </small>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <Field name={"rma_type"}>
                          {({ input, meta }) => {
                            // eslint-disable-next-line no-unused-vars
                            const { value, ...restInput } = input;
                            return (
                              <FormControl className="form-control">
                                <label>{t("doa")}</label>
                                <input
                                  type="radio"
                                  error={
                                    meta.touched &&
                                    (meta.error || meta.submitError)
                                  }
                                  value={2}
                                  {...restInput}
                                  onChange={(e) => {
                                    input.onChange(e);
                                    handleRmaTypeChange(e);
                                  }}
                                />
                                <small
                                  style={{
                                    display: "flex",
                                    color: "#ff3d3d",
                                    fontSize: "80%",
                                  }}
                                  className={"error"}
                                >
                                  {meta.touched &&
                                    (meta.error || meta.submitError)}
                                </small>
                              </FormControl>
                            );
                          }}
                        </Field>
                      </Box>
                      <Box>
                        {!invoice?.valid_for_doa && Number(rmaType) === 2 ? (
                          <span style={{ color: "red" }}>{t("doa_error")}</span>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                  </Box>
                </RmaInputContainer>
                <RmaInputContainer>
                  <Box className="page-header">
                    <Heading className="heading">
                      {t("contact_details")}
                    </Heading>
                  </Box>
                  <Box className="form-container">
                    <Grid columns={2} gap={1}>
                      <RFFInputField
                        placeholder={t("email")}
                        label={t("email")}
                        type={"text"}
                        name={"email"}
                        onChange={(e) => setErrorMessage(null)}
                        disabled={
                          !invoice?.valid_for_doa && Number(rmaType) === 2
                        }
                      />
                      <RFFInputField
                        placeholder={t("phone")}
                        label={t("phone")}
                        type={"text"}
                        name={"phone"}
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                        disabled={
                          !invoice?.valid_for_doa && Number(rmaType) === 2
                        }
                      />
                      <RFFInputField
                        placeholder={t("reference")}
                        label={t("reference")}
                        type={"text"}
                        name={"reference"}
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                        disabled={
                          !invoice?.valid_for_doa && Number(rmaType) === 2
                        }
                      />
                      <RFFSelectField
                        label={t("return_address")}
                        name={"return_address"}
                        options={invoice ? invoice.customer_address : []}
                        disabled={
                          !invoice?.valid_for_doa && Number(rmaType) === 2
                        }
                      />
                    </Grid>
                  </Box>
                </RmaInputContainer>
                <RmaInputContainer>
                  <Box className="page-header">
                    <Heading className="heading">
                      {t("device_information")}
                    </Heading>
                  </Box>
                  <Box className={"form-container"}>
                    <Grid columns={4} gap={1}>
                      <Field name={"serial"}>
                        {({ input, meta }) => {
                          const { value, ...restInput } = input;
                          return (
                            <FormControl className="form-control">
                              <Input
                                label={t("serial")}
                                type={"text"}
                                error={
                                  meta.touched &&
                                  (meta.error || meta.submitError)
                                }
                                {...restInput}
                                onChange={(e) => {
                                  input.onChange(e);
                                  handleSerialChange(e);
                                }}
                                value={
                                  searchType === "invoice"
                                    ? value || serial
                                    : serial
                                }
                                readOnly={searchType === "serial"}
                                disabled={
                                  searchType === "serial" ||
                                  (!invoice?.valid_for_doa &&
                                    Number(rmaType) === 2)
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <Field name={"article_no"}>
                        {({ input, meta }) => {
                          // eslint-disable-next-line no-unused-vars
                          const { value, ...restInput } = input;
                          return (
                            <FormControl className="form-control">
                              <Input
                                label={t("article_no")}
                                type={"text"}
                                error={
                                  meta.touched &&
                                  (meta.error || meta.submitError)
                                }
                                {...restInput}
                                onChange={(e) => {
                                  input.onChange(e);
                                  handleArticleChange(e);
                                }}
                                value={articleNumber}
                                readOnly={searchType === "serial"}
                                disabled={
                                  searchType === "serial" ||
                                  (!invoice?.valid_for_doa &&
                                    Number(rmaType) === 2)
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <Field name={"product_title"}>
                        {({ input, meta }) => {
                          // eslint-disable-next-line no-unused-vars
                          const { value, ...restInput } = input;
                          return (
                            <FormControl className="form-control">
                              <Input
                                label={t("product")}
                                type={"text"}
                                error={
                                  meta.touched &&
                                  (meta.error || meta.submitError)
                                }
                                {...restInput}
                                onChange={(e) => {
                                  input.onChange(e);
                                  handleProductChange(e);
                                }}
                                value={product}
                                readOnly={true}
                                disabled={true}
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <Button
                        sx={{
                          lineHeight: "1rem",
                          marginBottom: "0rem",
                          width: "11rem",
                          height: "2rem",
                          marginTop: "0.5rem",
                        }}
                        variant="secondaryOutline"
                        className="details-btn"
                        onClick={() => {
                          onChooseProduct(
                            invoice.articles.filter(
                              (article) =>
                                !checkArrayHasDuplicatedElementByProperty(
                                  invoice.articles,
                                  "article_no",
                                  article.article_no
                                ) || !!article.serial
                            ),
                            form
                          );
                        }}
                        type={"button"}
                        disabled={
                          !invoice?.valid_for_doa && Number(rmaType) === 2
                        }
                      >
                        {t("choose_product")}
                      </Button>
                    </Grid>
                    <Grid columns={1}>
                      <Field name={"rma_reason"}>
                        {({ input, meta }) => {
                          const error =
                            meta.touched && (meta.error || meta.submitError);
                          return (
                            <>
                              <label>
                                {t("rma_reason")}{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {t("only_in_english")}
                                </span>
                              </label>
                              <textarea
                                label={t("rma_reason")}
                                type={"textarea"}
                                rows={5}
                                error={error}
                                disabled={
                                  !invoice?.valid_for_doa &&
                                  Number(rmaType) === 2
                                }
                                {...input}
                                onChange={(e) => {
                                  input.onChange(e);
                                }}
                              />
                              <small
                                style={{
                                  display: "flex",
                                  color: "#ff3d3d",
                                  fontSize: "80%",
                                }}
                                className={"error"}
                              >
                                {error}
                              </small>
                            </>
                          );
                        }}
                      </Field>
                    </Grid>
                  </Box>
                </RmaInputContainer>
                <RmaInputContainer>
                  <Box className="page-header">
                    <Heading className="heading">
                      {t("package_contents")}
                    </Heading>
                  </Box>
                  <Box>
                    <FieldArray
                      name={"package_contents"}
                      render={({ meta, fields }) => {
                        return (
                          <div>
                            <CheckboxGroup
                              fields={fields}
                              disabled={
                                !invoice?.valid_for_doa && Number(rmaType) === 2
                              }
                            />
                            <small
                              style={{
                                display: "flex",
                                color: "#ff3d3d",
                                fontSize: "80%",
                              }}
                              className={"error"}
                            >
                              {meta.touched && (meta.error || meta.submitError)}
                            </small>
                          </div>
                        );
                      }}
                    />

                    <Grid columns={1} style={{ marginTop: "5px" }}>
                      <RFFInputField
                        type={"text"}
                        name={"extra_content"}
                        placeholder={t("other")}
                        onChange={() => setErrorMessage(null)}
                        disabled={
                          !invoice?.valid_for_doa && Number(rmaType) === 2
                        }
                      />
                    </Grid>
                  </Box>
                </RmaInputContainer>
                <RmaInputContainer>
                  <Box
                    style={{
                      backgroundColor: "#d9534f",
                      color: "white",
                      padding: "30px",
                    }}
                  >
                    <Grid columns={1}>
                      <Heading className="heading">{t("important")}</Heading>
                      {t("warranty_information")}
                    </Grid>
                  </Box>
                  <Box>
                    <Grid columns={1}>
                      <RFFCheckBoxField
                        name={"confirm_language"}
                        label={t("confirm_error_description")}
                        onChange={() => setErrorMessage(null)}
                        disabled={
                          !invoice?.valid_for_doa && Number(rmaType) === 2
                        }
                      />
                    </Grid>
                    <Grid columns={1}>
                      <RFFCheckBoxField
                        name={"confirm_not_locked"}
                        label={t("confirm_device_not_locked")}
                        onChange={() => setErrorMessage(null)}
                        disabled={
                          !invoice?.valid_for_doa && Number(rmaType) === 2
                        }
                      />
                    </Grid>
                    <Grid columns={1}>
                      <RFFCheckBoxField
                        name={"confirm_not_damaged"}
                        label={t("confirm_device_not_damaged")}
                        onChange={() => setErrorMessage(null)}
                        disabled={
                          !invoice?.valid_for_doa && Number(rmaType) === 2
                        }
                      />
                    </Grid>
                  </Box>
                </RmaInputContainer>
                <WithLoader isLoading={isCreating}>
                  <Button
                    variant={"secondary"}
                    type={"submit"}
                    sx={{ minWidth: "10rem" }}
                    disabled={
                      submitting ||
                      (!invoice?.valid_for_doa && Number(rmaType) === 2)
                    }
                  >
                    {t("submit")}
                  </Button>
                </WithLoader>
              </Box>
            )}
          />
        </Container>
      )}
    </WithLoader>
  );
};

export default CreateRma;
