import { Box, Button, Heading, Image } from "theme-ui";
import CounterInput from "../../../components/CounterInput";
import React, { useState } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getFormatedYukaPoints } from "../../../utils/helpers";
import DefaultImage from "../../../assets/images/default.jpg";
import { addDefaultSource } from "../../../utils/helpers";

const ProductBlock = styled(Box)`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.3rem;
  overflow: hidden;
  max-width: 64rem;
  margin-bottom: 1.5rem;

  .product-img {
    flex: 0 0 15rem;

    .img {
      width: 100%;
      height: 100%;
      // object-fit: contain;
      object-fit: contain;
    }
  }

  .product-details {
    flex: 1 1 auto;
    padding: 1.5rem;

    .product-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.25rem;
      margin-bottom: 1.5rem;

      .product-price {
        color: ${(props) => props.theme.colors.primary};
      }
    }

    .product-description {
      margin-bottom: 1rem;
    }

    .product-actions {
      display: flex;
      justify-content: flex-end;

      .btn {
        margin-left: 1rem;
        width: 100%;
        max-width: 10rem;
      }
    }
  }

  ${(props) => props.theme.customBreakpoints[0]} {
    flex-direction: column;

    .product-img {
      flex: 0 0 10rem;
    }

    .product-details {
      padding: 1rem;

      .product-header {
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .product-title {
          width: 100%;
          margin-bottom: 0.5rem;
        }
      }

      .product-actions {
        justify-content: space-between;
      }
    }
  }
`;

const YPProduct = ({
  data: {
    article_no,
    title,
    description,
    yukapoints,
    image,
    max_quantity,
    is_added_to_cart = false,
    cart_quantity,
  },
  onAddToCart,
  onUpdateToCart,
}) => {
  const { t } = useTranslation();

  const [quantity, setQuantity] = useState(max_quantity === 0 ? 0 : 1);

  return (
    <ProductBlock className="product-block">
      <Box className="product-img">
        <Image
          className="img"
          src={image || DefaultImage}
          alt={"not found"}
          onError={addDefaultSource}
        />
      </Box>
      <Box className="product-details">
        <Box className="product-header">
          <Heading className="product-title">{title}</Heading>
          <Heading className="product-price">
            {getFormatedYukaPoints(yukapoints)} YP
          </Heading>
        </Box>
        <Box className="product-description">{description}</Box>
        <Box className="product-actions">
          <CounterInput
            value={quantity}
            onChange={(value) => {
              setQuantity(value);
            }}
            maxValue={max_quantity}
          />
          {max_quantity > 0 ? (
            is_added_to_cart ? (
              <Button
                variant="secondary"
                className="btn"
                disabled={quantity === cart_quantity}
                onClick={() => {
                  onUpdateToCart(article_no, {
                    quantity,
                    total_points: quantity * yukapoints,
                  });
                }}
              >
                {t("update_redeem")}
              </Button>
            ) : (
              <Button
                variant="secondary"
                className="btn"
                onClick={() => {
                  onAddToCart({
                    article_no,
                    title,
                    description,
                    yukapoints,
                    image,
                    quantity,
                    total_points: quantity * yukapoints,
                  });
                }}
              >
                {t("redeem")}
              </Button>
            )
          ) : (
            <Button variant="secondary" className="btn" disabled={true}>
              {t("not_enough_yp")}
            </Button>
          )}
        </Box>
      </Box>
    </ProductBlock>
  );
};

YPProduct.propTypes = {
  data: PropTypes.shape({
    article_no: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    yukapoints: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    max_quantity: PropTypes.number.isRequired,
    is_added_to_cart: PropTypes.bool.isRequired,
    cart_quantity: PropTypes.number.isRequired,
  }),
  onAddToCart: PropTypes.func.isRequired,
  onUpdateToCart: PropTypes.func.isRequired,
};

export default YPProduct;
