import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text } from "theme-ui";
import themes from "../../themes";
import PrimaryButton from "../PrimaryButton";
import Input from "../form-fields/Input";
import IconClose from "../icons/icon-close";
import IconHelp from "../icons/icon-help";
import IconSearch from "../icons/icon-search";

const StepThreeSearchContainer = styled.div`
  width: calc(100% - 30em);
  background-color: ${(props) => props.theme.colors.searchBG};
  padding-top: 0.2em;
  padding-left: 0.3em;
  height: 3rem;
  margin: 0.5em 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  vertical-align: middle;
  @media screen and (max-width: 925px) {
    width: 100%;
    flex-direction: column;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    aligin-items: center;
  }
`;

const TradeInSearchBar = ({
  setSearchText,
  setIsDirectBuy,
  searchText,
  onModalOpen,
  setModalData,
  setShowHelpModal,
  setPreviousRowStopIndex,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const onHelpModal = useCallback(
    (values) => {
      setShowHelpModal(!!values);
    },
    [setShowHelpModal]
  );

  return (
    <Flex
      sx={{
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0.5em 2.1em 0.5em 0.5em",
        "@media screen and (max-width: 2570px)": {
          padding: "0.5em 2.1em 0.5em 0.5em",
        },
        "@media screen and (max-width: 1920px)": {
          padding: "0.5em 1.2em 0.5em 0.5em",
        },
        "@media screen and (max-width: 1540px)": {
          padding: "0.5em 0.9em 0.5em 0.5em",
        },
        "@media screen and (max-width: 1530px)": {
          padding: "0.5em 1.9em 0.5em 0.5em",
        },
        "@media screen and (max-width: 1270px)": {
          padding: "0.5em 4em 0.5em 0.5em",
        },
        "@media screen and (max-width: 925px)": {
          flexDirection: "column",
          padding: "0.5em 2.4em 0.5em 0em",
        },
      }}
    >
      <StepThreeSearchContainer>
        <Flex
          sx={{
            width: "calc(100% - 15em)",
            "@media screen and (max-width: 925px)": {
              width: "100%",
            },
            "@media screen and (max-width: 725px)": {
              width: "100%",
            },
            "@media screen and (max-width: 425px)": {
              width: "100%",
              marginTop: "30px",
            },
          }}
        >
          <Input
            label={t("search")}
            type="text"
            icon={
              searchText ? (
                <Box
                  onClick={() => {
                    setSearch("");
                    setSearchText("");
                  }}
                >
                  <IconClose />
                </Box>
              ) : (
                <IconSearch
                  onChange={() => {
                    setSearchText(search);
                  }}
                />
              )
            }
            style={{
              backgroundColor: themes.colors.white,
              width: "100%",
              height: "2.5rem",
              padding: "0.5em",
              borderRadius: "3px",
              border: `1px solid ${themes.colors.searchBorderBG}`,
            }}
            iconPlacement="suffix"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearchText(e.target.value);
              }
            }}
            onBlur={() => {
              if (!searchText) {
                setSearchText(search);
              }
            }}
          />
        </Flex>
        <Flex
          sx={{
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "14em",
            height: "2.5em",
            padding: "0.5em",
          }}
        >
          <Box>
            <Input
              name="direct_buy"
              component="input"
              type="checkbox"
              style={{
                width: "1.5em",
                height: "1.5em",
                border: `2px solid ${themes.colors.white}`,
                borderRadius: "4px",
                marginRight: "0.3em",
              }}
              onChange={(e) => {
                setPreviousRowStopIndex(-1);
                e.target.checked ? setIsDirectBuy(true) : setIsDirectBuy(false);
              }}
            />
          </Box>
          <Box>
            <Text
              sx={{
                width: "11em",
                fontSize: "1rem",
                fontWeight: 500,
                color: themes.colors.black,
                textWrap: "nowrap",
                marginLeft: "0.3em",
              }}
            >
              {t("step_three_filter_direct_buy_text")}
            </Text>
          </Box>
        </Flex>
      </StepThreeSearchContainer>
      <Flex
        sx={{
          flexDirection: "column",

          "@media screen and (min-width: 500px)": {
            flexDirection: "row",
            justifyContent: "space-between",
          },
          "@media screen and (max-width: 925px)": {
            width: "100%",
            justifyContent: "flex-end",
          },
        }}
      >
        <Flex
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            height: "3rem",
            margin: "0.5em 0 0.5em 1em",
            paddingTop: "0.2em",
            verticalAlign: "middle",
            "@media screen and (max-width: 925px)": {
              width: "100%",
              justifyContent: "flex-end",
              paddingRight: "-0.2em",
              marginLeft: "0",
            },
            "@media screen and (max-width: 769px)": {
              width: "98%",
              flexDirection: "row",
            },
            "@media screen and (max-width: 545px)": {
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: "4rem",
            },
            "@media screen and (max-width: 324px)": {
              width: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "6em",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginLeft: "1em",
              "@media (min-width: 325px)": {
                width: "12em",
              },
            }}
          >
            <PrimaryButton
              type={"button"}
              style={{
                textAlign: "center",
                width: "100%",
                height: "2.5em",
                borderRadius: "3px",
                borderWidth: 0,
                backgroundColor: themes.colors.tradeInHomeButtonBG,
                color: themes.colors.white,
                textTransform: "capitalize",
              }}
              onClick={() => {
                setModalData(null);
                onModalOpen();
              }}
            >
              {`+ ${t("add_product")}`}
            </PrimaryButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              marginLeft: "1em",
              "@media (min-width: 325px)": {
                width: "12em",
              },
            }}
          >
            <PrimaryButton
              type={"button"}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                height: "2.5em",
                borderRadius: "3px",
                borderWidth: 0,
                backgroundColor: themes.colors.primary,
                color: themes.colors.white,

                padding: "0.6em 0",
              }}
              onClick={onHelpModal}
            >
              <IconHelp width="18px" height="18px" />
              <Text
                sx={{
                  textTransform: "capitalize",
                  verticalAlign: "middle",
                  marginLeft: "0.3em",
                }}
              >
                {t("help")}
              </Text>
            </PrimaryButton>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

TradeInSearchBar.propTypes = {
  setSearchText: PropTypes.func,
  setIsDirectBuy: PropTypes.func,
  searchText: PropTypes.string,
  onModalOpen: PropTypes.func,
  setModalData: PropTypes.func,
  setShowHelpModal: PropTypes.func,
};

export default TradeInSearchBar;
