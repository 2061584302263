/** @jsx jsx */
import { Alert, jsx } from "theme-ui";
import PropTypes from "prop-types";

const Message = ({ message, ...props }) =>
  message ? (
    <Alert variant={"primary"} {...props}>
      <span>{message}</span>
    </Alert>
  ) : null;

Message.propTypes = { message: PropTypes.string };

export default Message;
