import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  areDatesEqual,
  convertToDate,
  getWeekdaysAfterDate,
} from "../../utils/helpers";

const InputField = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .input,
  .select {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    border-radius: 1rem;
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    font-size: 1rem;
    outline: none;
    cursor: text;
    font-family: "Roboto", sans-sarif;
    margin: 0;

    &:focus {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  .input-icon {
    height: 100%;
    width: 2rem;
    position: absolute;
    z-index: 3;
    bottom: 0;
    padding: 0.5rem 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &.prefix-icon {
    .input {
      padding-left: 2.5rem;
    }
    .input-icon {
      left: 0;
    }
  }

  &.suffix-icon {
    .input {
      padding-right: 2.5rem;
    }

    .input-icon {
      right: 0;
    }
  }
`;

const InputContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  .error,
  .info {
    display: none;
    color: ${(props) => props.theme.colors.danger};
    font-size: 80%;
    position: relative;
    margin-top: 0.375rem;
  }

  &.has-error {
    .error {
      display: inline-block;
    }

    .input {
      border-color: ${(props) => props.theme.colors.danger};
    }
    .select {
      border-color: ${(props) => props.theme.colors.danger};
    }
  }

  .info {
    display: inline-block;
    color: ${(props) => props.theme.colors.gray};
  }
`;

const InputDatePicker = ({
  id,
  name,
  minimumDate,
  value,
  onChange,
  specialDate,
  disabled,
}) => {
  const { i18n } = useTranslation();
  const activeLanguage = useMemo(() => i18n.language, [i18n.language]);

  const handleChange = useCallback(
    (date) => {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();

      const updateValue = `${year}-${month}-${day}`;
      onChange(id, name, updateValue);
    },
    [id, name, onChange]
  );
  return (
    <InputContainer className={`input-container`}>
      <InputField>
        <DatePicker
          id={id}
          name={name}
          dateFormat="yyyy-MM-dd"
          value={value}
          locale={activeLanguage}
          minDate={convertToDate(minimumDate)}
          onChange={(date) => handleChange(date)}
          dayClassName={(date) =>
            specialDate &&
            getWeekdaysAfterDate(minimumDate).some((weekday) =>
              areDatesEqual(weekday, date)
            )
              ? "green-date"
              : null
          }
          className={
            disabled
              ? "react-datepicker-custom-disabled-style"
              : "react-datepicker-custom-style"
          }
          disabled={disabled}
        />
      </InputField>
    </InputContainer>
  );
};

InputDatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  minimumDate: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  isDirectBuy: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputDatePicker;
