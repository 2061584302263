/** @jsx jsx */
import { Box, Button, Flex, Heading, jsx, Badge, IconButton } from "theme-ui";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Card from "./Card";
import CounterInput from "./CounterInput";
import { useState } from "react";
import { useModal } from "../contexts/ModalContext";
import IconClose from "./icons/icon-close";
import Money from "./Money";
import { useToast } from "../contexts/AlertProvider";
import { useTranslation } from "react-i18next";
import DefaultImage from "../assets/images/noimage.jpg";
import { addDefaultSource } from "../utils/helpers";
import IconShoppingCart from "./icons/icon-shopping-cart";

const ProductCardContainer = styled(Box)`
  .product-card {
      border-radius: 0.5rem;
      overflow: hidden;
      padding: 1rem;
      transition: all .3s ease;
      border: 1px solid  ${(props) => props.theme.colors.primary};
      height: 100%;
      position: relative;
      display: flex;
      flex-direction:  column;
      justify-content: space-between;


      &:hover {
        border-color:  ${(props) => props.theme.colors.secondary};
        .product-image {
          border-color:  ${(props) => props.theme.colors.secondary};
        }
      }

      .product-image {
          overflow: hidden;
          margin: -1rem -1rem 1rem -1rem;
          max-height: 15rem;
          height: 15rem;
          border-bottom: 1px solid  ${(props) => props.theme.colors.primary};

          img {
              display: block;
              height: 100%;
              width: 100%;
              object-fit: contain;
          }
      }

      .product-details-container{
        .product-category{
            text-transform: uppercase;
            font-size: 0.75rem;
            color: ${(props) => props.theme.colors.secondaryText};
            margin-bottom: 0.25rem;
        }

        .product-name {
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 0.75rem;
            font-family: ${(props) => props.theme.fonts.body};
        }

        .price-container{
            justify-content: space-between;
            margin-bottom: 0.5rem;

            .product-price,
            .yaku-points {
                font-size: 0.875rem;
                display: block;
                margin-bottom: 0.25rem
            }

            .yaku-points {
                color: ${(props) => props.theme.colors.primary};
            }

            .total-label,
            .total-amount{
                display: block;
                text-align: right;
            }

            .total-label{
                text-transform: uppercase;
                font-size: 0.875rem;
            }

            .total-amount{
                font-size: 1.5rem;
                color: ${(props) => props.theme.colors.secondary};
          
            }
        }

        .sold-out-badge {
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          width: calc(100% - 2rem);
          text-align:center;

        }
      }

    .action-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;


      .counter-input-container {
        max-width: 7rem;
        min-width: 6rem;
        margin-right: 0.75rem;
      }
    }
    }

  }
`;

const ProductImageModal = styled(Box)`
  margin: -20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.125), 1px 4px rgba(0, 0, 0, 0.075);

  .product-img-container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .img {
      max-height: 80vh;
      height: auto;
      max-width: 80vw;
      width: auto;
      margin-bottom: -0.375rem;
    }
  }

  .icon-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;

    height: 2.5rem;
    width: 2.5rem;

    .svg-icon {
      fill: white;
      height: 2rem;
      width: 2rem;
    }
  }
`;

const ProductCard = ({
  data: {
    article_no,
    image,
    description,
    category_name,
    selling_price,
    reward_points,
    max_quantity,
    is_added_to_cart = false,
    cart_quantity,
  },
  onAddToCart,
  onUpdateToCart,
  onClickTitle,
  csell,
}) => {
  const toast = useToast();
  const { t } = useTranslation();

  const { showModal } = useModal();
  const [quantity, setQuantity] = useState(max_quantity === 0 ? 0 : 1);

  return (
    <ProductCardContainer className="product-card-container">
      <Card className="product-card">
        <Box className="product-image">
          <img
            src={image || DefaultImage}
            alt={"product"}
            onError={addDefaultSource}
            onClick={() =>
              showModal(({ hideModal }) => {
                return (
                  <ProductImageModal className="product-img-modal">
                    <Box className="product-img-container">
                      <img
                        className="img"
                        src={image || DefaultImage}
                        onError={addDefaultSource}
                        alt={"hot deal"}
                      />
                    </Box>
                    <Button
                      className="icon-btn"
                      variant="secondaryIconButton"
                      onClick={() => hideModal()}
                    >
                      <IconClose />
                    </Button>
                  </ProductImageModal>
                );
              })
            }
          />
        </Box>

        <Box className="product-details-container">
          <span className="product-category">{category_name}</span>
          <Heading className="product-name">
            <span className={"link"}>
              <a
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  onClickTitle(article_no, description);
                }}
              >
                {description}
              </a>
            </span>
          </Heading>
          <Flex className="price-container">
            <Box>
              <span className="product-price">
                <Money amount={selling_price} />
              </span>
              <span className="yaku-points">
                {reward_points} {t("yuka_points")}
              </span>
            </Box>
            {quantity > 0 && (
              <Box>
                <span className="total-label">{t("total")}</span>
                <span className="total-amount">
                  <Money amount={selling_price * quantity} />
                </span>
              </Box>
            )}
          </Flex>
        </Box>
        {quantity > 0 ? (
          <Box className="action-container">
            <CounterInput
              value={quantity}
              onChange={(value) => {
                setQuantity(value);
              }}
              maxValue={max_quantity}
            />
            {is_added_to_cart ? (
              <IconButton
                variant="secondaryIconButton"
                className="cart-btn"
                sx={{ width: "40px", height: "40px" }}
                disabled={quantity === cart_quantity}
                onClick={() => {
                  onUpdateToCart(article_no, {
                    quantity,
                    total_amount: quantity * selling_price,
                    total_reward_points: quantity * reward_points,
                  });
                  toast(t("product_updated_message"), {
                    type: "success",
                  });
                }}
              >
                <IconShoppingCart
                  fill={"white"}
                  width={"30px"}
                  height={"30px"}
                  disabled={quantity === cart_quantity}
                />
              </IconButton>
            ) : (
              <div>
                <IconButton
                  variant="secondaryIconButton"
                  className="cart-btn"
                  sx={{ width: "40px", height: "40px" }}
                  disabled={max_quantity === 0}
                  onClick={() => {
                    onAddToCart({
                      article_no,
                      description,
                      selling_price,
                      quantity,
                      max_quantity,
                      total_amount: quantity * selling_price,
                      reward_points,
                      total_reward_points: quantity * reward_points,
                      csell: csell ? csell : false,
                    });
                    toast(t("product_updated_message"), {
                      type: "success",
                    });
                  }}
                >
                  <IconShoppingCart
                    fill={"white"}
                    width={"30px"}
                    height={"30px"}
                  />
                </IconButton>
              </div>
            )}
          </Box>
        ) : (
          <Box style={{ height: "2.5rem" }}>
            <Badge className="sold-out-badge" variant={"danger"}>
              Sold Out
            </Badge>
          </Box>
        )}
      </Card>
    </ProductCardContainer>
  );
};

ProductCard.propTypes = {
  data: PropTypes.shape({
    article_no: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    category_name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    selling_price: PropTypes.number.isRequired,
    reward_points: PropTypes.number.isRequired,
    max_quantity: PropTypes.number.isRequired,
    is_added_to_cart: PropTypes.bool,
    cart_quantity: PropTypes.number,
  }),
  onAddToCart: PropTypes.func.isRequired,
  onUpdateToCart: PropTypes.func.isRequired,
};
export default ProductCard;
