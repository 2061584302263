import React from "react";
import { Redirect } from "react-router-dom";
import Home from "../pages/authenticated/Home";
import Shop from "../pages/authenticated/Shop";
import Download from "../pages/authenticated/Download";
import RedeemYP from "../pages/authenticated/RedeemYP";
import Cart from "../pages/authenticated/Cart";
import Profile from "../pages/authenticated/Profile";
import ChangePassword from "../pages/authenticated/ChangePassword";
import Account from "../pages/authenticated/Account";
import TermsAndConditions from "../pages/authenticated/TermsAndConditions";
import Imprint from "../pages/authenticated/Imprint";
import DataProtection from "../pages/authenticated/DataProtection";
import RMA from "../pages/authenticated/RMA";
import CreateRma from "../pages/authenticated/RMA/CreateRma";
import TradeIn from "../pages/authenticated/TradeIn";
import TradeInHome from "../pages/authenticated/TradeInHome";
import PriceList from "../pages/authenticated/PriceList";
import TradeInOldOffer from "../pages/authenticated/TradeInOldOffer";
import HomePage from "../pages/unauthenticated/HomePage";
import CreateRmaReturn from "../pages/common/RMA/CreateRmaReturn";

export const AuthenticatedRedirectPage = () => <Redirect to="/home" />;

export default [
  {
    component: AuthenticatedRedirectPage,
    name: "Home",
    to: "/",
    exact: true,
  },
  {
    component: Home,
    name: "Home",
    to: "/home",
    exact: true,
  },
  {
    component: Shop,
    name: "Shop",
    to: "/shop",
    exact: true,
  },
  {
    component: Download,
    name: "Downloads",
    to: "/downloads",
    exact: true,
  },
  {
    component: Account,
    name: "Account",
    to: "/account",
    exact: false,
    isNested: true,
  },
  {
    component: RMA,
    name: "RMA",
    to: "/rma",
    exact: true,
  },
  {
    component: CreateRma,
    name: "RMA",
    to: "/rma/create",
    exact: true,
  },
  {
    component: RedeemYP,
    name: "Redeem YP",
    to: "/redeem-yp",
    exact: true,
  },
  {
    component: Cart,
    name: "Cart",
    to: "/cart",
    exact: true,
  },
  {
    component: Profile,
    name: "Profile",
    to: "/profile",
    exact: true,
  },
  {
    component: ChangePassword,
    name: "ChangePassword",
    to: "/change-password",
    exact: true,
  },
  {
    component: TermsAndConditions,
    name: "TermsAndConditions",
    to: "/terms-and-conditions/",
    exact: true,
  },
  {
    component: Imprint,
    name: "Imprint",
    to: "/imprint/",
    exact: true,
  },
  {
    component: DataProtection,
    name: "DataProtection",
    to: "/data-protection/",
    exact: true,
  },
  {
    component: CreateRma,
    name: "New RMA",
    to: "/rma/create",
    exact: true,
  },
  {
    component: TradeIn,
    name: "Trade In",
    to: "/trade-in",
    exact: true,
  },
  {
    component: TradeInHome,
    name: "Trade in",
    to: "/trade-in-home",
    exact: true,
  },
  {
    component: PriceList,
    name: "PriceList",
    to: "/price-list",
    exact: true,
  },
  {
    component: TradeInOldOffer,
    name: "Trade In",
    to: "/trade-in-old-offer",
    exact: false,
    isNested: true,
  },
  {
    component: HomePage,
    name: "Trade in",
    to: "/home-trade-in",
    exact: true,
  },
  {
    component: CreateRmaReturn,
    name: "Create Rma Return",
    to: "/rma/return",
    exact: true,
  },
];
