import React from "react";
import { Box, Heading } from "theme-ui";
import { TermsAndConditionContainer } from "../DataProtection";

export default function index() {
  return (
    <TermsAndConditionContainer>
      <Box className="page-header">
        <Heading className="heading">General Terms and Conditions</Heading>
      </Box>
      <Box className="list-item">
        <strong>1.</strong>
        <Box>
          All deliveries and services provided by Yukatel GmbH are based on
          these terms and conditions. Deviating and / or supplementary
          agreements require the express consent of Yukatel GmbH and must be
          made in the written form; this also applies to a waiver of the written
          form requirement.
        </Box>
      </Box>
      <Box className="list-item">
        <strong>2.</strong>
        <Box>
          <p>
            If possible, Yukatel GmbH will keep specified agreements to meet
            punctual delivery times.
          </p>
          <p>
            If these are exceeded by more than four weeks, the customer has the
            right to withdraw the contract by means of a written declaration.
          </p>
          <p>
            Should the buyer demand compensation for non-performance in the
            event of default or impossibility, this is limited to a maximum of
            5% of the total purchase price in the event of slight negligence and
            only includes compensation for direct damage, i.e. in particular not
            compensation for lost profit or any other indirect damage. Further
            claims of the buyer – especially for delivery – are excluded. All
            offers are non-binding and apply while stocks last. Errors and
            misprints reserved.
          </p>
        </Box>
      </Box>
      <Box className="list-item">
        <strong>3.</strong>
        <Box>
          The risk passes to the customer as soon as the goods have been handed
          over to the transport company and have left the warehouse. This also
          applies if Yukatel GmbH has assumed the transport costs. The customer
          must assert complaints about transport damage directly to the
          transport company within the deadlines provided. In case of shipments
          by the customer to Yukatel GmbH, the customer bears the full risk, in
          particular the transportation risk, until the goods arrive at Yukatel
          GmbH in Dreieich am Main.
        </Box>
      </Box>
      <Box className="list-item">
        <strong>4.</strong>
        <Box>
          <p>
            Invoices from Yukatel GmbH are due immediately and payable without
            any deduction. From the 30th day after the invoice date, Yukatel
            GmbH is entitled to demand interest at the statutory rate above the
            base interest rate, unless Yukatel GmbH proves higher default
            interest or the buyer proves a lower burden on Yukatel GmbH, without
            the previous reminder is required.
          </p>
          <p>
            Checks are only accepted on account of payment. Possible expenses
            are charged to the customer. A set-off is only permissible with
            undisputed claims or claims that have been legally established. The
            customer can only assert a right of retention if it is based on the
            same contractual relationship.
          </p>
        </Box>
      </Box>
      <Box className="list-item">
        <strong>5.</strong>
        <Box>
          <p>
            Complaints about scope of delivery, defects, incorrect deliveries,
            and quantity deviations must be made in writing immediately and no
            later than one week after receipt of the goods, insofar as these can
            be determined by reasonable examinations. In the case of justified
            complaints, Yukatel GmbH delivers the missing quantity of goods and,
            subject to exclusion, exchanges the goods at its own discretion,
            takes them back or grants the buyer a reduced price.
          </p>
          <p>
            If the second replacement delivery is also defective, the buyer has
            the right to conversion or reduction. The guarantee for device
            defects is limited to rework. In the event of a conversion, the
            device will be taken back with compensation for its value in use or
            at the current value of the device specified by the manufacturer /
            market. If the customer exercises a right of withdrawal according to
            § 3 of the Distance Selling Act, he has to bear the costs of
            returning an order up to an amount of 40 euros, unless the goods
            delivered do not correspond to the goods ordered. Software cannot be
            exchanged!! Goods delivered by Yukatel GmbH will only be taken back
            with the prior written consent of Yukatel GmbH. The goods must be in
            perfect condition, in the original packaging and complete (complete,
            undamaged original packaging, accessories, operating instructions,
            complete packaging material, etc.). Goods taken back will be
            credited minus 10% (at least EUR 15) for processing and warehouse
            turnover costs. Special promotions, special orders and custom-made
            products are generally excluded from return.
          </p>
        </Box>
      </Box>
      <Box className="list-item">
        <strong>6.</strong>
        <Box>
          <p>
            The object of purchase remains the property of Yukatel GmbH until
            settlement regarding claims due to the contract. The retention of
            title also remains for all claims, that Yukatel GmbH subsequently
            acquired against the buyer in connection with the purchase item,
            e.g. B. due to repairs and other services.
          </p>
          <p>
            For the duration of the retention of title, the buyer is entitled to
            possession and use the object of purchase in accordance with the
            contract, as long as he meets his obligations from the retention of
            title and his obligations from the business relationship in due
            time.
          </p>
        </Box>
      </Box>
      <Box className="list-item">
        <strong>7.</strong>
        <Box>
          The users registered with Yukatel receive, in addition to their
          customer number, login data that enable them to place orders online
          directly at Yukatel. The login data must be kept carefully and under
          lock and key against third parties. The respective user is liable for
          any damage that may arise from unauthorized use of the login data
        </Box>
      </Box>

      <Box className="list-item">
        <strong>8.</strong>
        <Box>
          Yukatel GmbH is entitled to collect, save, and process the customer’s
          personal data required in the context of the business relationship
        </Box>
      </Box>

      <Box className="list-item">
        <strong>9.</strong>
        <Box>
          <p>
            The ineffectiveness of individual points of these general terms and
            conditions does not affect the effectiveness of the remaining
            regulations.
          </p>
          <p>
            The ineffective provision will be replaced by a new provision that
            comes as close as possible to the ineffective regulations in terms
            of its economic impact.
          </p>
          <p>
            The place of fulfillment and jurisdiction for contracts that have
            been concluded including these General Terms and Conditions is
            Dreieich am Main, provided the contractual partner is a trader.
          </p>
        </Box>
      </Box>
    </TermsAndConditionContainer>
  );
}
