/* eslint-disable react/prop-types */
import React from "react";

const IconHamburger = ({
  fill = "inherit",
  width = "24px",
  height = "24px",
  className = "",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    fill={fill}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="Icon_Menu"
      data-name="Icon / Menu"
      d="M99-1400h18v-2H99Zm0-5h18v-2H99Zm0-7v2h18v-2Z"
      transform="translate(-96 1418)"
    />
  </svg>
);

export default IconHamburger;
