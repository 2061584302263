import { Flex, Image } from "theme-ui";
import IconSortUp from "../../../components/icons/icon-sort-up";
import React from "react";
import * as PropTypes from "prop-types";
import IconSortDown from "../../../components/icons/icon-sort-down";

const SortTableHead = ({
  label,
  value,
  sort,
  image = null,
  icon = null,
  onClick,
}) => {
  return (
    <th
      onClick={() => {
        onClick(value);
      }}
    >
      <Flex sx={{ alignItems: "center" }}>
        {image ? (
          <Image
            src={image}
            style={{
              height: "1rem",
              minWidth: "1rem",
              width: "auto",
              marginLeft: "0.25rem",
            }}
          />
        ) : icon ? (
          icon
        ) : (
          label
        )}
        {value === sort.sort_by ? (
          sort.sort_direction === "asc" ? (
            <IconSortUp className="active" />
          ) : (
            <IconSortDown className="active" />
          )
        ) : (
          <IconSortUp />
        )}
      </Flex>
    </th>
  );
};

SortTableHead.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    sort_by: PropTypes.string,
    sort_direction: PropTypes.oneOf(["asc", "desc"]),
  }),
  onClick: PropTypes.func.isRequired,
};

export default SortTableHead;
