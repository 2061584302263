import React from "react";

const IconEnFlag = ({ props, width = "15" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 149.16 99.442"
    {...props}
  >
    <g id="Group_1" data-name="Group 1" transform="translate(0 -85.33)">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M0,85.333H149.16v99.441H0Z"
        transform="translate(0 -0.002)"
        fill="#f0f0f0"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M83.9,85.33H65.258v40.4H0v18.645H65.258v40.4H83.9v-40.4H149.16V125.727H83.9Z"
        fill="#d80027"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M335.58,315.358l34.439,19.133V315.358Zm-23.928,0,58.367,32.426v-9.169l-41.862-23.257Zm42.82,32.426-42.82-23.791v23.791Z"
        transform="translate(-220.859 -163.014)"
        fill="#0052b4"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M311.652,315.358l58.367,32.426v-9.169l-41.862-23.257Z"
        transform="translate(-220.859 -163.014)"
        fill="#f0f0f0"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M311.652,315.358l58.367,32.426v-9.169l-41.862-23.257Z"
        transform="translate(-220.859 -163.014)"
        fill="#d80027"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M26.319,315.356,0,329.978V315.356Zm32.048,4.123v28.3H7.426Z"
        transform="translate(0 -163.013)"
        fill="#0052b4"
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M41.862,315.358,0,338.615v9.169l58.367-32.426Z"
        transform="translate(0 -163.014)"
        fill="#d80027"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M34.439,117.756,0,98.623v19.133Zm23.928,0L0,85.33V94.5l41.862,23.257ZM15.547,85.33l42.82,23.791V85.33Z"
        fill="#0052b4"
      />
      <path
        id="Path_9"
        data-name="Path 9"
        d="M58.367,117.756,0,85.33V94.5l41.862,23.257Z"
        fill="#f0f0f0"
      />
      <path
        id="Path_10"
        data-name="Path 10"
        d="M58.367,117.756,0,85.33V94.5l41.862,23.257Z"
        fill="#d80027"
      />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M343.7,117.757l26.319-14.622v14.622Zm-32.048-4.123v-28.3h50.941Z"
        transform="translate(-220.859 -0.001)"
        fill="#0052b4"
      />
      <path
        id="Path_12"
        data-name="Path 12"
        d="M328.157,117.756,370.019,94.5V85.33l-58.367,32.426Z"
        transform="translate(-220.859)"
        fill="#d80027"
      />
    </g>
  </svg>
);

export default IconEnFlag;
