import React, { useEffect, useState, useCallback } from "react";
import { getPriceList } from "../../../apis";
import { useHistory } from "react-router";
import WithLoader from "../../../components/WithLoader";
import { Box } from "theme-ui";
const PriceList = () => {
  const [data, setData] = useState();
  const [surCharge, setSurCharge] = useState(0);
  const history = useHistory();
  const fetchData = useCallback(async () => {
    getPriceList()
      .then((res) => {
        setData(res.html);
        setSurCharge(parseFloat(res.surcharge));
      })
      .catch(() => {
        history.push("/home");
      });
  }, [history]);

  useEffect(fetchData, []);

  usePutTheHTMLContent(data, surCharge);

  return (
    <WithLoader
      isLoading={!data}
      sx={{
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box dangerouslySetInnerHTML={{ __html: data }} />
    </WithLoader>
  );
};

const usePutTheHTMLContent = (data, surCharge) => {
  // This codes directly manipulates DOM because it have to manipulate been put Backend Response in DOM.
  useEffect(() => {
    if (document) {
      const topView = document.getElementsByTagName("table")[0];
      if (topView) {
        topView.remove();
      }
      document.querySelectorAll(".tr1, .tr2").forEach((trElement) => {
        const priceCell = trElement.querySelector("td:last-child");
        const currentPrice = parseFloat(
          priceCell.innerText.replace(/\./g, "").replace(",", ".")
        );
        const newPrice = (currentPrice + surCharge)
          .toFixed(2)
          .replace(".", ",");
        priceCell.innerText = newPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      });
    }
  }, [data, surCharge]);
};

export default PriceList;
