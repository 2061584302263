import React from "react";

const IconTradeINLogo = ({ width = "100%", height = "100%" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 292 97.238"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_386"
            data-name="Rectangle 386"
            width="292"
            height="97.238"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Logo" transform="translate(-640 -908)">
        <g id="Group_62" data-name="Group 62" transform="translate(640 908)">
          <path
            id="Path_946"
            data-name="Path 946"
            d="M2.055,7.505,0,19.077H17.8l-8.795,50.6H23.663l8.75-50.6h18.1L52.52,7.505Z"
            transform="translate(0 -4.085)"
            fill="#00408e"
          />
          <g id="Group_61" data-name="Group 61">
            <g id="Group_60" data-name="Group 60" clipPath="url(#clip-path)">
              <path
                id="Path_947"
                data-name="Path 947"
                d="M122.048,40.376q-6.148,0-10.669,7.011l1.062-6.062-13.108.034L91.306,87.566h14.056l5.2-29.251c2.224-3.014,5.43-4.471,9.652-4.358a40.237,40.237,0,0,1,4.347.429l1.84-13.412a20.328,20.328,0,0,0-4.358-.6"
                transform="translate(-49.691 -21.974)"
                fill="#00408e"
              />
              <path
                id="Path_948"
                data-name="Path 948"
                d="M199.442,78.877,202.9,57.145a14.715,14.715,0,0,0-1.49-8.524,13.7,13.7,0,0,0-5.916-5.758,20.457,20.457,0,0,0-9.2-2.2,26.582,26.582,0,0,0-10.307,1.727,17.989,17.989,0,0,0-7.666,5.295,13.449,13.449,0,0,0-3.037,8.095l14.089-.045q1.067-5.165,5.679-5.08c2.484.079,3.782,1.422,3.895,4.008l-.169,2.269-.52,2.608-4.7-.09q-10.5,0-16.562,4.03a13.557,13.557,0,0,0-6.277,11.549,12.054,12.054,0,0,0,3.714,9.529,13.9,13.9,0,0,0,9.991,4.008,14.238,14.238,0,0,0,11.312-5.035,12.657,12.657,0,0,0,.6,4.188H200l.045-.813a11.692,11.692,0,0,1-.813-5.385ZM185.861,74.61q-3.234,3.963-7.553,3.793a3.352,3.352,0,0,1-2.664-1.219,3.726,3.726,0,0,1-.666-2.879,7.533,7.533,0,0,1,2.856-5.182,9.516,9.516,0,0,1,5.814-1.987l3.624.034Z"
                transform="translate(-87.459 -22.124)"
                fill="#00408e"
              />
              <path
                id="Path_949"
                data-name="Path 949"
                d="M294.069,0l-4.482,23.493a12.56,12.56,0,0,0-9.777-4.956,16.5,16.5,0,0,0-13.92,6.356q-5.334,6.588-6.661,17.51l-.124.892a40.534,40.534,0,0,0-.169,5.261q.254,8.146,4.03,12.995a12.74,12.74,0,0,0,10.443,4.933q6.316.17,11.481-5.893l-.6,5h12.52L308.169,0ZM285.1,51.414c-2.156,2.732-4.629,4.053-7.384,3.974q-4.792-.135-4.787-7.214a34.777,34.777,0,0,1,.3-4.787q.643-6.875,3.048-10.352a7.189,7.189,0,0,1,6.345-3.4,5.705,5.705,0,0,1,5.634,4.053Z"
                transform="translate(-140.908 -0.001)"
                fill="#00408e"
              />
              <path
                id="Path_950"
                data-name="Path 950"
                d="M386.6,73.649l3.037,2.192c-.011,0-.011,0-.011.011a13.689,13.689,0,0,1-6.9,1.614,7.534,7.534,0,0,1-5.724-2.393,8.973,8.973,0,0,1-2.044-5.893h12.08l7.677-2.055,8.332-2.235.214-1.569a24.778,24.778,0,0,0-1.276-11.561,16.8,16.8,0,0,0-6.2-8.083,17.847,17.847,0,0,0-10.161-3.014,21.9,21.9,0,0,0-16.584,6.379q-6.774,6.638-7.925,18.041l-.124,1.2a21.611,21.611,0,0,0,1.964,11.335,18.384,18.384,0,0,0,7.316,8.027,21.467,21.467,0,0,0,11.041,2.924h.768a25.652,25.652,0,0,0,10.228-2.19,18.672,18.672,0,0,0,4.787-3.048c.011,0,.011,0,.011-.011l2.664,2.653,2.4-8.987,2.4-8.153Zm-7.056-20.015a7.21,7.21,0,0,1,5.193-1.874q4.64.135,5.374,4.38a9.422,9.422,0,0,1-.079,2.8l-.26,1.231H375.732a15.509,15.509,0,0,1,3.816-6.537"
                transform="translate(-196.418 -22.124)"
                fill="#00408e"
              />
              <path
                id="Path_951"
                data-name="Path 951"
                d="M473.786,7.505l-10.8,62.172h14.609L488.349,7.505Z"
                transform="translate(-251.966 -4.085)"
                fill="#d90010"
              />
              <path
                id="Path_952"
                data-name="Path 952"
                d="M552.939,7.505l-6.785,39.243L531.343,7.505h-14.27L506.313,69.677h14.609l6.83-39.243,14.857,39.243h14.135l10.8-62.172Z"
                transform="translate(-275.548 -4.085)"
                fill="#d90010"
              />
              <path
                id="Path_953"
                data-name="Path 953"
                d="M368.836,184.876a4.662,4.662,0,0,1-.922,2.46,2.3,2.3,0,0,1-1.938.868,1.7,1.7,0,0,1-1.462-.8l-.243.672H362.5l1.588-9.131h1.956l-.636,3.228a1.942,1.942,0,0,1,1.444-.649,1.81,1.81,0,0,1,1.474.675,2.944,2.944,0,0,1,.559,1.8,6.519,6.519,0,0,1-.03.743Zm-1.944-.116a6.115,6.115,0,0,0,.053-.689q0-.956-.684-1a1.175,1.175,0,0,0-1.016.516l-.464,2.565a.787.787,0,0,0,.809.5q.956.03,1.206-1.246l.083-.528Z"
                transform="translate(-197.28 -97.389)"
                fill="#161615"
              />
              <path
                id="Path_954"
                data-name="Path 954"
                d="M379.833,188.472l1.665-3.6h2.158l-3.768,7.4a3.234,3.234,0,0,1-.973,1.262,2.206,2.206,0,0,1-1.311.389,3.7,3.7,0,0,1-.862-.13l.149-1.433h.226a1.618,1.618,0,0,0,.636-.116.965.965,0,0,0,.458-.448l.279-.535-1.027-6.384h2.021Z"
                transform="translate(-205.032 -100.611)"
                fill="#161615"
              />
              <path
                id="Path_955"
                data-name="Path 955"
                d="M530.83,186.072H520.516l3.991-7.613H534.82Z"
                transform="translate(-283.278 -97.122)"
                fill="#fff"
              />
              <path
                id="Path_956"
                data-name="Path 956"
                d="M473.362,160.275l-3.483,6.716-.815,1.572H458.751l-3.991,7.613H399.69l8.288-15.9Z"
                transform="translate(-217.521 -87.226)"
                fill="#003f8f"
                fillRule="evenodd"
              />
              <path
                id="Path_957"
                data-name="Path 957"
                d="M559.34,178.459l-8.227,15.9H511.084l3.89-7.379.408-.908H525.7l3.99-7.613Z"
                transform="translate(-278.144 -97.122)"
                fill="#d9000d"
                fillRule="evenodd"
              />
              <path
                id="Path_958"
                data-name="Path 958"
                d="M531.818,179.6a3.311,3.311,0,1,0,3.313,3.311,3.276,3.276,0,0,0-3.313-3.311m0,6.138a2.8,2.8,0,0,1-2.828-2.828,2.826,2.826,0,0,1,.666-1.841l.005.008.03.02a.536.536,0,0,0,.291.123.77.77,0,0,0,.32-.111l.091-.045a.815.815,0,0,0,.553-.613l.021-.063-.064-.159a2.933,2.933,0,0,1,1.9.023v.265l-.268.007a.978.978,0,0,0-.376.062.79.79,0,0,0-.316.2.75.75,0,0,0-.145.223.2.2,0,0,0,.05.1.233.233,0,0,0,.21.054q.332-.255.393-.2a.064.064,0,0,1,.014.058c-.018.119-.035.237-.053.356-.473-.117-.629-.062-.68.015-.039.059-.029.146-.107.2s-.123.011-.206.054a.3.3,0,0,0-.13.139.182.182,0,0,0,.054.223.178.178,0,0,0,.2,0h.246c.1-.058.161-.052.2-.036.089.041.072.171.17.242a.285.285,0,0,0,.219.031l.322-.076.272.005.184.184-.3.286-.063.021-1.166-.307c-.184.123-.491.123-.737.307l-.123.307a.386.386,0,0,0,.246.491l.491.062.307.307a11.2,11.2,0,0,0,.184,1.166c.062.184.123.368.307.368a2.686,2.686,0,0,0,.368-.43l.123-.921c.184-.184.552-.307.43-.553l-.368-.614c0-.054,0-.108,0-.162l.549.53c.123,0,.369,0,.369-.123l.271-.287.713.026c0,.026,0,.051,0,.077a2.8,2.8,0,0,1-2.829,2.828m-.743-4.649c-.147.051-.21.268-.155.3.026.014.056-.028.2-.061a.362.362,0,0,0,.122-.033c.036-.023.084-.072.067-.127s-.1-.086-.155-.088a.205.205,0,0,0-.074.011"
                transform="translate(-287.627 -97.741)"
                fill="#003f87"
              />
              <path
                id="Path_959"
                data-name="Path 959"
                d="M549.262,195.425c-1.469,0-2.115-.38-2.115-1.444a2.379,2.379,0,0,1,.088-.671l.811-3.066h-1.279l.405-1.533a28.592,28.592,0,0,0,3.711-2.939h2.23l-.393,1.469h3.091l-.8,2.977h-3.091l-.506,1.862a1.467,1.467,0,0,0-.051.405c0,.38.241.519.8.519a14.069,14.069,0,0,0,2-.228l-1.026,2.28a22.888,22.888,0,0,1-3.876.367"
                transform="translate(-297.564 -101.102)"
                fill="#fff"
              />
              <path
                id="Path_960"
                data-name="Path 960"
                d="M568.991,196.872c-2.622,0-3.8-.735-3.8-2.673a5.767,5.767,0,0,1,.139-1.242,7.753,7.753,0,0,1,1.052-2.749,3.483,3.483,0,0,1,2.077-1.571,11.456,11.456,0,0,1,2.584-.253c3.041,0,4.522.735,4.522,2.5a5.389,5.389,0,0,1-.279,1.508q-.152.513-.38,1.1h-5.8a2.239,2.239,0,0,0-.05.418c0,.582.456.8,1.482.8a20.2,20.2,0,0,0,3.876-.469L573.4,196.29a17.746,17.746,0,0,1-4.408.583m3.319-5.105a1.661,1.661,0,0,0,.076-.469c0-.532-.368-.824-1.09-.824a1.678,1.678,0,0,0-1.8,1.292Z"
                transform="translate(-307.591 -102.524)"
                fill="#fff"
              />
              <path
                id="Path_961"
                data-name="Path 961"
                d="M587.369,194.655,590,184.8h3.889l-2.635,9.855Z"
                transform="translate(-319.661 -100.572)"
                fill="#fff"
              />
              <path
                id="Path_962"
                data-name="Path 962"
                d="M424.211,175.1l-1.812-7.207h4.244l.43,3.015a9.653,9.653,0,0,1,.1,1.317,6.966,6.966,0,0,1,.608-1.3l1.875-3.027h4.484l-6.726,9.411H422.69Z"
                transform="translate(-229.88 -91.369)"
                fill="#fff"
              />
              <path
                id="Path_963"
                data-name="Path 963"
                d="M451.108,167.888l-1.571,5.852a2.681,2.681,0,0,0-.114.6c0,.456.266.671.786.671.684,0,1.064-.392,1.279-1.216l1.571-5.9h4.015l-1.6,5.928a4.784,4.784,0,0,1-1.3,2.7c-.95.76-2.407,1.051-4.446,1.051-3.053,0-4.484-.608-4.484-2.23a6.2,6.2,0,0,1,.291-1.609l1.571-5.839Z"
                transform="translate(-242.312 -91.369)"
                fill="#fff"
              />
              <path
                id="Path_964"
                data-name="Path 964"
                d="M471.858,167.888h3.952l-.633,2.432c-.089.355-.216.811-.418,1.381a10.043,10.043,0,0,1,.8-1.064l2.2-2.749h4.928l-4.586,5,1.913,4.408h-4.37l-1.013-2.711c-.063-.19-.2-.532-.342-1.026a10.829,10.829,0,0,1-.241,1.115l-.7,2.622h-4.016Z"
                transform="translate(-255.425 -91.369)"
                fill="#fff"
              />
              <path
                id="Path_965"
                data-name="Path 965"
                d="M498.154,167.889h6.739l-.051,9.412h-3.914l.013-1.368h-3.154l-.8,1.368h-4.446Zm2.888,5.713.038-3.5-2.115,3.5Z"
                transform="translate(-268.054 -91.369)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconTradeINLogo;
