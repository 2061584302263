import React from "react";

const IconPlFlag = ({ props, width = "15" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 149.161 99.438"
    {...props}
  >
    <g id="Group_7" data-name="Group 7" transform="translate(0 -85.337)">
      <path
        id="Path_606"
        data-name="Path 606"
        d="M0,85.337H149.161v99.438H0Z"
        fill="#f0f0f0"
      />
      <path
        id="Path_607"
        data-name="Path 607"
        d="M0,85.337H149.161v49.719H0Z"
        fill="#f0f0f0"
      />
      <path
        id="Path_608"
        data-name="Path 608"
        d="M0,256H149.161v49.719H0Z"
        transform="translate(0 -120.944)"
        fill="#d80027"
      />
    </g>
  </svg>
);

export default IconPlFlag;
