import React from "react";

const IconGrFlag = ({ props, width = "15" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 148.199 111.149"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_680"
          data-name="Path 680"
          d="M0,0H148.2V111.149H0Z"
          fill="rgba(0,0,0,0.67)"
        />
      </clipPath>
    </defs>
    <g id="Group_20" data-name="Group 20" clipPath="url(#clip-path)">
      <path
        id="Path_669"
        data-name="Path 669"
        d="M0,0H166.724V12.35H0Z"
        fill="#0d5eaf"
        fillRule="evenodd"
      />
      <path
        id="Path_670"
        data-name="Path 670"
        d="M0,53.3H166.724V65.65H0Z"
        transform="translate(0 -40.95)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_671"
        data-name="Path 671"
        d="M0,106.6H166.724v12.35H0Z"
        transform="translate(0 -81.9)"
        fill="#0d5eaf"
        fillRule="evenodd"
      />
      <path
        id="Path_672"
        data-name="Path 672"
        d="M0,159.9H166.724v12.35H0Z"
        transform="translate(0 -122.85)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_673"
        data-name="Path 673"
        d="M0,213.2H166.724v12.35H0Z"
        transform="translate(0 -163.8)"
        fill="#0d5eaf"
        fillRule="evenodd"
      />
      <path
        id="Path_674"
        data-name="Path 674"
        d="M0,266.5H166.724v12.35H0Z"
        transform="translate(0 -204.75)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_675"
        data-name="Path 675"
        d="M0,319.8H166.724v12.35H0Z"
        transform="translate(0 -245.701)"
        fill="#0d5eaf"
        fillRule="evenodd"
      />
      <path
        id="Path_676"
        data-name="Path 676"
        d="M0,373.1H166.724v12.35H0Z"
        transform="translate(0 -286.651)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_677"
        data-name="Path 677"
        d="M0,98.8H166.724v12.35H0ZM0,0H61.75V61.75H0Z"
        fill="#0d5eaf"
        fillRule="evenodd"
      />
      <g id="Group_19" data-name="Group 19">
        <path
          id="Path_678"
          data-name="Path 678"
          d="M106.6,0h12.35V61.75H106.6Z"
          transform="translate(-81.9)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Path_679"
          data-name="Path 679"
          d="M0,106.6H61.75v12.35H0Z"
          transform="translate(0 -81.9)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default IconGrFlag;
