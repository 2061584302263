/** @jsx jsx */
import styled from "@emotion/styled";
import { Box, Button, Flex, jsx, Styled } from "theme-ui";
import Card from "../../../components/Card";
import WithLoader from "../../../components/WithLoader";
import Logo from "../../../assets/images/logo.png";
import { Form } from "react-final-form";
import { useCallback, useState } from "react";
import ErrorMessage from "../../../components/ErrorMessage";
import { checkEmail } from "../../../apis";
import RFFInputField from "../Login/RFFInputField";
import exceptionHandler from "../../../apis/exceptionHandler";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/LanguageSelector";
import { useToast } from "../../../contexts/AlertProvider";
import { composeValidators, isRequired } from "../../../utils/validators";

const LoginContainer = styled(Flex)`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background: rgb(52, 129, 185);
  background: linear-gradient(
    135deg,
    rgba(52, 129, 185, 1) 0%,
    rgba(177, 222, 255, 1) 50%,
    rgba(242, 114, 89, 1) 100%
  );
  padding: 0 1rem;

  .login-card {
    max-width: 32rem;
    padding: 2rem;
    border-radius: 0.3rem;
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.primary};

    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 1.5rem;
      color: ${(props) => props.theme.colors.primary};
    }

    .form-control {
      display: flex;
      align-items: flex-start;
      flex-wrap: no-wrap;
      width: 100%;

      label {
        flex: 0 0 8rem;
        margin: 0.75rem 0;
      }

      // for input width
      > * {
        flex: 1 1 auto;

        input {
          background-color: ${(props) => props.theme.colors.lightGray};
        }
      }

      .input {
        padding: 0.75rem 1.25rem;
      }

      ${(props) => props.theme.customBreakpoints[1]} {
        flex-wrap: wrap;
        label {
          width: 100%;
          flex: 1 1 auto;
        }
      }
    }

    .login-btn {
      margin-top: 1rem;
      min-width: 10rem;
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      padding: 1rem 1rem 2rem;

      .title {
        margin-bottom: 0.5rem;
      }

      .form-control {
        label {
          margin-top: 0;
        }
      }
    }
  }
`;

const Image = styled.img`
  display: block;
  height: 2.75rem;
  width: auto;
  margin-bottom: 1.5rem;
`;

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const onSubmit = useCallback(
    async (values) => {
      try {
        setIsLoading(true);
        await checkEmail(values);
        toast(t("mail_sent"), { type: "success" });
      } catch (e) {
        return exceptionHandler(
          e,
          (error) => {
            setErrorMessage(error);
          },
          (errors) => {
            return errors;
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [t, toast]
  );

  return (
    <LoginContainer>
      <Form
        onSubmit={onSubmit}
        validate={({ customer_number, email }) => ({
          customer_number: composeValidators(isRequired)(customer_number),
          email: composeValidators(isRequired)(email),
        })}
        render={({ handleSubmit }) => (
          <Card as="form" className="login-card" onSubmit={handleSubmit}>
            <span style={{ marginLeft: "auto", color: "#053668" }}>
              <LanguageSelector />
            </span>
            <Image className="brand-image" src={Logo} />
            <Styled.h4 className="title">
              {t("enter_account_details")}
            </Styled.h4>
            <ErrorMessage
              message={errorMessage}
              sx={{ marginBottom: 4, fontWeight: 400, width: "100%" }}
            />
            <RFFInputField
              placeholder={t("customer_number")}
              label={t("customer_number")}
              type={"text"}
              name={"customer_number"}
              onChange={() => {
                setErrorMessage(null);
              }}
            />
            <RFFInputField
              placeholder={t("email")}
              label={t("email")}
              type={"email"}
              name={"email"}
              onChange={() => {
                setErrorMessage(null);
              }}
            />

            <Box className="button-container">
              <WithLoader isLoading={isLoading}>
                <Button type="submit" variant="secondary" className="login-btn">
                  {t("submit")}
                </Button>
              </WithLoader>
            </Box>
          </Card>
        )}
      />
    </LoginContainer>
  );
};

export default ForgotPassword;
