import React from "react";
import { Box, Flex, Button, Text } from "theme-ui";
import themes from "../../../themes";
import { useTranslation } from "react-i18next";
import IconClose from "../../icons/icon-close";
import IconHelp from "../../icons/icon-help";
import PropTypes from "prop-types";

const TradeINHelpModal = ({ showState, setShowState }) => {
  const { t } = useTranslation();

  if (!showState) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: themes.colors.opaqueBlack,
        zIndex: 100,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: themes.colors.white,
          borderRadius: "4px",
          width: ["90%", "80%", "60%", "65%"],
          height: "80%",
          overflow: "auto",
          zIndex: 100,
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "70px",
            backgroundColor: themes.colors.snowWhite,
            borderBottom: `1px solid ${themes.colors.darkGray}`,
            padding: "20px",
          }}
        >
          <Flex
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <IconHelp width="18px" height="18px" fill={themes.colors.black} />
            <Text
              sx={{
                fontWeight: "bold",
                fontSize: 18,
                textTransform: "capitalize",
              }}
            >
              {t("help")}
            </Text>
          </Flex>
          <Box
            sx={{
              padding: 0,
              margin: 0,
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
            onClick={() => setShowState(false)}
          >
            <IconClose width="100%" height="100%" />
          </Box>
        </Flex>
        <Flex
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px",
            height: "calc( 100% - 70px )",
          }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Text sx={{ fontWeight: "bold", fontSize: 22 }}>
              {t("direct_buy_product")}
            </Text>
            <Text sx={{ mt: 1 }}>
              {t("trade_in_home_section_two_description_one")}
            </Text>
            <Text sx={{ mt: 2 }}>
              {t("trade_in_home_section_two_description_two")}
            </Text>
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Text sx={{ fontWeight: "bold", fontSize: 22, mt: 3 }}>
                {t("trade_in_home_section_three_title_one")}
              </Text>
              <Text sx={{ mt: 2 }}>
                {t("trade_in_home_section_three_description_one")}
              </Text>
            </Box>
            <Flex
              sx={{
                flexDirection: ["column", "row"],
                alignItems: ["center", "flex-start"],
                width: "100%",
                gap: 5,
              }}
            >
              <Box
                sx={{
                  width: ["90%", "50%"],
                  borderRadius: 6,
                  border: `1px solid ${themes.colors.black} `,
                  mt: 5,
                  padding: "2rem",
                  height: ["auto", "330px", "352px", "250px"],
                }}
              >
                <Text
                  sx={{
                    fontSize: 18,
                    borderRadius: "3px",
                    color: themes.colors.lightGreen,
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  {t("confirmed")}
                </Text>
                <Text>{t("trade_in_home_section_three_description_two")}</Text>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  width: ["90%", "50%"],
                  borderRadius: 6,
                  border: `1px solid ${themes.colors.black} `,
                  mt: 5,
                  padding: "2rem",
                  height: ["auto", "330px", "352px", "250px"],
                }}
              >
                <Text
                  sx={{
                    fontSize: 18,
                    borderRadius: "3px",
                    color: themes.colors.red,
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  {t("review")}
                </Text>
                <Text>
                  {t("trade_in_home_section_three_description_three")}
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Text sx={{ fontWeight: "bold", fontSize: 22, mt: 3 }}>
              {t("direct_buy_only_filter")}
            </Text>
            <Text sx={{ mt: 2 }}>
              {t("direct_buy_only_filter_description")}
            </Text>
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Text sx={{ fontWeight: "bold", fontSize: 22, mt: 3 }}>
              {t("add_product_header")}
            </Text>
            <Text sx={{ mt: 2 }}>{t("add_product_header_description")}</Text>
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
              gap: "5px",
              padding: "20px 0px",
              alignSelf: "flex-end",
              width: "100%",
            }}
          >
            <Button
              sx={{
                width: ["50%", "28%", "25%", "20%"],
                borderRadius: "3px",
                backgroundColor: themes.colors.primary,
                color: themes.colors.white,
                textTransform: "capitalize",
              }}
              type="submit"
              onClick={() => setShowState(false)}
            >
              {t("close")}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

TradeINHelpModal.propTypes = {
  showState: PropTypes.bool,
  setShowState: PropTypes.func,
};

export default TradeINHelpModal;
