import React, { useCallback } from "react";
import { Box, Button, Heading, Grid, Card } from "theme-ui";
import styled from "@emotion/styled";
import Container from "../../../components/Container";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";
import IconFileText from "../../../components/icons/icon-file-text";
import IconExchange from "../../../components/icons/icon-exchange";

const RmaHomeContainer = styled(Box)`
  margin: 3rem 0;

  .page-header {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }
    .svg-icon {
      margin-right: 0.5rem;
      height: 1.75rem;
      width: 1.75rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  .container {
    max-width: 65rem;
    .info {
      font-size: 16px;
      margin: 3px;
      text-align: center;
    }
  }
  .card {
    border: 1px solid black;
    margin: 10px;
    .card-header {
      background-color: aliceblue;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      font-weight: bold;
      text-align: center;
    }
    .card-body {
      padding: 5px;
      text-align: center;
      .text {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
`;

const IconSearch = ({
  fill = "inherit",
  width = "3rem",
  height = "3rem",
  className = "",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    fill={fill}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M10,18c1.846,0,3.543-0.635,4.897-1.688l4.396,4.396l1.414-1.414l-4.396-4.396C17.365,13.543,18,11.846,18,10 c0-4.411-3.589-8-8-8s-8,3.589-8,8S5.589,18,10,18z M10,4c3.309,0,6,2.691,6,6s-2.691,6-6,6s-6-2.691-6-6S6.691,4,10,4z" />
  </svg>
);

export const RmaHome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const createRma = useCallback(() => history.push("/rma/create"), [history]);
  const searchRma = useCallback(() => history.push("/rma/search"), [history]);
  const returnRma = useCallback(() => history.push("/rma/return"), [history]);
  return (
    <>
      <Header />
      <Grid columns={1}>
        <RmaHomeContainer>
          <Container className="container">
            <Box className="page-header">
              <Heading className="heading">{t("rma_portal")}</Heading>
            </Box>
          </Container>
          <Container className="container">
            <div className={"info"}>
              <p>{t("rma_welcome")}</p>
              <p>
                {t("rma_home_text")} &nbsp;
                <span
                  className="link"
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => window.open("mailto:rma@yukatel.de", "_self")}
                >
                  rma@yukatel.de
                </span>
              </p>
              <p>{t("rma_home_options_text")}</p>
            </div>
            <Grid columns={3} gap={2}>
              <Card
                variant={"primary"}
                className={"card"}
                style={{ position: "relative" }}
              >
                <Box className={"card-header"}>{t("search_rma")}</Box>
                <Box className={"card-body"}>
                  <IconSearch></IconSearch>
                  <div className={"text"}>
                    <p>{t("look_for_existing_cases")}</p>
                  </div>
                </Box>
                <Button
                  variant={"secondary"}
                  sx={{
                    width: "100%",
                    borderRadius: 0,
                    position: "absolute",
                    bottom: "0",
                  }}
                  onClick={() => searchRma()}
                >
                  {t("start")}
                </Button>
              </Card>
              <Card variant={"primary"} className={"card"}>
                <Box className={"card-header"}>{t("create_service_rma")}</Box>
                <Box className={"card-body"}>
                  <IconFileText></IconFileText>
                  <div className={"text"}>
                    <p>{t("open_service_case")}</p>
                    <p>{t("doa_repair_case")}</p>
                  </div>
                </Box>
                <Button
                  variant={"secondary"}
                  sx={{ width: "100%", borderRadius: 0 }}
                  onClick={() => createRma()}
                >
                  {t("start")}
                </Button>
              </Card>
              <Card variant={"primary"} className={"card"}>
                <Box className={"card-header"}>{t("create_return_rma")}</Box>
                <Box className={"card-body"}>
                  <IconExchange></IconExchange>
                  <div className={"text"}>
                    <p>{t("open_repair_case")}</p>
                    <p>{t("wrong_deliveries")}</p>
                  </div>
                </Box>
                <Button
                  variant={"secondary"}
                  sx={{ width: "100%", borderRadius: 0 }}
                  onClick={() => returnRma()}
                >
                  {t("start")}
                </Button>
              </Card>
            </Grid>
          </Container>
        </RmaHomeContainer>
      </Grid>
      <Footer />
    </>
  );
};
