import React from "react";

const IconItFlag = ({ props, width = "15" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 149.161 99.442"
    {...props}
  >
    <g id="Group_5" data-name="Group 5" transform="translate(0 -85.33)">
      <path
        id="Path_599"
        data-name="Path 599"
        d="M99.441,85.33H0v99.44H149.161V85.33Z"
        fill="#f0f0f0"
      />
      <path
        id="Path_600"
        data-name="Path 600"
        d="M0,85.333H49.719v99.442H0Z"
        transform="translate(0 -0.002)"
        fill="#6da544"
      />
      <path
        id="Path_601"
        data-name="Path 601"
        d="M341.337,85.333h49.719v99.442H341.337Z"
        transform="translate(-241.895 -0.002)"
        fill="#d80027"
      />
    </g>
  </svg>
);

export default IconItFlag;
