import React from "react";
import styled from "@emotion/styled";
import { Box, Flex, Heading } from "theme-ui";
import Container from "../../../components/Container";
import { useTranslation } from "react-i18next";

const DownloadContainer = styled(Box)`
  margin: 3rem 0;

  .page-header {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }
    .svg-icon {
      margin-right: 0.5rem;
      height: 1.75rem;
      width: 1.75rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }
  .text-primary {
    color: ${(props) => props.theme.colors.primary};
  }

  .text-secondary {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const Download = () => {
  const { t } = useTranslation();
  return (
    <DownloadContainer>
      <Container>
        <Box className="page-header">
          <Heading className="heading">Downloads</Heading>
        </Box>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            textAlign: "center",
            padding: "2.5rem 0",
          }}
        >
          <Heading sx={{ marginBottom: "2.5rem", fontWeight: 400 }}>
            {t("you_currently_have_no_files_to_download")}
          </Heading>
          <Box>
            <Heading sx={{ lineHeight: "1.5" }}>
              {t("need_help")} {""}
              <span className="text-primary">
                {t("call_us_at")} {""}
              </span>
              <span
                className="text-secondary"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("tel:+49 (0) 69 83 83 25 0", "_self")
                }
              >
                +49 (0) 69 83 83 25 0{" "}
              </span>
              <span className="text-primary"> {t("or_contact_us_by")} </span>
              <span
                style={{ cursor: "pointer" }}
                className="text-secondary"
                onClick={() => window.open("mailto:info@yukatel.de", "_self")}
              >
                {t("email")}
              </span>
            </Heading>
          </Box>
        </Flex>
      </Container>
    </DownloadContainer>
  );
};

export default Download;
