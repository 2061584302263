import React, { useEffect, useMemo, useState } from "react";
import "../style.css";
import themes from "../themes";
import { dateFormat } from "../utils/helpers";
import { useTranslation } from "react-i18next";

const Timer = ({ dateStr }) => {
  const [remainingTime, setRemainingTime] = useState(dateStr);
  const { t } = useTranslation();
  useEffect(() => {
    const interval = setInterval(
      () => setRemainingTime((prev) => prev - 1000),
      1000
    );
    return () => clearInterval(interval);
  }, [dateStr]);

  const { hours, minutes, seconds } = useMemo(() => dateFormat(remainingTime), [
    remainingTime,
  ]);

  return (
    <div className="countdown">
      <div className="content">
        <div className="box">
          <span
            style={{
              color: themes.colors.primary,
              fontWeight: "bold",
              fontSize: "12px",
              height: "23px",
            }}
          >
            {t("hh")}
          </span>
          <div className="value">
            <span>{hours}</span>
          </div>
        </div>
        <div className="box">
          <span
            style={{
              color: themes.colors.primary,
              fontWeight: "bold",
              fontSize: "12px",
              height: "23px",
            }}
          >
            {t("mm")}
          </span>
          <div className="value">
            <span>{minutes}</span>
          </div>
        </div>
        <div className="box">
          <span
            style={{
              color: themes.colors.primary,
              fontWeight: "bold",
              fontSize: "12px",
              height: "23px",
            }}
          >
            {t("ss")}
          </span>
          <div className="value">
            <span>{seconds}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
