import React, { createContext, useState } from "react";

export const MobileCartContext = createContext();

const MobileCartProvider = ({ children }) => {
  const [isMobileCartVisible, setIsMobileCartVisible] = useState(false);

  return (
    <MobileCartContext.Provider
      value={[isMobileCartVisible, setIsMobileCartVisible]}
      children={children}
    />
  );
};

export default MobileCartProvider;
