import { useQuery } from "react-query";
import { getBanners } from "../../../apis";
import React, { useCallback, useRef, useState } from "react";
import { Box, Button, Flex, Spinner } from "theme-ui";
import IconCheveronDown from "../../../components/icons/icon-cheveron-down";
import Container from "../../../components/Container";
import Input from "../../../components/form-fields/Input";
import IconSearch from "../../../components/icons/icon-search";
import styled from "@emotion/styled";
import { transparentize } from "@theme-ui/color";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as QueryString from "qs";
import { useTranslation } from "react-i18next";
import { addDefaultSource } from "../../../utils/helpers";
import DefaultImage from "../../../assets/images/banner1.png";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const BannerContainer = styled(Box)`
  position: relative;
  margin-bottom: 3rem;

  height: 27rem;
  width: 100%;

  ${(props) => props.theme.customBreakpoints[0]} {
    height: auto;
  }

  // tab - ipad view
  @media (max-width: 1020px) {
    height: 12rem;

    .banner-image,
    .banner-controls {
      display: none;
    }

    .banner-info-container {
      .container {
        align-items: center;

        .search-input-container {
          width: 100%;
        }
      }
    }
  }

  .banner-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-width: 2560px;
    margin: 0 auto;
    min-height: 172px;

    &.while-loading {
      max-height: 350px;

      img {
        opacity: 0;
        visibility: none;
      }
    }

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }

    .slick-slide {
      & > div {
        height: 100%;
        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .banner-controls {
    .next-control,
    .prev-control {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      fill: ${(props) => props.theme.colors.secondary};
      cursor: pointer;
      z-index: 10;

      .svg-icon {
        width: 5rem;
        height: 5rem;
      }

      &:hover {
        background: ${(props) =>
          transparentize(props.theme.colors.secondary, 0.92)};
      }

      ${(props) => props.theme.customBreakpoints[1]} {
        .svg-icon {
          height: 4rem;
          width: 4rem;
        }
      }
    }

    .next-control {
      right: 0;

      .svg-icon {
        transform: rotate(270deg);
      }
    }

    .prev-control {
      left: 0;

      .svg-icon {
        transform: rotate(90deg);
      }
    }
  }

  .banner-info-container {
    color: ${(props) => props.theme.colors.white};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 1.5rem;
    width: 100%;

    .container {
      display: flex;
      flex-direction: column;
      padding-left: 5rem;
      padding-right: 5rem;
    }

    ${(props) => props.theme.customBreakpoints[1]} {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .container {
        text-align: center;
        justify-content: center;
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      padding: 0;
    }

    .heading {
      font-size: 2.125rem;
      font-family: "Raleway", sans-serif;
      font-weight: 300;
      margin-bottom: 1rem;

      ${(props) => props.theme.customBreakpoints[1]} {
        font-size: 1.5rem;
      }
    }

    .sub-heading {
      font-size: 3rem;
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      margin-bottom: 2.5rem;

      ${(props) => props.theme.customBreakpoints[1]} {
        font-size: 1.75rem;
      }
    }
  }
`;

const SearchInputContainer = styled(Flex)`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 36rem;

  .input-container {
    width: 100%;

    .input {
      border-radius: 0.3rem;
      padding: 1rem 8.75rem 1rem 1.25rem;
      font-size: 1rem;

      &::placeholder {
        color: ${(props) => props.theme.colors.primaryText};
      }

      &:focus,
      &:active {
        border-color: ${(props) => props.theme.colors.secondary};
      }
    }
  }

  .search-btn {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 0.3rem 0.3rem 0;
    display: flex;
    align-items: center;
    height: 3em;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    .svg-icon {
      margin-right: 0.5rem;
      fill: ${(props) => props.theme.colors.white};
    }
  }

  ${(props) => props.theme.customBreakpoints[1]} {
    max-width: 100%;

    .input-container {
      .input {
        padding-left: 1rem;
        padding-right: 3.5rem;
      }
    }

    .search-btn {
      padding-left: 1rem;
      padding-right: 1rem;

      .svg-icon {
        margin: 0;
      }

      span {
        display: none;
      }
    }
  }
`;

const Banner = () => {
  const history = useHistory();
  const slider = useRef();
  const { t } = useTranslation();

  const { data, isLoading } = useQuery("banners-home", getBanners);
  const bannerList = data?.data;
  const [query, setQuery] = useState("");

  const search = useCallback(() => {
    if (query)
      history.push({
        pathname: "/shop",
        search: "?" + QueryString.stringify({ search: query }),
      });
  }, [history, query]);

  let loadingView = null;

  if (isLoading) {
    loadingView = (
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spinner></Spinner>
      </Flex>
    );
  } else {
    if (bannerList && bannerList?.length > 0) {
      loadingView = (
        <>
          <Box className="banner-image">
            <Slider {...settings} ref={slider}>
              {bannerList?.map((item) => {
                return (
                  <img
                    key={item.id}
                    src={item.file}
                    alt={"banner image " + item.id}
                    height="20rem"
                    onError={(e) => {
                      e.target.src = DefaultImage;
                    }}
                  />
                );
              })}
            </Slider>
          </Box>
          {bannerList.length > 1 && (
            <Box className="banner-controls">
              <Box
                className="prev-control"
                onClick={() => {
                  {
                    slider.current.slickPrev();
                  }
                }}
              >
                <IconCheveronDown />
              </Box>
              <Box
                className="next-control"
                onClick={() => {
                  {
                    slider.current.slickNext();
                  }
                }}
              >
                <IconCheveronDown />
              </Box>
            </Box>
          )}
        </>
      );
    } else {
      loadingView = (
        <Box className="banner-image">
          <img
            src={DefaultImage}
            alt={"banner image "}
            onError={addDefaultSource}
          />
        </Box>
      );
    }
  }

  return (
    <BannerContainer>
      {loadingView}
      <Box className="banner-info-container">
        <Container className="container">
          <SearchInputContainer className="search-input-container">
            <Input
              label={t("search_ean")}
              type="search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  search();
                }
              }}
            />
            <Button variant="secondary" className="search-btn" onClick={search}>
              <IconSearch onClick={() => search()} /> <span>{t("search")}</span>
            </Button>
          </SearchInputContainer>
        </Container>
      </Box>
    </BannerContainer>
  );
};

export default Banner;
