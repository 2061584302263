/** @jsx jsx */
import styled from "@emotion/styled";
import { Flex, jsx } from "theme-ui";
import Card from "../../../components/Card";
import Logo from "../../../assets/images/logo.png";
import { useAuthDispatch } from "../../../contexts/AuthContext";
import { useCallback, useEffect, useState } from "react";
import ErrorMessage from "../../../components/ErrorMessage";
import { invoiceLogin } from "../../../apis";
import exceptionHandler from "../../../apis/exceptionHandler";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { useLogout } from "../../../contexts/AuthContext";

const LoginContainer = styled(Flex)`
  width: 100vw;
  align-items: center;
  justify-content: center;
  background: rgb(52, 129, 185);
  background: linear-gradient(
    135deg,
    rgba(52, 129, 185, 1) 0%,
    rgba(177, 222, 255, 1) 50%,
    rgba(242, 114, 89, 1) 100%
  );
  padding: 0 1rem;

  .login-card {
    max-width: 32rem;
    padding: 2rem;
    border-radius: 0.3rem;
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.primary};

    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 1.5rem;
      color: ${(props) => props.theme.colors.primary};
    }

    .form-control {
      display: flex;
      align-items: flex-start;
      flex-wrap: no-wrap;
      width: 100%;

      label {
        flex: 0 0 8rem;
        margin: 0.75rem 0;
      }

      // for input width
      > * {
        flex: 1 1 auto;

        input {
          background-color: ${(props) => props.theme.colors.lightGray};
        }
      }

      .input {
        padding: 0.75rem 1.25rem;
      }

      ${(props) => props.theme.customBreakpoints[1]} {
        flex-wrap: wrap;
        label {
          width: 100%;
          flex: 1 1 auto;
        }
      }
    }

    .login-btn {
      margin-top: 1rem;
      min-width: 10rem;
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      padding: 1rem 1rem 2rem;

      .title {
        margin-bottom: 0.5rem;
      }

      .form-control {
        label {
          margin-top: 0;
        }
      }
    }
  }
`;

const Image = styled.img`
  display: block;
  height: 2.75rem;
  width: auto;
  margin-bottom: 1.5rem;
`;

const InvoiceLogin = () => {
  const { t } = useTranslation();
  const authDispatch = useAuthDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const { search: searchQuery } = useLocation();
  const history = useHistory();
  const authToken = qs.parse(searchQuery, {
    ignoreQueryPrefix: true,
  }).token;

  const logout = useLogout();
  const onSubmit = useCallback(
    async (values) => {
      try {
        const response = await invoiceLogin(values);
        authDispatch({
          type: "LOGIN",
          payload: {
            token: response.data.token,
            password_change_required: response.data.password_change_required,
            invoice_login: response.data.invoice_login,
          },
        });
        history.push("/account/invoices");
      } catch (e) {
        return exceptionHandler(
          e,
          (error) => {
            logout();
            setErrorMessage(error);
          },
          (errors) => {
            return errors;
          }
        );
      }
    },
    [authDispatch, history, logout]
  );

  useEffect(() => {
    if (authToken) {
      onSubmit({ auth_code: authToken });
    } else {
      logout();
      setErrorMessage(t("invalid_token"));
    }
  }, [authToken]);

  return (
    <LoginContainer>
      <Card className="login-card">
        <Image className="brand-image" src={Logo} />
        <ErrorMessage
          message={errorMessage}
          sx={{ marginBottom: 4, fontWeight: 400, width: "100%" }}
        />
        {authToken && !errorMessage && <span>Please Wait...</span>}
      </Card>
    </LoginContainer>
  );
};

export default InvoiceLogin;
