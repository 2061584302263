import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text } from "theme-ui";
import Money from "../../../components/Money";
import DirectBuyLogo from "../../../assets/images/Direct-buy.png";
import IconDocument from "../../../components/icons/icon-document";
import CounterOfferLogo from "../../../assets/images/Counter-offer.png";
import themes from "../../../themes";
import { parseDate } from "../../../utils/helpers";
import IconRotateSolid from "../../../components/icons/icon-rotate-solid";
import { TradeIn_Status } from "../../../constants";
import InitialSubmissionModal from "../../../components/TradeIn/modals/InitialSubmissionModal";
import YukatelLogo from "../../../assets/images/logo.png";
import OfferUsModal from "../../../components/TradeIn/modals/OfferUsModal";
import TradeInHistoryModal from "../../../components/TradeIn/modals/TradeInHistoryModal";
import IconClockRotate from "../../../components/icons/icon-clock-rotate-left";

const ApprovedAndRejectedTable = ({
  data,
  status,
  statusValue,
  refetch,
  isLoading,
}) => {
  const { t } = useTranslation();
  const isRejected = status === TradeIn_Status.STATUS_REJECTED.text;
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const maxWidthName = isRejected ? "180px" : "160px";

  return (
    <>
      {!isLoading && (!data || data?.length === 0) ? (
        <Text
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "4rem",
          }}
        >
          {t("no_data_in_the_list")}
        </Text>
      ) : (
        <Box className="table-container">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th>{t("product_name")}</th>
                <th style={{ textAlign: "left" }}>
                  <span style={{ justifyContent: "center" }}>{t("date")}</span>
                </th>
                <th style={{ textAlign: "center" }}>{t("quantity")}</th>
                <th style={{ textAlign: "center" }}>{t("unit_price")}</th>
                <th />
                <th />
                <th style={{ justifyContent: "flex-end" }}>{t("by")}</th>
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody className="table-body">
              {data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={{
                        maxWidth: maxWidthName,
                        textAlign: "left",
                      }}
                    >
                      {item?.article_name}
                    </td>
                    <td style={{ maxWidth: "20px" }}>
                      {parseDate(item?.created_at)}
                    </td>
                    <td
                      style={{ textAlign: "center" }}
                      className={isRejected ? "red-text" : "green-text"}
                    >
                      {item?.latest_counter_offer
                        ? item?.latest_counter_offer?.quantity
                        : item.min_order_quantity}
                    </td>
                    <td
                      style={{ textAlign: "center" }}
                      className={isRejected ? "red-text" : "green-text"}
                    >
                      <span>
                        <Money
                          amount={
                            item?.latest_counter_offer
                              ? item?.latest_counter_offer.price
                              : item?.unit_price
                          }
                        />
                      </span>
                    </td>
                    <td />
                    <td style={{ maxWidth: "96px" }}>
                      <Flex
                        sx={{
                          justifyContent: "flex-start",
                        }}
                      >
                        {item?.is_direct_buy || item?.latest_counter_offer ? (
                          item?.is_direct_buy ? (
                            <img
                              src={DirectBuyLogo}
                              width="80px"
                              height="19px"
                              alt="direct_buy"
                            />
                          ) : (
                            <img
                              src={CounterOfferLogo}
                              width="100px"
                              height="20px"
                              alt="counter_offer"
                            />
                          )
                        ) : (
                          <div></div>
                        )}
                      </Flex>
                    </td>
                    <td>
                      <Flex
                        sx={{
                          justifyContent: "flex-start",
                        }}
                      >
                        {item?.concluded_by === 1 ? (
                          <img
                            src={YukatelLogo}
                            width="80px"
                            height="19px"
                            alt="direct_buy"
                            style={{ marginTop: "0.3em" }}
                          />
                        ) : item?.concluded_by === 0 ? (
                          <Text sx={{ fontSize: "1rem", fontWeight: "500" }}>
                            {t("seller")}
                          </Text>
                        ) : null}
                      </Flex>
                    </td>
                    <td />
                    <td>
                      {isRejected && (
                        <Flex
                          sx={{
                            alignItems: "center",
                            width: "30px",
                            height: "30px",
                            borderRadius: "20%",
                            backgroundColor: themes.colors.lightBlue,
                            padding: "6px",
                            cursor: "pointer",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setShowOfferModal(true);
                            setSelectedItem(item);
                          }}
                          title={t("offer_us")}
                        >
                          <IconRotateSolid
                            width="15px"
                            fill={themes.colors.primary}
                          />
                        </Flex>
                      )}
                    </td>
                    <td>
                      <Flex
                        sx={{
                          gap: "20px",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        {item?.latest_counter_offer && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setShowHistoryModal(true);
                              setSelectedItem(item);
                            }}
                            title={t("history")}
                          >
                            <IconClockRotate fill={themes.colors.primary} />
                          </div>
                        )}
                        <div
                          style={{
                            cursor: "pointer",
                            marginRight: item.latest_counter_offer
                              ? "2.8rem"
                              : "0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setShowDetailModal(true);
                            setSelectedItem(item);
                          }}
                          title={t("initial_submission")}
                        >
                          <IconDocument fill={themes.colors.primary} />
                        </div>
                      </Flex>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      )}
      {showOfferModal && (
        <OfferUsModal
          setShowOfferModal={setShowOfferModal}
          selectedItem={selectedItem}
          statusValue={statusValue}
          refetch={refetch}
        />
      )}
      {showDetailModal && (
        <InitialSubmissionModal
          setShowInitialSubmissionModal={setShowDetailModal}
          selectedItem={selectedItem}
        />
      )}
      {showHistoryModal && (
        <TradeInHistoryModal
          setShowHistoryModal={setShowHistoryModal}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};

export default ApprovedAndRejectedTable;
