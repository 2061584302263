import React from "react";
import themes from "../../../themes";

const RFFErrorMessage = ({ errorMessage }) => {
  return (
    <span
      style={{
        position: "absolute",
        left: "0px",
        bottom: "-20px",
        color: themes.colors.danger,
        userSelect: "none",
      }}
    >
      {errorMessage}
    </span>
  );
};

export default RFFErrorMessage;
