import React from "react";
import { TradeIn_Status } from "../../../constants";
import UnderReviewTable from "../../../pages/authenticated/TradeInOldOffer/UnderReviewTable";
import ApprovedAndRejectedTable from "../../../pages/authenticated/TradeInOldOffer/ApprovedAndRejectedTable";

const components = new Map([
  [TradeIn_Status.STATUS_COUNTER_OFFER.value, UnderReviewTable],
  [TradeIn_Status.STATUS_UNDER_REVIEW.value, UnderReviewTable],
  [TradeIn_Status.STATUS_APPROVED.value, ApprovedAndRejectedTable],
  [TradeIn_Status.STATUS_REJECTED.value, ApprovedAndRejectedTable],
  [TradeIn_Status.STATUS_LOST_OPPURTUNITIES.value, UnderReviewTable],
]);

export const StatusComponent = (props) => {
  const Component = components.get(props.statusValue);
  return Component ? <Component {...props} /> : null;
};
