import React, { useMemo, useCallback } from "react";
import { Box, Label, Input } from "theme-ui";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import RFFErrorMessage from "./RFFErrorMessage";
import themes from "../../../themes";
import {
  getWeekdaysAfterDate,
  areDatesEqual,
  convertToDate,
} from "../../../utils/helpers";
import PropTypes from "prop-types";

const locales = {
  "en-GB": import("date-fns/locale/en-GB"),
  de: import("date-fns/locale/de"),
  es: import("date-fns/locale/es"),
  it: import("date-fns/locale/it"),
  fr: import("date-fns/locale/fr"),
  nl: import("date-fns/locale/nl"),
  tr: import("date-fns/locale/tr"),
  pl: import("date-fns/locale/pl"),
  hr: import("date-fns/locale/hr"),
  ro: import("date-fns/locale/ro"),
  el: import("date-fns/locale/el"),
  sv: import("date-fns/locale/sv"),
  hu: import("date-fns/locale/hu"),
  cs: import("date-fns/locale/cs"),
};

Object.entries(locales).forEach(([key, value]) => {
  value.then((locale) => {
    registerLocale(key, locale.default);
  });
});

const RFFDayPicker = ({
  id,
  name,
  label,
  minimumDate,
  defaultValue,
  isDirectBuy,
}) => {
  const { i18n } = useTranslation();
  const activeLanguage = useCallback(i18n.language, [i18n.language]);

  const defaultDate = useMemo(() => convertToDate(defaultValue), [
    defaultValue,
  ]);

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      {label && <Label sx={{ marginBottom: "2px" }}>{label}</Label>}
      <Field name={name} id={id} initialValue={defaultDate}>
        {({ input, meta }) => (
          <>
            <DatePicker
              {...input}
              customInput={
                <Input
                  sx={{
                    borderRadius: "6px",
                    padding: "8px 10px",
                    backgroundColor: themes.colors.inputBG,
                    borderColor:
                      meta.touched && meta.error
                        ? themes.colors.danger
                        : themes.colors.borderDarkGray,
                  }}
                />
              }
              dateFormat="dd/MM/yyyy"
              selected={input.value}
              onChange={(date) => input.onChange(date)}
              locale={activeLanguage}
              defaultDate={defaultDate}
              placeholderText={defaultDate}
              minDate={convertToDate(minimumDate)}
              dayClassName={(date) =>
                isDirectBuy &&
                getWeekdaysAfterDate(minimumDate).some((weekday) =>
                  areDatesEqual(weekday, date)
                )
                  ? "green-date"
                  : null
              }
            />
            {meta.touched && meta.error && (
              <RFFErrorMessage errorMessage={meta.error} />
            )}
          </>
        )}
      </Field>
    </Box>
  );
};

RFFDayPicker.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  minimumDate: PropTypes.string,
  defaultValue: PropTypes.string,
  isDirectBuy: PropTypes.bool,
};

export default RFFDayPicker;
