import React from "react";
import CreateRma from "../../authenticated/RMA/CreateRma";
import Header from "./Header";
import Footer from "./Footer";

export const CommonRma = () => {
  return (
    <>
      <Header />
      <CreateRma />;
      <Footer />
    </>
  );
};
