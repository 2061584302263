import React from "react";
import Login from "../pages/unauthenticated/Login";
import { Redirect } from "react-router-dom";
import Password from "../pages/unauthenticated/Password";
import ResetPassword from "../pages/unauthenticated/Password/ResetPassword";
import TermsAndConditions from "../pages/authenticated/TermsAndConditions";
import { CommonRma } from "../pages/common/RMA";
import { RmaHome } from "../pages/common/RMA/Home";
import { SearchRma } from "../pages/common/RMA/SearchRma";
import CreateRmaReturn from "../pages/common/RMA/CreateRmaReturn";
import InvoiceLogin from "../pages/unauthenticated/InvoiceLogin";
import HomePage from "../pages/unauthenticated/HomePage";

export const RedirectPage = () => <Redirect to="/login" />;

export default [
  {
    component: RedirectPage,
    name: "Login",
    to: "/",
    exact: true,
  },
  {
    component: Login,
    name: "Login",
    to: "/login",
    exact: true,
  },
  {
    component: Password,
    name: "Forgot Password",
    to: "/forgot-password",
    exact: true,
  },
  {
    component: ResetPassword,
    name: "Reset Password",
    to: "/reset-password",
    exact: true,
  },
  {
    component: TermsAndConditions,
    name: "TermsAndConditions",
    to: "/terms-and-conditions/",
    exact: true,
  },
  {
    component: CommonRma,
    name: "Create Rma",
    to: "/rma/create",
    exact: true,
  },
  {
    component: RmaHome,
    name: "Rma Home",
    to: "/rma/home",
    exact: true,
  },
  {
    component: SearchRma,
    name: "Search Rma",
    to: "/rma/search",
    exact: true,
  },
  {
    component: CreateRmaReturn,
    name: "Create Rma Return",
    to: "/rma/return",
    exact: true,
  },
  {
    component: InvoiceLogin,
    name: "Invoice Login",
    to: "/invoice-login",
    exact: true,
  },
  {
    component: HomePage,
    name: "Trade in",
    to: "/home-trade-in",
    exact: true,
  },
];
