import { Redirect } from "react-router-dom";
import React from "react";
import Container from "../components/Container";
import { MyAccountContainer } from "../pages/authenticated/Account";
import ExternalInvoices from "../pages/authenticated/ExternalInvoice";
import InvoiceLogin from "../pages/unauthenticated/InvoiceLogin";

export const AuthenticatedInvoicePage = () => (
  <Redirect to="/account/invoices" />
);

export default [
  {
    component: () => (
      <MyAccountContainer>
        <Container>
          <ExternalInvoices />
        </Container>
      </MyAccountContainer>
    ),
    name: "Invoices",
    to: "/account/invoices",
    exact: false,
  },
  {
    component: InvoiceLogin,
    name: "Invoice Login",
    to: "/invoice-login",
    exact: true,
  },
];
