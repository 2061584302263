/** @jsx jsx */
import { Box, jsx } from "theme-ui";

// eslint-disable-next-line react/prop-types
const Container = ({ sx, ...props }) => (
  <Box
    sx={{
      maxWidth: "1400px",
      width: "100%",
      margin: "0 auto",
      padding: "0 1rem",
      ...sx,
    }}
    {...props}
  />
);

export default Container;
