import { Badge, Box, Button } from "theme-ui";
import React, { useState } from "react";
import WithLoader from "../../../components/WithLoader";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { searchRma, getRmaDocumentPublic } from "../../../apis";
import styled from "@emotion/styled";
import IconSearch from "../../../components/icons/icon-search";
import Input from "../../../components/form-fields/Input";
import { transparentize } from "@theme-ui/color";
import { RMA_STATUS_BADGE_VARIANT } from "../../../utils/helpers";
import { useToast } from "../../../contexts/AlertProvider";
import Header from "./Header";
import Footer from "./Footer";

const Container = styled(Box)`
  margin: 3rem 1rem;

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0.5rem 1rem;

    .filter-menu {
      display: inline-flex;
      border: 1px solid ${(props) => props.theme.colors.secondary};
      border-radius: 2rem;
      padding: 0.375rem 1.25rem;
      height: auto;
      color: ${(props) => props.theme.colors.primary};
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 2rem;

      .svg-icon {
        height: 1.25rem;
        width: 1.25rem;
      }

      &:hover {
        background-color: ${(props) =>
          transparentize(props.theme.colors.primaryText, 0.96)};
      }
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      flex-wrap: wrap;
      justify-content: center;

      > .filter-menu {
        margin-right: 0;
        margn-bottom: 0.5rem;
      }
    }
  }

  .table-container {
    overflow: hidden;
    overflow-x: auto;
    border: 1px solid ${(props) => props.theme.colors.primary};
    width: 100%;

    .table {
      width: 100%;
      font-size: 0.875rem;

      .table-head {
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.white};

        th {
          padding: 0.75rem 1rem;
          text-transform: uppercase;
          line-height: 1.6;
          text-align: left;

          .svg-icon {
            margin-left: 0.5rem;
            height: 1.25rem;
            min-width: 1.25rem;
            fill: ${(props) => props.theme.colors.white};

            &.active {
              fill: ${(props) => props.theme.colors.secondary};
            }
          }
        }
      }

      .table-body {
        tr {
          &:nth-of-type(even) {
            background-color: #dcdcdd;
          }

          td {
            padding: 0.5rem 1rem;
            vertical-align: middle;

            .table-counter-input {
              display: flex;
              position: relative;

              .input {
                max-width: 5rem;
                width: 5rem;
                border-radius: 0;
                background-color: transparent;
                border-color: ${(props) => props.theme.colors.primary};
                border-bottom: 1px solid transparent;
              }

              .arrow-counter {
                position: absolute;
                right: 0;
                height: 100%;
              }
            }

            .icon-button {
              height: 1.5rem;
              width: 1.5rem;
              min-width: 1.5rem;
              min-height: 1.5rem;
              margin-left: 0.75rem;

              .svg-icon {
                height: 1.25rem;
                width: 1.25rem;
              }
            }
          }
        }
      }
    }
  }
`;

const STATUS_MAP = ["waiting", "processing", "declined", "completed"];

const SearchRmaInner = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState();
  const [searchText, setSearchText] = useState();
  const [isFetchingDocument, setIsFetchingDocument] = useState(false);
  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const toast = useToast();
  const { data, isLoading, isFetching } = useQuery(
    ["rma", searchText],
    searchRma,
    {
      retry: false,
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      enabled: Boolean(searchText),
    }
  );

  const tableData = data?.data || [];

  const onPrintRma = async (id, type) => {
    setSelectedId(id);
    if (type === "document") {
      setIsFetchingDocument(true);
    } else {
      setIsFetchingReport(true);
    }
    getRmaDocumentPublic(id, type)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/pdf",
        });
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl, "_blank");
      })
      .catch(() => toast(t("document_not_available"), { type: "error" }))
      .finally(() => {
        setIsFetchingDocument(false);
        setIsFetchingReport(false);
      });
  };

  let tableBody = null;

  if (isLoading || isFetching) {
    tableBody = (
      <tr>
        <td colSpan={9} rowSpan={7}>
          <WithLoader isLoading={true} height={"16rem"} />
        </td>
      </tr>
    );
  } else {
    if (tableData?.length > 0) {
      tableBody = tableData?.map((item, index) => {
        const {
          artnr,
          created,
          id,
          product,
          rma,
          serial,
          serial_scan_time,
          status,
          type,
        } = item;

        return (
          <tr key={index}>
            <td>{rma}</td>
            <td>{type}</td>
            <td>{artnr}</td>
            <td>{product}</td>
            <td>{serial}</td>
            <td>{created}</td>
            <td>{serial_scan_time}</td>
            <td>
              <Box className="flex-center">
                <Badge
                  variant={
                    !Number.isNaN(status)
                      ? RMA_STATUS_BADGE_VARIANT[status]
                      : RMA_STATUS_BADGE_VARIANT[0]
                  }
                >
                  {!Number.isNaN(status) && t(STATUS_MAP[status])}
                </Badge>
              </Box>
            </td>
            <td>
              <Box className="flex-center">
                <WithLoader
                  width={"80%"}
                  isLoading={id === selectedId && isFetchingDocument}
                  spinnerSize={20}
                >
                  <Button
                    sx={{ lineHeight: "0.5rem", marginBottom: "0.5rem" }}
                    variant="secondaryOutline"
                    className="details-btn"
                    onClick={() => onPrintRma(id, "document")}
                  >
                    {t("receipt")}
                  </Button>
                </WithLoader>
              </Box>
              <Box className="flex-center">
                <WithLoader
                  width={"80%"}
                  spinnerSize={20}
                  isLoading={id === selectedId && isFetchingReport}
                >
                  <Button
                    sx={{ lineHeight: "0.5rem" }}
                    variant="secondaryOutline"
                    className="details-btn"
                    onClick={() => onPrintRma(id, "report")}
                  >
                    {t("print_rma_report")}
                  </Button>
                </WithLoader>
              </Box>
            </td>
          </tr>
        );
      });
    } else {
      tableBody = (
        <tr>
          <td colSpan={9}>{t("no_data_in_the_list")}</td>
        </tr>
      );
    }
  }

  return (
    <Container>
      <Box className={"pagination-container"}>
        <Input
          label={t("search")}
          type="search"
          icon={
            <IconSearch
              onClick={() => {
                setSearchText(search);
              }}
            />
          }
          iconPlacement="suffix"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setSearchText(search);
            }
          }}
          onBlur={() => {
            if (search === "") {
              setSearchText(search);
            }
          }}
        />
      </Box>
      <Box className="table-container">
        <table className="table">
          <thead className="table-head">
            <tr>
              <th>{t("rma_id")}</th>
              <th>{t("rma_type")}</th>
              <th>{t("art_no")}</th>
              <th>{t("product")}</th>
              <th>{t("serial")}</th>
              <th>{t("created")}</th>
              <th>{t("received")}</th>
              <th>{t("status")}</th>
              <th>{t("receipt")}</th>
            </tr>
          </thead>
          <tbody className="table-body">{tableBody}</tbody>
        </table>
      </Box>
    </Container>
  );
};

export const SearchRma = () => {
  return (
    <>
      <Header />
      <SearchRmaInner />
      <Footer />
    </>
  );
};
