import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AutoSizer, MultiGrid } from "react-virtualized";
import "react-virtualized/styles.css";
import { Button, Flex, Text, Box } from "theme-ui";
import DirectBuyLogo from "../../../assets/images/directbuy.png";
import {
  columnData,
  productTableResolutions,
  rightInputStyle,
  validCurreny,
} from "../../../constants";
import themes from "../../../themes";
import {
  calculateMinDate,
  tradeINAddProductButtonCheckDisable,
} from "../../../utils/helpers";
import InputDatePicker from "../../form-fields/InputDatePicker";
import InputGroup from "../../form-fields/InputGroup";
import InputNumber from "../../form-fields/InputNumber";
import IconPlus from "../../icons/icon-plus";

const ProductsTable = ({
  stockList,
  onScroll,
  cartProducts,
  handleAddToCart,
  handleChange,
  handleValues,
  errorInputs,
  windowWidth,
  focusId,
  setFocusId,
  windowHeight,
}) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const list = [
      {
        id: -1,
        style: "",
        product_description: "",
        ean: "",
        part_number: "",
        unit_price: "",
        minimum_order_quantity: "",
        take_all_qty_price_optional: "",
        delivery_date: "",
        direct_buy: "",
        event: "",
      },
      ...stockList,
    ];
    const view = list?.map((item, key) => {
      const product =
        cartProducts?.length > 0 &&
        cartProducts?.find(
          (cartItem) => cartItem?.article_no === item?.article_no
        );
      return {
        id: key,
        style: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor:
            (item?.direct_buy_price !== -1 &&
              themes.colors.directBuyProductBG) ||
            (key % 2 === 0
              ? themes.colors.tableBgGray
              : themes.colors.lightGray),
        },
        product_description: (
          <Text
            sx={{
              fontSize: "0.85em",
              color: themes.colors.black,
              verticalAlign: "middle",
            }}
          >
            {t(item?.description)}
          </Text>
        ),
        european_article_number_short_and_part_number: (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              sx={{
                fontSize: "0.85em",
                color: themes.colors.black,
                verticalAlign: "middle",
              }}
            >
              {t(item?.ean)}
            </Text>
            <Text
              sx={{
                fontSize: "0.85em",
                color: themes.colors.black,
                fontStyle: "italic",
              }}
            >
              {item?.part_number}
            </Text>
          </Box>
        ),

        unit_price: (
          <Flex sx={{ width: "100%", justifyContent: "center" }}>
            {item?.direct_buy_price !== -1 ? (
              <InputGroup
                key={key}
                type="number"
                name="unit_price"
                id={`unit_price${key}`}
                label={""}
                directBuyPrice={
                  item?.direct_buy_price !== -1 && item?.direct_buy_price
                }
                value={
                  product?.unit_price ||
                  handleValues?.[item?.article_no]?.unit_price ||
                  null
                }
                iconPlacement="suffix"
                toolTipMessage={
                  (product?.unit_price ||
                    handleValues?.[item?.article_no]?.unit_price) &&
                  t("trade_in_input_group_max_value_error_message")
                }
                toolTipStyle={{
                  display:
                    (product?.unit_price ||
                      handleValues?.[item?.article_no]?.unit_price) >
                    item?.direct_buy_price + 10
                      ? "block"
                      : "none",
                }}
                onChange={(id, name, value) =>
                  handleChange(id, name, value, item)
                }
                onFocus={(e) => setFocusId(e.target.id)}
                focusId={focusId}
                onBlur={(id, name, value) =>
                  handleChange(id, name, value, item)
                }
                rightText={`<${item?.direct_buy_price}${validCurreny}`}
                errorInputs={errorInputs}
                disabled={product}
                autocomplete="off"
              />
            ) : (
              <InputNumber
                key={key}
                type="number"
                name="unit_price"
                id={`unit_price${key}`}
                label={""}
                value={
                  handleValues?.[item?.article_no]?.unit_price ||
                  product?.unit_price ||
                  null
                }
                iconPlacement="suffix"
                max={99999}
                onChange={(id, name, value) =>
                  handleChange(id, name, value, item)
                }
                toolTipMessage={
                  (handleValues?.[item?.article_no]?.unit_price ||
                    product?.unit_price) > 99999 &&
                  t("trade_in_input_group_max_value_error_message")
                }
                onBlur={(id, name, value) =>
                  handleChange(id, name, value, item)
                }
                onFocus={(e) => setFocusId(e.target.id)}
                focusId={focusId}
                errorInputs={errorInputs}
                disabled={product}
                autocomplete="off"
              />
            )}
          </Flex>
        ),
        minimum_order_quantity: (
          <Flex sx={{ width: "100%", justifyContent: "center" }}>
            <InputNumber
              key={key}
              type="number"
              name="min_order_quantity"
              id={`min_order_quantity${key}`}
              iconPlacement="suffix"
              placeholder={
                item?.quantity_set <= 100 && `max. ${item?.quantity_set}`
              }
              min={1}
              max={item?.quantity_set}
              value={
                handleValues?.[item?.article_no]?.min_order_quantity ||
                product?.min_order_quantity ||
                null
              }
              onChange={(id, name, value) =>
                handleChange(id, name, value, item)
              }
              onBlur={(id, name, value) => handleChange(id, name, value, item)}
              disabled={product}
              autocomplete="off"
              onFocus={(e) => setFocusId(e.target.id)}
              focusId={focusId}
              isDirectBuy={item?.direct_buy_price !== -1}
              toolTipMessage={
                item?.direct_buy_price !== -1
                  ? `${t(
                      "trade_in_direct_buy_min_order_quantity_input_max_value_error_message"
                    )}  ${item?.quantity_set}`
                  : `${t(
                      "trade_in_min_order_quantity_input_max_value_error_message"
                    )}  ${item?.quantity_set}`
              }
              errorInputs={errorInputs}
            />
          </Flex>
        ),
        take_all_qty_price_optional: (
          <Flex
            sx={{
              width: "200px",
              justifyContent: "flex-start",
              gap: "0.3em",
            }}
          >
            <Flex sx={{ width: "49%" }}>
              <InputGroup
                key={key}
                type="number"
                max={99999}
                name="take_all_quantity"
                id={`take_all_quantity${key}`}
                placeHolder=""
                value={
                  product?.take_all_quantity ||
                  handleValues?.[item?.article_no]?.take_all_quantity ||
                  null
                }
                min={
                  handleValues?.[item?.article_no]?.min_order_quantity ||
                  product?.min_order_quantity ||
                  item?.quantity_set
                }
                iconPlacement="suffix"
                leftInputStyle={{
                  width: "70px",
                  fontWeight: 500,
                  fontSize: "0.85em",
                }}
                rightInputStyle={{
                  rightInputStyle,
                }}
                onFocus={(e) => setFocusId(e.target.id)}
                focusId={focusId}
                toolTipMessage={
                  (product?.take_all_quantity ||
                    handleValues?.[item?.article_no]?.take_all_quantity) <=
                  item?.quantity_set
                    ? `${t("total_quantity_tooltip_message")} > ${
                        item?.quantity_set
                      }`
                    : t("total_quantity_tooltip_message")
                }
                toolTipStyle={
                  (product?.take_all_quantity ||
                    handleValues?.[item?.article_no]?.take_all_quantity) <=
                  item?.quantity_set
                    ? {
                        backgroundColor: themes.colors.danger,
                        color: themes.colors.white,
                      }
                    : {
                        backgroundColor: themes.colors.warning,
                        color: themes.colors.black,
                      }
                }
                onChange={(id, name, value) =>
                  handleChange(id, name, value, item)
                }
                onBlur={(id, name, value) =>
                  handleChange(id, name, value, item)
                }
                rightText={t("quantity_short")}
                disabled={
                  !handleValues?.[item?.article_no]?.min_order_quantity ||
                  product ||
                  (handleValues?.[item?.article_no]?.min_order_quantity ||
                    product?.min_order_quantity) !== item?.quantity_set
                }
                errorInputs={errorInputs}
                autocomplete="off"
              />
            </Flex>
            <Flex sx={{ width: "49%" }}>
              <InputGroup
                key={key}
                type="number"
                name="take_all_unit_price"
                id={`take_all_unit_price${key}`}
                placeHolder=""
                max={99999}
                value={
                  product?.take_all_unit_price ||
                  handleValues?.[item?.article_no]?.take_all_unit_price ||
                  null
                }
                iconPlacement="suffix"
                leftInputStyle={{ width: "80px" }}
                rightInputStyle={{
                  ...rightInputStyle,
                }}
                onFocus={(e) => setFocusId(e.target.id)}
                focusId={focusId}
                toolTipMessage={
                  (product?.take_all_unit_price ||
                    handleValues?.[item?.article_no]?.take_all_unit_price) >=
                  99999
                    ? t("trade_in_input_group_max_value_error_message")
                    : t("total_price_tooltip_message")
                }
                toolTipStyle={
                  (product?.take_all_unit_price ||
                    handleValues?.[item?.article_no]?.take_all_unit_price) >=
                  99999
                    ? {
                        backgroundColor: themes.colors.danger,
                        color: themes.colors.white,
                      }
                    : {
                        backgroundColor: themes.colors.warning,
                        color: themes.colors.black,
                      }
                }
                onChange={(id, name, value) =>
                  handleChange(id, name, value, item)
                }
                onBlur={(id, name, value) =>
                  handleChange(id, name, value, item)
                }
                rightText={` ${validCurreny}`}
                disabled={
                  !handleValues?.[item?.article_no]?.min_order_quantity ||
                  product ||
                  (handleValues?.[item?.article_no]?.min_order_quantity ||
                    product?.min_order_quantity) !== item?.quantity_set
                }
                errorInputs={errorInputs}
                autocomplete="off"
              />
            </Flex>
          </Flex>
        ),
        delivery_date: (
          <Flex sx={{ width: "100%", justifyContent: "center" }}>
            <InputDatePicker
              id={`delivery_date${key}`}
              name="delivery_date"
              minimumDate={calculateMinDate()}
              value={
                handleValues?.[item?.article_no]?.delivery_date ||
                product?.delivery_date ||
                null
              }
              defaultValue={calculateMinDate()}
              onChange={(id, name, value) =>
                handleChange(id, name, value, item)
              }
              specialDate={item?.direct_buy_price !== -1}
              style={{
                alignItems: "center",
                width: "100px",
                height: "80%",
                borderRadius: "0.2rem",
                border: `0.3px solid ${themes.colors.productTableInputBorder}`,
                zIndex: "9999",
              }}
              disabled={product}
              autocomplete="off"
            />
          </Flex>
        ),
        direct_buy: (
          <Flex sx={{ width: "100%", justifyContent: "center" }}>
            {item?.direct_buy_price !== -1 ? (
              <img
                src={DirectBuyLogo}
                width="70px"
                height="auto"
                alt="direct_buy"
                style={{ display: "flex", margin: "auto" }}
              />
            ) : (
              <Text
                sx={{
                  fontSize: "0.80em",
                  fontWeight: 500,
                  color: themes.colors.black,
                  wrap: "wrap",
                  display: "flex",
                  textAlign: "center",
                }}
              >
                {t("product_not_direct_buy")}
              </Text>
            )}
          </Flex>
        ),
        event: (
          <Button
            type={"button"}
            variant="primaryIconButton"
            className="icon-button"
            onClick={() => handleAddToCart(key, item, handleValues)}
            sx={{
              height: "1.7em",
              width: "1.7em",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            disabled={
              product ||
              tradeINAddProductButtonCheckDisable(product, handleValues, item)
            }
          >
            <IconPlus />
          </Button>
        ),
      };
    });

    return view;
  }, [
    cartProducts,
    errorInputs,
    focusId,
    setFocusId,
    handleAddToCart,
    handleChange,
    handleValues,
    stockList,
    t,
  ]);

  const headerHeight = 60;
  const tableHeaderIndex = 0;

  return (
    <Flex
      sx={{
        width: windowWidth,
        height: `${windowHeight}px - 14rem`,
        flexDirection: "column",
      }}
    >
      <AutoSizer>
        {({ width = windowWidth, height = `${windowHeight}px - 14rem` }) => (
          <Flex sx={{ width: width, height: height, flexDirection: "column" }}>
            <MultiGrid
              width={width}
              height={height}
              rowCount={data.length}
              onSectionRendered={onScroll}
              columnCount={columnData.length}
              fixedRowCount={1}
              rowHeight={({ index }) => {
                if (index === tableHeaderIndex) {
                  return headerHeight;
                } else if (
                  windowWidth > productTableResolutions.rowHeight.minWidth
                ) {
                  return productTableResolutions.rowHeight.minHeight;
                } else {
                  return productTableResolutions.rowHeight.maxHeight;
                }
              }}
              rowWidth={20}
              columnWidth={({ index }) => {
                const column = columnData[index];
                return windowWidth >= 1024 &&
                  (windowWidth < 1270 || windowWidth >= 1440)
                  ? (width / productTableResolutions.columnWidth.percent) *
                      column.width.xl
                  : column.width.md;
              }}
              cellRenderer={({ key, rowIndex, columnIndex, style }) => {
                const isHeaderRow = rowIndex === 0;
                const cellStyle = {
                  ...style,
                  ...(isHeaderRow
                    ? {
                        backgroundColor: themes.colors.primary,
                        color: themes.colors.white,
                        margin: "0",
                        padding: "1em",
                        fontSize: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }
                    : {
                        ...style,
                        padding: "0.8em",
                        ...data[rowIndex]?.style,
                      }),
                };
                if (
                  isHeaderRow &&
                  (columnData[columnIndex].name === "direct_buy" ||
                    columnData[columnIndex].name === "event")
                ) {
                  return <div key={key} style={cellStyle} />;
                }
                return (
                  <div key={key} style={cellStyle}>
                    {isHeaderRow
                      ? t(columnData[columnIndex].name)
                      : data[rowIndex][columnData[columnIndex].name]}
                  </div>
                );
              }}
            />
          </Flex>
        )}
      </AutoSizer>
    </Flex>
  );
};

ProductsTable.propTypes = {
  isLoading: PropTypes.bool,
  stockList: PropTypes.object,
  cartProducts: PropTypes.object,
  handleValues: PropTypes.object,
  handleChange: PropTypes.func,
  handleAddToCart: PropTypes.func,
  fetchMore: PropTypes.func,
  canFetchMore: PropTypes.bool,
  productData: PropTypes.object,
  onScroll: PropTypes.func,
  isFetching: PropTypes.bool,
  windowWidth: PropTypes.number,
};

export default ProductsTable;
