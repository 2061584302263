import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import IconMessage from "./icons/icon-message";
import { Form } from "react-final-form";
import { Flex, Spinner, Text, Box } from "theme-ui";
import RFFInput from "./form-fields/rff-fields/RFFInput";
import useSocket from "../utils/socket";
import { defaultLimit, defaultPage, MAX_CHAR_LIMIT } from "../constants";
import {
  reverseArrayWithLastElementFirst,
  toLocaleTimeString,
} from "../utils/helpers";
import { getMessages } from "../apis";
import { getItem } from "../utils/storage";
import { useTranslation } from "react-i18next";
import IconRefresh from "./icons/icon-refresh";
import { SenderRole } from "../constants";
import { useToast } from "../contexts/AlertProvider";
import themes from "../themes";

const ChatUIWrapper = styled.div`
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 350px;
  height: 500px;
  background-color: ${(props) => props.theme.colors.inputBG};
  border-radius: 20px;
  box-shadow: 0 4px 20px ${(props) => props.theme.colors.opaqueBlack};
  display: ${(props) => (props.isOpen ? "block" : "none")};
  z-index: 1000;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 70%;
    height: 50%;
    bottom: 60px;
    right: 0;
    border-radius: 20px 20px 0 0;
  }

  @media (max-width: 480px) {
    height: 70%;
  }
`;

const ChatHeader = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding: 20px;
  border-radius: 20px 20px 0 0;
  text-align: center;
  font-size: 18px;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 16px;
  }
`;

const ChatBody = styled.div`
  padding: 20px;
  height: calc(100% - 140px);
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 15px;
    height: calc(100% - 130px);
  }
`;

const ChatFooter = styled.div`
  padding: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.messageColor};
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const ChatInputWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 5px 10px;

  @media (max-width: 768px) {
    padding: 5px;
    margin-right: 5px;
  }
`;

const SendButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
`;

const ChatToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1001;
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.colors.lightGray
      : props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 50px;
  border: none;
  width: auto;
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 45px;
    padding: 0 12px;
  }
`;

const MessageBubble = styled.div`
  background-color: ${(props) =>
    props.isSentByUser
      ? props.theme.colors.primary
      : props.theme.colors.bubbleColor};
  color: ${(props) => props.theme.colors.white};
  padding: 10px 15px;
  border-radius: 15px;
  margin-top: 10px;
  max-width: 80%;
  word-wrap: break-word;
  margin-left: ${({ isSentByUser }) => (isSentByUser ? "auto" : "0")};
  position: relative;
`;

const MessageInfo = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.black};
  text-align: right;
  width: ${({ isSentByUser }) => (isSentByUser ? "auto" : "80%;")};
`;

const UnreadBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: ${(props) => props.theme.colors.red};
  color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
`;
const TooltipWrapper = styled.div`
  position: absolute;
  top: -95px;
  left: -130px;
  right: -10px;
  background-color: ${(props) => props.theme.colors.red};
  color: ${(props) => props.theme.colors.white};
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 100px;
    margin-left: 0;
    border-width: 5px;
    border-style: solid;
    border-color: red transparent transparent transparent;
  }
`;

const CharacterCounter = styled.div`
  font-size: 9px;
  margin-right: 5px;
`;

const RetryButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  text-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  border: none;
  width: 100px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
`;

const ChatUI = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const chatUsername = getItem("chat_username");
  const chatToken = getItem("chat_token");
  const conversationId = getItem("conversationId");
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [page, setPage] = useState(defaultPage);
  const [limit, setLimit] = useState(defaultLimit);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
  const [isNewMessageTooltip, setIsNewMessageTooltip] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [charCount, setCharCount] = useState(MAX_CHAR_LIMIT);
  const {
    sendMessage,
    setMessages,
    messages,
    chatBodyRef,
    joinConversation,
    loadChatHistory,
    lastActiveAt,
    connectionError,
    reconnectSocket,
  } = useSocket(setUnreadCount, isChatOpen, setLastMessage);

  const onInputChange = (value) => setCharCount(MAX_CHAR_LIMIT - value.length);

  useEffect(() => {
    if (lastMessage && !isChatOpen) {
      setIsNewMessageTooltip(true);
      const timer = setTimeout(() => {
        setIsNewMessageTooltip(false);
        setLastMessage(null);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (loadChatHistory && lastActiveAt) {
      const lastActiveDate = new Date(lastActiveAt);

      const newMessagesCount = loadChatHistory?.filter(
        (data) =>
          new Date(data.sentAt) > lastActiveDate &&
          data?.sender?.role === SenderRole.OPERATOR
      ).length;
      setUnreadCount(newMessagesCount);
    }
  }, [loadChatHistory, lastActiveAt, isChatOpen]);

  const handleCloseChat = () => {
    setIsChatOpen(false);
    setUnreadCount(0);
  };

  const handleLoginClick = () => {
    if (!isChatOpen) {
      setUnreadCount(0);
    }
    joinConversation();
    setIsChatOpen((prevState) => {
      setIsNewMessageTooltip(false);
      return !prevState;
    });
  };

  const loadMessages = useCallback(async () => {
    try {
      const fetchedMessages = await getMessages(conversationId, page, limit);
      const reversedMessages = reverseArrayWithLastElementFirst(
        fetchedMessages?.data?.data || []
      );
      setMessages((prev) => {
        const combinedMessages = [...prev, ...reversedMessages];
        const sortedMessages = combinedMessages.sort(
          (a, b) => new Date(a.sentAt) - new Date(b.sentAt)
        );
        return sortedMessages;
      });
    } catch (error) {
      toast(error, "error");
    } finally {
      setIsLoading(false);
    }
  }, [conversationId, page, limit, setMessages]);

  useEffect(() => {
    if (connectionError) {
      setMessages([]);
      return;
    }
  }, []);

  useEffect(() => {
    if (isChatOpen) {
      setUnreadCount(0);
    }
  }, [isChatOpen]);

  useEffect(() => {
    loadMessages();
  }, []);

  const handleScroll = useCallback(async () => {
    const chatBody = chatBodyRef.current;

    if (chatBody.scrollTop === 0 && !isLoadingMoreMessages) {
      setIsLoadingMoreMessages(true);

      const previousScrollHeight = chatBody.scrollHeight;
      setPage((prevPage) => prevPage + 1);
      await loadMessages();

      chatBody.scrollTop = chatBody.scrollHeight - previousScrollHeight;
      setIsLoadingMoreMessages(false);
    }
  }, [chatBodyRef, isLoadingMoreMessages]);

  useEffect(() => {
    const chatBody = chatBodyRef.current;
    if (chatBody) {
      chatBody.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatBody) {
        chatBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [chatBodyRef, handleScroll]);

  useEffect(() => {
    if (isChatOpen && chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [isChatOpen, messages]);

  const onSubmit = async (values) => {
    await sendMessage(values);
    setCharCount(MAX_CHAR_LIMIT);
    setIsLoading(false);
  };

  useEffect(() => {
    const chatBody = chatBodyRef.current;
    if (chatBody) {
      chatBody.scrollTop = chatBody.scrollHeight;
    }
  }, [messages]);

  const handleChatLoginClick = () => {
    if (chatToken !== null) {
      handleLoginClick();
    }
  };

  return (
    <>
      <Flex
        sx={{
          position: "fixed",
          flexDirection: "column-reverse",
          bottom: "20px",
          right: "20px",
          zIndex: 1001,
        }}
      >
        <ChatToggleButton
          onClick={handleChatLoginClick}
          isDisabled={chatToken === "null"}
        >
          {t("chat_with_us")}
          <IconMessage width="24px" height="18px" />
          {unreadCount > 0 && <UnreadBadge>{unreadCount}</UnreadBadge>}
        </ChatToggleButton>
        <TooltipWrapper isVisible={isTooltipVisible || isNewMessageTooltip}>
          {lastMessage || t("connection_failed")}
        </TooltipWrapper>
      </Flex>
      <ChatUIWrapper isOpen={isChatOpen}>
        <CloseButton onClick={handleCloseChat}>×</CloseButton>
        <ChatHeader>{t("live_chat")}</ChatHeader>
        <ChatBody ref={chatBodyRef}>
          {connectionError ? (
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="flex-end"
              p={2}
              style={{ height: "100%" }}
            >
              <Box>
                <Text
                  mt="8px"
                  mr="10px"
                  color={themes.colors.red}
                  fontsize="20px"
                  style={{ fontWeight: "bold" }}
                >
                  {t("connection_error")}
                </Text>
              </Box>
              <Box>
                <RetryButton onClick={reconnectSocket}>
                  <IconRefresh /> {t("reconnect")}
                </RetryButton>
              </Box>
            </Flex>
          ) : !isLoading ? (
            <>
              {messages?.length > 0 ? (
                messages?.map((conversation) => (
                  <div key={conversation.id}>
                    <MessageBubble
                      isSentByUser={conversation.senderName === chatUsername}
                    >
                      {conversation.content}
                    </MessageBubble>
                    <MessageInfo
                      isSentByUser={conversation.senderName === chatUsername}
                    >
                      {conversation.senderName === chatUsername
                        ? toLocaleTimeString(conversation.sentAt)
                        : `${
                            conversation.label ?? conversation.senderName
                          } - ${toLocaleTimeString(conversation.sentAt)}`}
                    </MessageInfo>
                  </div>
                ))
              ) : (
                <Flex
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner />
                </Flex>
              )}
            </>
          ) : (
            <Flex
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </Flex>
          )}
        </ChatBody>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form, values }) => (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(event);
                form.reset();
              }}
            >
              <ChatFooter>
                <ChatInputWrapper>
                  <RFFInput
                    type="text"
                    placeholder={t("enter_your_message")}
                    name="content"
                    autoComplete="off"
                    maxLength={MAX_CHAR_LIMIT}
                    sx={{
                      shadow: "0",
                      borderRadius: "50px",
                      width: "100%",
                      backgroundColor: themes.colors.transparent,
                      borderColor: "lightGray",
                    }}
                    onChange={(e) => onInputChange(e)}
                    disabled={!messages?.length && isLoading}
                  />
                </ChatInputWrapper>
                <CharacterCounter remainingChars={charCount}>
                  {charCount} / {MAX_CHAR_LIMIT}
                </CharacterCounter>

                <SendButton
                  type="button"
                  onClick={(event) => {
                    handleSubmit(event);
                    form.reset();
                  }}
                  disabled={
                    !values.content?.trim() || (!messages?.length && isLoading)
                  }
                >
                  ➤
                </SendButton>
              </ChatFooter>
            </form>
          )}
        </Form>
      </ChatUIWrapper>
    </>
  );
};

export default ChatUI;
