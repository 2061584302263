import dayjs from "dayjs";
import DefaultImage from "../assets/images/noimage.jpg";
import { TradeIn_Status, tradeInMaxInputValue } from "../constants";
import themes from "../themes";
import useMediaQuery from "./useMediaQuery";

export const getStockStatus = (quantity, upcomingQuantity) =>
  quantity > 0 ? "available" : upcomingQuantity > 0 ? "upcoming" : "outofstock";

export const ORDER_STATUS_BADGE_VARIANT = {
  completed: "success",
  submitted: "warning",
  partially: "partial",
};

export const RMA_STATUS_BADGE_VARIANT = [
  "info",
  "warning",
  "danger",
  "success",
];

export const INVOICE_STATUS_BADGE_VARIANT = {
  paid: "success",
  unpaid: "danger",
};

export const getCookieExpiryTime = () => {
  return new Date(new Date().getTime() + 8 * 60 * 60 * 1000);
};
export const getEuropeanDateFormat = (dateString) => {
  return dayjs(dateString, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm");
};

export const getFormatedYukaPoints = (yukapoint) => {
  return Number(yukapoint).toLocaleString("de-DE", {
    style: "decimal",
  });
};

export const downloadBlob = (blob, fileName = "download.pdf") => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
export const downloadBlobCsv = (url, fileName = "download.csv") => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const addDefaultSource = (e) => {
  e.target.src = DefaultImage;
};

export const checkArrayHasDuplicatedElementByProperty = (
  array,
  propertyName,
  value
) => {
  return array.filter((item) => item[propertyName] === value).length > 1;
};

export const ORDER_ID_KEY = "artnr";

export const calculateMinDate = (date) => {
  const today = new Date();

  const finalDate = date
    ? new Date(date)
    : new Date(today.setDate(today.getDate() + 1));
  const day = finalDate.getDate().toString().padStart(2, "0");
  const month = (finalDate.getMonth() + 1).toString().padStart(2, "0");
  const year = finalDate.getFullYear();

  const minDate = `${year}-${month}-${day}`;
  return minDate;
};

export const getWeekdaysAfterDate = () => {
  const weekdays = [];
  const day = new Date();
  day.setDate(day.getDate() + 1);
  let count = 0;
  while (count < 3) {
    const currentDay = day.getDay();
    if (currentDay === 0 || currentDay === 6) {
      weekdays.push(new Date(day));
    } else {
      weekdays.push(new Date(day));
      count++;
    }
    day.setDate(day.getDate() + 1);
  }
  return weekdays;
};

export const areDatesEqual = (date1, date2) =>
  date1 instanceof Date &&
  date2 instanceof Date &&
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

export const roundToInteger = (number) =>
  number ? Math.floor(Number(number)) : 0;

export const isDeliveryDateAfterWeekdays = (item, weekdaysAfterDate) =>
  weekdaysAfterDate?.some((weekday) =>
    areDatesEqual(weekday, new Date(item?.delivery_date))
  );

export const isDirectBuyConfirmed = (item) =>
  item?.direct_buy_price > item?.unit_price;

export const isSameDirectPriceAndUnitPrice = (item) =>
  item?.direct_buy_price > item?.unit_price;

export const convertToDate = (date) => {
  if (!date) return new Date();
  const dateValue = new Date(date);
  return dateValue;
};

export const generateRandomComplexValue = () => {
  const regexCharacters = "0123456789";
  const complexValue = Array.from(
    { length: 8 },
    () => regexCharacters[Math.floor(Math.random() * regexCharacters.length)]
  ).join("");
  return complexValue;
};

export const inputBorderColor = ({ meta, sx }) =>
  meta.touched && meta.error
    ? themes.colors.danger
    : (sx && sx.borderColor) || themes.colors.borderDarkGray;

export const getDirectBuyStatus = (item) => {
  const deliveryDateAfterWeekdays = isDeliveryDateAfterWeekdays(
    item,
    getWeekdaysAfterDate()
  );
  const directBuyConfirmed = isDirectBuyConfirmed(item);

  const color =
    deliveryDateAfterWeekdays && directBuyConfirmed
      ? themes.colors.success
      : themes.colors.danger;

  const text =
    deliveryDateAfterWeekdays && directBuyConfirmed
      ? "direct_buy_confirmed"
      : "direct_buy_under_review";

  return { color, text };
};
export const customISOString = (year, month, day) => {
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return "Invalid date";
  }

  month = String(month).padStart(2, "0");
  day = String(day).padStart(2, "0");

  return `${year}-${month}-${day}T00:00:00.000Z`;
};

export const convertDateISOString = (values) => {
  const updatedCartProducts = values.reduce((acc, product) => {
    if (!product.delivery_date) {
      throw Error("Date should not be empty.");
    }

    const [year, month, day] = product.delivery_date.split("-");

    if (!year || !month || !day) {
      throw Error("Date format should be valid.");
    }

    const updateDeliveryDate = customISOString(year, month, day);
    acc.push({
      ...product,
      delivery_date: updateDeliveryDate,
    });
    return acc;
  }, []);
  return updatedCartProducts;
};
export const tradeInInputValueCheck = (setErrorInputs, id, value1, value2) => {
  if (value1 > value2) {
    return true;
  } else
    setErrorInputs((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
};
export const tradeINAddProductButtonCheckDisable = (
  product,
  handleValues,
  item
) => {
  if (
    (item?.direct_buy_price > 0 &&
      handleValues?.[item?.article_no]?.unit_price >
        item.direct_buy_price + 10) ||
    (handleValues?.[item?.article_no]?.take_all_quantity ||
      product?.take_all_quantity) <
      (handleValues?.[item?.article_no]?.min_order_quantity ||
        product?.min_order_quantity) ||
    ((handleValues?.[item?.article_no]?.take_all_quantity ||
      product?.take_all_quantity) &&
      !(
        handleValues?.[item?.article_no]?.take_all_unit_price ||
        product?.take_all_unit_price
      )) ||
    (handleValues?.[item?.article_no]?.min_order_quantity ||
      product?.min_order_quantity) > item?.quantity_set ||
    (handleValues?.[item?.article_no]?.take_all_quantity ||
      product?.take_all_quantity) > 9999 ||
    (handleValues?.[item?.article_no]?.take_all_unit_price ||
      product?.take_all_unit_price) > tradeInMaxInputValue ||
    (handleValues?.[item?.article_no]?.unit_price || product?.unit_price) >
      tradeInMaxInputValue
  ) {
    return true;
  }
};

export function formatValues(values, formInitialValues) {
  const formattedValues = {
    ...values,
    article_no: formInitialValues?.article_no,
    delivery_date: calculateMinDate(values?.delivery_date),
    is_direct_buy: formInitialValues?.is_direct_buy,
    new_product: formInitialValues?.new_product,
    direct_buy_price: formInitialValues?.direct_buy_price,
    european_article_number: values?.european_article_number,
    unit_price: values?.unit_price,
    take_all_quantity:
      values?.min_order_quantity === formInitialValues?.quantity_set &&
      values?.take_all_quantity > 0
        ? values?.take_all_quantity
        : undefined,
    take_all_unit_price:
      values?.min_order_quantity === formInitialValues?.quantity_set &&
      values?.take_all_unit_price > 0
        ? values?.take_all_unit_price
        : undefined,
    quantity_set: formInitialValues?.quantity_set,
  };
  return formattedValues;
}

export const parseDate = (dateStr) => {
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  return `${year}/${formattedMonth}/${formattedDay}`;
};

export const totalPriceandQuantity = (tradein_order_items) => {
  let totalPrice = 0;
  let totalQuantity = 0;
  let total = 0;

  tradein_order_items.forEach((itemlist) => {
    const unitPrice = itemlist.unit_price;
    const quantity = itemlist.min_order_quantity;

    totalPrice += unitPrice;
    totalQuantity += quantity;
    total += unitPrice * quantity;
  });

  return { totalPrice, totalQuantity, total };
};

export const tradeInDisplayedLength = (oldOffersLength) =>
  oldOffersLength > 10 ? "10+" : oldOffersLength;

export const tradeInOldOfferTabStatus = (pathname) => {
  let newActiveColor, newStatus;

  switch (true) {
    case pathname.includes("counter-offer"):
      newActiveColor = themes.colors.yellow;
      newStatus = TradeIn_Status.STATUS_COUNTER_OFFER.value;
      break;
    case pathname.includes("under-review"):
      newActiveColor = themes.colors.borderGray;
      newStatus = TradeIn_Status.STATUS_UNDER_REVIEW.value;
      break;
    case pathname.includes("approved"):
      newActiveColor = themes.colors.lightGreen;
      newStatus = TradeIn_Status.STATUS_APPROVED.value;
      break;
    case pathname.includes("rejected"):
      newActiveColor = themes.colors.secondary;
      newStatus = TradeIn_Status.STATUS_REJECTED.value;
      break;
    default:
      newActiveColor = themes.colors.yellow;
      newStatus = TradeIn_Status.STATUS_COUNTER_OFFER.value;
  }

  return { newActiveColor, newStatus };
};

export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const useSmallMobileView = () =>
  useMediaQuery("(max-width:769px)", true, false);

export const parseDateTime = (dateStr) => {
  const targetDate = new Date(dateStr);
  const currentDate = new Date();
  targetDate.setHours(23, 59, 59, 999);

  if (targetDate > currentDate) {
    const milliseconds = Math.abs(targetDate - currentDate);
    return { milliseconds };
  } else {
    return { milliseconds: -1 };
  }
};

export const calculateNextDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;
};

export const isEqualValue = (item) => {
  const isEqualQuantity =
    item?.min_order_quantity === item?.latest_counter_offer?.quantity;
  const isEqualPrice = item?.unit_price === item?.latest_counter_offer?.price;
  return { isEqualPrice, isEqualQuantity };
};

export const getStatusText = (status) => {
  switch (status) {
    case TradeIn_Status.STATUS_APPROVED.value:
      return TradeIn_Status.STATUS_APPROVED.text;
    case TradeIn_Status.STATUS_REJECTED.value:
      return TradeIn_Status.STATUS_REJECTED.text;
    case TradeIn_Status.STATUS_UNDER_REVIEW.value:
      return TradeIn_Status.STATUS_UNDER_REVIEW.text;
    case TradeIn_Status.STATUS_COUNTER_OFFER.value:
      return TradeIn_Status.STATUS_COUNTER_OFFER.text;
    case TradeIn_Status.STATUS_LOST_OPPURTUNITIES.value:
      return TradeIn_Status.STATUS_LOST_OPPURTUNITIES.text;
    default:
      return status;
  }
};

export const reverseArrayWithLastElementFirst = (array) => {
  if (!Array.isArray(array) || array.length === 0) return array;
  const lastElement = array[array.length - 1];
  const remainingElements = array.slice(0, -1).reverse();
  return [lastElement, ...remainingElements];
};

export const calculatedHeight = (item, windowWidth) => {
  if (item?.new_product && windowWidth > 320) {
    return "16em";
  } else if (item?.new_product && windowWidth < 320) {
    return "17em";
  } else if (windowWidth > 320) {
    return "16em";
  } else {
    return "16.5em";
  }
};

export const handleChange = (value, type, id, name, onChange) => {
  if (type === "number" && parseFloat(value) < 0) {
    value = Math.abs(value);
  } else {
    value = roundToInteger(value.replace(",", "."));
  }
  onChange(id, name, value);
};

export const toLocaleTimeString = (date) =>
  new Date(date).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

export const dateFormat = (date) => {
  const utcToDate = new Date(date);
  const hours = utcToDate.getUTCHours().toString().padStart(2, "0");
  const minutes = utcToDate.getUTCMinutes().toString().padStart(2, "0");
  const seconds = utcToDate.getUTCSeconds().toString().padStart(2, "0");
  return {
    hours,
    minutes,
    seconds,
  };
};

export const tradeINCartRowHeight = (isNewProduct, windowWidth) => {
  if (isNewProduct || windowWidth > 320) {
    return "16em";
  } else {
    return "17.5em";
  }
};

export const getDataOptions = (data) =>
  data?.map((item) => ({
    value: item.id,
    label: item.country,
  })) || [];
