import React, { useEffect, useMemo, useState } from "react";
import { getTopProducts } from "../apis";

import { useCartState } from "../contexts/CartContext";

import { Box, Button, Flex, Heading, Text } from "theme-ui";

import CrossSellList from "./CrossSellList";
import { useModal } from "../contexts/ModalContext";

import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

const ConfirmationModal = styled(Box)`
  text-align: center;
  max-width: 24rem;

  .modal-title {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;

    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      height: 1.5rem;
      width: 1.5rem;
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 0.5rem;
    }
  }

  .modal-body {
    margin-bottom: 2rem;
  }

  .modal-footer {
    justify-content: center;
    align-items: center;

    button {
      min-width: 10rem;
      margin: 0 1rem;
    }
  }
`;
const CrossSellModal = ({
  setIsCrossSellConfirmed,
  hideModals,
  onCheckoutConfirmation,
}) => {
  const cart = useCartState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTopProducts(cart.items.map((i) => i.article_no)).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, []);

  const { t } = useTranslation();
  const { showModal } = useModal();

  const stockList = useMemo(() => {
    return (
      data?.reduce((acc, item) => {
        const bar = item.data.map(
          ({ quantity, next_delivery_amount, stock_status, ...item }) => {
            const cartItem = cart.items.find(
              (cartItem) => cartItem.article_no === item.article_no
            );

            return {
              ...item,
              max_quantity: quantity,
              next_delivery_amount,
              stock_status,
              is_added_to_cart: Boolean(cartItem),
              cart_quantity: cartItem?.quantity,
              csell: true,
            };
          }
        );

        return acc.concat(bar);
      }, []) || []
    );
  }, [cart.items, data]);

  const ButtonCheck = useMemo(() => {
    const Items = cart.items.map((i) => i.csell);
    if (Items.includes(true)) {
      return (
        <Button
          variant="primary"
          onClick={() => {
            setIsCrossSellConfirmed(true);
            hideModals();
          }}
        >
          {t("buttonAfterChange")}
        </Button>
      );
    }

    return (
      <Button
        variant="primary"
        onClick={() => {
          setIsCrossSellConfirmed(true);
          hideModals();
          showModal(({ hideModal }) => {
            return (
              <ConfirmationModal>
                <Heading className="modal-title">{t("confirm")}</Heading>
                <Box className="modal-body">
                  <Text>{t("do_you_want_to_confirm_your_order")}</Text>
                </Box>
                <Flex className="modal-footer">
                  <Button
                    variant={"primary"}
                    onClick={() => {
                      onCheckoutConfirmation();
                      hideModal();
                    }}
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      hideModal();
                    }}
                  >
                    {t("go_back")}
                  </Button>
                </Flex>
              </ConfirmationModal>
            );
          });
        }}
      >
        {t("buttonWithoutChange")}
      </Button>
    );
  }, [
    cart.items,
    hideModals,
    onCheckoutConfirmation,
    setIsCrossSellConfirmed,
    showModal,
    t,
  ]);

  return (
    <div>
      <CrossSellList items={stockList} isLoading={isLoading} />
      <Box
        className="confirmation-button-container"
        style={{ marginTop: "20px", float: "right" }}
      >
        {ButtonCheck}
      </Box>
    </div>
  );
};

export default CrossSellModal;
