import styled from "@emotion/styled";
import * as PropTypes from "prop-types";
import React from "react";
import { Box, Button, Text } from "theme-ui";
import IconCheveronDown from "../../../components/icons/icon-cheveron-down";
import Menu from "../../../components/Menu";

const PaginationBlock = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  .page-info {
    margin-right: 1rem;
    display: flex;
  }

  .pagination-btn-group {
    display: flex;
    @media (max-width: 417px) {
    {
       padding-right:4rem;
    }
  }
  }

  .pagination-btn {
    fill: ${(props) => props.theme.colors.secondary};

    &.next-btn {
      .svg-icon {
        transform: rotate(270deg);
      }
    }

    &.prev-btn {
      .svg-icon {
        transform: rotate(90deg);
      }
    }
  }
  .dropdown-menu {
    color: ${(props) => props.theme.colors.primary};
  }
  @media (max-width: 769px) {
    .page-info {
      width: 80px;
      margin:0;
    }
  }
`;

const Pagination = ({
  from,
  to,
  total,
  last_page,
  current_page,
  onChange,
  pageOptions,
}) => {
  const pageBlock = pageOptions ? (
    <Menu
      showDropdownIcon={false}
      label={
        <Box className="page-info">
          <Text sx={{ color: "secondary" }}>
            {from || 0} - {to || 0}
          </Text>
          <Text sx={{ margin: "0 0.5rem" }}>of</Text>
          <Text>{total || 0} </Text>
        </Box>
      }
      items={pageOptions}
    />
  ) : (
    <Box className="page-info">
      <Text sx={{ color: "secondary" }}>
        {from || 0} - {to || 0}
      </Text>
      <Text sx={{ margin: "0 0.5rem" }}>of</Text>
      <Text>{total || 0} </Text>
    </Box>
  );

  return (
    <PaginationBlock className="pagination-block">
      {pageBlock}
      <Box className="pagination-btn-group">
        <Button
          variant="iconButton"
          className="pagination-btn prev-btn"
          disabled={current_page === 1}
          onClick={() => onChange(current_page - 1)}
        >
          <IconCheveronDown />
        </Button>{" "}
        <Button
          variant="iconButton"
          className="pagination-btn next-btn"
          disabled={last_page === current_page}
          onClick={() => onChange(current_page + 1)}
        >
          <IconCheveronDown />
        </Button>
      </Box>
    </PaginationBlock>
  );
};

Pagination.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  total: PropTypes.number,
  last_page: PropTypes.number,
  current_page: PropTypes.number,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  data: PropTypes.any,
};

export default Pagination;