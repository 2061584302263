import React, { useCallback, useState } from "react";
import { Flex, Box, Heading, Text, Button } from "theme-ui";
import IconRightLeft from "../icons/icon-right-left";
import { Link } from "react-router-dom/cjs/react-router-dom";
import themes from "../../themes";
import { useTranslation } from "react-i18next";
import IconCheckSucces from "../icons/icon-check-succes";
import IconTradeINLogo from "../icons/icon-trade-in-logo";
import IconThumbsUpLight from "../icons/icon-thumbs-up-light";
import IconShield from "../icons/icon-shield";
import IconClock from "../icons/icon-clock";
import { useAuthContext } from "../../contexts/AuthContext";
import IconKey from "../icons/icon-key";
import IconCustomer from "../icons/icon-customer";
import RFFInputField from "../../pages/unauthenticated/Login/RFFInputField";
import { tradeInLoginCustomerValidate } from "../../utils/validators";
import { Form } from "react-final-form";
import { useAuthDispatch } from "../../contexts/AuthContext";
import WithLoader from "../WithLoader";
import ErrorMessage from "../ErrorMessage";
import CreateCustomerModal from "./modals/CreateCustomerModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Field } from "react-final-form";
import { submitLogin } from "../Login";
import { listItems } from "../../constants";

const HomePageBody = () => {
  const { t } = useTranslation();
  const { authenticated } = useAuthContext();
  const authDispatch = useAuthDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const history = useHistory();
  const onModalOpen = useCallback(
    (values) => {
      if (values) {
        setModalData(values);
      } else {
        setModalData(null);
      }
      setShowCreateProductModal(true);
    },
    [setModalData, setShowCreateProductModal]
  );

  const onSubmit = useCallback(
    (values) =>
      submitLogin(values, authDispatch, history, setErrorMessage, setIsLoading),
    [setErrorMessage, setIsLoading, authDispatch, history]
  );

  const validate = useCallback(
    (values) => tradeInLoginCustomerValidate(values, t("field_required")),
    [t]
  );

  return (
    <Flex
      sx={{
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Flex
        sx={{
          background: themes.colors.tradeInHomeSectionGradient,
          backgroundColor: themes.colors.homeSectionsBG,
          height: "auto",
          justifyContent: "center",
          "@media screen and (max-width: 1024px)": {
            width: "100%",
          },
          paddingBottom: "3em",
        }}
      >
        <Flex
          sx={{
            width: "65em",
            flexDirection: "column",
            gap: "1em",
            "@media screen and (max-width: 1024px)": {
              width: "55em",
              padding: "0 1em",
            },
          }}
        >
          <Flex sx={{ width: "100%", justifyContent: "center" }}>
            <Heading
              sx={{
                width: "18em",
                fontSize: "2em",
                fontWeight: 700,
                color: themes.colors.white,
                paddingBottom: "0.5rem",
                marginTop: "2.5rem",
                textAlign: "center",
              }}
            >
              {t("trade_in_home_section_one_title_one")}
            </Heading>
          </Flex>
          <Text
            sx={{
              fontSize: "1em",
              color: themes.colors.white,
              textAlign: "center",
            }}
          >
            {t("trade_in_home_section_one_description_one")}
          </Text>
          {listItems.map((item, index) => (
            <Flex sx={{ width: "100%", flexDirection: "column" }} key={index}>
              <Flex
                sx={{
                  fontSize: "1em",
                  color: themes.colors.white,
                  verticalAlign: "middle",
                  flexDirection: "row",
                }}
              >
                <IconCheckSucces
                  width="22"
                  height="20"
                  color={themes.colors.success}
                />
                <Text
                  sx={{
                    fontSize: "1em",
                    color: themes.colors.white,
                    padding: "0 0 0 0.6em",
                    fontWeight: 700,
                  }}
                >
                  {t(item.title)}:
                </Text>
              </Flex>
              <Box sx={{ marginLeft: "22px" }}>
                <Text
                  sx={{
                    fontSize: "1em",
                    color: themes.colors.white,
                    padding: "0 0 0 0.6em",
                  }}
                >
                  {t(item.text)}
                </Text>
              </Box>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex
        sx={{
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "2em",
        }}
      >
        <Flex sx={{ width: "100%", justifyContent: "center" }}>
          <Box
            sx={{
              width: "20em",
              height: "10em",
              "@media screen and (max-width: 725px)": {
                width: "98%",
              },
            }}
          >
            <IconTradeINLogo width="100%" height="auto" />
          </Box>
        </Flex>
        <Flex
          sx={{
            justifyContent: "center",
            gap: "7em",
            "@media screen and (max-width: 1024px)": {
              gap: "1em",
            },
            "@media screen and (max-width: 725px)": {
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
              gap: "0",
              padding: "0 0 0 1em",
            },
          }}
        >
          <Flex
            sx={{
              width: "15em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              "@media screen and (max-width: 725px)": {
                margin: "1em 0",
              },
            }}
          >
            <Flex
              sx={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Flex sx={{ width: "100%", justifyContent: "center" }}>
                <Box
                  sx={{
                    width: "6em",
                    height: "6em",
                    padding: "1em",
                    borderRadius: "50%",
                    border: `5px solid ${themes.colors.primary}`,
                  }}
                >
                  <IconThumbsUpLight width="100%" height="100%" />
                </Box>
              </Flex>
              <Flex sx={{ width: "100%", justifyContent: "center" }}>
                <Heading
                  sx={{
                    fontSize: "2em",
                    marginTop: "1em",
                    fontWeight: 700,
                    color: themes.colors.black,
                    textTransform: "uppercase",
                  }}
                >
                  {t("trade_in_home_section_two_list_item_one_title")}
                </Heading>
              </Flex>
            </Flex>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.white,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckSucces
                width="22"
                height="20"
                color={themes.colors.success}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  padding: "0 0 0 0.6em",
                  fontWeight: 700,
                }}
              >
                {t("trade_in_home_section_two_list_item_one_text_one")}
              </Text>
            </Flex>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.white,
                verticalAlign: "middle",
                flexDirection: "row",
                width: "20em",
              }}
            >
              <IconCheckSucces
                width="22"
                height="20"
                color={themes.colors.success}
              />
              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  padding: "0 0 0 0.6em",
                  fontWeight: 700,
                }}
              >
                {t("trade_in_home_section_two_list_item_one_text_two")}
              </Text>
            </Flex>
          </Flex>
          <Flex
            sx={{
              minWidth: "15em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              "@media screen and (max-width: 725px)": {
                margin: "1em 0",
              },
            }}
          >
            <Flex
              sx={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Flex sx={{ width: "100%", justifyContent: "center" }}>
                <Box
                  sx={{
                    width: "6em",
                    height: "6em",
                    padding: "1em",
                    borderRadius: "50%",
                    border: `5px solid ${themes.colors.primary}`,
                  }}
                >
                  <IconClock width="100%" height="100%" />
                </Box>
              </Flex>
              <Flex sx={{ width: "100%", justifyContent: "center" }}>
                <Heading
                  sx={{
                    fontSize: "2em",
                    marginTop: "1em",
                    fontWeight: 700,
                    color: themes.colors.black,
                    textTransform: "uppercase",
                  }}
                >
                  {t("trade_in_home_section_two_list_item_two_title")}
                </Heading>
              </Flex>
              <Flex
                sx={{
                  fontSize: "1em",
                  color: themes.colors.white,
                  verticalAlign: "middle",
                  flexDirection: "row",
                }}
              >
                <IconCheckSucces
                  width="22"
                  height="20"
                  color={themes.colors.success}
                />

                <Text
                  sx={{
                    fontSize: "1em",
                    color: themes.colors.black,
                    padding: "0 0 0 0.6em",
                    fontWeight: 700,
                  }}
                >
                  {t("trade_in_home_section_two_list_item_two_text_one")}
                </Text>
              </Flex>
              <Flex
                sx={{
                  fontSize: "1em",
                  color: themes.colors.white,
                  verticalAlign: "middle",
                  flexDirection: "row",
                }}
              >
                <IconCheckSucces
                  width="22"
                  height="20"
                  color={themes.colors.success}
                />
                <Text
                  sx={{
                    fontSize: "1em",
                    color: themes.colors.black,
                    padding: "0 0 0 0.6em",
                    fontWeight: 700,
                  }}
                >
                  {t("trade_in_home_section_two_list_item_two_text_two")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            sx={{
              width: "15em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              "@media screen and (max-width: 725px)": {
                margin: "1em 0",
              },
            }}
          >
            <Flex
              sx={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Flex sx={{ width: "100%", justifyContent: "center" }}>
                <Box
                  sx={{
                    width: "6em",
                    height: "6em",
                    padding: "1em",
                    borderRadius: "50%",
                    border: `5px solid ${themes.colors.primary}`,
                  }}
                >
                  <IconShield width="100%" height="100%" />
                </Box>
              </Flex>
              <Flex sx={{ width: "100%", justifyContent: "center" }}>
                <Heading
                  sx={{
                    fontSize: "2em",
                    marginTop: "1em",
                    fontWeight: 700,
                    color: themes.colors.black,
                    textTransform: "uppercase",
                  }}
                >
                  {t("trade_in_home_section_two_list_item_three_title")}
                </Heading>
              </Flex>
              <Flex
                sx={{
                  fontSize: "1em",
                  color: themes.colors.white,
                  verticalAlign: "middle",
                  flexDirection: "row",
                }}
              >
                <IconCheckSucces
                  width="22"
                  height="20"
                  color={themes.colors.success}
                />

                <Text
                  sx={{
                    fontSize: "1em",
                    color: themes.colors.black,
                    padding: "0 0 0 0.6em",
                    fontWeight: 700,
                  }}
                >
                  {t("trade_in_home_section_two_list_item_three_text_one")}
                </Text>
              </Flex>
              <Flex
                sx={{
                  fontSize: "1em",
                  color: themes.colors.white,
                  verticalAlign: "middle",
                  flexDirection: "row",
                }}
              >
                <IconCheckSucces
                  width="22"
                  height="20"
                  color={themes.colors.success}
                />
                <Text
                  sx={{
                    fontSize: "1em",
                    color: themes.colors.black,
                    padding: "0 0 0 0.6em",
                    fontWeight: 700,
                  }}
                >
                  {t("trade_in_home_section_two_list_item_three_text_two")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {authenticated ? (
        <Flex
          sx={{
            background: themes.colors.tradeInHomeSectionGradient,
            backgroundColor: themes.colors.homeSectionsBG,
            width: "100%",
            height: "23em",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5em",
          }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Heading
              sx={{
                fontSize: "2.7em",
                fontWeight: 700,
                color: themes.colors.white,
              }}
            >
              {t("trade_in_home_auth_start_title")}
            </Heading>
            <Flex
              sx={{
                alignItems: "end",
                marginBottom: 0,
                height: "100%",
                padding: "1em 0",
              }}
            >
              <Link
                to="/trade-in"
                style={{
                  backgroundColor: themes.colors.tradeInHomeButtonBG,
                  textDecoration: "none",
                  color: themes.colors.white,
                  display: "flex",
                  gap: "1rem",
                  fontSize: " 1.2em",
                  fontWeight: 500,
                  width: "14rem",
                  padding: "0.8rem",
                  alignItems: "center",
                  justifyContent: "center",
                  "@media screen and (max-width: 1024px)": {
                    fontSize: "12px",
                    marginLeft: "0",
                  },
                  borderRadius: "3px",
                }}
              >
                <IconRightLeft />
                {t("trade_in_home_section_fixe_start_button_text")}
              </Link>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex
          sx={{
            background: themes.colors.tradeInHomeSectionGradient,
            backgroundColor: themes.colors.homeSectionsBG,
            width: "100%",
            height: "25em",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5em",
            padding: "3em  10em",
            "@media screen and (max-width: 725px)": {
              flexDirection: "column",
              justifyContent: "center",
              height: "45em",
            },
            "@media screen and (max-width: 400px)": {
              flexDirection: "column",
              justifyContent: "center",
              height: "60em",
            },
          }}
        >
          <Flex
            sx={{
              width: "30em",
              flexDirection: "column",
              justifyContent: "center",
              paddingRight: "7em",
              marginRight: "3em",
              marginLeft: "-5em",
              borderRight: `1px solid ${themes.colors.tableBgGray}`,
              "@media screen and (max-width: 725px)": {
                borderRight: 0,
                borderBottom: `1px solid ${themes.colors.tableBgGray}`,
                paddingRight: 0,
                paddingBottom: "4em",
                margin: "0 0 3em 0",
              },
              "@media screen and (max-width: 400px)": {
                width: "20em",
                borderRight: 0,
                borderBottom: `1px solid ${themes.colors.tableBgGray}`,
                paddingRight: 0,
                paddingBottom: "2em",
                margin: "0 0 2em 0",
              },
            }}
          >
            <Flex
              sx={{
                width: "100%",
                justifyContent: "center",
                marginBottom: "1em",
              }}
            >
              <IconKey width="3.5em" height="3.5em" />
            </Flex>
            <Flex sx={{ width: "100%", flexDirection: "column" }}>
              <Heading
                sx={{
                  fontSize: "1.2em",
                  fontWeight: 700,
                  color: themes.colors.white,
                  textAlign: "center",
                  marginBottom: "1em",
                }}
              >
                {t("trade_in_home_noauth_new_customer_title")}
              </Heading>
              <Text
                sx={{
                  fontSize: "0.85em",
                  color: themes.colors.white,
                  padding: "0 0 0 0.6em",
                  fontWeight: 500,
                  textAlign: "center",
                  marginBottom: "1em",
                }}
              >
                {t("trade_in_home_noauth_new_customer_text")}
              </Text>
            </Flex>
            <Flex sx={{ width: "100%", justifyContent: "center" }}>
              <Button
                sx={{
                  backgroundColor: themes.colors.tradeInHomeButtonBG,
                  borderRadius: "5px",
                  margin: "1.1em 0 0 0",
                  ":hover": {
                    backgroundColor: `${themes.colors.tradeInHomeButtonBG} !important`,
                    opacity: "0.80",
                  },
                }}
                onClick={onModalOpen}
              >
                <Flex
                  sx={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.6em",
                  }}
                >
                  <IconKey width="1em" height="1em" />
                  <Text
                    sx={{
                      fontSize: "1em",
                      color: themes.colors.white,
                      marginLeft: "0.3em",
                      padding: "0 0 0 0.6em",
                      fontWeight: 700,
                      textTransform: "capitalize",
                    }}
                  >
                    {t("trade_in_home_noauth_new_customer_button_text")}
                  </Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <Flex sx={{ flexDirection: "column" }}>
            <Box>
              <Heading
                sx={{
                  fontSize: "1.2em",
                  fontWeight: 700,
                  color: themes.colors.white,
                  marginBottom: "1em",
                  textAlign: "center",
                }}
              >
                {t("trade_in_home_noauth_login_customer_title")}
              </Heading>
            </Box>
            <Flex sx={{ width: "100%" }}>
              <Form
                onSubmit={onSubmit}
                validate={validate}
                render={({ handleSubmit }) => (
                  <Box
                    as="form"
                    onSubmit={handleSubmit}
                    sx={{
                      color: `${themes.colors.white} !important`,
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <RFFInputField
                        placeholder={t("username")}
                        label={t("username")}
                        type={"text"}
                        name={"username"}
                        icon={<IconCustomer width="0.8em" height="0.8em" />}
                        style={{ borderRadius: "2px" }}
                      />
                      <RFFInputField
                        placeholder={t("password")}
                        label={t("password")}
                        type={"password"}
                        name={"password"}
                        icon={<IconKey width="0.8em" height="0.8em" />}
                        style={{ borderRadius: "2px" }}
                      />
                    </Box>
                    <Field name={"terms_and_condition"}>
                      {({ input, meta }) => {
                        return (
                          <>
                            <div className={"form-control"}>
                              <div>
                                <input
                                  type={"checkbox"}
                                  {...input}
                                  onChange={(e) => {
                                    input.onChange(e);
                                    setErrorMessage(null);
                                  }}
                                />
                                {t("we_hereby_confirm")}
                                <a
                                  href={"#!"}
                                  style={{
                                    cursor: "pointer",
                                    color: themes.colors.red,
                                  }}
                                  onClick={() =>
                                    history.push({
                                      pathname: "/terms-and-conditions/",
                                    })
                                  }
                                >
                                  {t("terms_and_condition")}
                                </a>
                                <small
                                  style={{
                                    display: "flex",
                                    color: themes.colors.alertRed,
                                    fontSize: "80%",
                                  }}
                                  className={"error"}
                                >
                                  {meta.touched &&
                                    (meta.error || meta.submitError)}
                                </small>
                              </div>
                            </div>
                          </>
                        );
                      }}
                    </Field>
                    <Flex
                      sx={{
                        mt: "10px",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: "2.5em",
                        "@media screen and (max-width: 1024px)": {
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        },
                        "@media screen and (max-width: 925px)": {
                          flexDirection: "column",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          height: "100%",
                          "@media screen and (max-width: 925px)": {
                            paddingTop: "0.5em",
                          },
                        }}
                      >
                        <Link
                          to="/trade-in"
                          style={{
                            backgroundColor: themes.colors.transparent,
                            textDecoration: "none",
                            color: themes.colors.white,
                            display: "flex",
                            fontSize: " 1.2em",
                            fontWeight: 500,
                            width: "14rem",
                            verticalAlign: "middle",
                            alignItems: "center",
                            "@media screen and (max-width: 1024px)": {
                              fontSize: "12px",
                              marginLeft: "0",
                            },
                          }}
                        >
                          {t("forgot_password")}
                        </Link>
                      </Box>
                      <Box
                        sx={{
                          width: "10em",
                          "@media screen and (max-width: 925px)": {
                            paddingTop: "0.5em",
                          },
                        }}
                      >
                        <WithLoader isLoading={isLoading}>
                          <Button
                            sx={{
                              display: "flex",
                              width: "100%",
                              borderRadius: "2px",
                              height: "100%",
                              justifyContent: "space-evenly",
                              textTransform: "capitalize",
                              backgroundColor:
                                themes.colors.tradeInHomeButtonBG,
                              alignItems: "center",
                              ":hover": {
                                backgroundColor: `${themes.colors.tradeInHomeButtonBG} !important`,
                                opacity: "0.80",
                              },
                            }}
                            type="submit"
                            className="login-btn"
                          >
                            <IconCustomer width="0.8em" height="0.8em" />
                            {t("login")}
                          </Button>
                        </WithLoader>
                      </Box>
                    </Flex>
                  </Box>
                )}
              />
            </Flex>
            <ErrorMessage
              message={errorMessage}
              sx={{ marginTop: "1em", fontWeight: 400 }}
            />
          </Flex>
        </Flex>
      )}
      <CreateCustomerModal
        showState={showCreateProductModal}
        setShowState={setShowCreateProductModal}
        data={modalData}
        setData={setModalData}
      />
    </Flex>
  );
};

export default HomePageBody;
