import * as PropTypes from "prop-types";

// navigator.language
const withMoneySymbol = (amount) => {
  return Number(amount).toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
};

const Money = ({ amount }) => {
  if (!amount) return "-";
  return withMoneySymbol(amount);
};
Money.propTypes = {
  amount: PropTypes.number,
};
export { withMoneySymbol };
export default Money;
