import React, { Fragment } from "react";
import { Field } from "react-final-form";

export const RFFCheckBoxField = ({
  name,
  label,
  onChange,
  disabled = false,
}) => {
  return (
    <Field name={name}>
      {({ input, meta }) => {
        return (
          <Fragment>
            <label>
              <div className={"form-control"} style={{ marginTop: "1rem" }}>
                <div>
                  <input
                    type={"checkbox"}
                    {...input}
                    onChange={(e) => {
                      input.onChange(e);
                      onChange(e);
                    }}
                    disabled={disabled}
                  />
                  {label}
                  <small
                    style={{
                      display: "flex",
                      color: "#ff3d3d",
                      fontSize: "80%",
                    }}
                    className={"error"}
                  >
                    {meta.touched && (meta.error || meta.submitError)}
                  </small>
                </div>
              </div>
            </label>
          </Fragment>
        );
      }}
    </Field>
  );
};
