import React, { useCallback, useState } from "react";
import { Field } from "react-final-form";
import { Box, Label, Input } from "theme-ui";
import RFFErrorMessage from "./RFFErrorMessage";
import themes from "../../../themes";
import { roundToInteger, inputBorderColor } from "../../../utils/helpers";
import CustomSwitch from "../CustomSwitch";

const RFFInput = ({
  name,
  type,
  label,
  placeholder,
  defaultValue,
  sx,
  disabled,
  max,
  min,
  currency,
  maxLength,
  isSwitch,
  switchValue,
  setSwitchValue,
  onChange,
}) => {
  const handleChange = useCallback(
    (value, input) => {
      if (type === "number" && parseFloat(value) < 0) {
        value = Math.abs(value);
      }
      if (name === "european_article_number") {
        value = value.replace(/[a-zA-Z]+$/, "");
      }
      if (
        type === "number" &&
        typeof value === "string" &&
        value.includes(".")
      ) {
        value = roundToInteger(value.replace(",", "."));
      }

      input.onChange(type === "number" ? Number(value) : value);
      if (onChange) onChange(value);
    },
    [name, type, onChange]
  );

  const handleSwitchChange = (value) => {
    setSwitchValue(value);
  };

  return (
    <Field
      name={name}
      initialValue={defaultValue}
      render={({ input, meta }) => (
        <Box
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          {label && <Label sx={{ marginBottom: "2px" }}>{label}</Label>}
          <Input
            type={type}
            sx={{
              borderRadius: "6px",
              padding: "8px 10px",
              backgroundColor: themes.colors.inputBG,
              ...sx,
              borderColor: inputBorderColor({ meta, sx }),
            }}
            {...input}
            placeholder={placeholder}
            disabled={disabled}
            max={max}
            min={min}
            maxLength={maxLength}
            onInput={(e) => handleChange(e.target.value, input)}
            onBlur={(e) => handleChange(e.target.value, input)}
            step="any"
            autoComplete="off"
          />
          {currency && (
            <span
              style={{
                position: "absolute",
                top: "70%",
                right: "15px",
                transform: "translateY(-50%)",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {currency}
            </span>
          )}
          {isSwitch && (
            <div
              style={{
                position: "absolute",
                top: "75%",
                right: "15px",
                transform: "translateY(-50%)",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <CustomSwitch
                checked={switchValue}
                onChange={handleSwitchChange}
              />
            </div>
          )}
          {meta.touched && meta.error && (
            <Label sx={{ marginTop: "0.3rem" }}>
              <RFFErrorMessage errorMessage={meta.error} />
            </Label>
          )}
        </Box>
      )}
    />
  );
};

export default RFFInput;
