import React from "react";
import styled from "@emotion/styled";
import { Flex, NavLink, Badge } from "theme-ui";
import Logo from "../../../assets/images/logo.png";
import IconPhone from "../../../components/icons/icon-phone";
import IconEmail from "../../../components/icons/icon-email";
import Menu from "../../../components/Menu";
import { useHistory } from "react-router-dom";
import LanguageSelector from "../../../components/LanguageSelector";
import { useTranslation } from "react-i18next";
import themes from "../../../themes";
import { transparentize } from "@theme-ui/color";
import { useTradeInCartContext } from "../../../contexts/TradeInCartContext";
import IconRightLeft from "../../../components/icons/icon-right-left";
import { phoneNumber, mailTo } from "../../../constants";

const Image = styled.img`
  display: block;
  height: 2.5rem;
  width: auto;
`;
const NavLinkContainer = styled.div`
  .nav-item {
    padding: 0.5rem;
    margin: 0 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.white};
    border-radius: 0.25rem;
    position: relative;
    text-decoration: none;
    &.icon-link {
      margin: 0;
      padding: 1rem;
      @media (max-width: 320px) {
        padding: 0.5rem;
      }
      svg {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

const Header = ({ showNavLink, customStyle }) => {
  const { t } = useTranslation();
  const { openModal, productCount, isCartEnabled } = useTradeInCartContext();
  const history = useHistory();

  return (
    <Flex
      sx={{
        width: "100%",
        backgroundColor: themes.colors.primary,
        color: "white",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: " 6rem",
        "@media (max-width: 831px)": {
          paddingLeft: "3rem",
        },
        "@media (max-width: 642px)": {
          paddingLeft: "1.5rem",
        },
        "@media (max-width: 370px)": {
          paddingLeft: "0rem",
        },
        ...customStyle,
      }}
    >
      <NavLink p={2}>
        <Image
          className="brand-image"
          src={Logo}
          onClick={() => history.push("/")}
        />
      </NavLink>
      <Flex
        sx={{
          alignItems: "center",
          paddingRight: customStyle ? "3rem" : "6rem",
          "@media (max-width: 831px)": {
            paddingRight: "3rem",
          },
          "@media (max-width: 642px)": {
            paddingRight: "1.5rem",
          },
          "@media (max-width: 370px)": {
            paddingRight: "0",
            width: "250",
          },
        }}
      >
        <NavLink
          sx={{
            "&:hover": {
              backgroundColor: transparentize(themes.colors.white, 0.92),
              color: themes.colors.white,
              border: `1px solid ${themes.colors.primary}`,
              borderRadius: "10px",
            },
          }}
        >
          <Menu
            label={t("contact").toUpperCase()}
            showDropdownIcon={false}
            contentStyle={{ minWidth: "14rem", color: themes.colors.black }}
            items={[
              {
                label: (
                  <>
                    <IconPhone /> +49 (0) 69 83 83 25 0
                  </>
                ),
                onClick: () => {
                  window.open(phoneNumber, "_self");
                },
              },
              {
                label: (
                  <>
                    <IconEmail />
                    info@yukatel.de
                  </>
                ),
                onClick: () => {
                  window.open(mailTo, "_self");
                },
              },
            ]}
          />
        </NavLink>
        <NavLink
          sx={{
            "&:hover": {
              backgroundColor: transparentize(themes.colors.white, 0.92),
              color: themes.colors.white,
              border: `1px solid ${themes.colors.primary}`,
              borderRadius: "10px",
            },
          }}
        >
          <LanguageSelector textColor={themes.colors.black} />
        </NavLink>
        {showNavLink && isCartEnabled && (
          <NavLinkContainer>
            <NavLink
              to={"#!"}
              className={"nav-item icon-link"}
              activeClassName={"active"}
              onClick={openModal}
            >
              <IconRightLeft />
              <Badge
                variant="cartBadge"
                style={{
                  right: "0.125rem",
                  top: "0.375rem",

                  "@media (max-width: 320px)": {
                    top: "0.8rem",
                    width: "1rem",
                    height: "1rem",
                  },
                }}
              >
                {productCount}
              </Badge>
            </NavLink>
          </NavLinkContainer>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
