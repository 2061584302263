import React, { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { queryCache } from "react-query";
import { toast } from "react-toastify";
import { Box, Flex, Text } from "theme-ui";
import { tradeInOrderStatusChange } from "../../../apis";
import exceptionHandler from "../../../apis/exceptionHandler";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Money from "../../../components/Money";
import Timer from "../../../components/Timer";
import OfferUsModal from "../../../components/TradeIn/modals/OfferUsModal";
import IconCircleCheck from "../../../components/icons/circle-check-solid";
import IconXmarkSolid from "../../../components/icons/circle-xmark-solid";
import IconClockRotate from "../../../components/icons/icon-clock-rotate-left";
import IconDocument from "../../../components/icons/icon-document";
import IconRotateSolid from "../../../components/icons/icon-rotate-solid";
import { TradeIn_Status } from "../../../constants";
import { useModal } from "../../../contexts/ModalContext";
import themes from "../../../themes";
import { isEqualValue, parseDate, parseDateTime } from "../../../utils/helpers";
import TradeInHistoryModal from "../../../components/TradeIn/modals/TradeInHistoryModal";
import InitialSubmissionModal from "../../../components/TradeIn/modals/InitialSubmissionModal";

const UnderReviewTable = ({ data, refetch, status, statusValue }) => {
  const { t } = useTranslation();
  const [setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { showModal } = useModal();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const isUnderReview = status === TradeIn_Status.STATUS_UNDER_REVIEW.text;
  const isCounterOffer = status === TradeIn_Status.STATUS_COUNTER_OFFER.text;
  const statusUpdate = useCallback(
    async (id, status) => {
      try {
        showModal(
          ({ hideModal }) => {
            return (
              <ConfirmationModal
                title={t("confirm")}
                titleStyle={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  textAlign: "left !important",
                  backgroundColor: themes.colors.tableBgGray,
                  width: "100%",
                  marginBottom: "1.5em",
                }}
                question={t("status_update_confirmation")}
                positiveButton={{
                  text: t("yes"),
                  style: {
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    textTransform: "capitalize",
                    borderRadius: "3px",
                    width: "7em",
                    padding: "0.5em",
                    alignItems: "center",
                    verticalAlign: "middle",
                    marginRight: "1em",
                  },
                  action: async () => {
                    try {
                      await tradeInOrderStatusChange({ id, status });
                      hideModal();
                      toast(t("success"), { type: "success" });
                      refetch();
                    } catch {
                      hideModal();
                      toast(t("errors"), { type: "error" });
                    }
                  },
                }}
                negativeButton={{
                  text: t("no"),
                  style: {
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    textTransform: "capitalize",
                    borderRadius: "3px",
                    width: "7em",
                    padding: "0.5em",
                    alignItems: "center",
                    verticalAlign: "middle",
                  },
                  action: () => {
                    hideModal();
                  },
                }}
              />
            );
          },
          () => {
            queryCache.clear();
          }
        );
      } catch (e) {
        return exceptionHandler(
          e,
          (error) => {
            setErrorMessage(error);
          },
          (errors) => {
            return errors;
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [refetch, setErrorMessage, showModal, t]
  );

  return (
    <>
      {!isLoading && (!data || data?.length === 0) ? (
        <Text
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "4rem",
          }}
        >
          {t("no_data_in_the_list")}
        </Text>
      ) : (
        <Box className="table-container">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th style={{ width: isCounterOffer ? "430px" : "300px" }}>
                  {t("product_name")}
                </th>
                <th>{t("date")}</th>
                <th>{t("delivery_date")}</th>
                <th>{t("quantity")}</th>
                <th style={{ width: "105px" }}>{t("unit_price")}</th>
                {isUnderReview ? (
                  <>
                    <th />
                    <th />
                  </>
                ) : (
                  <>
                    <th>{t("o_quantity")}</th>
                    <th style={{ width: "118px" }}>{t("o_unit_price")}</th>
                  </>
                )}
                <th>{t("take_qty_price")}</th>
                {isCounterOffer && (
                  <th style={{ textAlign: "center" }}>{t("cms")}</th>
                )}
                <th />
                <th />
                <th />
                <th>{isCounterOffer ? t("time_remaining") : ""}</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {data?.map((item, key) => (
                <Fragment key={key}>
                  <tr key={key}>
                    <td style={{ maxWidth: "50px", textAlign: "left" }}>
                      {item.article_name}
                    </td>
                    <td>{parseDate(item?.created_at)}</td>
                    <td>{parseDate(item?.delivery_date)}</td>
                    <td
                      className={
                        isEqualValue(item).isEqualQuantity
                          ? "black-text"
                          : isCounterOffer && "red-text"
                      }
                      style={
                        isCounterOffer
                          ? {
                              border: `2px solid ${themes.colors.lightCoral}`,
                              borderRight: "none",
                              color: themes.colors.lightCoral,
                              marginRight: "3px",
                            }
                          : null
                      }
                    >
                      {item?.min_order_quantity}
                    </td>
                    <td
                      className={
                        isEqualValue(item).isEqualPrice
                          ? "black-text"
                          : isCounterOffer && "red-text"
                      }
                      style={
                        isCounterOffer
                          ? {
                              border: `2px solid ${themes.colors.lightCoral}`,
                              borderLeft: "none",
                              color: themes.colors.lightCoral,
                            }
                          : null
                      }
                    >
                      <span>
                        <Money amount={item?.unit_price} />
                      </span>
                    </td>
                    {!isUnderReview ? (
                      <>
                        <td
                          className={
                            isEqualValue(item).isEqualQuantity
                              ? "black-text"
                              : isCounterOffer && "green-text"
                          }
                          style={
                            isCounterOffer
                              ? {
                                  border: `2px solid ${themes.colors.lightGreen}`,
                                  borderRight: "none",
                                  color: themes.colors.lightGreen,
                                }
                              : null
                          }
                        >
                          {item?.latest_counter_offer?.quantity}
                        </td>
                        <td
                          className={
                            isEqualValue(item).isEqualPrice
                              ? "black-text"
                              : isCounterOffer && "green-text"
                          }
                          style={
                            isCounterOffer
                              ? {
                                  border: `2px solid ${themes.colors.lightGreen}`,
                                  borderRight: "none",
                                  borderLeft: "none",
                                  color: themes.colors.lightGreen,
                                }
                              : null
                          }
                        >
                          <span>
                            <Money amount={item?.latest_counter_offer?.price} />
                          </span>
                        </td>
                      </>
                    ) : (
                      <>
                        <td />
                        <td />
                      </>
                    )}
                    <td
                      className={isCounterOffer && "green-text"}
                      style={
                        isCounterOffer
                          ? {
                              border: `2px solid ${themes.colors.lightGreen}`,
                              borderLeft: "none",
                              color: themes.colors.lightGreen,
                            }
                          : null
                      }
                    >
                      {item?.take_all_quantity ? (
                        <>
                          {`${item.take_all_quantity}/`}
                          <span>
                            <Money amount={item?.take_all_unit_price} />
                          </span>
                        </>
                      ) : (
                        <span
                          style={{
                            paddingLeft: isCounterOffer ? "1.4rem" : "3rem",
                          }}
                        >
                          -
                        </span>
                      )}
                    </td>
                    {isCounterOffer && (
                      <td>
                        <div
                          style={
                            isCounterOffer
                              ? {
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }
                              : null
                          }
                          title={t("commission")}
                        >
                          {item.latest_counter_offer?.has_commission === 1 && (
                            <IconCircleCheck fill={themes.colors.primary} />
                          )}
                        </div>
                      </td>
                    )}
                    <td style={{ maxWidth: "159px" }}>
                      {!isUnderReview && (
                        <>
                          <Flex
                            sx={{
                              gap: "1",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {isCounterOffer ? (
                              <>
                                {item?.latest_counter_offer
                                  ?.is_given_by_seller ? (
                                  <Text
                                    sx={{
                                      minWidth: "160px",
                                      fontSize: "1rem",
                                      textAlign: "center",
                                      color: themes.colors.grayTextColor,
                                    }}
                                  >
                                    {t("yukatel_will_review")}
                                  </Text>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        marginRight: "1rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      onClick={() =>
                                        statusUpdate(
                                          item.id,
                                          TradeIn_Status.STATUS_APPROVED.value
                                        )
                                      }
                                      title={t("approved")}
                                    >
                                      <IconCircleCheck
                                        fill={themes.colors.success}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        marginRight: "1rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      onClick={() =>
                                        statusUpdate(
                                          item.id,
                                          TradeIn_Status.STATUS_REJECTED.value
                                        )
                                      }
                                      title={t("rejected")}
                                    >
                                      <IconXmarkSolid
                                        width="30px"
                                        fill={themes.colors.danger}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "26px",
                                        height: "26px",
                                        borderRadius: "10%",
                                        backgroundColor:
                                          themes.colors.lightBlue,
                                        padding: "7px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setShowOfferModal(true);
                                        setSelectedItem(item);
                                      }}
                                      title={t("offer_us")}
                                    >
                                      <IconRotateSolid
                                        fill={themes.colors.primary}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <Flex
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "28px",
                                  height: "28px",
                                  borderRadius: "50%",
                                  backgroundColor: themes.colors.lightBlue,
                                  padding: "6px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowOfferModal(true);
                                  setSelectedItem(item);
                                }}
                                title={t("offer_us")}
                              >
                                <IconRotateSolid fill={themes.colors.primary} />
                              </Flex>
                            )}
                          </Flex>
                        </>
                      )}
                    </td>
                    <td />
                    <td>
                      {!isCounterOffer ? (
                        <>
                          <Flex
                            sx={{
                              gap: "20px",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: "0.7rem",
                            }}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowDetailModal(true);
                                setSelectedItem(item);
                              }}
                              title={t("initial_submission")}
                            >
                              <IconDocument fill={themes.colors.primary} />
                            </div>
                          </Flex>
                        </>
                      ) : (
                        <>
                          <Flex
                            sx={{
                              gap: "20px",
                              mt: "0.7rem",
                            }}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowHistoryModal(true);
                                setSelectedItem(item);
                              }}
                              title={t("history")}
                            >
                              <IconClockRotate fill={themes.colors.primary} />
                            </div>
                            {!isCounterOffer && (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShowDetailModal(true);
                                  setSelectedItem(item);
                                }}
                                title={t("initial_submission")}
                              >
                                <IconDocument fill={themes.colors.primary} />
                              </div>
                            )}
                          </Flex>
                        </>
                      )}
                    </td>
                    {isCounterOffer && item?.created_at ? (
                      <>
                        {!item?.latest_counter_offer?.is_given_by_seller ? (
                          <td>
                            <Flex style={{ alignItems: "center" }}>
                              <Timer
                                dateStr={
                                  parseDateTime(
                                    item?.latest_counter_offer?.created_at
                                  ).milliseconds
                                }
                              />
                            </Flex>
                          </td>
                        ) : (
                          <td />
                        )}
                      </>
                    ) : (
                      <>
                        <td />
                      </>
                    )}
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </Box>
      )}
      {showOfferModal && (
        <OfferUsModal
          setShowOfferModal={setShowOfferModal}
          selectedItem={selectedItem}
          statusValue={statusValue}
          refetch={refetch}
        />
      )}
      {showHistoryModal && (
        <TradeInHistoryModal
          setShowHistoryModal={setShowHistoryModal}
          selectedItem={selectedItem}
        />
      )}
      {!isCounterOffer && showDetailModal && (
        <InitialSubmissionModal
          setShowInitialSubmissionModal={setShowDetailModal}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};

export default UnderReviewTable;
