import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { Flex } from "theme-ui";
import Logo from "../../../assets/images/logo.png";
import IconPhone from "../../../components/icons/icon-phone";
import IconEmail from "../../../components/icons/icon-email";
import Container from "../../../components/Container";
import Menu from "../../../components/Menu";
import { transparentize } from "@theme-ui/color";
import { useHistory } from "react-router-dom";
import LanguageSelector from "../../../components/LanguageSelector";
import { useTranslation } from "react-i18next";

const HeaderContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 0.5rem 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.125);

  .main-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mobile-toggle-block {
      display: none;
    }
  }

  .nav-links {
    display: flex;
    font-family: "Raleway", san-sarif;
    font-size: 0.875rem;

    .nav-item {
      padding: 0.5rem;
      margin: 0 0.5rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.colors.white};
      border-radius: 0.25rem;
      position: relative;
      text-decoration: none;

      > span {
        text-transform: uppercase;
      }

      .dropdown-label-block {
        text-transform: uppercase;
      }

      &.icon-link {
        margin: 0;
        padding: 1rem;
      }

      &.dropdown-link {
        margin: 0;
        padding: 0;

        .dropdown-menu {
          padding: 1rem;
          height: auto;
        }

        .dropdown-label-block {
          font-size: 0.875rem;
        }

        &.language-dropdown {
          .dropdown-menu {
            padding-right: 1.75rem;
          }
        }
      }

      &:hover {
        background-color: ${(props) =>
          transparentize(props.theme.colors.white, 0.92)};
      }

      &.active {
        &::after {
          display: block;
          content: "";
          position: absolute;
          left: 0;
          bottom: -0.5rem;
          height: 3px;
          width: 100%;
          background: ${(props) => props.theme.colors.secondary};
        }
      }

      .dropdown-menu {
        .dropdown-content {
          color: ${(props) => props.theme.colors.primary};
          font-family: "Roboto", sans-sarif;
        }

        .menu-item-icon {
          fill: ${(props) => props.theme.colors.primary};
          margin-right: 0.75rem;
          height: 1.25rem;
          width: 1.25rem;
        }
      }
    }
  }

  .mobile-nav-container {
    display: none;
  }

  // media query

  @media (max-width: 1024px) {
    padding: 0.75rem 0;

    .main-nav-container {
      .mobile-toggle-block {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page-title {
          color: ${(props) => props.theme.colors.white};
          font-size: 1.25rem;
          margin-left: 1rem;
        }

        .mobile-toggle {
          display: flex;
          padding: 0.25rem 0.5rem;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .svg-icon {
            fill: ${(props) => props.theme.colors.white};
            width: 1.5rem;
            height: 1.5rem;
          }

          &.active {
            background-color: ${(props) =>
              transparentize(props.theme.colors.white, 0.92)};
          }

          &:after {
            display: none;
          }
        }
      }

      .nav-links,
      .brand-image {
        display: none;
      }
    }

    .mobile-nav-wrapper {
      position: fixed;
      left: -100%;
      top: 0;
      z-index: 100;
      height: 100%;
      width: 100%;
      transition: all 0.4s ease;

      display: flex;

      &.opened {
        left: 0;
      }
    }

    .outside-click {
      flex: 1 1 auto;
      background: transparent;
    }

    .mobile-nav-container {
      display: flex;
      flex-direction: column;
      background-color: ${(props) => props.theme.colors.white};
      box-shadow: ${(props) => props.theme.shadows.default};
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      padding-top: 1rem;
      padding-bottom: 2rem;
      max-width: 20rem;
      flex: 1 1 20rem;
      position: relative;

      .icon-btn {
        position: absolute;
        right: 0.5rem;
        top: 1rem;
      }

      .nav-links {
        flex-direction: column;

        .nav-item {
          color: ${(props) => props.theme.colors.primaryText};
          align-items: flex-start;
          font-weight: 600;
          padding: 1rem;
          font-size: 1rem;
          margin: 0;

          > span {
            text-transform: capitalize;
          }

          &:hover {
            background-color: ${(props) =>
              transparentize(props.theme.colors.secondary, 0.92)};
          }

          &.active {
            color: ${(props) => props.theme.colors.secondary};
            background-color: ${(props) =>
              transparentize(props.theme.colors.secondary, 0.92)};

            &::after {
              display: none;
            }
          }
        }

        .accordion-container {
          flex-direction: column;
          padding-bottom: 0.5rem;

          .accordion-title {
            display: flex;
            position: relative;

            .svg-icon {
              transition: all 0.3s ease;
              position: absolute;
              top: 1rem;
              right: 1rem;
            }
          }

          .accordion-content {
            flex-direction: column;
            padding-left: 1rem;
            display: none;

            .nav-sub-link {
              padding: 0.75rem 1rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              display: flex;
              align-items: center;
              text-decoration: none;
              color: currentColor;

              .svg-icon {
                margin-right: 0.5rem;
              }

              &:hover {
                background-color: ${(props) =>
                  transparentize(props.theme.colors.secondary, 0.92)};
              }

              &.active {
                color: ${(props) => props.theme.colors.secondary};

                .svg-icon {
                  fill: currentColor;
                }
              }
            }
          }

          &.opened {
            .accordion-title {
              color: ${(props) => props.theme.colors.secondary};

              .svg-icon {
                transform: rotate(180deg);
              }
            }

            .accordion-content {
              display: flex;
            }
          }
        }
      }
    }
  }

  ${(props) => props.theme.customBreakpoints[1]} {
    .main-nav-container {
      .mobile-toggle-block {
        .page-title {
          font-size: 1.5rem;
        }

        .mobile-toggle {
          .svg-icon {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }

  ${(props) => props.theme.customBreakpoints[0]} {
    position: sticky;
    top: 0;
    z-index: 100;
  }
`;

const Image = styled.img`
  display: block;
  height: 2.5rem;
  width: auto;
`;

const Header = () => {
  const { t } = useTranslation();
  let history = useHistory();

  // const [showMobile, setShowMobile] = useState(false);

  return (
    <HeaderContainer as="nav" className="header-container">
      {/* Main Navbar */}
      <Container className="main-nav-container">
        {/* Only visible on mobile views */}

        <Image
          className="brand-image"
          src={Logo}
          onClick={() => history.push("/")}
        />
        <Flex className="nav-links">
          <a className="nav-item dropdown-link" href={"#!"}>
            <Menu
              label={t("contact")}
              showDropdownIcon={false}
              contentStyle={{ minWidth: "14rem" }}
              items={[
                {
                  label: (
                    <Fragment>
                      <IconPhone className="menu-item-icon" /> +49 (0) 69 83 83
                      25 0
                    </Fragment>
                  ),
                  onClick: () => {
                    window.open("tel:+49 (0) 69 83 83 25 0", "_self");
                  },
                },
                {
                  label: (
                    <Fragment>
                      <IconEmail className="menu-item-icon" />
                      info@yukatel.de
                    </Fragment>
                  ),
                  onClick: () => {
                    window.open("mailto:info@yukatel.de", "_self");
                  },
                },
              ]}
            />
          </a>
          <a className="nav-item dropdown-link language-dropdown" href={"#!"}>
            <LanguageSelector />
          </a>
        </Flex>
      </Container>

      {/* Mobile Drawer Navbar */}
    </HeaderContainer>
  );
};

export default Header;
