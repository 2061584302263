export const formattedAddress = (
  street,
  house_no,
  post_code,
  city,
  country_name
) => {
  return street && house_no && post_code && city && country_name
    ? `${street}.${house_no}` + `, ${post_code} ${city}` + `, ${country_name}`
    : `${street || ""} ${house_no || ""} ${post_code || ""} ${city || ""} ${
        country_name || ""
      }`;
};

export const formattedDeliveryAddress = (
  firstname,
  com_lastname,
  street,
  house_no,
  post_code,
  city,
  country_name
) => {
  return firstname &&
    com_lastname &&
    street &&
    house_no &&
    post_code &&
    city &&
    country_name
    ? `${firstname} ${com_lastname}, ${street}.${house_no}, ${post_code} ${city}, ${country_name}`
    : `${firstname || ""} ${com_lastname || ""}, ${street || ""}.${
        house_no || ""
      }, ${post_code || ""} ${city || ""}, ${country_name || ""}`;
};
