/* eslint-disable react/prop-types */
import React from "react";

const IconAlert = ({
  fill = "inherit",
  width = "24px",
  height = "24px",
  className = "",
  viewBox = "0 0 24 24",
  onClick,
}) => (
  <svg
    onClick={onClick}
    width={width}
    fill={fill}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M20.3614 20.498H3.6319C3.04616 20.5018 2.50421 20.1884 2.21527 19.6789C1.92529 19.1812 1.92848 18.5652 2.2236 18.0706L10.5809 3.80513C10.8762 3.30609 11.413 3 11.9929 3C12.5728 3 13.1096 3.30609 13.405 3.80513L21.7731 18.0714C22.0682 18.5661 22.0714 19.182 21.7814 19.6797C21.492 20.1886 20.9502 20.5016 20.3648 20.498H20.3614ZM3.29774 18.7031C3.23144 18.8121 3.23144 18.949 3.29774 19.058C3.36564 19.1778 3.49344 19.2509 3.63107 19.2489H20.3614C20.499 19.2508 20.6267 19.1776 20.6948 19.058C20.7609 18.9493 20.7609 18.8127 20.6948 18.7039L12.3308 4.43762C12.2598 4.32159 12.1335 4.25085 11.9975 4.25085C11.8615 4.25085 11.7352 4.32159 11.6642 4.43762L3.29774 18.7031ZM11.9975 14.8798C11.6523 14.8798 11.3725 14.6 11.3725 14.2548V9.25498C11.3725 8.90982 11.6523 8.63 11.9975 8.63C12.3427 8.63 12.6225 8.90982 12.6225 9.25498V14.2548C12.6225 14.6 12.3427 14.8798 11.9975 14.8798ZM11.9975 17.3798C11.7026 17.3769 11.4507 17.1665 11.3954 16.8768C11.34 16.5872 11.4966 16.2987 11.7697 16.1873C12.0427 16.076 12.3564 16.1726 12.5194 16.4183C12.6825 16.6641 12.6496 16.9907 12.4408 17.1989C12.3217 17.3139 12.163 17.3786 11.9975 17.3798Z" />
  </svg>
);

export default IconAlert;
