import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Image, Text } from "theme-ui";
import CounterInput from "../../../components/CounterInput";
import IconEdit from "../../../components/icons/icon-edit";
import IconPlus from "../../../components/icons/icon-plus";
import Money from "../../../components/Money";
import {
  addDefaultSource,
  getFormatedYukaPoints,
} from "../../../utils/helpers";
import PropTypes from "prop-types";
import IconTruck from "../../../components/icons/icon-truck";
import IconImage from "../../../components/icons/icon-image";
import IconClose from "../../../components/icons/icon-close";
import { useModal } from "../../../contexts/ModalContext";
import ProductImageModal from "../../../components/ProductImageModal";
import YPImage from "../../../assets/images/YP.png";
import { useCOAStatus } from "../../../contexts/COAProvider";
import ReactTooltip from "react-tooltip";
import IconPhone from "../../../components/icons/icon-phone";
import { useTranslation } from "react-i18next";

const MobileViewProductItemContainer = styled(Box)`
  display: grid;
  grid-row-gap: 0.75rem;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  .product-name {
    grid-column: 1 / span 2;
  }

  .product-price,
  .est-delivary {
    grid-row: 2;
  }

  .est-delivary {
    justify-self: end;
    color: ${(props) => props.theme.colors.secondaryText};
  }

  .yuka-points {
    grid-row: 3;
    color: ${(props) => props.theme.colors.secondaryText};
    display: flex;
    align-items: center;
  }

  .product-actions {
    grid-row: 3;
    justify-self: end;
    display: flex;
    align-items: center;

    .product-qty {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      width: 10rem;
    }
  }
`;

const MobileViewProductItem = ({
  data: {
    article_no,
    ean,
    part_number,
    description,
    selling_price,
    reward_points,
    max_quantity,
    next_delivery_date,
    stock_status,
    is_added_to_cart = false,
    cart_quantity,
    image,
    actual_stock,
    purchased_stock,
    tele_bestellen,
  },
  onAddToCart,
  onUpdateToCart,
}) => {
  const isCOAEnabled = useCOAStatus();
  const { t } = useTranslation();

  const [quantity, setQuantity] = useState(max_quantity === 0 ? 0 : 1);

  useEffect(() => {
    setQuantity(max_quantity === 0 ? 0 : 1);
  }, [max_quantity]);
  const { showModal } = useModal();
  return (
    <MobileViewProductItemContainer className="mobile-view-product-item-container">
      <Box className="product-name">
        <Flex>
          <div>
            <span>{description}</span>
            <Text>{`${t("european_article_number_short")}: ${ean || ""}`}</Text>
            <Text>{`${t("part_number")}: ${part_number || ""}`}</Text>
          </div>
          {image && (
            <Button
              variant="iconButton"
              sx={{
                marginLeft: "0.5rem",
                height: "1.75rem",
                width: "1.75rem",
                position: "absolute",
                right: "1rem",
              }}
            >
              <IconImage
                style={{ height: "1.5rem", width: "1.5rem" }}
                onClick={() =>
                  showModal(({ hideModal }) => {
                    return (
                      <ProductImageModal className="product-img-modal">
                        <Box className="product-img-container">
                          <img
                            className="img"
                            src={image}
                            onError={addDefaultSource}
                            alt={"hot deal"}
                          />
                        </Box>
                        <Button
                          className="icon-btn"
                          variant="secondaryIconButton"
                          onClick={() => hideModal()}
                        >
                          <IconClose />
                        </Button>
                      </ProductImageModal>
                    );
                  })
                }
              />
            </Button>
          )}
        </Flex>
      </Box>
      <Box className="product-price">
        {stock_status === "available" || stock_status === "upcoming" ? (
          <Text sx={{ fontSize: "1em", fontWeight: 700 }}>
            <Money amount={selling_price} />
          </Text>
        ) : (
          t("stock_status_not_available")
        )}
      </Box>
      <Box className="est-delivary">
        {next_delivery_date === "unknown" ? "-" : next_delivery_date}
        {next_delivery_date !== "unknown" && (
          <IconTruck
            height="18px"
            width="18px"
            style={{ transform: "scaleX(-1)" }}
          />
        )}
      </Box>
      <Box className="yuka-points">
        {getFormatedYukaPoints(reward_points)}
        <Image
          src={YPImage}
          style={{ height: "1rem", width: "auto", marginLeft: "0.25rem" }}
        />
      </Box>
      <Box className="product-actions">
        <Box className="product-qty">
          <span
            data-tip={`${
              stock_status === "available" || stock_status === "upcoming"
                ? "On Stock: " +
                  actual_stock +
                  "<br/>Purchased: " +
                  purchased_stock +
                  "<br/>Max Quantity: " +
                  max_quantity
                : "Max Quantity: " + max_quantity
            }`}
            data-for={article_no}
            className={`status-indicator ${stock_status}`}
          >
            <ReactTooltip
              place={"bottom"}
              type={max_quantity === 0 ? "error" : "success"}
              id={article_no}
              multiline={true}
            />
          </span>
          <CounterInput
            value={quantity}
            maxValue={max_quantity}
            telephoneOrder={tele_bestellen}
            onChange={(value) => {
              setQuantity(value);
            }}
          />
        </Box>
        {tele_bestellen === "ja" ? (
          <span>
            <ReactTooltip
              place={"bottom"}
              type={"warning"}
              id={`tel_${article_no}`}
              multiline={true}
            />
            <Button
              data-tip={t("contact_agent")}
              data-for={`tel_${article_no}`}
              variant="primaryIconButton"
              className="icon-button"
            >
              <IconPhone />
            </Button>
          </span>
        ) : is_added_to_cart ? (
          <Button
            variant="primaryIconButton"
            className="icon-button"
            disabled={quantity === cart_quantity || !isCOAEnabled}
            onClick={() => {
              onUpdateToCart(article_no, {
                quantity,
                total_amount: quantity * selling_price,
                total_reward_points: quantity * reward_points,
              });
            }}
          >
            <IconEdit />
          </Button>
        ) : (
          <Button
            variant="primaryIconButton"
            className="icon-button"
            disabled={max_quantity === 0 || !isCOAEnabled}
            onClick={() => {
              onAddToCart({
                article_no,
                description,
                selling_price,
                quantity,
                max_quantity,
                total_amount: quantity * selling_price,
                reward_points,
                total_reward_points: quantity * reward_points,
              });
            }}
          >
            <IconPlus />
          </Button>
        )}
      </Box>
    </MobileViewProductItemContainer>
  );
};
MobileViewProductItem.propTypes = {
  data: PropTypes.shape({
    article_no: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    selling_price: PropTypes.number.isRequired,
    reward_points: PropTypes.number.isRequired,
    max_quantity: PropTypes.number.isRequired,
    next_delivery_date: PropTypes.string,
    stock_status: PropTypes.string.isRequired,
    is_added_to_cart: PropTypes.bool,
    cart_quantity: PropTypes.number,
  }),
  onAddToCart: PropTypes.func.isRequired,
  onUpdateToCart: PropTypes.func.isRequired,
};
export default MobileViewProductItem;
