import React from "react";

const IconFrFlag = ({ props, width = "15" }) => (
  <svg
    id="Group_4"
    data-name="Group 4"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 149.161 111.871"
    {...props}
  >
    <path
      id="Path_595"
      data-name="Path 595"
      d="M0,0H149.161V111.871H0Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      id="Path_596"
      data-name="Path 596"
      d="M0,0H49.721V111.871H0Z"
      fill="#00267f"
      fillRule="evenodd"
    />
    <path
      id="Path_597"
      data-name="Path 597"
      d="M426.662,0h49.721V111.871H426.662Z"
      transform="translate(-327.223)"
      fill="#f31830"
      fillRule="evenodd"
    />
  </svg>
);

export default IconFrFlag;
