import IconCheveronDown from "../../../components/icons/icon-cheveron-down";
import { Box, Flex, Image } from "theme-ui";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import YPImage from "../../../assets/images/YP.png";

const SubMenu = ({
  type,
  text,
  icon,
  pathname: menuPathname,
  href,
  isActive,
  onClick,
  setShowMobile,
  target,
}) => {
  let history = useHistory();
  const { pathname } = useLocation();

  if (type === "navlink") {
    return (
      <a
        className={`nav-sub-link ${isActive ? "active" : ""} ${
          menuPathname === pathname ? "active" : ""
        }`}
        onClick={() => {
          history.push(menuPathname);
          setShowMobile(false);
        }}
      >
        {icon || ""} {text}
      </a>
    );
  }
  if (type === "anchor") {
    return (
      <a
        href={href}
        target={target}
        className={`nav-sub-link ${isActive ? "active" : ""}`}
      >
        {icon || ""} {text}
      </a>
    );
  }

  if (type === "button") {
    return (
      <a
        className={`nav-sub-link ${isActive ? "active" : ""}`}
        onClick={() => {
          onClick();
          setShowMobile();
        }}
      >
        {icon || ""} {text}
      </a>
    );
  }
};

const MobileDrawerMenu = ({
  menuText,
  pathname: menuPathname,
  subMenus,
  setShowMobile,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  if (!subMenus) {
    return (
      <a
        className={`nav-item ${menuPathname === pathname ? "active" : ""}`}
        onClick={() => {
          history.push(menuPathname);
          setShowMobile(false);
        }}
      >
        <Flex>
          {menuPathname === "/redeem-yp" ? (
            <Image
              className="brand-image"
              src={YPImage}
              style={{
                width: "1.5rem",
                height: "1.5rem",
                marginRight: "0.75rem",
              }}
            />
          ) : null}
          {menuText}
        </Flex>
      </a>
    );
  }

  return (
    <Box className={`accordion-container ${isOpened ? "opened" : ""}`}>
      <a
        className="nav-item accordion-title"
        onClick={() => {
          setIsOpened((prevState) => !prevState);
        }}
      >
        <span>{menuText}</span>
        <IconCheveronDown />
      </a>
      <Box className="accordion-content">
        {subMenus.map((subMenu, index) => (
          <SubMenu key={index} {...subMenu} setShowMobile={setShowMobile} />
        ))}
      </Box>
    </Box>
  );
};

export default MobileDrawerMenu;
