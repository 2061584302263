import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Box, Flex, Input } from "theme-ui";
import themes from "../../themes";
import { roundToInteger } from "../../utils/helpers";
import Tooltip from "../Tooltip";

const InputNumber = ({
  icon,
  key,
  id,
  placeholder,
  type,
  value,
  onChange,
  name,
  max,
  toolTipMessage,
  disabled,
  min,
  errorInputs,
  toolTipStyle,
  focusId,
  ...props
}) => {
  const handleChange = useCallback(
    (value) => {
      if (type === "number" && parseFloat(value) < 0) {
        value = Math.abs(value);
      } else{
        value = roundToInteger(value.replace(",", "."));
      }
      onChange(id, name, value);
    },
    [id, name, onChange]
  );

  return (
    <Flex sx={{ width: "100%", flexDirection: "column", height: "100%" }}>
      <Flex
        hasIcon={Boolean(icon)}
        className={
          (errorInputs?.[id] && "input-container w-100 has-error") ||
          "input-container"
        }
        sx={{
          width: "100px",
          height: "34px",
          borderRadius: "3px",
          border:
            (errorInputs?.[id] && `0.3px solid ${themes.colors.danger}`) ||
            `0.3px solid ${themes.colors.productTableInputBorder}`,
        }}
      >
        {icon && <Box className="input-icon">{icon}</Box>}
        <Input
          key={key}
          id={id}
          className="input"
          type={type}
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          {...props}
          max={max}
          min={min}
          style={{
            padding: "8px 3px 8px 4px",
            height: "31px",
            backgroundColor: disabled
              ? themes.colors.transparent
              : themes.colors.white,
            width: "100%",
            border: 0,
            fontWeight: "bold",
            fontSize: "0.75em",
          }}
          onChange={(e) => handleChange(e.target.value)}
          disabled={disabled}
        />
      </Flex>

      <Flex>
        {errorInputs?.[id] && focusId === id && (
          <Tooltip message={toolTipMessage} toolTipStyle={toolTipStyle} />
        )}
      </Flex>
    </Flex>
  );
};

InputNumber.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  iconPlacement: PropTypes.oneOf(["prefix", "suffix"]),
  error: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
  max: PropTypes.number,
  style: PropTypes.object,
  toolTipMessage: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.number,
};

export default InputNumber;
