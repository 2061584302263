import DOMPurify from "dompurify";

const news_en_raw = `We welcome you to our new dealer online store. <br><br>
With our many years of experience in mobile phone wholesale, we have revised the entire store system and redeveloped it to the highest technical level.<br><br>
If you have any questions, please feel free to contact your personal advisor at any time.<br><br><span style="font-weight: bold;">Your Yukatel Team.</span>`;

const news_de_raw = `Herzlich willkommen in unserem neuen <span style="font-weight: bold;">Händler-Onlineshop</span>. <br><br>
Mit unserer langjährigen Erfahrung im Mobilfunk-Großhandel, haben wir das gesamte Shopsystem überarbeitet und auf technisch höchstem Niveau neuentwickelt.<br><br>
Bei Fragen wenden Sie sich jederzeit gerne an Ihren persönlichen Betreuer.<br><br><span style="font-weight: bold;">Ihr Yukatel Team.</span>`;

const news_cz_raw = `Srdečně vítejte v našem novém <span style="font-weight: bold;">online obchodě pro prodejce</span>.<br><br>
S našimi dlouholetými zkušenostmi ve velkoobchodě s mobilními rádiovými přístroji jsme přepracovali celý systém obchodu a nově ho vyvinuli na nejvyšší technické úrovni.<br><br>
V případě otázek se kdykoli obracejte na svého osobního konzultanta, který vám ochotně pomůže.<br><br><span style="font-weight: bold;">Váš tým Yukatel.</span>`;

const news_el_raw = `Καλώς ορίσατε στο νέο μας <span style="font-weight: bold;">online κατάστημα για εμπόρους</span>.<br><br>
Βασιζόμενοι στην πολυετή εμπειρία μας στο χονδρεμπόριο τηλεπικοινωνιών σχεδιάσαμε από την αρχή και δημιουργήσαμε ένα κατάστημα ύψιστου τεχνολογικού επιπέδου.<br><br>
Εάν έχετε οποιαδήποτε απορία, μπορείτε να απευθύνεστε ανά πάσα στιγμή στον προσωπικό σας σύμβουλο.<br><br><span style="font-weight: bold;">Η ομάδα της Yukatel.</span>`;

const news_es_raw = `Bienvenidos/as a nuestra nueva <span style="font-weight: bold;">tienda online para distribuidores</span>.<br><br>
Gracias a nuestra dilatada experiencia en la venta al por mayor de telefonía móvil, hemos revisado todo el sistema de la tienda y lo hemos rediseñado hasta alcanzar el máximo nivel técnico. <br><br>
Si tiene alguna pregunta, no dude en ponerse en contacto con su responsable personal.<br><br><span style="font-weight: bold;">Su equipo de Yukatel.</span>`;

const news_fr_raw = `Soyez les bienvenus dans notre nouveau <span style="font-weight: bold;">magasin en ligne</span>.<br><br>
Forts de nos nombreuses années d’expérience en tant que grossiste en téléphonie mobile, nous avons revu l’ensemble de notre système de vente et nous l’avons redéveloppé pour atteindre le plus haut niveau technique.<br><br>
Si vous avez des questions, n’hésitez pas à contacter à tout moment votre personne de contact.<br><br><span style="font-weight: bold;">L’équipe Yukatel.</span>`;

const news_hr_raw = `Dobrodošli u našu novu <span style="font-weight: bold;">internetsku trgovinu</span>.<br><br>
Zahvaljujući našoj dugogodišnjem iskustvu u veleprodaji mobilnih komunikacija, izmijenili smo čitav sustav trgovina i razvili ga na najvišoj tehničkoj razini.<br><br>
Ako imate bilo kakvih pitanja, slobodno se obratite osobnom nadzorniku. <br><br><span style="font-weight: bold;">Vaš Yukatel tim.</span>`;

const news_hu_raw = `Üdvözöljük új <span style="font-weight: bold;">internetes kereskedői áruházunkban</span>.<br><br>
A mobilkommunikációs eszközök nagykereskedésében szerzett sok éves tapasztalatunkra támaszkodva felülvizsgáltuk, és a legmagasabb technikai sztenderdek szerint megújítottuk teljes vásárlási rendszerünket.<br><br>
Kérdések esetén forduljon bizalommal személyes felügyelőjéhez.<br><br><span style="font-weight: bold;">A Yukatel csapata.</span>`;

const news_it_raw = `Benvenuti nel nostro nuovo <span style="font-weight: bold;">Store online riservato ai nostri rivenditori</span>.<br><br>
Grazie alla nostra pluriennale esperienza nel settore della vendita all'ingrosso di Smartphone, Tablet e Accessori, abbiamo rinnovato totalmente il concept del nostro store, implementando il più alto livello tecnologico.<br><br>
Per eventuali domande, potete rivolgervi al vostro commerciale di riferimento<br><br><span style="font-weight: bold;">Il vostro Team Yukatel.</span>`;

const news_nl_raw = `Hartelijk welkom in onze nieuwe <span style="font-weight: bold;">onlineshop voor dealers</span>.<br><br>
Met onze jarenlange ervaring in de groothandel in mobiele telefonie hebben we het hele winkelsysteem opnieuw uitgewerkt en nieuw ontwikkeld op het hoogste technische niveau.<br><br>
Wend u bij vragen te allen tijd tot uw persoonlijke adviseur.<br><br><span style="font-weight: bold;">Het Yukatel-team.</span>`;

const news_pl_raw = `Witamy w naszym nowym <span style="font-weight: bold;">sklepie internetowym</span>.<br><br>
Dzięki naszemu wieloletniemu doświadczeniu w sprzedaży hurtowej telefonii komórkowej, zreformowaliśmy cały system sklepu i przebudowaliśmy go na najwyższy poziom techniczny.<br><br>
Jeśli masz jakieś pytania, nie wahaj się skontaktować ze swoim osobistym opiekunem.<br><br><span style="font-weight: bold;">Twój zespół Yukatel.</span>`;

const news_ro_raw = `Bine ați venit la noul nostru <span style="font-weight: bold;">magazin online al distribuitorilor</span>.<br><br>
Cu experiența noastră îndelungată în comerțul en-gross de telefonie mobilă, am prelucrat întregul sistem al magazinului și l-am dezvoltat din nou la cel mai înalt nivel tehnic.<br><br>
Dacă aveți întrebări, vă rugăm să vă adresați oricând agentului dvs. de vânzări<br><br><span style="font-weight: bold;">Echipa Yukatel.</span>`;

const news_se_raw = `Hjärtligt välkommen till vår nya <span style="font-weight: bold;">Handels-Onlineshop</span>.<br><br>
Med vår långa erfarenhet som mobilradioåterförsäljare, har vi nu uppdaterat alla delar i vårt on-linesystem samt vidareutvecklat detta till tekniskt fulländat.<br><br>
Vid frågor, vänd Dig direkt till Din personliga kontaktperson.<br><br><span style="font-weight: bold;">Yukatel Team.</span>`;

const news_tr_raw = `Yeni <span style="font-weight: bold;">online Bayi-Mağazamıza</span> hoş geldiniz.<br><br>
Mobil iletişim toptan satışında uzun yıllara dayanan tecrübemizle, tüm mağaza sistemini en üst düzeyde yeniden tasarladık ve geliştirdik.<br><br>
Herhangi bir sorunuz varsa, lütfen kişisel danışmanınızla iletişime geçmekten çekinmeyin.<br><br><span style="font-weight: bold;">Yukatel Ekibiniz</span>`;

export const news_en = DOMPurify.sanitize(news_en_raw);
export const news_de = DOMPurify.sanitize(news_de_raw);
export const news_cz = DOMPurify.sanitize(news_cz_raw);
export const news_el = DOMPurify.sanitize(news_el_raw);
export const news_es = DOMPurify.sanitize(news_es_raw);
export const news_fr = DOMPurify.sanitize(news_fr_raw);
export const news_hr = DOMPurify.sanitize(news_hr_raw);
export const news_hu = DOMPurify.sanitize(news_hu_raw);
export const news_it = DOMPurify.sanitize(news_it_raw);
export const news_nl = DOMPurify.sanitize(news_nl_raw);
export const news_pl = DOMPurify.sanitize(news_pl_raw);
export const news_ro = DOMPurify.sanitize(news_ro_raw);
export const news_se = DOMPurify.sanitize(news_se_raw);
export const news_tr = DOMPurify.sanitize(news_tr_raw);
