/** @jsx jsx */
import styled from "@emotion/styled";
import { Box, jsx } from "theme-ui";
import PropTypes from "prop-types";
import Money from "../../../components/Money";
import { transparentize } from "@theme-ui/color";

const OrderDetailRowContainer = styled(Box)`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  font-size: 1rem;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  align-items: center;

  &.info {
    background-color: ${(props) =>
      transparentize(props.theme.colors.info, 0.88)};
  }

  .title {
    grid-column: span 6;
  }
  .counter-block {
    margin-left: 2.5rem;
  }
  .amount {
    grid-column: span 3;
  }
`;

const OrderDetailRow = ({
  description,
  selling_price,
  quantity,
  isUpdated,
}) => (
  <OrderDetailRowContainer className={`cart-item ${isUpdated ? "info" : ""}`}>
    <Box className="title" dangerouslySetInnerHTML={{ __html: description }} />
    <Box className="counter-block">{quantity}</Box>
    <Box className="amount">
      <Money amount={selling_price} />
    </Box>
  </OrderDetailRowContainer>
);

OrderDetailRow.propTypes = {
  description: PropTypes.string.isRequired,
  selling_price: PropTypes.any.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default OrderDetailRow;
