import i18next from "i18next";
import { EMAIL_REGEX, STRONG_REGEX } from "../constants";
export const isRequired = (value) =>
  value ? undefined : i18next.t("field_required");
export const isPassword = (value) =>
  value && !STRONG_REGEX.test(value)
    ? i18next.t("invalid_password_message")
    : undefined;
export const isPasswordConfirmed = (password) => (passwordConfirmation) =>
  password !== passwordConfirmation
    ? i18next.t("password_mismatch_message")
    : undefined;
export const isCheckedTerms = (value) =>
  value ? undefined : i18next.t("agree_to_terms_and_conditions");
export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const isSamePassword = (password) => (newPassword) =>
  password === newPassword ? i18next.t("password_same_message") : undefined;

export const isEmail = (value) => {
  if (value) {
    return !EMAIL_REGEX.test(value) ? i18next.t("invalid_email") : undefined;
  }
  return i18next.t("field_required");
};
export const isRequiredSerial = (value, serialNumberItem) =>
  Boolean(serialNumberItem) && !value ? i18next.t("field_required") : undefined;
export const isRequiredArray = (value) =>
  value && value.length > 0 ? undefined : i18next.t("field_required");

export const isGreaterThanZero = (value) =>
  value && Number(value) > 0
    ? undefined
    : "The quantity should be greater than zero.";

export const isPhoneNumberValid = (phoneNumber) => {
  const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return phoneRegex.test(phoneNumber);
};
export const tradeInNoAuthFormValidate = (values, requiredMessage) => {
  const requiredFields = [
    "agreement_one",
    "agreement_two",
    "agreement_three",
    "company_name",
    "company_email",
    "phone_number",
    "country_id",
  ];

  const errors = requiredFields.reduce((acc, field) => {
    if (!values[field]) {
      acc[field] = requiredMessage;
    }
    return acc;
  }, {});

  return errors;
};
export const tradeInAuthFormValidate = (values, requiredMessage) => {
  const requiredFields = ["agreement_one", "agreement_two"];

  const errors = requiredFields.reduce((acc, field) => {
    if (!values[field]) {
      acc[field] = requiredMessage;
    }
    return acc;
  }, {});

  return errors;
};

export const createProductValidate = (values, requiredMessage, new_product) => {
  const requiredFields = [
    "article_name",
    "min_order_quantity",
    "unit_price",
    "delivery_date",
  ];
  if (new_product) {
    requiredFields.push("european_article_number");
  }
  const errors = requiredFields.reduce((acc, field) => {
    if (!values[field]) {
      acc[field] = requiredMessage;
    }
    if (field === "european_article_number" && values[field]) {
      const hasWhitespace = /\s/.test(values[field]);
      const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(values[field]);

      if (hasWhitespace || hasSpecialCharacters) {
        acc[field] = i18next.t("european_article_number_invalid");
      }
    }

    return acc;
  }, {});

  return errors;
};

export const tradeInAddToProductValidate = (values, requiredMessage) => {
  const requiredFields = [
    "min_order_quantity",
    "take_all_quantity",
    "unit_price",
    "delivery_date",
  ];

  const errors = requiredFields.reduce((acc, field) => {
    if (!values[field]) {
      acc[field] = requiredMessage;
    }
    return acc;
  }, {});

  return errors;
};

export const tradeInCustomerCreateModalValidate = (values, requiredMessage) => {
  const requiredFields = [
    "company_name",
    "name",
    "country_id",
    "company_email",
    "phone_number",
    "website",
  ];
  const errors = requiredFields.reduce((acc, field) => {
    if (!values[field]) {
      acc[field] = requiredMessage;
    }
    return acc;
  }, {});

  const emailError = isEmail(values["company_email"]);
  if (emailError) {
    errors["company_email"] = emailError;
  }

  if (values["phone_number"] && !isPhoneNumberValid(values["phone_number"])) {
    errors["phone_number"] = i18next.t("invalid_phone_number");
  }
  return errors;
};

export const tradeInLoginCustomerValidate = (values, requiredMessage) => {
  const requiredFields = ["username", "password", "terms_and_condition"];
  const errors = requiredFields.reduce((acc, field) => {
    if (field === "terms_and_condition" && isCheckedTerms(values[field])) {
      acc[field] = isCheckedTerms(values[field]);
    } else if (!values[field]) {
      acc[field] = requiredMessage;
    }
    return acc;
  }, {});

  return errors;
};

export const createRmaReturnValidate = (values) => {
  const requiredFields = ["customer_number", "invoice_number", "invoice_date"];
  const errors = {};

  requiredFields.forEach((field) => {
    if (!values || isRequired(values[field])) {
      errors[field] = isRequired(values && values[field]);
    }
  });

  return errors;
}

export const tradeInOrderOfferChangeValidate = (values, requiredMessage) => {
  const requiredFields = ["quantity", "price", "delivery_date"];

  const errors = requiredFields.reduce((acc, field) => {
    if (!values[field]) {
      acc[field] = requiredMessage;
    }
    return acc;
  }, {});

  return errors;
};
