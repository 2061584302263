import React, { Suspense, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useEditMode } from "../contexts/EditModeProvider";
import { useCartActions } from "../contexts/CartContext";

const renderRoutes = (routes) => {
  return (
    <Switch>
      {routes.map(({ component: Component, to, exact, name, params }) => (
        <Route
          exact={exact}
          key={toString}
          path={to + (params ? params.reduce((a, b) => a + b) : "")}
        >
          <Helmet>
            <title>{name}</title>
          </Helmet>
          <Component />
        </Route>
      ))}
    </Switch>
  );
};

const Routes = ({ routes, Layout, RedirectPage }) => {
  const { pathname } = useLocation();
  const [editData, setEditData] = useEditMode();
  const { clearCart } = useCartActions();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!(pathname === "/shop" || pathname === "/cart") && editData?.orderId) {
      clearCart();
      setEditData(undefined);
    }
  }, [clearCart, editData?.orderId, pathname, setEditData]);

  return routes.find(({ isNested = false, ...route }) =>
    isNested ? pathname.startsWith(route.to) : pathname === route.to
  ) ? (
    <Suspense fallback="&nbsp;">
      <Layout>{renderRoutes(routes)}</Layout>
    </Suspense>
  ) : (
    <RedirectPage />
  );
};

Routes.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.elementType.isRequired,
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      exact: PropTypes.bool.isRequired,
      isNested: PropTypes.bool,
    })
  ),
  Layout: PropTypes.elementType.isRequired,
  RedirectPage: PropTypes.elementType.isRequired,
};

export default Routes;
