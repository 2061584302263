import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Badge, Box, Button, Flex } from "theme-ui";
import IconFilter from "../../../../components/icons/icon-filter";
import Menu from "../../../../components/Menu";
import Pagination from "../../Shop/Pagination";
import { downloadOrder, getMyOrders } from "../../../../apis";
import { useQuery } from "react-query";
import SortTableHead from "../../Shop/SortTableHead";
import { useModal } from "../../../../contexts/ModalContext";
import OrderDetail from "../OrderDetail";
import { useTranslation } from "react-i18next";
import IconDownload from "../../../../components/icons/icon-download";
import {
  downloadBlob,
  getEuropeanDateFormat,
  ORDER_STATUS_BADGE_VARIANT,
} from "../../../../utils/helpers";
import Money from "../../../../components/Money";
import { useToast } from "../../../../contexts/AlertProvider";
import WithLoader from "../../../../components/WithLoader";
import { useHistory, useLocation } from "react-router-dom";
import { useCartActions } from "../../../../contexts/CartContext";
import { useEditMode } from "../../../../contexts/EditModeProvider";
import IconSearch from "../../../../components/icons/icon-search";
import Input from "../../../../components/form-fields/Input";

const MyOrders = () => {
  const { showModal } = useModal();
  const { t } = useTranslation();
  const toast = useToast();
  const history = useHistory();
  const { clearCart } = useCartActions();
  const [, setEditData] = useEditMode();

  const { state } = useLocation();

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [id, setId] = useState(null);

  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({
    sort_by: null,
    sort_direction: "asc",
  });
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState();
  const [search, setSearch] = useState();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["orders", page, sort.sort_by, sort.sort_direction, status, searchText],
    getMyOrders,
    {
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );
  const {
    from,
    to,
    total,
    current_page,
    last_page,
    data: tableData,
  } = data?.data || {
    data: [],
  };

  const onSortClick = useCallback(
    (value) => {
      setPage(1);
      if (value !== sort.sort_by) {
        setSort({ sort_by: value, sort_direction: "asc" });
        return;
      }
      if ("asc" === sort.sort_direction) {
        setSort({ sort_by: value, sort_direction: "desc" });
        return;
      }
      setSort({ sort_by: null, sort_direction: "asc" });
    },
    [sort.sort_by, sort.sort_direction]
  );

  const onOrderDownload = async (id, type) => {
    try {
      setId(id);
      setIsDownloadLoading(true);
      let fileResponse = await downloadOrder(id);
      const fileName = `${type}_${id}.pdf`;
      downloadBlob(fileResponse.data, fileName);
      setIsDownloadLoading(false);
    } catch (error) {
      setIsDownloadLoading(false);
      toast(t("document_not_available"), { type: "error" });
    }
  };

  const onViewDetail = useCallback(
    (id) => {
      showModal(({ hideModal }) => (
        <OrderDetail
          type={"order"}
          id={id}
          hideModal={hideModal}
          showModal={() => {
            onViewDetail(id);
          }}
          downloadFile={(id) => downloadOrder(id)}
          onUpdate={async () => {
            hideModal();
            clearCart();
            history.push({
              pathname: "/shop",
            });
            setEditData({ orderId: id, mode: "update" });
          }}
          refetchOrder={refetch}
          previousData={state?.previousDetail}
        />
      ));
    },
    [
      page,
      showModal,
      sort.sort_by,
      sort.sort_direction,
      status,
      state?.previousDetail,
    ]
  );

  useEffect(
    () => {
      if (state?.orderId) {
        onViewDetail(state.orderId);
      }
    },
    //Won't add onViewDetail as dependency as it will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state?.orderId, state?.orderDetail]
  );

  return (
    <>
      <Box
        className="pagination-container"
        style={{ justifyContent: "space-between" }}
      >
        <Input
          label={t("search")}
          type="number"
          min="1"
          icon={
            <IconSearch
              onClick={() => {
                setSearchText(search);
                setPage(1);
              }}
            />
          }
          iconPlacement="suffix"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setSearchText(search);
              setPage(1);
            }
          }}
          onBlur={() => {
            if (search === "") {
              setSearchText(search);
              setPage(1);
            }
          }}
        />
        <Flex sx={{ flexDirection: "row" }}>
          <Menu
            className="filter-menu"
            showDropdownIcon={false}
            label={
              <Fragment>
                {status === null || status === undefined
                  ? `${t("filter_by_status")}`
                  : `${t(status)}`}
                <IconFilter sx={{ marginLeft: "0.5rem" }} />
              </Fragment>
            }
            items={[
              {
                label: `${t("completed")}`,
                onClick: () => {
                  setPage(1);
                  setStatus("completed");
                },
              },
              {
                label: `${t("submitted")}`,
                onClick: () => {
                  setPage(1);
                  setStatus("submitted");
                },
              },
              {
                label: `${t("partially")}`,
                onClick: () => {
                  setPage(1);
                  setStatus("partially");
                },
              },
              {
                label: `${t("all")}`,
                onClick: () => {
                  setPage(1);
                  setStatus(null);
                },
              },
            ]}
          />
          <Pagination
            {...{ from, to, total, current_page, last_page }}
            onChange={(page) => {
              setPage(page);
            }}
          />
        </Flex>
      </Box>
      <Box className="table-container">
        <table className="table">
          <thead className="table-head">
            <tr>
              <SortTableHead
                label={t("id")}
                value={"id"}
                sort={sort}
                onClick={onSortClick}
              />
              <SortTableHead
                label={t("order_date")}
                value={"date"}
                sort={sort}
                onClick={onSortClick}
              />
              <th>{t("quantity")}</th>
              <SortTableHead
                label={t("total_cost")}
                value={"cost"}
                sort={sort}
                onClick={onSortClick}
              />
              <th className="text-center">{t("status")}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody className="table-body">
            {!isLoading && tableData.length === 0 && (
              <tr>
                <td colSpan={9}>{t("no_data_in_the_list")}</td>
              </tr>
            )}
            {isLoading || isFetching ? (
              <tr>
                <td colSpan={9} rowSpan={7}>
                  <WithLoader isLoading={true} height={"16rem"} />
                </td>
              </tr>
            ) : (
              tableData.map(
                (
                  { order_id, date_placed, total_quantity, total_cost, status },
                  index
                ) => (
                  <tr key={index}>
                    <td>{order_id}</td>
                    <td>
                      <span>{getEuropeanDateFormat(date_placed)}</span>
                    </td>
                    <td>{total_quantity}</td>
                    <td>
                      <span>
                        <Money amount={total_cost} />
                      </span>
                    </td>
                    <td>
                      <Box className="flex-center">
                        <Badge variant={ORDER_STATUS_BADGE_VARIANT[status]}>
                          {t(status)}
                        </Badge>
                      </Box>
                    </td>
                    <td>
                      <Box className="flex-center">
                        <Button
                          variant="secondaryOutline"
                          className="details-btn"
                          onClick={() => {
                            onViewDetail(order_id);
                          }}
                        >
                          {t("view_details")}
                        </Button>
                      </Box>
                    </td>
                    <td>
                      {status === "submitted" && (
                        <Flex sx={{ justifyContent: "center" }}>
                          <WithLoader
                            isLoading={id === order_id && isDownloadLoading}
                          >
                            <Button
                              variant="secondaryOutline"
                              onClick={() => onOrderDownload(order_id, "Order")}
                            >
                              <IconDownload /> {t("download")}
                            </Button>
                          </WithLoader>
                        </Flex>
                      )}
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </Box>
    </>
  );
};

export default MyOrders;
