import { Box, Button, Heading } from "theme-ui";
import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import IconClose from "../../../../components/icons/icon-close";
import WithLoader from "../../../../components/WithLoader";
import { useQuery } from "react-query";
import { getInvoiceTrackingDetail } from "../../../../apis";

const Container = styled(Box)`
  max-width: 720px;
  min-height: 156px;
  width: calc(100vw - 2rem);

  .modal-header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading {
      font-size: 1.5rem;
    }

    ${(props) => props.theme.customBreakpoints[2]} {
      flex-wrap: wrap;

      .heading {
        flex: 1 1 auto;
        width: 100%;
      }
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      > * {
        margin-bottom: 0.5rem;
      }
    }
  }

  .list-container {
    margin-bottom: 1.5rem;

    .header {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(12, 1fr);
      font-size: 1rem;
      padding: 0.75rem 1rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
      align-items: center;

      .tracking_number {
        grid-column: span 6;
      }

      .link {
        margin-left: 2.5rem;
      }
    }

    .list-items {
      max-height: 32vh;
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .text-normal {
    font-weight: normal;
    font-family: ${(props) => props.theme.fonts.body};
    margin: 0 1rem;
  }

  .icon-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;

    height: 2.5rem;
    width: 2.5rem;

    .svg-icon {
      fill: white;
      height: 2rem;
      width: 2rem;
    }
  }
`;

const RowContainer = styled(Box)`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  font-size: 1rem;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  align-items: center;

  .tracking_number {
    grid-column: span 6;
  }

  .link {
    margin-left: 2.5rem;
  }
`;

const TrackingDetail = (props) => {
  const { hideModal, orderId } = props;
  const { t } = useTranslation();

  const { isLoading, data } = useQuery(
    ["invoice-tracking-detail", orderId],
    getInvoiceTrackingDetail
  );
  const trackingList = data?.data || [];

  return (
    <Container>
      <Button
        className="icon-btn"
        variant="secondaryIconButton"
        onClick={() => {
          hideModal();
        }}
      >
        <IconClose />
      </Button>
      <WithLoader isLoading={isLoading} height={"156px"}>
        <Box className="modal-header">
          <Heading className="heading">
            {t("invoice_id")}
            <span className="text-normal">#{orderId}</span>
          </Heading>
        </Box>
        <Box className="list-container">
          <Box className="header">
            <Heading className="tracking_number">
              {t("tracking_number")}
            </Heading>
            <Heading className="link">{t("link")}</Heading>
          </Box>
          <Box className="list-items">
            {trackingList.map((item, index) => (
              <RowContainer key={index}>
                <Box className="tracking_number">{item.tracking}</Box>
                <Box className="link">
                  <a
                    href={item.link}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {t("link")}
                  </a>
                </Box>
              </RowContainer>
            ))}
          </Box>
        </Box>
      </WithLoader>
    </Container>
  );
};

TrackingDetail.propTypes = {
  orderId: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default TrackingDetail;
