import React, { createContext, useContext, useEffect, useReducer } from "react";
import { checkCOA } from "../apis";

const COAContext = createContext({ status: true });
const COAProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "UPDATE_STATUS":
          return { ...state, status: action.payload };
        default:
          throw new Error("undefined action type used");
      }
    },
    { status: true }
  );

  useEffect(() => {
    checkCOA().then((res) => {
      dispatch({ type: "UPDATE_STATUS", payload: res?.status || false });
    });
  }, []);

  return (
    <COAContext.Provider value={{ state, dispatch }}>
      {children}
    </COAContext.Provider>
  );
};

export default COAProvider;

export const useCOA = () => {
  const context = useContext(COAContext);
  if (context === undefined) {
    throw new Error("useCOA must be used within a COAProvider");
  }
  return context;
};

export const useCOAStatus = () => {
  const { state } = useCOA();

  return state?.status;
};
