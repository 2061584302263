import React, { useState } from "react";
import styled from "@emotion/styled";
import TradeInComponent from "../../../components/TradeIn/new-offer/TradeInComponent";
import { welcomeStep } from "../../../constants";

const TradeInContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TradeIn = () => {
  const [formStep, setFormStep] = useState(welcomeStep);

  return (
    <TradeInContainer>
      <TradeInComponent formStep={formStep} setFormStep={setFormStep} />
    </TradeInContainer>
  );
};

export default TradeIn;
