/* eslint-disable react/prop-types */
import React from "react";

const IconEyeClose = ({
  fill = "inherit",
  width = "1.3rem",
  height = "1.5rem",
  className = "",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    fill={fill}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="Path_2043"
      data-name="Path 2043"
      d="M1.3,0A1.18,1.18,0,0,0,0,1,1.18,1.18,0,0,0,1.3,2H24.7A1.18,1.18,0,0,0,26,1a1.18,1.18,0,0,0-1.3-1Z"
      transform="translate(4.995 22.517) rotate(-60)"
    />
    <path
      id="Path_2044"
      data-name="Path 2044"
      d="M16,12a4,4,0,1,1-4-4A4,4,0,0,1,16,12Zm-2,0a2,2,0,1,1-2-2A2,2,0,0,1,14,12Z"
      transform="translate(-0.378 0.845)"
      fillRule="evenodd"
    />
    <path
      id="Path_2045"
      data-name="Path 2045"
      d="M12.378,3a12.33,12.33,0,0,1,12,9,12.33,12.33,0,0,1-12,9,12.33,12.33,0,0,1-12-9A12.33,12.33,0,0,1,12.378,3Zm0,16a10.3,10.3,0,0,1-9.853-7,10.3,10.3,0,0,1,9.853-7,10.3,10.3,0,0,1,9.853,7A10.3,10.3,0,0,1,12.378,19Z"
      transform="translate(-0.378 0.577)"
      fillRule="evenodd"
    />
  </svg>
);

export default IconEyeClose;
