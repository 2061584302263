import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Grid, Heading, Text } from "theme-ui";
import DirectBuyLogo from "../../../assets/images/directbuy.png";
import { validCurreny } from "../../../constants";
import themes from "../../../themes";
import {
  calculatedHeight,
  getWeekdaysAfterDate,
  isDeliveryDateAfterWeekdays,
  isDirectBuyConfirmed,
  isSameDirectPriceAndUnitPrice,
  tradeINCartRowHeight,
} from "../../../utils/helpers";
import IconEdit from "../../icons/icon-edit";
import IconTrash from "../../icons/icon-trash";
import { useCallback } from "react";

const CartTitle = styled.div`
  width: 100%;
  font-weight: 700;
  color: ${(props) => props.theme.colors.titleColor};
  margin-left: 0.3em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0.1em;
  word-break: break-word;
  display: inline-block;
`;

const AddProductCard = ({
  data,
  errProducts,
  handleRemove,
  onModalOpen,
  windowWidth,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: `100%`,
        overflowY: "auto",
        "@media screen and (max-width:1270px)": {
          height: `calc(100% - 7em)`,
        },
      }}
    >
      {data?.length ? (
        <>
          <table style={{ width: "100%" }}>
            <tbody style={{ padding: "0.5em" }}>
              {data?.map((item, key) => (
                <>
                  <tr
                    key={key}
                    style={{
                      height: tradeINCartRowHeight(
                        item?.new_product,
                        windowWidth
                      ),
                    }}
                  >
                    <Box
                      sx={{
                        height: calculatedHeight(item, windowWidth),
                        margin: "0.5em",
                        borderRadius: "3px",
                        padding: "0.2rem 0.6rem 0 0 ",
                        backgroundColor: errProducts.includes(item?.article_no)
                          ? themes.colors.tradeInCartErrorBG
                          : themes.colors.tradeInCartBG,
                      }}
                    >
                      <Grid columns={1} gap={0}>
                        <Grid
                          columns={["4fr 1fr"]}
                          sx={{
                            verticalAlign: "middle",
                            paddingTop: "0.23em",
                            marginBottom: "0.23em",
                            gap: 0,
                          }}
                        >
                          <Flex sx={{ width: "100%", flexDirection: "column" }}>
                            <CartTitle>{item?.article_name} </CartTitle>
                          </Flex>
                          <Flex
                            sx={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              onClick={() => handleRemove(item)}
                              sx={{ cursor: "pointer" }}
                            >
                              <IconTrash
                                fill={themes.colors.tradeInHomeButtonBG}
                                width="20px"
                                height="20px"
                              />
                            </Box>
                            <Box
                              onClick={() => onModalOpen(item)}
                              sx={{
                                marginLeft: "1rem",
                                cursor: "pointer",
                              }}
                            >
                              <IconEdit
                                fill={themes.colors.darkblue}
                                width="20px"
                                height="20px"
                              />
                            </Box>
                          </Flex>
                        </Grid>
                        <Grid
                          sx={{
                            height: "0.6em",
                            marginLeft: "5px",
                            gap: 0,
                          }}
                        >
                          <Grid columns={2} gap={0}>
                            <Flex sx={{ flexDirection: "column" }}>
                              <Text>{t("minimum_order_quantity_short")}</Text>
                              <Text sx={{ fontWeight: "column" }}>
                                {item?.min_order_quantity}
                              </Text>
                            </Flex>
                            <Flex sx={{ flexDirection: "column" }}>
                              <Text>{t("take_all_qty_price")}</Text>
                              <Text sx={{ fontWeight: 700 }}>
                                {`${item?.take_all_quantity || "-"} / ${
                                  item?.take_all_unit_price || "-"
                                } ${validCurreny}`}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid columns={2} gap={0}>
                            <Flex sx={{ flexDirection: "column" }}>
                              {item?.is_direct_buy ? (
                                <>
                                  <Text
                                    sx={{
                                      color:
                                        !isSameDirectPriceAndUnitPrice(item) &&
                                        themes.colors.tradeInHomeButtonBG,
                                    }}
                                  >
                                    {t("unit_price_short")}
                                  </Text>
                                  <Text
                                    sx={{
                                      fontWeight: 700,
                                      color:
                                        !isSameDirectPriceAndUnitPrice(item) &&
                                        themes.colors.tradeInHomeButtonBG,
                                    }}
                                  >
                                    {`${item?.unit_price} ${validCurreny}`}
                                  </Text>
                                </>
                              ) : (
                                <>
                                  <Text>{t("unit_price_short")} </Text>
                                  <Text sx={{ fontWeight: 700 }}>
                                    {`${item?.unit_price} ${validCurreny}`}
                                  </Text>
                                </>
                              )}
                            </Flex>
                            <Flex
                              sx={{
                                flexDirection: "column",
                                color: `${
                                  !isDeliveryDateAfterWeekdays(
                                    item,
                                    getWeekdaysAfterDate()
                                  ) &&
                                  item?.is_direct_buy &&
                                  `${themes.colors.tradeInHomeButtonBG} !important`
                                }`,
                              }}
                            >
                              <Text>{t("delivery_date_short")}</Text>
                              <Text sx={{ fontWeight: 700 }}>
                                {item?.delivery_date}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid columns={1} gap={0}>
                            <>
                              <Text>{t("european_article_number")}</Text>
                              <Text sx={{ fontWeight: 700 }}>
                                {item?.european_article_number}
                              </Text>
                            </>
                          </Grid>
                          <Grid columns={1} gap={0}>
                            <Flex
                              style={{
                                flexDirection: "row",
                                width: "100%",
                                textAlign: "center",
                                height:
                                  isDeliveryDateAfterWeekdays(
                                    item,
                                    getWeekdaysAfterDate()
                                  ) && isDirectBuyConfirmed(item)
                                    ? "auto"
                                    : "100%",
                              }}
                            >
                              <Heading
                                sx={{
                                  height: "2em",
                                  color:
                                    isDeliveryDateAfterWeekdays(
                                      item,
                                      getWeekdaysAfterDate()
                                    ) && isDirectBuyConfirmed(item)
                                      ? themes.colors.success
                                      : themes.colors.tradeInHomeButtonBG,
                                  fontWeight: 700,
                                  fontSize: "1em",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  verticalAlign: "middle",
                                  aliginItem: "center",
                                }}
                              >
                                <Flex
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <img
                                    src={DirectBuyLogo}
                                    width="80px"
                                    height="19px"
                                    alt="direct_buy"
                                    style={{ marginTop: "0.3em" }}
                                  />
                                </Flex>
                                <Flex
                                  sx={{
                                    justifyContent: "center",
                                    padding: "0.47em",
                                    height: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  {` - ${
                                    isDeliveryDateAfterWeekdays(
                                      item,
                                      getWeekdaysAfterDate()
                                    ) && isDirectBuyConfirmed(item)
                                      ? t("direct_buy_confirmed")
                                      : t("direct_buy_under_review")
                                  }`}
                                </Flex>
                              </Heading>
                            </Flex>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <Text sx={{ padding: "1.3em" }}>
          {t("you_currently_dont_have_any_orders_start_shopping_now")}
        </Text>
      )}
    </Box>
  );
};

AddProductCard.propTypes = {
  data: PropTypes.object,
  errProducts: PropTypes.object,
  setData: PropTypes.func,
  onModalOpen: PropTypes.func,
  handleRemove: PropTypes.func,
};

export default AddProductCard;
