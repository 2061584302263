/** @jsx jsx */
import styled from "@emotion/styled";
import { Box, Button, Flex, jsx } from "theme-ui";
import { SmallCounterInput } from "./CounterInput";
import IconTrash from "./icons/icon-trash";
import IconAlert from "./icons/icon-alert";
import PropTypes from "prop-types";
import Money from "./Money";
import { getFormatedYukaPoints } from "../utils/helpers";
import { transparentize } from "@theme-ui/color";
import ReactTooltip from "react-tooltip";
import { Text } from "theme-ui";
import { useTranslation } from "react-i18next";

const CartItemContainer = styled(Box)`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  align-items: center;

  &:first-of-type {
    border-top: 1px solid ${(props) => props.theme.colors.lightGray};
  }

  &.cart-item-error {
    background-color: ${(props) =>
      transparentize(props.theme.colors.danger, 0.88)} !important;
  }

  &.bg-diff {
    background-color: #dcdcdd;
  }

  .title {
    grid-column: span 6;

    .warning-icon {
      position: realtive;
      margin-left: 1rem;
      fill: orangered;
      cursor: pointer;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .article-number {
    font-style: italic;
    font-size: 0.7rem;
  }

  .counter-block {
    grid-column: span 3;
    display: flex;
    align-items: center;

    .trash-btn {
      margin-left: 0.5rem;
    }
  }

  .amount {
    grid-column: span 3;
  }

  .trash-btn {
    .svg-icon {
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  &.cart-item-sm {
    font-size: 0.875rem;
    padding: 0.25rem 1rem;
    border: none;
    align-items: unset;
    grid-gap: 0.5rem;

    .trash-btn {
      height: 1.75rem;
      width: 1.75rem;
      min-width: 1.75rem;
      padding: 0.25rem;

      .svg-icon {
        max-height: 1.25rem;
        max-width: 1.25rem;
        fill: ${(props) => props.theme.colors.primary};
      }
    }

    .title {
      grid-column: span 7;
      .warning-icon {
        position: absolute;
        right: -2rem;
        top: 0;
        fill: orangered;
      }
    }

    .selling-price {
      color: ${(props) => props.theme.colors.primary};
    }

    .counter-block {
      grid-column: span 5;
      justify-self: end;

      .counter-input-container {
        width: 5.5rem;
      }
    }
  }
`;

const CartItem = ({
  ean,
  description,
  selling_price,
  quantity,
  max_quantity,
  onChange,
  isDeleteButtonDisabled = false,
  onRemove,
  isSmall = true,
  type = "normal",
  error = null,
  keyForData,
  ind,
}) => {
  const { t } = useTranslation();
  let background = ind % 2 === 0 ? true : false;
  return (
    <Box>
      <CartItemContainer
        className={`cart-item ${isSmall ? "cart-item-sm" : null} ${
          error ? "cart-item-error" : null
        } ${background ? "bg-diff" : null}`}
      >
        <Box className="title">
          <Flex sx={{ position: "relative", flexDirection: "column" }}>
            <span>{description}</span>
            {ean && (
              <Text>{`${t("european_article_number_short")} : ${ean} `}</Text>
            )}
            {error ? (
              <span data-tip={error.message} data-for={keyForData}>
                <Box className="warning-icon">
                  <IconAlert height="18px" width="18px" />
                </Box>
                <ReactTooltip place={"bottom"} type={"error"} id={keyForData} />
              </span>
            ) : null}
          </Flex>
          {isSmall && (
            <Box className="selling-price">
              {type === "normal" ? (
                <span>
                  <Money amount={selling_price} />
                </span>
              ) : (
                getFormatedYukaPoints(selling_price) + " YP"
              )}
            </Box>
          )}
        </Box>
        <Box className="counter-block">
          <SmallCounterInput
            value={quantity}
            onChange={onChange}
            maxValue={error?.max_quantity || max_quantity}
          />
          <Button
            variant="iconButton"
            className="trash-btn"
            onClick={onRemove}
            disabled={isDeleteButtonDisabled}
          >
            <IconTrash />
          </Button>
        </Box>
        {!isSmall && (
          <Box className="amount">
            {type === "normal" ? (
              <span>
                <Money amount={selling_price} />
              </span>
            ) : (
              getFormatedYukaPoints(selling_price) + " YP"
            )}
          </Box>
        )}
        {!isSmall && (
          <Box className="amount">
            {type === "normal" ? (
              <span>
                <Money amount={selling_price * quantity} />
              </span>
            ) : (
              getFormatedYukaPoints(selling_price * quantity) + " YP"
            )}
          </Box>
        )}
      </CartItemContainer>
    </Box>
  );
};

CartItem.propTypes = {
  description: PropTypes.string.isRequired,
  selling_price: PropTypes.any.isRequired,
  quantity: PropTypes.number.isRequired,
  max_quantity: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  isSmall: PropTypes.bool,
  type: PropTypes.oneOf(["normal", "yp"]),
  isDeleteButtonDisabled: PropTypes.bool,
};

export default CartItem;
