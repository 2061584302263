import React, { createContext, useContext, useState } from "react";
import { getItem, removeItem, setItem } from "../utils/storage";

export const EditModeContext = createContext();

const EditModeProvider = (props) => {
  const editModeCookieData = getItem("edit_mode_data");
  const [data, setData] = useState(
    editModeCookieData ? JSON.parse(editModeCookieData) : undefined
  );

  return (
    <EditModeContext.Provider
      value={[
        data,
        (data) => {
          if (!data) {
            removeItem("edit_mode_data");
          } else {
            setItem("edit_mode_data", JSON.stringify(data));
          }
          setData(data);
        },
      ]}
    >
      {props.children}
    </EditModeContext.Provider>
  );
};

export default EditModeProvider;

export const useEditMode = () => {
  const context = useContext(EditModeContext);
  if (context === undefined) {
    throw new Error("useEditMode must be used within a EditModeProvider");
  }

  return context;
};
