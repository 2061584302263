import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 3em;
  @media (max-width: 769px) {
    width: 100%;
  }
`;
const TooltipText = styled.span`
  position: absolute;
  z-index: 2;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.colors.danger};
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 1em;
  white-space: nowrap;
  margin: 3px 10px 10px 10px;
`;
const Tooltip = ({ message, style }) => {
  return (
    <TooltipWrapper>
      <TooltipText style={style}>{message}</TooltipText>
    </TooltipWrapper>
  );
};
Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  style: PropTypes.object,
};
export default Tooltip;
