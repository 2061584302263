import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

const ModalStateContext = createContext();
const ModalStateModifierContext = createContext();
Modal.defaultStyles.overlay.zIndex = 101;
Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.4)";

const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    View: null,
    onRequestClose: null,
  });
  const { isOpen, View, onRequestClose } = modalState;

  const showModal = (View, onRequestClose = null) => {
    setModalState({ View: View, isOpen: true, onRequestClose });
  };

  const hideModal = () => {
    setModalState({
      View: null,
      isOpen: false,
      onRequestClose: null,
    });
  };

  return (
    <ModalStateContext.Provider value={modalState}>
      <ModalStateModifierContext.Provider value={{ showModal, hideModal }}>
        {children}
        <Modal
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          isOpen={isOpen}
          onRequestClose={() => {
            setModalState({ ...modalState, isOpen: false });
            if (onRequestClose) onRequestClose();
          }}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "100%",
              overflow: "auto",
            },
          }}
        >
          {View && <View hideModal={hideModal} />}
        </Modal>
      </ModalStateModifierContext.Provider>
    </ModalStateContext.Provider>
  );
};
ModalProvider.propTypes = {
  children: PropTypes.element,
};

export default ModalProvider;

export const useModal = () => {
  const context = useContext(ModalStateModifierContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
};
