import React from "react";
import {
  news_en,
  news_de,
  news_cz,
  news_el,
  news_es,
  news_fr,
  news_hr,
  news_hu,
  news_it,
  news_nl,
  news_pl,
  news_ro,
  news_se,
  news_tr,
} from "../../../newsFile";
import Container from "../../../components/Container";
import styled from "@emotion/styled";
import { Box, Heading } from "theme-ui";
import { useTranslation } from "react-i18next";
import i18n from "../../../utils/i18n";

export default function HotNews() {
  const { t } = useTranslation();
  let newsContent = news_de;
  switch (i18n.language) {
    case "en":
      newsContent = news_en;
      break;
    case "de":
      newsContent = news_de;
      break;
    case "es":
      newsContent = news_es;
      break;
    case "it":
      newsContent = news_it;
      break;
    case "fr":
      newsContent = news_fr;
      break;
    case "nl":
      newsContent = news_nl;
      break;
    case "tr":
      newsContent = news_tr;
      break;
    case "pl":
      newsContent = news_pl;
      break;
    case "hr":
      newsContent = news_hr;
      break;
    case "ro":
      newsContent = news_ro;
      break;
    case "el":
      newsContent = news_el;
      break;
    case "sv":
      newsContent = news_se;
      break;
    case "hu":
      newsContent = news_hu;
      break;
    case "cs":
      newsContent = news_cz;
      break;
    default:
      newsContent = news_de;
  }

  return newsContent ? (
    <NewsContainer>
      <Box className="page-header">
        <Heading className="heading">{t("hot_news")}</Heading>
      </Box>
      <Box
        className="page-details"
        dangerouslySetInnerHTML={{ __html: newsContent }}
      />
    </NewsContainer>
  ) : null;
}

const NewsContainer = styled(Container)`
  margin-top: 0;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.colors.secondaryText};

  .page-header {
    font-size: 1.75rem;
    margin-bottom: 2rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }
  }
  .page-details {
    > div {
      margin-bottom: 2rem;
    }
  }
`;
