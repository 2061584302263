import React from "react";

const IconDeFlag = ({ props, width = "15" }) => (
  <svg
    id="Group_2"
    data-name="Group 2"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 149.16 111.871"
    {...props}
  >
    <path
      id="Path_13"
      data-name="Path 13"
      d="M0,320H149.16v37.291H0Z"
      transform="translate(0 -245.42)"
      fill="#ffce00"
    />
    <path id="Path_14" data-name="Path 14" d="M0,0H149.16V37.29H0Z" />
    <path
      id="Path_15"
      data-name="Path 15"
      d="M0,160H149.16v37.29H0Z"
      transform="translate(0 -122.71)"
      fill="#d00"
    />
  </svg>
);

export default IconDeFlag;
