import ChangePassword from "../pages/authenticated/ChangePassword";
import { Redirect } from "react-router-dom";
import React from "react";

export const AuthenticatedUpdatePasswordPage = () => (
  <Redirect to="/change-password" />
);

export default [
  {
    component: ChangePassword,
    name: "Change Password",
    to: "/change-password",
    exact: true,
  },
];
