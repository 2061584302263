import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { SOCKET_URL, SocketConnetionType } from "../constants";
import { getItem, setItem } from "./storage";
import { apiRefreshChatToken } from "../apis";

let chatOpen = false;

const useSocket = (setUnreadCount, isChatOpen, setLastMessage) => {
  const chatUsername = getItem("chat_username");
  const currentToken = getItem("chat_token");
  const [chatId, setChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [lastActiveAt, setLastActiveAt] = useState(null);
  const [loadChatHistory, setLoadChatHistory] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const chatBodyRef = useRef(null);
  const socketRef = useRef(null);

  const refreshChatToken = async () => {
    try {
      const response = await apiRefreshChatToken();
      const newToken = response.data.chat_credentials.token;
      setItem("chat_token", newToken);
      return newToken;
    } catch (error) {
      return null;
    }
  };

  const connectSocket = (token) => {
    if (!socketRef.current) {
      socketRef.current = io(SOCKET_URL, {
        transports: ["websocket"],
        reconnection: true,
        multiplex: false,
        withCredentials: true,
        query: {},
        path: "/chat-app/socket",
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
        auth: {
          authorizationToken: `Bearer ${token}`,
        },
      });

      socketRef.current.on(SocketConnetionType.CONNECTION, () => {
        console.log("Connected to socket");
      });

      socketRef.current.on(SocketConnetionType.INITIAL_DATA, (data) => {
        setLoadChatHistory(data.messages);
        setChatId(data.conversationId);
        setLastActiveAt(data.lastOnlineTime);
      });

      socketRef.current.on(SocketConnetionType.MESSAGE, (newMsg) => {
        const isSentByUser = newMsg.senderName === chatUsername;
        if (!chatOpen) {
          setUnreadCount((prevCount) => prevCount + 1);
        }
        setMessages((prev) => {
          const messageIndex = prev.findIndex(
            (msg) =>
              msg.sentAt === newMsg.sentAt && msg.content === newMsg.content
          );
          if (messageIndex === -1) {
            if (!isSentByUser) {
              setLastMessage(newMsg.content);
            }
            return [...prev, newMsg];
          }
          return prev;
        });

        if (chatBodyRef.current) {
          chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
      });

      socketRef.current.on("connect_error", async (error) => {
        if (error?.data?.status === 401) {
          const newToken = await refreshChatToken();
          if (newToken) {
            socketRef.current.disconnect();
            socketRef.current = null;
            connectSocket(newToken);
          }
        } else {
          setConnectionError(true);
        }
      });
    }
  };

  useEffect(() => {
    chatOpen = isChatOpen;
    connectSocket(currentToken);

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [currentToken, isChatOpen]);

  const reconnectSocket = async () => {
    const newToken = await refreshChatToken();
    if (!newToken) {
      return;
    }

    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }
    connectSocket(newToken);
    setConnectionError(false);
  };

  const sendMessage = (content) => {
    if (socketRef.current && socketRef.current.connected) {
      socketRef.current.emit(SocketConnetionType.MESSAGE, content);
    } else {
      setConnectionError(true);
    }
  };

  const joinConversation = () => {
    if (socketRef.current.connected) {
      socketRef.current.emit(SocketConnetionType.JOIN_CONVERSATION);
      setConnectionError(false);
    }
  };

  const leaveConversation = () => {
    if (!socketRef.current) return;

    if (socketRef.current.connected) {
      socketRef.current.emit(SocketConnetionType.LEAVE_CONVERSATION);
    }
  };

  return {
    sendMessage,
    chatId,
    messages,
    setMessages,
    chatBodyRef,
    joinConversation,
    leaveConversation,
    lastActiveAt,
    setLastActiveAt,
    loadChatHistory,
    connectionError,
    reconnectSocket,
  };
};

export default useSocket;
