import { Badge, Box, Button, Flex, Heading, Text } from "theme-ui";
import React, { useState } from "react";
import Money from "../../../components/Money";
import styled from "@emotion/styled";
import IconDownload from "../../../components/icons/icon-download";
import IconClose from "../../../components/icons/icon-close";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  getCreditNoteDetail,
  getInvoiceDetail,
  getOrderDetail,
  lockOrder,
  deleteOrder,
} from "../../../apis";
import PropTypes from "prop-types";
import WithLoader from "../../../components/WithLoader";
import { formattedAddress } from "../../../utils/address";
import OrderDetailRow from "./OrderDetailRow";
import { useToast } from "../../../contexts/AlertProvider";
import { useTranslation } from "react-i18next";
import {
  downloadBlob,
  getEuropeanDateFormat,
  INVOICE_STATUS_BADGE_VARIANT,
  ORDER_STATUS_BADGE_VARIANT,
} from "../../../utils/helpers";
import { useCartState } from "../../../contexts/CartContext";
import { useModal } from "../../../contexts/ModalContext";
import ConfirmationModal from "../../../components/ConfirmationModal";
import exceptionHandler from "../../../apis/exceptionHandler";

const AccountDetailsContainer = styled(Box)`
  max-width: 720px;
  width: calc(100vw - 2rem);

  .modal-header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading {
      font-size: 1.5rem;
    }

    ${(props) => props.theme.customBreakpoints[2]} {
      flex-wrap: wrap;

      .heading {
        flex: 1 1 auto;
        width: 100%;
      }
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      > * {
        margin-bottom: 0.5rem;
      }
    }
  }

  .cart-list-container {
    margin-bottom: 1.5rem;

    .cart-header {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(12, 1fr);
      font-size: 1rem;
      padding: 0.75rem 1rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
      align-items: center;
    }

    .cart-header,
    .cart-item {
      .title {
        grid-column: span 7;
      }

      .counter-block {
        grid-column: span 3;
      }

      .amount {
        grid-column: span 2;
        justify-self: end;
      }
    }

    .cart-item {
      &.cart-item-sm {
        .title {
          grid-column: span 6;
        }

        .counter-block {
          grid-column: span 6;
        }
      }
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      .cart-header {
        display: none;
      }

      .cart-item {
        grid-gap: 0.5rem 1rem;
        padding: 0.75rem 0;
        grid-template-columns: 1fr auto;

        &:first-of-type {
          border-top: 1px solid ${(props) => props.theme.colors.lightGray};
        }

        .title {
          grid-row: 1;
          grid-column: auto;
        }

        .counter-block {
          justify-self: end;
          align-self: center;
          grid-row: 1 / span 2;
          grid-column: auto;
        }

        .amount {
          grid-row: 2;
          justify-self: start;
          grid-column: auto;
        }
      }
    }

    .cart-list-items {
      max-height: 32vh;
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .text-primary {
    color: ${(props) => props.theme.colors.primary};
  }

  .text-secondary {
    color: ${(props) => props.theme.colors.secondary};
  }

  .text-normal {
    font-weight: normal;
    font-family: ${(props) => props.theme.fonts.body};
    margin: 0 1rem;
  }

  .icon-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;

    height: 2.5rem;
    width: 2.5rem;

    .svg-icon {
      fill: white;
      height: 2rem;
      width: 2rem;
    }
  }
`;

const LabelValueList = styled(Flex)`
  margin-bottom: 0.5rem;
  align-items: center;
  font-size: 1rem;

  .label {
    flex: 1 1 auto;
  }

  .value {
    flex: 0 1 20rem;
    text-align: right;

    .btn {
      width: 100%;
      max-width: 10rem;
    }
  }
`;

const ORDER_DETAIL_TYPES = {
  order: {
    queryKey: ["order-detail"],
    queryFn: getOrderDetail,
  },
  credit_note: {
    queryKey: ["credit-note-detail"],
    queryFn: getCreditNoteDetail,
  },
  invoice: {
    queryKey: ["invoice-detail"],
    queryFn: getInvoiceDetail,
  },
};

const OrderDetail = ({
  type,
  id,
  hideModal,
  showModal: showDetailModal,
  onUpdate,
  downloadFile,
  refetchOrder,
  previousData,
  downloadDeliveryNote,
}) => {
  const { t } = useTranslation();
  const cart = useCartState();
  const toast = useToast();
  const { showModal } = useModal();
  const queryCache = useQueryCache();

  const { data, isLoading, isError, error } = useQuery(
    [...ORDER_DETAIL_TYPES[type].queryKey, id, "default"],
    ORDER_DETAIL_TYPES[type].queryFn
  );
  if (isError) {
    exceptionHandler(
      error,
      (error) => {
        hideModal();
        toast(error, { type: "error" });
      },
      (errors) => {
        // eslint-disable-next-line no-console
        console.log("order_create_error", errors);
      }
    );
  }
  const {
    order_id,
    date_placed,
    credit_id,
    invoice_id,
    order_date,
    issued_date,
    // date_processed,
    editable: isEditable = false,
    status,
    items = [],
    shippingAddress = {},
    totals = {},
  } = data?.data || {};
  const { city, country, postal, street } = shippingAddress;
  const {
    // insurance_charge,
    shippingMethod,
    shipping_charge,
    sub_total,
    tax_total,
    total,
    // total_quantity,
  } = totals;

  const [isDownloadLoading, setIsDownloadingLoading] = useState(false);
  const [isDeliveryDownloadLoading, setIsDeliveryDownloadLoading] =
    useState(false);

  const checkLock = async (orderId) => {
    try {
      const response = await lockOrder(1, orderId);

      return Boolean(response?.status);
    } catch (e) {
      return false;
    }
  };

  const [deleteOrderMutate, { isLoading: isDeleting }] = useMutation(
    deleteOrder,
    {
      onSuccess: () => {
        refetchOrder && refetchOrder();
        queryCache.invalidateQueries("stock-list");
        toast(t("order_deleted_successfully"), { type: "success" });
        hideModal();
      },
      onError: (error) => {
        exceptionHandler(
          error,
          (error) => {
            toast(error, { type: "error" });
          },
          (errors) => {
            // eslint-disable-next-line no-console
            console.log("error", errors);
          }
        );
      },
    }
  );

  const onDelete = () => {
    deleteOrderMutate({
      id: order_id,
    });
  };

  const onOrderDelete = () => {
    showModal(({ hideModal }) => {
      return (
        <ConfirmationModal
          title={t("confirm")}
          question={t("delete_order_question")}
          positiveButton={{
            text: t("yes"),
            action: () => {
              checkLock(id).then((available) => {
                if (available) {
                  hideModal();
                  showDetailModal && showDetailModal();
                  onDelete();
                } else {
                  toast(t("order_locked"), { type: "error" });
                }
              });
            },
          }}
          negativeButton={{
            text: t("no"),
            action: () => {
              hideModal();
              showDetailModal && showDetailModal();
            },
          }}
        />
      );
    });
  };

  const onEdit = () => {
    if (cart.items.length > 0) {
      showModal(({ hideModal }) => {
        return (
          <ConfirmationModal
            title={t("confirm")}
            question={t("do_you_want_to_override_your_cart")}
            positiveButton={{
              text: t("yes"),
              action: () => {
                checkLock(id).then((available) => {
                  if (available) {
                    hideModal();
                    onUpdate();
                  } else {
                    toast(t("order_locked"), { type: "error" });
                  }
                });
              },
            }}
            negativeButton={{
              text: t("no"),
              action: () => {
                hideModal();
                showDetailModal && showDetailModal();
              },
            }}
          />
        );
      });
    } else {
      checkLock(id).then((available) => {
        if (available) {
          onUpdate();
        } else {
          toast(t("order_locked"), { type: "error" });
        }
      });
    }
  };

  const onClickDownload = async (id, type) => {
    try {
      setIsDownloadingLoading(true);
      let fileResponse = await downloadFile(id);
      const fileName = `${type}_${id}.pdf`;
      downloadBlob(fileResponse.data, fileName);
      setIsDownloadingLoading(false);
    } catch (error) {
      setIsDownloadingLoading(false);
      toast(t("document_not_available"), { type: "error" });
    }
  };
  const onClickDeliveryDownload = async (id, type) => {
    try {
      setIsDeliveryDownloadLoading(true);
      let fileResponse = await downloadDeliveryNote(id);
      const fileName = `${type}_${id}.pdf`;
      downloadBlob(fileResponse.data, fileName);
      setIsDeliveryDownloadLoading(false);
    } catch (error) {
      setIsDeliveryDownloadLoading(false);
      toast(t("document_not_available"), { type: "error" });
    }
  };

  let enableDownloadButton = false;
  if (type !== "order") {
    enableDownloadButton = true;
  } else {
    if (status === "submitted") {
      enableDownloadButton = true;
    }
  }
  return (
    <AccountDetailsContainer>
      <Button
        className="icon-btn"
        variant="secondaryIconButton"
        onClick={() => {
          hideModal();
        }}
      >
        <IconClose />
      </Button>
      <WithLoader isLoading={isLoading} height={"400px"}>
        <Box className="modal-header">
          <Heading className="heading">
            {type === "order"
              ? `${t("order_id")}`
              : type === "credit_note"
              ? `${t("credit_id")}`
              : `${t("invoice_id")}`}
            <span className="text-normal">
              #
              {type === "order"
                ? order_id
                : type === "credit_note"
                ? credit_id
                : invoice_id}
            </span>
          </Heading>
        </Box>
        <Box>
          <Heading sx={{ marginBottom: "1.5rem" }}>
            {t("order_date")}
            <span className="text-normal">
              {getEuropeanDateFormat(date_placed || order_date || issued_date)}
            </span>
          </Heading>
        </Box>
        <Box className="modal-header">
          <Badge
            sx={{ lineHeight: "1rem" }}
            variant={
              type === "order"
                ? ORDER_STATUS_BADGE_VARIANT[status]
                : INVOICE_STATUS_BADGE_VARIANT[status]
            }
          >
            {t(status)}
          </Badge>
          <Flex>
            {isEditable && (
              <WithLoader isLoading={isDeleting}>
                <Button variant="primaryOutline" onClick={onOrderDelete}>
                  {t("delete")}
                </Button>
              </WithLoader>
            )}
            {isEditable && (
              <Button
                variant="primary"
                onClick={onEdit}
                sx={{ marginLeft: "0.5rem" }}
              >
                {t("update")}
              </Button>
            )}
            {enableDownloadButton && (
              <Flex
                sx={
                  type === "invoice"
                    ? { lineHeight: "0.5rem", marginBottom: "0.5rem" }
                    : { minWidth: "10rem", justifyContent: "center" }
                }
              >
                <WithLoader isLoading={isDownloadLoading}>
                  <Button
                    variant="secondaryOutline"
                    sx={{ marginLeft: "0.5rem" }}
                    onClick={() => onClickDownload(id, type)}
                  >
                    <IconDownload />
                    {type === "invoice" ? t("download_invoice") : t("download")}
                  </Button>
                </WithLoader>
              </Flex>
            )}
            {type === "invoice" && (
              <Flex sx={{ lineHeight: "0.5rem", marginBottom: "0.5rem" }}>
                <WithLoader isLoading={isDeliveryDownloadLoading}>
                  <Button
                    variant="secondaryOutline"
                    sx={{ marginLeft: "0.5rem" }}
                    onClick={() => onClickDeliveryDownload(id, "DeliveryNote")}
                  >
                    <IconDownload />
                    {t("download_delivery_note")}
                  </Button>
                </WithLoader>
              </Flex>
            )}
          </Flex>
        </Box>
        <Box className="cart-list-container">
          <Box className="cart-header">
            <Heading className="title">{t("item_description")}</Heading>
            <Heading className="counter-block">{t("quantity")}</Heading>
            <Heading className="amount">{t("cost")}</Heading>
          </Box>
          <Box className="cart-list-items">
            {items?.map(({ artnr, description, price, quantity }, key) => {
              let isUpdated =
                Number(previousData?.order_id) === id &&
                previousData?.items?.find((item) => {
                  return item.artnr === artnr && item.quantity !== quantity;
                });
              if (
                Number(previousData?.order_id) === id &&
                !isUpdated &&
                previousData?.items
              ) {
                isUpdated = !previousData?.items?.find((item) => {
                  return item.artnr === artnr;
                });
              }
              return (
                <OrderDetailRow
                  key={key}
                  description={description}
                  selling_price={price}
                  quantity={quantity}
                  isUpdated={isUpdated}
                />
              );
            })}
          </Box>
        </Box>

        <LabelValueList>
          <Box className="label">{t("shipping_address")}</Box>
          <Text className="value">
            {formattedAddress(street, "", postal, city, country)}
          </Text>
        </LabelValueList>
        <LabelValueList>
          <Box className="label">{t("shipment_type")}</Box>
          <Text className="value">{shippingMethod}</Text>
        </LabelValueList>
        <LabelValueList>
          <Box className="label">{t("sub_total")}</Box>
          <Text className="value">
            <Money amount={sub_total} />
          </Text>
        </LabelValueList>
        <LabelValueList>
          <Box className="label">{t("shipping_charge")}</Box>
          <Text className="value">
            <Money amount={shipping_charge} />
          </Text>
        </LabelValueList>
        <LabelValueList>
          <Box className="label">{t("sales_tax")}</Box>
          <Text className="value">
            <Money amount={tax_total} />
          </Text>
        </LabelValueList>
        <LabelValueList
          sx={{ color: "secondary", fontWeight: "bold", marginTop: "1rem" }}
        >
          <Box className="label">{t("total")}</Box>
          <Text className="value">
            <Money amount={total} />
          </Text>
        </LabelValueList>
      </WithLoader>
    </AccountDetailsContainer>
  );
};

OrderDetail.propTypes = {
  type: PropTypes.oneOf(["order", "credit_note", "invoice"]),
  id: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default OrderDetail;
