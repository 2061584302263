import React from "react";

const IconHuFlag = ({ props, width = "15" }) => (
  <svg
    id="a57efc611fc04f2d083f6bf9aaad7ca5"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 58.579 43.933"
    {...props}
  >
    <path
      id="Path_32"
      data-name="Path 32"
      d="M58.579,43.933H0V0H58.579Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      id="Path_33"
      data-name="Path 33"
      d="M58.579,334.641H0V320H58.579Z"
      transform="translate(0 -290.707)"
      fill="#388d00"
      fillRule="evenodd"
    />
    <path
      id="Path_34"
      data-name="Path 34"
      d="M58.579,14.774H0V.13H58.579Z"
      transform="translate(0 -0.118)"
      fill="#d43516"
      fillRule="evenodd"
    />
  </svg>
);

export default IconHuFlag;
