import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

const shimmer = keyframes`
   0% {
        background-position: -400px 0;
    }
    100% {
        background-position: 400px 0;
    }
`;

const Skeleton = styled.div`
  background: rgba($rgb, 0.03);
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0.15) 20%,
    rgba(0, 0, 0, 0.05) 40%,
    rgba(0, 0, 0, 0.05) 100%
  );
  background-size: 800px 100px;
  border-radius: 4px;
  height: 2rem;
  width: 100%;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;
`;
export default Skeleton;
