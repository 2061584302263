import React, { useCallback, useState } from "react";
import { Box, Button, Flex } from "theme-ui";
import Pagination from "../../Shop/Pagination";
import { downloadCreditNotes, getCreditNotes } from "../../../../apis/index";
import { useQuery } from "react-query";
import SortTableHead from "../../Shop/SortTableHead";
import { useTranslation } from "react-i18next";
import IconDownload from "../../../../components/icons/icon-download";
import { useModal } from "../../../../contexts/ModalContext";
import OrderDetail from "../OrderDetail";
import Money from "../../../../components/Money";
import { downloadBlob, getEuropeanDateFormat } from "../../../../utils/helpers";
import { useToast } from "../../../../contexts/AlertProvider";
import WithLoader from "../../../../components/WithLoader";
import IconSearch from "../../../../components/icons/icon-search";
import Input from "../../../../components/form-fields/Input";

const CreditNotes = () => {
  const { t } = useTranslation();
  const { showModal } = useModal();

  const toast = useToast();

  const [isDownloadLoading, setIsDownloadingLoading] = useState(false);
  const [id, setId] = useState(null);

  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({
    sort_by: null,
    sort_direction: "asc",
  });

  const [searchText, setSearchText] = useState();
  const [search, setSearch] = useState();

  //Api Call
  const { data, isLoading, isFetching } = useQuery(
    ["credit-notes", page, sort.sort_by, sort.sort_direction, searchText],
    getCreditNotes,
    {
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );
  const creditData = data?.data || { data: [] };

  const { from, to, total, current_page, last_page } = creditData;

  const onSortClick = useCallback(
    (value) => {
      setPage(1);
      if (value !== sort.sort_by) {
        setSort({ sort_by: value, sort_direction: "asc" });
        return;
      }
      if ("asc" === sort.sort_direction) {
        setSort({ sort_by: value, sort_direction: "desc" });
        return;
      }
      setSort({ sort_by: null, sort_direction: "asc" });
    },
    [sort.sort_by, sort.sort_direction]
  );

  const onCreditNotesDownload = async (id, type) => {
    try {
      setId(id);
      setIsDownloadingLoading(true);
      let fileResponse = await downloadCreditNotes(id);
      const fileName = `${type}_${id}.pdf`;
      downloadBlob(fileResponse.data, fileName);
      setIsDownloadingLoading(false);
    } catch (error) {
      setIsDownloadingLoading(false);
      toast(t("document_not_available"), { type: "error" });
    }
  };

  const onViewDetail = useCallback(
    (id) => {
      showModal(({ hideModal }) => (
        <OrderDetail
          type={"credit_note"}
          id={id}
          hideModal={hideModal}
          downloadFile={(id) => downloadCreditNotes(id)}
        />
      ));
    },
    [showModal]
  );

  return (
    <>
      <Box
        className="pagination-container"
        style={{ justifyContent: "space-between" }}
      >
        <Input
          label={t("search")}
          type="number"
          min="1"
          icon={
            <IconSearch
              onClick={() => {
                setSearchText(search);
                setPage(1);
              }}
            />
          }
          iconPlacement="suffix"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setSearchText(search);
              setPage(1);
            }
          }}
          onBlur={() => {
            if (search === "") {
              setSearchText(search);
              setPage(1);
            }
          }}
        />
        <Pagination
          {...{ from, to, total, current_page, last_page }}
          onChange={(page) => setPage(page)}
        />
      </Box>
      <Box className="table-container">
        <table className="table">
          <thead className="table-head">
            <tr>
              <SortTableHead
                label={t("id")}
                value={"id"}
                sort={sort}
                onClick={onSortClick}
              />
              <SortTableHead
                label={t("issued_date")}
                value={"date"}
                sort={sort}
                onClick={onSortClick}
              />
              <th>{t("quantity")}</th>
              <SortTableHead
                label={t("amount")}
                value={"cost"}
                sort={sort}
                onClick={onSortClick}
              />
              <th />
              <th />
            </tr>
          </thead>
          <tbody className="table-body">
            {!isLoading && creditData.data.length === 0 && (
              <tr>
                <td colSpan={9}>{t("no_data_in_the_list")}</td>
              </tr>
            )}
            {isLoading || isFetching ? (
              <tr>
                <td colSpan={9} rowSpan={7}>
                  <WithLoader isLoading={true} height={"16rem"} />
                </td>
              </tr>
            ) : (
              creditData?.data.map(
                (
                  { credit_id, issued_date, total_quantity, total_cost },
                  index
                ) => (
                  <tr key={index}>
                    <td>{credit_id}</td>
                    <td>
                      <span>{getEuropeanDateFormat(issued_date)}</span>
                    </td>
                    <td>{total_quantity}</td>
                    <td>
                      <span>
                        <Money amount={total_cost} />
                      </span>
                    </td>
                    <td>
                      <Box className="flex-center">
                        <Button
                          variant="secondaryOutline"
                          className="details-btn"
                          onClick={() => {
                            onViewDetail(credit_id);
                          }}
                        >
                          {t("view_details")}
                        </Button>
                      </Box>
                    </td>
                    <td>
                      <Flex sx={{ justifyContent: "center" }}>
                        <WithLoader
                          isLoading={id === credit_id && isDownloadLoading}
                        >
                          <Button
                            variant="secondaryOutline"
                            onClick={() =>
                              onCreditNotesDownload(credit_id, "Credit_note")
                            }
                          >
                            <IconDownload /> {t("download")}
                          </Button>
                        </WithLoader>
                      </Flex>
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </Box>
    </>
  );
};

export default CreditNotes;
