import { Box } from "theme-ui";
import Input from "../../../components/form-fields/Input";
import IconCheveronDown from "../../../components/icons/icon-cheveron-down";
import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { transparentize } from "@theme-ui/color";

const TableCounterInputContainer = styled(Box)`
  display: flex;
  position: relative;
  padding: 0.5rem 0;
  .input {
    max-width: 4rem;
    width: 4rem;
    border-radius: 0;
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.primary};
  }

  .arrow-counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    .arrow-up {
      .svg-icon {
        transform: rotate(180deg);
      }
    }

    .arrow-up,
    .arrow-down {
      display: flex;
      align-items: flex-start;
      border: none;
      padding: 0;
      background: transparent;

      .svg-icon {
        fill: ${(props) => props.theme.colors.primary};
        width: 18px;
        height: 18px;
      }

      &:hover:enabled {
        background-color: ${(props) =>
          transparentize(props.theme.colors.primary, 0.88)};
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;

        .svg-icon {
          fill: ${(props) => props.theme.colors.secondaryText};
        }
      }
    }
  }
`;

const TableCounterInput = ({
  value,
  onChange,
  maxValue = 1,
  minValue = 1,
  telephone_order,
}) => {
  return (
    <TableCounterInputContainer>
      <Input
        label="qty"
        type="number"
        value={value}
        min={minValue}
        max={maxValue}
        onChange={(e) => {
          const newValue = e.target.value;
          if (
            !newValue ||
            (newValue && newValue >= minValue && newValue <= maxValue)
          ) {
            onChange(newValue);
          }
        }}
        onBlur={(e) => {
          const newValue = e.target.value;
          if (!newValue) onChange(minValue);
        }}
        disabled={maxValue === 0 || telephone_order === "ja"}
      />

      <Box className="arrow-counter">
        <button
          className="arrow-up"
          disabled={
            maxValue === 0 || value >= maxValue || telephone_order === "ja"
          }
          onClick={() => {
            if (value < maxValue) onChange(parseInt(value) + 1);
          }}
        >
          <IconCheveronDown />
        </button>
        <button
          className="arrow-down"
          disabled={
            maxValue === 0 || value <= minValue || telephone_order === "ja"
          }
          onClick={() => {
            if (value > minValue) onChange(parseInt(value) - 1);
          }}
        >
          <IconCheveronDown />
        </button>
      </Box>
    </TableCounterInputContainer>
  );
};

TableCounterInput.propTypes = {
  value: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onChange: PropTypes.func,
};

export default TableCounterInput;
