import React, { useState, useCallback, createContext } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { MIN_WIDTH_FOR_MODAL } from "../constants";

const Context = createContext();

export const useTradeInCartContext = () => useContext(Context);

export const TradeInCartProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [isCartEnabled, setIsCartEnabled] = useState(false);
  const { pathname } = useLocation();

  const openModal = useCallback(() => {
    if (window.innerWidth < MIN_WIDTH_FOR_MODAL) {
      setIsOpen(true);
    }
  }, [setIsOpen]);

  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  const changeProductCount = useCallback(
    (count) => setProductCount(count ? Number(count) : 0),
    [setProductCount]
  );

  const enableTradeInCart = useCallback(() => {
    if (pathname?.includes("trade-in")) {
      setIsCartEnabled(true);
    }
  }, [setIsCartEnabled, pathname]);

  const disableTradeInCart = useCallback(() => setIsCartEnabled(false), [
    setIsCartEnabled,
  ]);

  return (
    <Context.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        productCount,
        changeProductCount,
        enableTradeInCart,
        disableTradeInCart,
        isCartEnabled,
      }}
    >
      {children}
    </Context.Provider>
  );
};
