import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Form } from "react-final-form";
import RFFInputField from "../../unauthenticated/Login/RFFInputField";
import { Box, Button, Flex, Heading } from "theme-ui";
import Container from "../../../components/Container";
import { changePassword } from "../../../apis";
import exceptionHandler from "../../../apis/exceptionHandler";
import {
  composeValidators,
  isPassword,
  isPasswordConfirmed,
  isRequired,
  isSamePassword,
} from "../../../utils/validators";
import WithLoader from "../../../components/WithLoader";
import { useModal } from "../../../contexts/ModalContext";
import { useAuthContext, useLogout } from "../../../contexts/AuthContext";
import { useQueryCache } from "react-query";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../../components/ErrorMessage";

const ChangePasswordContainer = styled(Box)`
  margin: 3rem 0;

  .page-header {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }
    .svg-icon {
      margin-right: 0.5rem;
      height: 1.75rem;
      width: 1.75rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  .container {
    max-width: 40rem;
  }

  .form-control {
    display: flex;
    align-items: flex-start;
    flex-wrap: no-wrap;
    width: 100%;

    label {
      flex: 0 0 15rem;
      margin: 0.75rem 0;
    }

    // for input width
    > * {
      flex: 1 1 auto;

      input {
        background-color: ${(props) => props.theme.colors.lightGray};
      }
    }

    .input {
      padding: 0.75rem 1.25rem;
    }

    &.has-error {
      margin-bottom: 2.5rem;
    }

    ${(props) => props.theme.customBreakpoints[2]} {
      flex-wrap: wrap;
      label {
        width: 100%;
        flex: 1 1 auto;
      }
    }
  }
`;

const ConfirmationModal = styled(Box)`
  text-align: center;
  max-width: 24rem;

  .modal-title {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;

    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      height: 1.5rem;
      width: 1.5rem;
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 0.5rem;
    }
  }

  .modal-body {
    margin-bottom: 2rem;
  }

  .modal-footer {
    justify-content: center;
    align-items: center;
    button {
      min-width: 10rem;
      margin: 0 1rem;
    }
  }
`;

const ChangePassword = () => {
  const { t } = useTranslation();
  const logout = useLogout();
  const queryCache = useQueryCache();

  const { showModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { password_change_required } = useAuthContext();

  const onSubmit = useCallback(
    async (values) => {
      try {
        setIsLoading(true);
        await changePassword(values);
        showModal(
          ({ hideModal }) => {
            return (
              <ConfirmationModal>
                <Heading
                  as="h2"
                  sx={{ marginBottom: "1.5rem", fontSize: "1.25rem" }}
                >
                  {t("your_password_has_been_updated")}
                </Heading>
                <Flex className="modal-footer">
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      logout();
                      queryCache.clear();
                      hideModal();
                    }}
                  >
                    {t("continue")}
                  </Button>
                </Flex>
              </ConfirmationModal>
            );
          },
          () => {
            logout();
            queryCache.clear();
          }
        );
      } catch (e) {
        return exceptionHandler(
          e,
          (error) => {
            setErrorMessage(error);
          },
          (errors) => {
            return errors;
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [logout, queryCache, showModal, t]
  );

  return (
    <ChangePasswordContainer className="change-password-container">
      <Container className="container">
        <Box className="page-header">
          <Heading className="heading">{t("change_password")}</Heading>
        </Box>
        {password_change_required && (
          <div style={{ marginBottom: "20px" }}>
            <span style={{ color: "#FF3D3D" }}>{t("password_expired")}</span>
          </div>
        )}
        <Form
          onSubmit={onSubmit}
          validate={({ current_password, new_password, confirm_password }) => ({
            current_password: isRequired(current_password),
            new_password: composeValidators(
              isRequired,
              isPassword,
              isSamePassword(current_password)
            )(new_password),
            confirm_password: composeValidators(
              isRequired,
              isPasswordConfirmed(new_password)
            )(confirm_password),
          })}
          render={({ handleSubmit }) => (
            <Box as="form" className="form-container" onSubmit={handleSubmit}>
              <ErrorMessage
                message={errorMessage}
                sx={{ marginBottom: 4, fontWeight: 400, width: "100%" }}
              />
              <RFFInputField
                placeholder={t("current_password")}
                label={t("current_password")}
                type={"password"}
                name={"current_password"}
                onChange={(e) => setErrorMessage(null)}
              />
              <RFFInputField
                placeholder={t("new_password")}
                label={t("new_password")}
                type={"password"}
                name={"new_password"}
                onChange={() => null}
              />
              <RFFInputField
                placeholder={t("confirm_password")}
                label={t("confirm_password")}
                type={"password"}
                name={"confirm_password"}
                onChange={() => null}
              />
              <Flex sx={{ justifyContent: "flex-end", marginTop: "2.5rem" }}>
                <WithLoader isLoading={isLoading}>
                  <Button
                    variant={"secondary"}
                    type={"submit"}
                    sx={{ minWidth: "10rem" }}
                  >
                    {t("update")}
                  </Button>
                </WithLoader>
              </Flex>
            </Box>
          )}
        />
      </Container>
    </ChangePasswordContainer>
  );
};

export default ChangePassword;
