import { Box, Button, Flex, Heading, Text } from "theme-ui";
import IconClose from "../../../components/icons/icon-close";
import theme from "../../../themes";
import MobileDrawerMenu from "./MobileDrawerMenu";
import IconUser from "../../../components/icons/icon-user";
import IconEdit from "../../../components/icons/icon-edit";
import IconLogout from "../../../components/icons/icon-logout";
import IconPhone from "../../../components/icons/icon-phone";
import IconEmail from "../../../components/icons/icon-email";
import { changeLanguage, languages } from "../../../utils/i18n";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getCustomerProfile, getTradeInOrder } from "../../../apis";
import { downloadPriceList } from "../../../apis";
import IconDownload from "../../../components/icons/icon-download";
import IconList from "../../../components/icons/icon-list";
import IconPlus from "../../../components/icons/icon-plus";
import IconGrid from "../../../components/icons/icon-grid";
import themes from "../../../themes";
import { TradeIn_Status } from "../../../constants";
import { tradeInDisplayedLength } from "../../../utils/helpers";
import IconHome from "../../../components/icons/icon-home";

const MobileDrawer = ({ showMobile, setShowMobile, logoutConfirmation }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { data: profile } = useQuery("profile", getCustomerProfile);
  const { data } = useQuery(
    [TradeIn_Status.STATUS_COUNTER_OFFER.value],
    getTradeInOrder
  );

  const displayedLength = useMemo(() => {
    const oldOffersLength = data?.data?.length;
    return tradeInDisplayedLength(oldOffersLength);
  }, [data]);

  const NAV_LINKS = [
    {
      name: t("home"),
      pathname: "/home",
    },
    {
      name: t("shop"),
      pathname: "/shop",
    },
    {
      name: t("trade_in"),
      pathname: "/trade-in-home",
      subMenus: [
        {
          type: "navlink",
          text: t("home"),
          pathname: "/home-trade-in",
          icon: <IconHome />,
        },
        {
          type: "navlink",
          text: t("new_offer"),
          pathname: "/trade-in",
          icon: <IconPlus />,
        },
        {
          type: "navlink",
          pathname: "/trade-in-old-offer",
          icon: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconGrid className="menu-item-icon" />
                {t("overview")}
              </div>
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: themes.colors.secondary,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
              >
                {displayedLength}
              </div>
            </div>
          ),
        },
      ],
    },
    {
      name: t("account"),
      pathname: "/account",
      subMenus: [
        {
          type: "navlink",
          text: t("my_orders"),
          pathname: "/account/my-orders",
        },
        {
          type: "navlink",
          text: t("credit_notes"),
          pathname: "/account/credit-notes",
        },
        {
          type: "navlink",
          text: t("invoices"),
          pathname: "/account/invoices",
        },
      ],
    },
    {
      name: t("rma"),
      subMenus: [
        {
          type: "navlink",
          text: t("list_rma"),
          pathname: "/rma",
        },
        {
          type: "anchor",
          text: t("create_rma"),
          href: "http://rma.yukatel.de/",
          target: "_blank",
        },
      ],
    },
    {
      name: t("contact"),
      subMenus: [
        {
          type: "anchor",
          text: "+49 (0) 69 83 83 25 0",
          icon: <IconPhone />,
          href: "tel:+49 (0) 69 83 83 25 0",
        },
        {
          type: "anchor",
          text: "info@yukatel.de",
          icon: <IconEmail />,
          href: "mailto:info@yukatel.de",
        },
      ],
    },
    {
      name: t("price_list"),
      subMenus: [
        {
          type: "navlink",
          text: t("price_list"),
          icon: <IconList className="menu-item-icon" />,
          pathname: "/price-list",
        },
        {
          type: "button",
          text: t("price_list_xls"),
          icon: <IconDownload className="menu-item-icon" />,
          onClick: async () => {
            await downloadPriceList();
          },
        },
      ],
    },
    {
      name: t("redeem_yp"),
      pathname: "/redeem-yp",
    },
    {
      name: t("profile"),
      subMenus: [
        {
          type: "navlink",
          text: t("view_profile"),
          icon: <IconUser />,
          pathname: "/profile",
        },
        {
          type: "navlink",
          text: t("change_password"),
          icon: <IconEdit />,
          pathname: "/change-password",
        },
        {
          type: "button",
          text: t("logout"),
          icon: <IconLogout />,
          onClick: () => {
            logoutConfirmation();
          },
        },
      ],
    },
    {
      name: t("language"),
      subMenus: Object.keys(languages).map((key) => ({
        type: "button",
        text: languages[key],
        isActive: i18n.language === key,
        onClick: () => changeLanguage(key),
      })),
    },
  ];

  return (
    <Box className={`mobile-nav-wrapper ${showMobile ? "opened" : ""}`}>
      <Box className="mobile-nav-container">
        <Button
          variant="secondaryIconButton"
          className="icon-btn"
          onClick={() => {
            setShowMobile((prev) => !prev);
          }}
        >
          <IconClose />
        </Button>
        <Heading
          style={{
            padding: "1rem 2.5rem 1rem 1rem",
            color: theme.colors.primary,
            fontSize: "1.25rem",
          }}
        >
          <Text style={{ fontWeight: 400, display: "inline-block" }}>
            Hello,
          </Text>{" "}
          {profile?.data?.name}
        </Heading>
        <Flex className="nav-links">
          {NAV_LINKS.map(({ name, pathname, subMenus }, key) => {
            return (
              <MobileDrawerMenu
                menuText={name}
                pathname={pathname}
                subMenus={subMenus}
                setShowMobile={setShowMobile}
                key={key}
                navLinks={NAV_LINKS}
              />
            );
          })}
        </Flex>
      </Box>
      <Box
        className="outside-click"
        onClick={() => {
          setShowMobile((prev) => !prev);
        }}
      />
    </Box>
  );
};

export default MobileDrawer;
