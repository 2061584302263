import React from "react";
import themes from "../../themes";

const CustomSwitch = ({ checked, onChange }) => {
  const handleChange = () => {
    onChange && onChange(!checked);
  };

  return (
    <label
      style={{
        position: "relative",
        display: "inline-block",
        width: "47px",
        height: "25px",
      }}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        style={{
          display: "none",
        }}
      />
      <span
        style={{
          position: "absolute",
          cursor: "pointer",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          backgroundColor: checked
            ? themes.colors.primary
            : themes.colors.switchBG,
          borderRadius: "24px",
          transition: "0.4s",
        }}
      >
        <span
          style={{
            position: "absolute",
            transition: "0.4s",
            transform: checked ? "translateX(22px)" : "translateX(0)",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: themes.colors.white,
            boxShadow: checked ? "0 2px 5px rgba(0, 0, 0, 0.2)" : "none",
            top: "2px",
            marginLeft: "3px",
          }}
        />
      </span>
    </label>
  );
};
export default CustomSwitch;
