import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useAuthContext } from "../../../contexts/AuthContext";
import InvoiceHeader from "./InvoiceHeader";

const AuthenticatedLayout = (props) => {
  const { invoice_login } = useAuthContext();
  return (
    <>
      {!invoice_login ? <Header /> : <InvoiceHeader />}
      <div style={{ minHeight: "calc(100vh - 28.5rem)" }} {...props} />
      <Footer />
    </>
  );
};

export default AuthenticatedLayout;
