import { Box, Heading, Label, Radio } from "theme-ui";
import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const FilterBox = styled(Box)`
  margin-bottom: 1rem;

  .radio-label {
    margin-bottom: 0.5rem;

    > div {
      flex: 0 0 2.5rem;
    }

    > span {
      word-break: break-all;
    }

    &.all {
      svg {
        fill: ${(props) => props.theme.colors.primary};
      }
    }

    &.available {
      svg {
        fill: ${(props) => props.theme.colors.success};
      }
    }

    &.unavailable {
      svg {
        fill: ${(props) => props.theme.colors.danger};
      }
    }

    &.upcoming {
      svg {
        fill: ${(props) => props.theme.colors.warning};
      }
    }
    &.availablewithupcoming {
      svg {
        fill: ${(props) => props.theme.colors.success};
      }
    }
  }
`;
const FilterHeading = styled(Heading)`
  margin-bottom: 0.75rem;
`;

const RadioFilter = ({ data, onChange, defaultId, value }) => {
  const { t } = useTranslation();
  return (
    <FilterBox>
      <FilterHeading>{t("availability")}</FilterHeading>
      <Box>
        {data.map(({ id, name }) => {
          return (
            <Label key={id} className={`radio-label ${id}`}>
              <Radio
                name={"radio-filter"}
                value={id}
                checked={value === id}
                onChange={() => {
                  onChange(id);
                }}
              />
              <span>{t(name)}</span>
            </Label>
          );
        })}
      </Box>
    </FilterBox>
  );
};

RadioFilter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  defaultId: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default RadioFilter;
