import React from "react";

const IconTrFlag = ({ props, width = "15" }) => (
  <svg
    id="Group_6"
    data-name="Group 6"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 149.161 111.871"
    width={width}
    {...props}
  >
    <path
      id="Path_602"
      data-name="Path 602"
      d="M0,0H149.161V111.871H0Z"
      fill="#f31930"
      fillRule="evenodd"
    />
    <path
      id="Path_603"
      data-name="Path 603"
      d="M219.893,155.524a28.431,28.431,0,1,1-28.427-27.942,28.187,28.187,0,0,1,28.427,27.942Z"
      transform="translate(-125.041 -97.847)"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      id="Path_604"
      data-name="Path 604"
      d="M263.41,173.91a22.744,22.744,0,1,1-22.741-22.353A22.55,22.55,0,0,1,263.41,173.91Z"
      transform="translate(-167.137 -116.235)"
      fill="#f31830"
      fillRule="evenodd"
    />
    <path
      id="Path_605"
      data-name="Path 605"
      d="M398.262,191.465l-.23,10.329L388.4,204.41l9.5,3.383-.229,9.472,6.195-7.4,9.361,3.247-5.415-7.938,6.608-7.893-10.141,2.8-6.011-8.615Z"
      transform="translate(-297.875 -146.841)"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default IconTrFlag;
