import { Select } from "theme-ui";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { getShippingAddressList } from "../../../apis";
import { formattedAddress } from "../../../utils/address";
import PropTypes from "prop-types";
import WithLoader from "../../../components/WithLoader";
import { useTranslation } from "react-i18next";

const ShippingAddressSelect = ({
  value,
  onChange,
  hasDefaultOption = true,
}) => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery("addresses", getShippingAddressList);
  const list = data?.data?.data;

  useEffect(() => {
    if (list?.length > 0) {
      const primaryAddress = list?.find((item) => item?.primary === 1);
      if (Number.isNaN(value) || value === -1) {
        if (primaryAddress) {
          onChange(primaryAddress.address_id);
        } else {
          onChange(list[0].address_id);
        }
      }
    }
  }, [list]);

  return (
    <WithLoader isLoading={isLoading}>
      <Select
        paddingRight={"2rem"}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {hasDefaultOption && <option value={-1}>{t("select_address")}</option>}
        {list?.map(({ address_id, street, no, postal, city, country }, key) => (
          <option key={key} value={address_id}>
            {formattedAddress(street, no, postal, city, country)}
          </option>
        ))}
      </Select>
    </WithLoader>
  );
};

ShippingAddressSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  hasDefaultOption: PropTypes.bool,
};

export default ShippingAddressSelect;
