import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

export const InputField = styled.div`
  position: relative;
  width: 100%;
  .input,
  .select {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    border-radius: 1rem;
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    font-size: 1rem;
    outline: none;
    cursor: text;
    font-family: "Roboto", sans-sarif;
    margin: 0;

    &:focus {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  .input-icon {
    height: 100%;
    width: 2rem;
    position: absolute;
    z-index: 3;
    bottom: 0;
    padding: 0.5rem 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &.prefix-icon {
    .input {
      padding-left: 2.5rem;
    }
    .input-icon {
      left: 0;
    }
  }

  &.suffix-icon {
    .input {
      padding-right: 2.5rem;
    }

    .input-icon {
      right: 0;
    }
  }
`;

export const InputContainer = styled.div`
  display: block;
  width: 100%;
  .error,
  .info {
    display: none;
    color: ${(props) => props.theme.colors.danger};
    font-size: 80%;
    position: relative;
    margin-top: 0.375rem;
  }

  &.has-error {
    .error {
      display: inline-block;
    }

    .input {
      border-color: ${(props) => props.theme.colors.danger};
    }
    .select {
      border-color: ${(props) => props.theme.colors.danger};
    }
  }

  .info {
    display: inline-block;
    color: ${(props) => props.theme.colors.gray};
  }
`;

const Input = ({
  label,
  type,
  icon,
  iconPlacement,
  value,
  error,
  ...props
}) => {
  return (
    <InputContainer
      hasIcon={Boolean(icon)}
      className={
        type === "search"
          ? `search-input-container`
          : `input-container ${error && "has-error"}`
      }
    >
      <InputField className={icon ? `${iconPlacement || "prefix"}-icon` : null}>
        {icon && <div className="input-icon">{icon}</div>}
        <input
          className="input"
          type={type}
          autoComplete="off"
          placeholder={label}
          value={value}
          {...props}
        />
      </InputField>
      <span className="error">{error}</span>
    </InputContainer>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.object,
  iconPlacement: PropTypes.oneOf(["prefix", "suffix"]),
  error: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Input;
