import React from "react";
import { ThemeProvider } from "theme-ui";
import theme from "./themes";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import UnauthenticatedLayout from "./pages/unauthenticated/Layout";
import unauthenticated, { RedirectPage } from "./routes/unauthenticated";
import { useAuthContext } from "./contexts/AuthContext";
import AuthenticatedLayout from "./pages/authenticated/Layout";
import authenticated, {
  AuthenticatedRedirectPage,
} from "./routes/authenticated";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import ModalProvider from "./contexts/ModalContext";
import MobileCartProvider from "./contexts/MobileCartProvider";
import EditModeProvider from "./contexts/EditModeProvider";
import COAProvider from "./contexts/COAProvider";
import changePassword from "./routes/changepassword";
import { AuthenticatedUpdatePasswordPage } from "./routes/changepassword";
import invoice from "./routes/invoice";
import { AuthenticatedInvoicePage } from "./routes/invoice";
import "react-datepicker/dist/react-datepicker.css";
import { TradeInCartProvider } from "./contexts/TradeInCartContext";
import ChatUI from "./components/ChatUi";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      staleTime: 1000 * 60 * 5,
    },
  },
});

function App() {
  const {
    authenticated: isAuthenticated,
    password_change_required,
    invoice_login,
  } = useAuthContext();
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <EditModeProvider>
            <BrowserRouter>
              <TradeInCartProvider>
                {isAuthenticated ? (
                  <COAProvider>
                    <MobileCartProvider>
                      <>
                        {password_change_required ? (
                          <Routes
                            Layout={AuthenticatedLayout}
                            routes={changePassword}
                            RedirectPage={AuthenticatedUpdatePasswordPage}
                          />
                        ) : (
                          <>
                            {invoice_login ? (
                              <Routes
                                Layout={AuthenticatedLayout}
                                routes={invoice}
                                RedirectPage={AuthenticatedInvoicePage}
                              />
                            ) : (
                              <Routes
                                Layout={AuthenticatedLayout}
                                routes={authenticated}
                                RedirectPage={AuthenticatedRedirectPage}
                              />
                            )}
                          </>
                        )}
                      </>
                      <ChatUI />
                    </MobileCartProvider>
                  </COAProvider>
                ) : (
                  <Routes
                    Layout={UnauthenticatedLayout}
                    routes={unauthenticated}
                    RedirectPage={RedirectPage}
                  />
                )}
              </TradeInCartProvider>
            </BrowserRouter>
          </EditModeProvider>
          <ReactQueryDevtools initialIsOpen />
        </ReactQueryCacheProvider>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default App;
