import { useQuery } from "react-query";
import { getFeaturedStockList } from "../../../apis";
import React, { useMemo } from "react";
import ProductCard from "../../../components/ProductCard";
import Container from "../../../components/Container";
import styled from "@emotion/styled";
import { Box, Heading } from "theme-ui";
import WithLoader from "../../../components/WithLoader";
import { useCartActions, useCartState } from "../../../contexts/CartContext";
import { getStockStatus } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import * as QueryString from "qs";
import { useHistory } from "react-router-dom";

const SectionHeading = styled(Heading)`
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 2rem;
  text-align: center;
`;

const ProductGridContainer = styled(Box)`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 5rem;

  .product-card-container {
    grid-column: span 3;

    ${(props) => props.theme.customBreakpoints[3]} {
      grid-column: span 4;
    }
    ${(props) => props.theme.customBreakpoints[1]} {
      grid-column: span 6;
    }
    ${(props) => props.theme.customBreakpoints[0]} {
      grid-column: span 12;
    }
  }
`;
const HotDeal = () => {
  const { t } = useTranslation();

  const cart = useCartState();
  const history = useHistory();

  const onClickTitle = (id, title) => {
    if (id && title) {
      history.push({
        pathname: "/shop",
        search: "?" + QueryString.stringify({ article_no: id, title }),
      });
    }
  };

  const { data, isLoading } = useQuery(
    "featured-stock-list",
    getFeaturedStockList
  );
  const hotDealData = data?.data?.data;
  const hotDealList = useMemo(() => {
    return hotDealData?.map(({ quantity, next_delivery_amount, ...data }) => {
      const cartItem = cart.items.find(
        (item) => item.article_no === data.article_no
      );
      return {
        ...data,
        max_quantity: quantity,
        next_delivery_amount,
        stock_status: getStockStatus(quantity, next_delivery_amount),
        is_added_to_cart: Boolean(cartItem),
        cart_quantity: cartItem?.quantity,
      };
    });
  }, [cart.items, hotDealData]);

  const { addItem, updateItem } = useCartActions();

  return (
    <Container>
      {hotDealData?.length > 0 && (
        <SectionHeading>{t("hot_deals")}</SectionHeading>
      )}
      <WithLoader isLoading={isLoading} height={"400px"}>
        <ProductGridContainer>
          {hotDealList?.map((data, index) => (
            <ProductCard
              key={index}
              data={data}
              onAddToCart={addItem}
              onUpdateToCart={updateItem}
              onClickTitle={onClickTitle}
            />
          ))}
        </ProductGridContainer>
      </WithLoader>
    </Container>
  );
};

export default HotDeal;
