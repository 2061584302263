import React from "react";

const IconNlFlag = ({ props, width = "15" }) => (
  <svg
    id="_00d62e119d10f3260b50fb317afab4b2"
    {...props}
    data-name="00d62e119d10f3260b50fb317afab4b2"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 149.161 111.871"
  >
    <rect
      id="Rectangle_1"
      data-name="Rectangle 1"
      width="149.161"
      height="111.381"
      fill="#fff"
    />
    <rect
      id="Rectangle_2"
      data-name="Rectangle 2"
      width="149.161"
      height="37.127"
      transform="translate(0 74.744)"
      fill="#21468b"
    />
    <path
      id="Path_598"
      data-name="Path 598"
      d="M0,0H149.161V37.127H0Z"
      fill="#ae1c28"
      fillRule="evenodd"
    />
  </svg>
);

export default IconNlFlag;
