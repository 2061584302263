import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, Flex, Heading, Text } from "theme-ui";
import Container from "../../../components/Container";
import IconShoppingCart from "../../../components/icons/icon-shopping-cart";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  createYukaPointOrder,
  getCustomerYukaPoints,
  getYukaPointProducts,
} from "../../../apis";
import YPProduct from "./YPProduct";
import WithLoader from "../../../components/WithLoader";
import YPCart from "./YPCart";
import exceptionHandler from "../../../apis/exceptionHandler";
import { useToast } from "../../../contexts/AlertProvider";
import { useTranslation } from "react-i18next";
import Pagination from "./YukaPagination";
import IconClose from "../../../components/icons/icon-close";
import { MobileCartContext } from "../../../contexts/MobileCartProvider";

const RedeemYPContainer = styled(Box)`
  margin-bottom: 3rem;

  .redeem-yp-banner {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-bottom: 2rem;
    background-color: ${(props) => props.theme.colors.lightGray};

    .page-header {
      margin-bottom: 1.5rem;
      font-size: 1.125rem;

      .heading {
        display: inline-flex;
        font-size: 1.5rem;
        align-items: center;
        padding-bottom: 0.5rem;
        color: ${(props) => props.theme.colors.primary};
        border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
        margin-bottom: 1.5rem;
      }
    }
  }

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
  }

  .redeem-yp-shop-container {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: auto 22rem;
    justify-content: center;

    ${(props) => props.theme.customBreakpoints[2]} {
      grid-column-gap: 0;
      grid-template-columns: 1fr;
    }

    .drawer-wrapper {
      display: flex;
      align-items: flex-start;
      transition: all 0.4s ease;

      .outside-click {
        display: none;
        flex: 1 1 auto;
        background: transparent;
      }

      ${(props) => props.theme.customBreakpoints[2]} {
        position: fixed;
        top: 0;
        bottom: 0;
        right: -100%;
        z-index: 100;
        width: 100%;

        align-items: stretch;

        .outside-click {
          display: block;
        }
      }

      &.opened {
        right: 0;
      }
    }
  }
`;

const CartContainer = styled(Box)`
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.3rem;
  padding: 1rem;
  font-size: 0.875rem;
  max-height: calc(100vh - 6rem);
  position: sticky;
  top: 3rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.primary};
    fill: ${(props) => props.theme.colors.primary};
    border-bottom: 1px solid ${(props) => props.theme.colors.primary};
    padding: 1rem;
    margin: -1rem -1rem 0.5rem -1rem;

    .svg-icon {
      margin-right: 0.75rem;
    }
  }

  .cart-list-container {
    max-height: calc(100vh - 32rem);
    overflow: hidden;
    overflow-y: auto;
    margin: 0 -1rem 1rem -1rem;
  }

  .cart-item {
    padding: 0.5rem 1rem;
    transition: background 0.3s ease;

    &:hover {
      background-color: ${(props) => props.theme.colors.lightGray};
    }
  }

  .btn {
    width: 10rem;
    padding: 0.375rem 1rem;
  }

  &.drawer-container {
    flex: 1 1 auto;

    .header {
      .icon-btn {
        display: none;
      }
    }

    ${(props) => props.theme.customBreakpoints[2]} {
      max-height: none;
      max-width: 30rem;
      padding: 1rem;
      width: 100%;

      background-color: ${(props) => props.theme.colors.white};
      border-radius: 0;
      border: none;
      box-shadow: ${(props) => props.theme.shadows.dropDown};

      .header {
        font-size: 1.25rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        position: relative;

        .icon-btn {
          display: flex;
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);

          .svg-icon {
            margin: 0;
          }
        }
      }

      .cart-list-container {
        max-height: calc(100vh - 23rem);
      }

      @media (orientation: landscape) and (max-height: 40rem) {
        overflow-y: auto;
        .cart-list-container {
          max-height: 20rem;
        }
      }
    }
  }
`;

const ID_KEY = "article_no";

const RedeemYP = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryCache = useQueryCache();
  const [showCart, setShowCart] = useContext(MobileCartContext);

  const [page, setPage] = useState(1);

  const {
    data: { data: yukaPointDetail } = { data: {} },
    isLoading: isLoadingYukaPointDetail,
  } = useQuery("yuka-points", getCustomerYukaPoints, {
    staleTime: 0,
    refetchOnMount: true,
    keepPreviousData: true,
  });

  const {
    // activation_date,
    available_points = 0,
    processing_points,
    shopping_disabled = true,
  } = yukaPointDetail;

  const productContainerStyle = shopping_disabled
    ? { gridColumn: "span 2" }
    : {};

  const [cart, setCart] = useState({
    items: [],
    total_points: null,
    remaining_points: null,
    order_note: null,
  });

  const { data, isLoading: isLoadingYukaPointProducts } = useQuery(
    ["yuka-point-product-list", page],
    getYukaPointProducts,
    {
      keepPreviousData: true,
    }
  );
  const paginatedTableData = data?.data || { data: [] };

  const { current_page, last_page } = paginatedTableData;

  const ypProductList = useMemo(() => {
    return paginatedTableData.data.map(({ yukapoints, ...item }) => {
      const cartItem = cart.items.find(
        (cartItem) => cartItem[ID_KEY] === item[ID_KEY]
      );
      const maxQuantity =
        cart.remaining_points && cart.total_points
          ? Math.floor(
              (cart?.remaining_points + (cartItem?.total_points || 0)) /
                yukapoints
            )
          : Math.floor(available_points / yukapoints);

      return {
        ...item,
        yukapoints,
        max_quantity: maxQuantity,
        is_added_to_cart: Boolean(cartItem),
        cart_quantity: cartItem?.quantity || 0,
      };
    });
  }, [
    available_points,
    cart.items,
    cart.remaining_points,
    cart.total_points,
    paginatedTableData.data,
  ]);

  const onAddToCart = useCallback(
    (data) => {
      if (!cart.items.find((item) => item[ID_KEY] === data[ID_KEY])) {
        const items = [...cart.items, data];
        const totalPoints = items
          .map((item) => item.total_points)
          .reduce((p, c) => p + c);
        setCart({
          items: items,
          total_points: totalPoints,
          remaining_points: available_points - totalPoints,
        });
      }
    },
    [available_points, cart.items]
  );

  const onUpdateToCart = useCallback(
    (id, data) => {
      const items = cart.items.map((item) => {
        if (item[ID_KEY] === id) return { ...item, ...data };
        return item;
      });
      const totalPoints = items
        .map((item) => item.total_points)
        .reduce((p, c) => p + c);
      setCart({
        items: items,
        total_points: totalPoints,
        remaining_points: available_points - totalPoints,
      });
    },
    [available_points, cart.items]
  );

  const onRemoveCartItem = useCallback(
    (id) => {
      const items = cart.items.filter((item) => item[ID_KEY] !== id);
      const totalPoints =
        items.length > 0
          ? items.map((item) => item.total_points).reduce((p, c) => p + c)
          : 0;
      setCart({
        items: items,
        total_points: totalPoints,
        remaining_points: available_points - totalPoints,
      });
    },
    [available_points, cart.items]
  );

  const clearCart = useCallback(() => {
    setCart({
      items: [],
      total_points: null,
      remaining_points: null,
      order_note: null,
    });
  }, []);
  const [createYukaPointOrderMutate, { isLoading: isCheckingOut }] =
    useMutation(createYukaPointOrder, {
      onSuccess: () => {
        setShowCart(false);
        queryCache.refetchQueries(["yuka-points"]);
        clearCart();
        toast(`${t("your_order_have_been_processed")}`, { type: "success" });
      },
      onError: (error) => {
        exceptionHandler(
          error,
          (error) => {
            toast(error, { type: "error" });
          },
          (errors) => {
            console.log("yukapoint_order_error", errors);
          }
        );
      },
    });
  return (
    <RedeemYPContainer>
      <Box className="redeem-yp-banner">
        <Container>
          <Box className="page-header">
            <Heading className="heading">{t("yuka_reward_shop")}</Heading>
            <Text>{t("welcome_to_shop_with_yukapoints")}</Text>
          </Box>
        </Container>
      </Box>
      <Container>
        <WithLoader
          isLoading={isLoadingYukaPointDetail || isLoadingYukaPointProducts}
          height={"20rem"}
        >
          <Box className="redeem-yp-shop-container">
            {shopping_disabled && (
              <Flex sx={{ justifyContent: "center", gridColumn: "span 2" }}>
                <Button variant="primary">
                  {t("your_account_hasnot_been_activated_yet")}
                </Button>
              </Flex>
            )}

            <Box className="product-container" sx={productContainerStyle}>
              {ypProductList.map((item, key) => {
                return (
                  <YPProduct
                    key={key}
                    data={item}
                    onUpdateToCart={onUpdateToCart}
                    onAddToCart={onAddToCart}
                  />
                );
              })}
            </Box>

            {!shopping_disabled && (
              <Flex className={`drawer-wrapper ${showCart ? "opened" : ""}`}>
                <Box
                  className="outside-click"
                  onClick={() => {
                    setShowCart((prev) => !prev);
                  }}
                />
                <CartContainer className={`cart-container drawer-container`}>
                  <Box className="header">
                    <IconShoppingCart />
                    <Heading>{t("your_cart_item")}</Heading>
                    <Button
                      variant="secondaryIconButton"
                      className="icon-btn"
                      onClick={() => {
                        setShowCart((prev) => !prev);
                      }}
                    >
                      <IconClose />
                    </Button>
                  </Box>
                  <YPCart
                    available_points={Number(available_points)}
                    processing_points={processing_points}
                    cart={cart}
                    updateItem={onUpdateToCart}
                    removeItem={onRemoveCartItem}
                    onOrderNoteChange={(value) => {
                      setCart({ ...cart, order_note: value });
                    }}
                    onCheckout={() => {
                      createYukaPointOrderMutate({
                        items: cart.items.map(({ article_no, quantity }) => ({
                          article_number: article_no,
                          quantity,
                        })),
                        order_note: cart.order_note,
                      });
                    }}
                    isSubmitting={isCheckingOut}
                  />
                </CartContainer>
              </Flex>
            )}
          </Box>
        </WithLoader>
        <Box className="pagination-container">
          <Pagination
            {...{ current_page, last_page }}
            onChange={(page) => {
              setPage(page);
            }}
          />
        </Box>
      </Container>
    </RedeemYPContainer>
  );
};

export default RedeemYP;
