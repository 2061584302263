import React from "react";
import { useQuery } from "react-query";
import { getCustomerYukaPoints } from "../../../apis";
import YPInfo from "./YPInfo";
import RedeemYP from "./RedeemYP";

const RedeemYPMain = () => {
  const { data: { data: yukaPointDetail } = { data: {} } } = useQuery(
    "yuka-points",
    getCustomerYukaPoints,
    {
      staleTime: 0,
      refetchOnMount: true,
      keepPreviousData: true,
    }
  );

  const { shopping_disabled = true } = yukaPointDetail;

  if (shopping_disabled) {
    return <YPInfo />;
  }
  return <RedeemYP />;
};

export default RedeemYPMain;
