import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text, Grid, Button } from "theme-ui";
import IconClose from "../../icons/icon-close";
import themes from "../../../themes";
import Money from "../../Money";
import { useQuery } from "react-query";
import { getTradeInInitialSubmission } from "../../../apis";
import { getEuropeanDateFormat, getStatusText } from "../../../utils/helpers";
import useMediaQuery from "../../../utils/useMediaQuery";

export const InitialSubmissionContainer = styled(Flex)`
    margin: 2rem 0 2rem 0;
  .table-container {
    margin-top: 0.2rem
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    border:none;

    .table {
      margin-top:2rem;
      width: 100%;
      font-size: 0.875rem;
      min-width: 1180px;
      
      .red-text {
        color: ${(props) => props.theme.colors.secondary};
        font-weight:600;
      }
      .green-text {
        color: ${(props) => props.theme.colors.green};
        font-weight:600;
      }

      .gray-text {
        color: ${(props) => props.theme.colors.buttonGray};
      }

      .table-sublist {
        background-color: ${(props) => props.theme.colors.white};
        color:${(props) => props.theme.colors.primary};

        th {
          padding: 0.50rem 0.50rem;
          text-transform: uppercase;
          line-height: 1.8;
          text-align: center;
          font-size: 1rem;
          font-weight: 700;
          color:${(props) => props.theme.colors.tableHeaderTextGray};

          @media (max-width: 1240px) {
            font-size:1rem;
        }
        }
        }

        .table-body {
          background-color: #f2f2f2;
          color: ${(props) => props.theme.colors.black};

          th {
            padding: 0.75rem 0.75rem;
            text-align: left;
            width: 328px;
            @media (max-width: 1240px) {
              width:355px;
          }
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1.1rem;
          }

          td {
            font-size:0.9rem;
            padding: 0.75rem 0.75rem;
            text-transform: none;
            line-height: 1.6;
            text-align: center;
          }
          td span {
            font-size: 0.9rem;
            font-weight: 400;
          }
        }

        .table-body tr:nth-child(odd) {
          background-color:${(props) => props.theme.colors.lightTableGray}; 
        }
        
        .table-body tr:nth-child(even) {
          background-color: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`;

const InitialSubmissionModal = ({
  selectedItem,
  setShowInitialSubmissionModal,
}) => {
  const { t } = useTranslation();

  const { data } = useQuery(
    selectedItem?.tradein_order_id,
    getTradeInInitialSubmission,
    {
      cacheTime: 0,
    }
  );

  return (
    <InitialSubmissionContainer>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: themes.colors.opaqueBlack,
          zIndex: 100,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: themes.colors.white,
            borderRadius: "4px",
            width: "90%",
            height: "90%",
            overflow: "auto",
            zIndex: 100,
          }}
        >
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "70px",
              backgroundColor: themes.colors.primary,
              borderBottom: `1px solid ${themes.colors.darkGray}`,
              padding: "20px",
            }}
          >
            <Text
              sx={{
                fontWeight: "400",
                color: themes.colors.white,
              }}
            >
              {`${t("submission_id")} ${
                selectedItem?.tradein_order_id
              } / ${getEuropeanDateFormat(selectedItem?.created_at)}`}
            </Text>
            <Box>
              <Button
                sx={{
                  position: "absolute",
                  right: "1rem",
                  top: "1rem",
                  height: "2rem",
                  width: "2rem",
                  backgroundColor: themes.colors.white,
                }}
                variant="iconButton"
                onClick={() => setShowInitialSubmissionModal(false)}
              >
                <IconClose
                  fill={themes.colors.primary}
                  height="1.5rem"
                  width="1.5rem"
                />
              </Button>
            </Box>
          </Flex>
          <Box>
            <Grid columns="1" gap={0}>
              <Box className="table-container">
                <table className="table">
                  <thead className="table-sublist">
                    <tr>
                      <th
                        colSpan="1"
                        style={{ textAlign: "left", paddingLeft: "1rem" }}
                      >
                        {t("product_name")}
                      </th>
                      <th colSpan="1">{t("quantity")}</th>
                      <th colSpan="1">{t("unit_price")}</th>
                      <th colSpan="1">{t("o_quantity")}</th>
                      <th colSpan="1">{t("o_unit_price")}</th>
                      <th colSpan="1" style={{ textAlign: "center" }}>
                        {t("status")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {data?.data?.map(
                      (
                        {
                          article_name,
                          min_order_quantity,
                          unit_price,
                          counter_offer_price,
                          counter_offer_quantity,
                          status,
                        },
                        key
                      ) => (
                        <tr key={key}>
                          <td
                            className="gray-text"
                            style={{
                              textAlign: "left",
                              fontWeight: themes.fontWeights.heading,
                              width: "300px",
                            }}
                          >
                            {article_name}
                          </td>
                          <td>{min_order_quantity}</td>
                          <td>
                            <span>
                              <Money amount={unit_price} />
                            </span>
                          </td>
                          <td>{counter_offer_quantity}</td>
                          <td>
                            <span>
                              <Money amount={counter_offer_price} />
                            </span>
                          </td>
                          <td>
                            <span style={{ fontWeight: "700" }}>
                              {t(getStatusText(status))}
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </InitialSubmissionContainer>
  );
};

export default InitialSubmissionModal;
