/** @jsx jsx */
import Container from "../../../components/Container";
import styled from "@emotion/styled";
import { Box, Flex, jsx } from "theme-ui";
import { transparentize } from "@theme-ui/color";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import MyOrders from "./MyOrders";
import CreditNotes from "./CreditNotes";
import Invoices from "./Invoices";
import { useTranslation } from "react-i18next";

export const MyAccountContainer = styled(Flex)`
  margin: 3rem 0;

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0.5rem 1rem;

    ${(props) => props.theme.customBreakpoints[0]} {
      flex-wrap: wrap;
      justify-content: center;

      > .filter-menu {
        margin-right: 0;
        margn-bottom: 0.5rem;
      }
    }
  }

  .table-container {
    overflow: hidden;
    overflow-x: auto;
    border: 1px solid ${(props) => props.theme.colors.primary};
    width: 100%;

    .table {
      width: 100%;
      font-size: 0.875rem;

      .table-head {
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.white};

        th {
          padding: 0.75rem 1rem;
          text-transform: uppercase;
          line-height: 1.6;
          text-align: left;

          .svg-icon {
            margin-left: 0.5rem;
            height: 1.25rem;
            min-width: 1.25rem;
            fill: ${(props) => props.theme.colors.white};

            &.active {
              fill: ${(props) => props.theme.colors.secondary};
            }
          }
        }
      }

      .table-body {
        tr {
          &:nth-of-type(even) {
            background-color: #dcdcdd;
          }

          td {
            padding: 0.5rem 1rem;
            vertical-align: middle;

            .table-counter-input {
              display: flex;
              position: relative;

              .input {
                max-width: 5rem;
                width: 5rem;
                border-radius: 0;
                background-color: transparent;
                border-color: ${(props) => props.theme.colors.primary};
                border-bottom: 1px solid transparent;
              }

              .arrow-counter {
                position: absolute;
                right: 0;
                height: 100%;
              }
            }

            .icon-button {
              height: 1.5rem;
              width: 1.5rem;
              min-width: 1.5rem;
              min-height: 1.5rem;
              margin-left: 0.75rem;

              .svg-icon {
                height: 1.25rem;
                width: 1.25rem;
              }
            }
          }
        }
      }
    }
  }

  .details-btn {
    color: ${(props) => props.theme.colors.primary};
  }

  .delivery-btn {
    display: inline-flex;
    align-items: center;

    &:hover {
      svg {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }

  .filter-menu {
    display: inline-flex;
    border: 1px solid ${(props) => props.theme.colors.secondary};
    border-radius: 2rem;
    padding: 0.375rem 1.25rem;
    height: auto;
    color: ${(props) => props.theme.colors.primary};
    fill: ${(props) => props.theme.colors.primary};
    margin-right: 2rem;

    .svg-icon {
      height: 1.25rem;
      width: 1.25rem;
    }

    &:hover {
      background-color: ${(props) =>
        transparentize(props.theme.colors.primaryText, 0.96)};
    }
  }

  .text-center {
    text-align: center !important;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${(props) => props.theme.customBreakpoints[1]} {
    margin-top: 1rem;

    .details-btn {
      white-space: nowrap;
    }
  }
`;

const TabContainer = styled(Flex)`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  margin-bottom: 2rem;

  .tab-item {
    font-size: 1.25rem;

    color: ${(props) => props.theme.colors.primaryText};
    border-bottom: 3px solid transparent;
    margin-right: 1rem;
    font-weight: 300;
    font-family: "Raleway", san-sarif;

    a {
      color: inherit;
      text-decoration: none;
      display: block;
      padding: 0.75rem 1rem;
    }

    &:hover {
      color: ${(props) => props.theme.colors.primary};
      background-color: ${(props) =>
        transparentize(props.theme.colors.primary, 0.92)};
    }

    &.active {
      border-bottom-color: ${(props) => props.theme.colors.secondary};
      font-weight: 600;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  ${(props) => props.theme.customBreakpoints[0]} {
    .tab-item {
      font-size: 1.125rem;
      margin-right: 0.5rem;
      border-width: 2px;

      a {
        padding: 0.75rem 0.5rem;
      }
    }
  }
`;

const Account = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  let { path, url } = useRouteMatch();

  return (
    <MyAccountContainer>
      <Container>
        <TabContainer>
          <Box
            className={`tab-item ${
              pathname === `${url}/my-orders` ? "active" : ""
            }`}
          >
            <Link to={`${url}/my-orders`}>{t("my_orders")}</Link>
          </Box>
          <Box
            className={`tab-item ${
              pathname === `${url}/credit-notes` ? "active" : ""
            }`}
          >
            <Link to={`${url}/credit-notes`}>{t("credit_notes")}</Link>
          </Box>
          <Box
            className={`tab-item ${
              pathname === `${url}/invoices` ? "active" : ""
            }`}
          >
            <Link to={`${url}/invoices`}>{t("invoices")}</Link>
          </Box>
        </TabContainer>
        <section>
          <Switch>
            <Route path={path} exact>
              <Redirect to={`${path}/my-orders`} />
            </Route>
            <Route path={`${path}/my-orders`}>
              <MyOrders />
            </Route>
            <Route path={`${path}/credit-notes`}>
              <CreditNotes />
            </Route>
            <Route path={`${path}/invoices`}>
              <Invoices />
            </Route>
          </Switch>
        </section>
      </Container>
    </MyAccountContainer>
  );
};

export default Account;
