import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import HomePageBody from "../../../components/TradeIn/HomePageBody";
import { getItem } from "../../../utils/storage";

const HomePage = () => {
  return (
    <>
      {getItem().token ? (
        <>
          <HomePageBody />
        </>
      ) : (
        <>
          <Header showNavLink={false} />
          <HomePageBody />
          <Footer />
        </>
      )}
    </>
  );
};

export default HomePage;
