/** @jsx jsx */
import styled from "@emotion/styled";
import { darken } from "@theme-ui/color";
import { Box, Button, jsx } from "theme-ui";
import Input from "./form-fields/Input";
import IconMinus from "./icons/icon-minus";
import IconPlus from "./icons/icon-plus";
import PropTypes from "prop-types";

const CounterInputContainer = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 8rem;
  width: 100%;

  .add-btn,
  .remove-btn {
    position: absolute;
    height: calc(100% - 2px);
    top: 1px;
    padding: 0.25rem;
    border-color: transparent;
    outline: none;
    display: flex;
    align-items: center;

    background: ${(props) => props.theme.colors.gray};

    &:hover:enabled {
      background: ${(props) => darken(props.theme.colors.gray, 0.12)};
    }

    .svg-icon {
      height: 1.25rem;
      width: 1.25rem;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .add-btn {
    right: 1px;
    border-radius: 0 2rem 2rem 0;
  }

  .remove-btn {
    left: 1px;
    border-radius: 2rem 0 0 2rem;
  }

  .input-container {
    width: 100%;
    .input {
      border-radius: 2rem;
      border-color: ${(props) => props.theme.colors.primary};
      text-align: center;
      padding: 0.5rem 2rem;
      font-size: 0.875rem;
      line-height: 1.6;

      &:focus,
      &:active {
        border-color: ${(props) => props.theme.colors.secondary};
      }
    }
  }

  &.counter-sm {
    max-width: 6rem;

    .input-container {
      .input {
        padding: 0.25rem 1.5rem;
        line-height: 1;
      }
    }

    .add-btn,
    .remove-btn {
      .svg-icon {
        height: 1rem;
        width: 1rem;
      }
    }
  }
`;

const CounterInput = ({
  value,
  onChange,
  maxValue = 1,
  minValue = 1,
  isSmall = false,
  telephoneOrder,
}) => (
  <CounterInputContainer
    className={`counter-input-container ${isSmall ? "counter-sm" : ""} `}
  >
    <Input
      label={""}
      type="number"
      value={value}
      min={minValue}
      max={maxValue}
      onChange={(e) => {
        const newValue = e.target.value;
        if (
          !newValue ||
          (newValue && newValue >= minValue && newValue <= maxValue)
        ) {
          onChange(newValue);
        }
      }}
      onBlur={(e) => {
        const newValue = e.target.value;
        if (!newValue) onChange(minValue);
      }}
      disabled={maxValue === 0 || telephoneOrder === "ja"}
    />
    <Button
      variant="outline"
      className="add-btn"
      disabled={maxValue === 0 || value >= maxValue || telephoneOrder === "ja"}
      onClick={() => {
        if (value < maxValue) onChange(parseInt(value) + 1);
      }}
    >
      <IconPlus />
    </Button>
    <Button
      variant="outline"
      className="remove-btn"
      disabled={maxValue === 0 || value <= minValue || telephoneOrder === "ja"}
      onClick={() => {
        if (value > minValue) onChange(parseInt(value) - 1);
      }}
    >
      <IconMinus />
    </Button>
  </CounterInputContainer>
);

CounterInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onChange: PropTypes.func,
  isSmall: PropTypes.bool,
};

export default CounterInput;

export const SmallCounterInput = (props) => (
  <CounterInput {...props} isSmall={true} />
);
