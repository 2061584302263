import React from "react";
import { Field } from "react-final-form";
import FormControl from "../../../components/FormControl";
import Input from "../../../components/form-fields/Input";
import * as PropTypes from "prop-types";
import { Box, Label } from "theme-ui";
const RFFInputField = ({
  label = null,
  name,
  type,
  placeholder = null,
  onChange,
  icon,
  style,
  value,
  disabled = false,
}) => {
  return (
    <Field name={name} initialValue={value}>
      {({ input, meta }) => {
        return (
          <FormControl className="form-control">
            <Box>
              {label && (
                <Label
                  sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}
                >
                  {icon && icon}
                  {label}
                </Label>
              )}

              <Input
                label={placeholder}
                type={type}
                error={meta.touched && (meta.error || meta.submitError)}
                {...input}
                onChange={(e) => {
                  input.onChange(e);
                  if (onChange) onChange(e);
                }}
                value={value}
                style={style}
                disabled={disabled}
              />
            </Box>
          </FormControl>
        );
      }}
    </Field>
  );
};

RFFInputField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  icon: PropTypes.any,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default RFFInputField;
