import { Box, Button, Image, Styled, Text } from "theme-ui";
import React from "react";
import styled from "@emotion/styled";
import YPLogo from "../../../assets/images/yplogo.png";
import Container from "../../../components/Container";
import De_PDF from "../../../assets/documents/yukapoints_form_de.pdf";
import En_PDF from "../../../assets/documents/yukapoints_form_en.pdf";
import { useTranslation } from "react-i18next";
import i18n from "../../../utils/i18n";

const YPInfoContainer = styled(Container)`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 12rem 1fr;
  margin-top: 3rem;
  margin-bottom: 3rem;
  max-width: 64rem;

  .yp-logo-container {
  }

  .yp-details {
    .title {
      margin-bottom: 1.5rem;
    }

    .info-german,
    .info-english {
      margin-bottom: 2rem;
    }

    .info-english {
      font-style: normal;
    }

    .paragraph {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }

  ${(props) => props.theme.customBreakpoints[1]} {
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
    justify-content: center;
    .yp-logo-container {
      img {
        max-width: 12rem;
      }
    }

    .yp-details {
      .title {
        font-size: 1.25rem;
      }

      .paragraph {
        font-size: 1rem;
      }
    }
  }
`;

const YPInfo = () => {
  const { t } = useTranslation();
  const openDocument = () => {
    const pdfDoc = i18n.language === "en" ? En_PDF : De_PDF;
    window.open(pdfDoc);
  };
  return (
    <YPInfoContainer>
      <Box className="yp-logo-container">
        <Image src={YPLogo}></Image>
      </Box>
      <Box className="yp-details">
        <Styled.h4 className="title">{t("yukapoint_reward_store")}</Styled.h4>

        <Box className="info-english">
          <Text className="paragraph">{t("yukapoint_info_first")}</Text>
          <Text className="paragraph">{t("yukapoint_info_second")}</Text>
          <Text className="paragraph">{t("yukapoint_info_third")}</Text>

          <Button
            className="btn"
            variant="secondary"
            onClick={() => {
              openDocument();
            }}
          >
            {t("yukapoint_info_button")}
          </Button>
        </Box>
      </Box>
    </YPInfoContainer>
  );
};

export default YPInfo;
