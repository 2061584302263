import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./style.css";
import AuthProvider from "./contexts/AuthContext";
import CartProvider from "./contexts/CartContext";
import AlertProvider from "./contexts/AlertProvider";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <CartProvider>
        <AlertProvider>
          <App />
        </AlertProvider>
      </CartProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
