/* eslint-disable react/prop-types */
import React from "react";

const IconDocument = ({
  fill = "inherit",
  width = "24px",
  height = "24px",
  className = "",
  viewBox = "0 0 384 512",
  onClick,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    width={width}
    fill={fill}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />{" "}
  </svg>
);

export default IconDocument;
