import React from "react";

const IconSvFlag = ({ props, width = "15" }) => (
  <svg
    width={width}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 203.55 141.6"
  >
    <g fillRule="nonzero">
      <path
        fill="#006AA7"
        d="M11.19 0h181.17c6.15 0 11.19 5.03 11.19 11.19v119.22c0 6.16-5.04 11.19-11.19 11.19H11.19C5.03 141.6 0 136.57 0 130.41V11.19C0 5.03 5.03 0 11.19 0z"
      />
      <path
        fill="#FECC00"
        d="M0 56.51h58.9V0h28.58v56.51h116.07v28.58H87.48v56.51H58.9V85.09H0z"
      />
    </g>
  </svg>
);

export default IconSvFlag;
