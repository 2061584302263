import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider, Flex, Text } from "theme-ui";
import themes from "../../../themes";
import {
  getWeekdaysAfterDate,
  isDeliveryDateAfterWeekdays,
} from "../../../utils/helpers";
import IconAlert from "../../icons/icon-alert";
import IconClose from "../../icons/icon-close";

const DirectBuyCheckModal = ({
  showState,
  setShowState,
  data,
  cartProducts,
  setCartProducts,
  setShowProductModal,
}) => {
  const calculatedDate = useMemo(
    () => isDeliveryDateAfterWeekdays(data, getWeekdaysAfterDate()),
    [data, getWeekdaysAfterDate]
  );

  const { t } = useTranslation();
  if (!showState) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: themes.colors.opaqueBlack,
        zIndex: 100,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: themes.colors.white,
          borderRadius: "4px",
          width: ["90%", "80%", "60%", "40%"],
          overflow: "auto",
          zIndex: 100,
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "70px",
            backgroundColor: themes.colors.snowWhite,
            borderBottom: `1px solid ${themes.colors.darkGray}`,
            padding: "20px",
          }}
        >
          <Text sx={{ fontWeight: "bold", fontSize: 18 }}>
            {t("direct_buy_check")}
          </Text>
          <Box
            sx={{
              padding: 0,
              margin: 0,
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
            onClick={() => {
              const updatedItems = cartProducts.filter(
                (cartItem) => cartItem?.article_no !== data?.article_no
              );
              setCartProducts(updatedItems);
              setShowState(false);
            }}
          >
            <IconClose width="100%" height="100%" />
          </Box>
        </Flex>
        <Flex
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Flex
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <IconAlert height="3rem" width="3rem" fill={themes.colors.red} />
            <Box>
              <Text sx={{ fontWeight: "bold" }}>
                {t("direct_buy_check_info_text")}
              </Text>
              <Text
                sx={{
                  color: themes.colors.red,
                  fontWeight: "bold",
                }}
              >
                {data?.article_name}
              </Text>
            </Box>
          </Flex>
          <Divider />
          <Flex
            sx={{
              flexDirection: "column",
            }}
          >
            <Text sx={{ fontWeight: "bold" }}>{t("reason")}:</Text>
            {Number(data?.unit_price) >= data?.direct_buy_price && (
              <Text>&bull;{t("direct_buy_check_reason_price")} </Text>
            )}
            {!calculatedDate && (
              <Text>&bull;{t("direct_buy_check_reason_date")} </Text>
            )}
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              padding: "20px 0px",
              alignSelf: "flex-end",
              width: "100%",
            }}
          >
            <Box sx={{ justifyContent: "center", alignItems: "center" }}>
              <Text sx={{ fontWeight: "bold" }}>
                {t("do_you_still_want_to_add_product")}
              </Text>
            </Box>
            <Flex
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: ["10px", "20px"],
                padding: "20px 0px",
                width: "100%",
              }}
            >
              <Button
                sx={{
                  width: "20%",
                  borderRadius: "3px",
                  backgroundColor: themes.colors.danger,
                  color: themes.colors.white,
                }}
                type="button"
                onClick={() => {
                  setShowState(false);
                  if (setShowProductModal) return setShowProductModal(false);
                }}
              >
                {t("yes")}
              </Button>
              <Button
                sx={{
                  width: "20%",
                  borderRadius: "3px",
                  backgroundColor: themes.colors.primary,
                  color: themes.colors.white,
                  textTransform: "capitalize",
                }}
                type="submit"
                onClick={() => {
                  const updatedItems = cartProducts.filter(
                    (cartItem) => cartItem?.article_no !== data?.article_no
                  );
                  setCartProducts(updatedItems);
                  setShowState(false);
                  if (setShowProductModal) return setShowProductModal(false);
                }}
              >
                {t("no")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

DirectBuyCheckModal.propTypes = {
  showState: PropTypes.bool,
  setShowState: PropTypes.func,
  data: PropTypes.object,
  cartProducts: PropTypes.object,
  setCartProducts: PropTypes.func,
  setShowProductModal: PropTypes.func,
};

export default DirectBuyCheckModal;
