import React, { useCallback, useState } from "react";
import { Grid, Button, Flex, Box, Text, Link, Heading, Label } from "theme-ui";
import { useTranslation } from "react-i18next";
import themes from "../../../themes";
import LoaderModal from "../../LoaderModal";
import IconCheckDanger from "../../icons/icon-check-danger";
import IconCheckSucces from "../../icons/icon-check-succes";
import { Field } from "react-final-form";
import IconLinkArrow from "../../icons/icon-link-arrow";
import PropTypes from "prop-types";

const TradeINAgreement = ({ isSubmitting }) => {
  const { t } = useTranslation();
  const [agreementOneChecked, setAgreementOneChecked] = useState(false);
  const [agreementTwoChecked, setAgreementTwoChecked] = useState(false);

  const handleAgreementOneChange = useCallback(() => {
    setAgreementOneChecked(!agreementOneChecked);
  }, [agreementOneChecked]);
  const handleAgreementTwoChange = useCallback(() => {
    setAgreementTwoChecked(!agreementTwoChecked);
  }, [agreementTwoChecked]);

  return (
    <Flex
      sx={{
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        margin: "1em 0 2em 0",
        alignItems: "center",
        verticalAlign: "middle",
        padding: "0 24% 0 24%",
        "@media screen and (max-width: 1920px)": {
          padding: "0 4em 0 5.5em",
        },
        "@media screen and (max-width: 1450px)": {
          padding: "0 3em 0 2em",
        },
        "@media screen and (max-width: 570px)": {
          width: "98%",
          padding: "0 0.7em 0 0.7em",
        },
      }}
    >
      <Grid
        sx={{
          padding: [
            "2em 1em 2em 1em",
            "2.2em 3em 0.5em 3em",
            "3em 5em 4em 12em",
          ],
          height: "auto",
          width: "100%",
        }}
      >
        <Heading
          sx={{
            width: "100%",
            textAlign: "center",
            fontSize: ["1.6em", "2em", "2.7em"],
            fontWeight: 700,
            color: themes.colors.black,
          }}
        >
          {t("trade_in_step_agreement_title_one")}
        </Heading>
        <Text
          sx={{
            fontSize: "1em",
            color: themes.colors.black,
            "@media (max-width: 1024px)": {
              marginRight: "6rem",
            },
            "@media (max-width: 831px)": {
              marginRight: "3rem",
            },
            "@media (max-width: 642px)": {
              marginRight: "1.5rem",
            },
            "@media (max-width: 370px)": {
              marginRight: "0",
            },
          }}
        >
          {t("trade_in_step_agreement_description_one")}
        </Text>
        <Text
          sx={{
            fontSize: "1em",
            color: themes.colors.black,
            "@media (max-width: 1024px)": {
              marginRight: "6rem",
            },
            "@media (max-width: 831px)": {
              marginRight: "3rem",
            },
            "@media (max-width: 642px)": {
              marginRight: "1.5rem",
            },
            "@media (max-width: 370px)": {
              marginRight: "0",
            },
          }}
        >
          {t("trade_in_step_agreement_description_two")}
        </Text>
        <Text
          sx={{
            fontSize: "1em",
            color: themes.colors.black,
            "@media (max-width: 1024px)": {
              marginRight: "6rem",
            },
            "@media (max-width: 831px)": {
              marginRight: "3rem",
            },
            "@media (max-width: 642px)": {
              marginRight: "1.5rem",
            },
            "@media (max-width: 370px)": {
              marginRight: "0",
            },
          }}
        >
          {t("trade_in_step_agreement_description_three")}
        </Text>
        <Grid columns={[1, 1, 2, 2]} sx={{ marginTop: "2em", gap: 0 }}>
          <Grid>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckSucces
                width="22"
                height="20"
                color={themes.colors.success}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_succes_one")}
              </Text>
            </Flex>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckSucces
                width="22"
                height="20"
                color={themes.colors.success}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_succes_two")}
              </Text>
            </Flex>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckSucces
                width="22"
                height="20"
                color={themes.colors.success}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_succes_three")}
              </Text>
            </Flex>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckSucces
                width="22"
                height="20"
                color={themes.colors.success}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_succes_four")}
              </Text>
            </Flex>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckSucces
                width="22"
                height="20"
                color={themes.colors.success}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_succes_five")}
              </Text>
            </Flex>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckSucces
                width="22"
                height="20"
                color={themes.colors.success}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_succes_six")}
              </Text>
            </Flex>

            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckSucces
                width="22"
                height="20"
                color={themes.colors.success}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_succes_seven")}
              </Text>
            </Flex>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              "@media screen and (max-width: 725px)": {
                marginTop: "2em",
              },
            }}
          >
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckDanger
                width="22"
                height="20"
                color={themes.colors.danger}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_danger_one")}
              </Text>
            </Flex>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckDanger
                width="22"
                height="20"
                color={themes.colors.danger}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_danger_two")}
              </Text>
            </Flex>
            <Flex
              sx={{
                fontSize: "1em",
                color: themes.colors.black,
                verticalAlign: "middle",
                flexDirection: "row",
              }}
            >
              <IconCheckDanger
                width="22"
                height="20"
                color={themes.colors.danger}
              />

              <Text
                sx={{
                  fontSize: "1em",
                  color: themes.colors.black,
                  paddingLeft: "0.6em",
                }}
              >
                {t("trade_in_step_agreement_list_item_danger_three")}
              </Text>
            </Flex>
          </Grid>
        </Grid>
        <Flex sx={{ width: "100%", flexDirection: "column" }}>
          <Box>
            <Label
              sx={{
                margin: "2rem 0 1rem 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Field
                name="agreement_one"
                component="input"
                type="checkbox"
                onClick={handleAgreementOneChange}
                sx={{
                  width: ["40px", "40px"],
                  height: ["40px", "40px"],
                  border: `2px solid ${themes.colors.white}`,
                  borderRadius: "4px",
                  marginRight: "1rem",
                }}
              />
              <Text
                className="box-text"
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: 700,
                  color: themes.colors.black,
                }}
              >
                {t("trade_in_agreement_one_label")}
              </Text>
            </Label>
          </Box>
          <Box>
            <Label
              sx={{
                margin: "2rem 0 1rem 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Field
                name="agreement_two"
                component="input"
                type="checkbox"
                onClick={handleAgreementTwoChange}
                sx={{
                  width: ["40px", "40px"],
                  height: ["40px", "40px"],
                  border: `2px solid ${themes.colors.white}`,
                  borderRadius: "4px",
                  marginRight: "1rem",
                }}
              />
              <Text
                className="box-text"
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: 700,
                  color: themes.colors.black,
                }}
              >
                {t("trade_in_agreement_two_label")}
              </Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Link
                  href="/terms-and-conditions/"
                  sx={{
                    color: themes.colors.danger,
                    textDecoration: "underline",
                    marginLeft: "1em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  target={"_blank"}
                >
                  <IconLinkArrow
                    width="16px"
                    height="16px"
                    fill={themes.colors.danger}
                  />
                </Link>
              </div>
            </Label>
          </Box>
        </Flex>
        <Flex
          sx={{
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "end",
            marginBottom: 0,
            height: "100%",
            width: "100%",
            paddingBottom: "0.5rem",
          }}
        >
          <Button
            sx={{
              width: "10em",
              maxHeight: "3rem",
              padding: "0.5rem",
              backgroundColor: themes.colors.primary,
              borderRadius: 0,
            }}
            type={"submit"}
            disabled={
              isSubmitting || !(agreementOneChecked && agreementTwoChecked)
            }
          >
            {isSubmitting ? t("trade_submitting") : t("trade_in_submit_button")}
          </Button>
        </Flex>
      </Grid>
      <LoaderModal showState={isSubmitting} />
    </Flex>
  );
};

TradeINAgreement.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default TradeINAgreement;
