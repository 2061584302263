import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Box, Button, Flex, Styled } from "theme-ui";
import Logo from "../../../assets/images/logo.png";
import Container from "../../../components/Container";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const FooterContainer = styled(Box)`
  margin-bottom: 0;
  .container {
    display: flex;
    justify-content: space-between;
  }

  .top-footer-container {
    background-color: ${(props) => props.theme.colors.lightGray};
    padding: 2rem 0;

    .footer-block {
      padding: 0 1rem;

      .footer-block-title {
        font-family: "Raleway", san-serif;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      .link {
        display: block;
        padding: 0.3125rem 0;
        font-size: 1rem;
      }
    }

    ${(props) => props.theme.customBreakpoints[1]} {
      .container {
        flex-wrap: wrap;

        .footer-block {
          margin-bottom: 2.5rem;

          &:first-of-type {
            width: 100%;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          .footer-block-title {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  .bottom-footer-container {
    background-color: ${(props) => props.theme.colors.primary};
    width: 100%;
    color: ${(props) => props.theme.colors.white};
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;

    .link {
      display: block;
      padding: 0.75rem 1rem;
      font-size: 1rem;
    }

    ${(props) => props.theme.customBreakpoints[1]} {
      .container {
        flex-direction: column-reverse;
        text-align: center;

        .link-block {
          justify-content: center;
        }
      }
    }
  }
`;

const Image = styled.img`
  display: block;
  height: 3rem;
  width: auto;
  margin-bottom: 2rem;
`;

const Footer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const legalAggrementRoute = useCallback(
    (path) => {
      history.push(`/${path}/`);
    },
    [history]
  );

  return (
    <FooterContainer>
      <Box className="top-footer-container">
        <Container className="container">
          <Box className="footer-block">
            <Image className="brand-image" src={Logo} />
            <Button
              variant="secondary"
              onClick={() => window.open("mailto:info@yukatel.de", "_self")}
            >
              <span>{t("message_us")}</span>
            </Button>
          </Box>
          <Box className="footer-block">
            <Styled.h5 className="footer-block-title">
              {t("business_areas")}
            </Styled.h5>
            <span className="link">
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={"https://yukatel.de"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("mobile_wholesale")}
              </a>
            </span>
            <span className="link">
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={"https://www.routecontrol.de"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("route_control")}
              </a>
            </span>
            <span className="link">
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={"https://nextwawi.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("next_wawi")}
              </a>
            </span>
            <span className="link">
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={"https://www.m2m-unity.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("m2m_unity")}
              </a>
            </span>
            <span className="link">
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={"https://www.yukamo.de"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("yukamo")}
              </a>
            </span>
          </Box>
          <Box className="footer-block">
            <Styled.h5 className="footer-block-title">
              {t("contact_us")}
            </Styled.h5>
            <span className="link">{t("yukatel_gmbh")}</span>
            <span className="link">
              Yuka-Platz 1, 63303 Dreieich, {t("germany")}
            </span>
            <span
              className="link"
              style={{ cursor: "pointer" }}
              onClick={() => window.open("tel: +49 (0) 69 83 83 25 0", "_self")}
            >
              Tel: +49 (0) 69 83 83 25 0
            </span>
            <span
              className="link"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("fax:+49 (0) 69 83 83 25 252", "_self")
              }
            >
              Fax: +49 (0) 69 83 83 25 252
            </span>
            <span
              className="link"
              style={{ cursor: "pointer" }}
              onClick={() => window.open("mailto:info@yukatel.de", "_self")}
            >
              info@yukatel.de
            </span>
          </Box>
        </Container>
      </Box>
      <Box className="bottom-footer-container">
        <Container className="container">
          <span className="link">
            Yukatel GmbH {new Date().getFullYear()}, {t("all_right_reserved")}
          </span>
          <Flex className="link-block">
            <span
              className="link"
              style={{ cursor: "pointer" }}
              onClick={() => legalAggrementRoute("imprint")}
            >
              {t("imprint")}
            </span>
            <span
              className="link"
              style={{ cursor: "pointer" }}
              onClick={() => legalAggrementRoute("data-protection")}
            >
              {t("data_protection")}
            </span>
            <span
              className="link"
              style={{ cursor: "pointer" }}
              onClick={() => legalAggrementRoute("terms-and-conditions")}
            >
              {t("terms_and_condition")}
            </span>
          </Flex>
        </Container>
      </Box>
    </FooterContainer>
  );
};

export default Footer;
