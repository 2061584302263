import styled from "@emotion/styled";
import { Box, Button, Flex, Heading, Text } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
const Container = styled(Box)`
  text-align: ;
  max-width: 24rem;

  .modal-title {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      height: 1.5rem;
      width: 1.5rem;
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 0.5rem;
    }
  }

  .modal-body {
    margin-bottom: 2rem;
  }

  .modal-footer {
    justify-content: center;
    align-items: center;
  }
`;
const ConfirmationModal = ({
  title,
  titleStyle,
  question,
  positiveButton,
  negativeButton,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Heading className="modal-title" sx={titleStyle}>
        {title}
      </Heading>
      <Box className="modal-body">
        <Text>{question}</Text>
      </Box>
      <Flex className="modal-footer">
        {positiveButton && (
          <Button
            variant={"primary"}
            onClick={positiveButton.action}
            sx={positiveButton.style}
          >
            <Flex sx={{ justifyContent: "center" }}>{positiveButton.icon}</Flex>
            <Flex sx={{ paddingLeft: "0.6em", justifyContent: "center" }}>
              {positiveButton.content || t("ok")}
            </Flex>
          </Button>
        )}
        {negativeButton && (
          <Button
            variant={"secondary"}
            onClick={negativeButton.action}
            sx={negativeButton.style}
          >
            <Flex sx={{ justifyContent: "center" }}>{negativeButton.icon}</Flex>
            <Flex sx={{ paddingLeft: "0.6em", justifyContent: "center" }}>
              {negativeButton.content || t("no")}
            </Flex>
          </Button>
        )}
      </Flex>
    </Container>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  positiveButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    content: PropTypes.any,
    style: PropTypes.object,
    icon: PropTypes.any,
  }).isRequired,
  negativeButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    content: PropTypes.any,
    style: PropTypes.object,
    icon: PropTypes.any,
  }).isRequired,
  titleStyle: PropTypes.object,
};

export default ConfirmationModal;
