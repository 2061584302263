/**  @jsx jsx */
import { Box, jsx, Spinner } from "theme-ui";
import Proptypes from "prop-types";

// eslint-disable-next-line react/prop-types
const WithLoader = ({
  children,
  isLoading,
  height,
  width,
  position,
  spinnerSize,
  ...props
}) => {
  const customStyle = {};
  if (height) {
    customStyle.height = height;
  }
  if (width) {
    customStyle.width = width;
  }
  if (position) {
    customStyle.position = position;
  }

  if (isLoading && isLoading)
    return (
      <Box
        sx={{
          ...customStyle,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        {...props}
      >
        <Spinner size={spinnerSize} />
      </Box>
    );

  return children;
};

WithLoader.propTypes = {
  isLoading: Proptypes.bool,
  height: Proptypes.string,
  width: Proptypes.string,
  position: Proptypes.string,
};

export default WithLoader;
