import styled from "@emotion/styled";
import { Box } from "theme-ui";

const ProductImageModal = styled(Box)`
  margin: -20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.125), 1px 4px rgba(0, 0, 0, 0.075);

  .product-img-container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .img {
      max-height: 80vh;
      height: auto;
      max-width: 80vw;
      width: auto;
      margin-bottom: -0.375rem;
    }
  }

  .icon-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;

    height: 2.5rem;
    width: 2.5rem;

    .svg-icon {
      fill: white;
      height: 2rem;
      width: 2rem;
    }
  }
`;
export default ProductImageModal;
