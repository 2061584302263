import React from "react";

const IconExchange = ({
  fill = "inherit",
  width = "3rem",
  height = "3rem",
  className = "",
  viewBox = "0 0 24 24",
}) => (
  <svg
    fill={fill}
    viewBox="0 0 24 24"
    width={width}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 24 16 L 24 19.429 C 24 19.583 23.958 19.717 23.873 19.83 C 23.788 19.943 23.688 20 23.571 20 L 5.143 20 L 5.143 23.429 C 5.143 23.583 5.1 23.717 5.016 23.83 C 4.931 23.943 4.83 24 4.714 24 C 4.607 24 4.5 23.94 4.393 23.821 L 0.121 18.107 C 0.04 18 0 17.869 0 17.714 C 0 17.548 0.04 17.411 0.121 17.304 L 4.406 11.589 C 4.487 11.482 4.589 11.429 4.714 11.429 C 4.83 11.429 4.931 11.485 5.016 11.598 C 5.1 11.711 5.143 11.845 5.143 12 L 5.143 15.429 L 23.571 15.429 C 23.688 15.429 23.788 15.485 23.873 15.598 C 23.958 15.711 24 15.845 24 16 Z M 24 6.286 C 24 6.452 23.96 6.589 23.879 6.696 L 19.594 12.411 C 19.513 12.518 19.411 12.571 19.286 12.571 C 19.17 12.571 19.069 12.515 18.984 12.402 C 18.9 12.289 18.857 12.155 18.857 12 L 18.857 8.571 L 0.429 8.571 C 0.313 8.571 0.212 8.515 0.127 8.402 C 0.042 8.289 0 8.155 0 8 L 0 4.571 C 0 4.417 0.042 4.283 0.127 4.17 C 0.212 4.057 0.313 4 0.429 4 L 18.857 4 L 18.857 0.571 C 18.857 0.405 18.897 0.268 18.978 0.161 C 19.058 0.054 19.161 0 19.286 0 C 19.393 0 19.5 0.06 19.607 0.179 L 23.879 5.875 C 23.96 5.982 24 6.119 24 6.286 Z" />
  </svg>
);

export default IconExchange;
