import React from "react";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { changeLanguage, languages, languagesFlag } from "../utils/i18n";
import Menu from "./Menu";
import themes from "../themes";
const LanguageSelector = ({
  borderColor = null,
  rightIconColor = themes.colors.white,
  textColor = null,
}) => {
  const { i18n } = useTranslation();

  return (
    <Menu
      label={i18n.language.toUpperCase()}
      Icon={languagesFlag[i18n.language]}
      borderColor={borderColor}
      textColor={textColor}
      rightIconColor={rightIconColor}
      items={Object.keys(languages).map((key) => ({
        key: key,
        label: languages[key],
        onClick: () => changeLanguage(key),
      }))}
    />
  );
};

LanguageSelector.propTypes = {
  borderColor: PropTypes.string,
  rightIconColor: PropTypes.string,
  textColor: PropTypes.string,
};

export default LanguageSelector;
