/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import themes from "../themes";

// eslint-disable-next-line react/prop-types
const Card = ({ sx, ...props }) => (
  <Box
    sx={{
      boxShadow: "card",
      padding: "1.5rem",
      width: "100%",
      backgroundColor: themes.colors.white,
      ...sx,
    }}
    {...props}
  />
);

export default Card;
