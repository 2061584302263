import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { createTradeIn } from "../../../apis";
import {
  PageRoutes,
  responseStatusSuccesCode,
  tradeInAuthInitialValues,
} from "../../../constants";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useModal } from "../../../contexts/ModalContext";
import themes from "../../../themes";
import { convertDateISOString } from "../../../utils/helpers";
import { tradeInAuthFormValidate } from "../../../utils/validators";
import ConfirmationModal from "../../ConfirmationModal";
import TradeINAgreement from "./TradeINAgreement";
import TradeINProducts from "./TradeINProducts";

const StepContentMainContainer = styled.div`
  width: 100%;
`;

const StepElement = ({ children, isActive, ...restProps }) => {
  if (!isActive) return null;
  return React.cloneElement(children, restProps);
};

const StepsArr = [
  { component: <TradeINProducts key="step3" />, isActive: false },
  { component: <TradeINAgreement key="step4" />, isActive: false },
];

const TradeInComponent = (props) => {
  const history = useHistory();
  const { authenticated } = useAuthContext();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { formStep, setFormStep } = props;
  const [cartProducts, setCartProducts] = useState([]);
  const [errProducts, setErrProducts] = useState([]);

  const nextStep = useCallback(() => setFormStep((prev) => prev + 1), [
    setFormStep,
  ]);

  const previousStep = useCallback(
    (e) => {
      if (e !== undefined) {
        e.preventDefault();
      }
      setFormStep((prev) => (prev > 0 ? prev - 1 : 0));
    },
    [setFormStep]
  );

  const [mutateCreate, { isLoading }] = useMutation(createTradeIn, {
    onError: (err) => {
      if (err.response.data.errors?.invalid_direct_buy_items)
        setErrProducts(err.response.data.errors.invalid_direct_buy_items);
    },
  });
  const onSubmit = useCallback(
    async (values) => {
      const cartProductsUpdate = convertDateISOString(cartProducts);

      const updateEAN = cartProductsUpdate.map((product) => {
        return {
          ...product,
          european_article_number: Number(product.european_article_number),
        };
      });

      const updatedValues = {
        ...values,
        products: updateEAN,
        authenticated: authenticated,
      };

      const response = await mutateCreate(updatedValues);
      if (response && response.status === responseStatusSuccesCode) {
        onConfirmModal(t("trade_in_form_submit_succes_modal_message"), true);
      } else {
        onConfirmModal(t("trade_in_form_submit_error_modal_message"), false);
      }
    },
    [authenticated, cartProducts, mutateCreate, t]
  );

  const validate = (values) =>
    tradeInAuthFormValidate(values, t("field_required"));

  const onConfirmModal = useCallback(
    (err, submitStatus) => {
      showModal(({ hideModal }) => {
        return (
          <ConfirmationModal
            title={t("confirm")}
            question={err}
            positiveButton={{
              content: t("yes"),
              style: {
                width: "10em",
                backgroundCoolor: `${themes.colors.danger} !important`,
              },
              action: () => {
                if (submitStatus) {
                  history.push(PageRoutes.HOME);
                  hideModal();
                } else {
                  setFormStep(0);
                  hideModal();
                }
              },
            }}
          />
        );
      });
    },
    [showModal, t, history, setFormStep]
  );

  return (
    <>
      <StepContentMainContainer>
        <Form
          initialValues={tradeInAuthInitialValues}
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: themes.colors.searchBG,
              }}
              onSubmit={handleSubmit}
            >
              <>
                {StepsArr.map((step, index) => (
                  <StepElement
                    formValues={values}
                    formStep={formStep}
                    key={`stepElement${index}`}
                    nextStep={() => nextStep(values)}
                    previousStep={previousStep}
                    setFormStep={setFormStep}
                    cartProducts={cartProducts}
                    errProducts={errProducts}
                    setCartProducts={setCartProducts}
                    isSubmitting={isLoading}
                    isActive={index === formStep}
                    handleStepAlertModal={onConfirmModal}
                    isSubmit={index === StepsArr.length - 1}
                  >
                    {step.component}
                  </StepElement>
                ))}
              </>
            </form>
          )}
        />
      </StepContentMainContainer>
    </>
  );
};

TradeInComponent.propTypes = {
  formStep: PropTypes.number,
  setFormStep: PropTypes.func,
};

export default TradeInComponent;
