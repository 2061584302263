import { Box, Button, Flex, Heading, Text } from "theme-ui";
import IconClose from "../../../components/icons/icon-close";
import theme from "../../../themes";
import MobileDrawerMenu from "./MobileDrawerMenu";
import IconLogout from "../../../components/icons/icon-logout";
import IconPhone from "../../../components/icons/icon-phone";
import IconEmail from "../../../components/icons/icon-email";
import { changeLanguage, languages } from "../../../utils/i18n";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getCustomerProfile } from "../../../apis";

const InvoiceMobileDrawer = ({
  showMobile,
  setShowMobile,
  logoutConfirmation,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { data: profile } = useQuery("profile", getCustomerProfile);

  const NAV_LINKS = [
    {
      name: t("home"),
      pathname: "/home",
    },
    {
      name: t("invoices"),
      pathname: "/account/invoices",
    },
    {
      name: t("contact"),
      subMenus: [
        {
          type: "anchor",
          text: "+49 (0) 69 83 83 25 0",
          icon: <IconPhone />,
          href: "tel:+49 (0) 69 83 83 25 0",
        },
        {
          type: "anchor",
          text: "info@yukatel.de",
          icon: <IconEmail />,
          href: "mailto:info@yukatel.de",
        },
      ],
    },
    {
      name: t("profile"),
      subMenus: [
        {
          type: "button",
          text: t("logout"),
          icon: <IconLogout />,
          onClick: () => {
            logoutConfirmation();
          },
        },
      ],
    },
    {
      name: t("language"),
      subMenus: Object.keys(languages).map((key) => ({
        type: "button",
        text: languages[key],
        isActive: i18n.language === key,
        onClick: () => changeLanguage(key),
      })),
    },
  ];

  return (
    <Box className={`mobile-nav-wrapper ${showMobile ? "opened" : ""}`}>
      <Box className="mobile-nav-container">
        <Button
          variant="secondaryIconButton"
          className="icon-btn"
          onClick={() => {
            setShowMobile((prev) => !prev);
          }}
        >
          <IconClose />
        </Button>
        <Heading
          style={{
            padding: "1rem 2.5rem 1rem 1rem",
            color: theme.colors.primary,
            fontSize: "1.25rem",
          }}
        >
          <Text style={{ fontWeight: 400, display: "inline-block" }}>
            Hello,
          </Text>{" "}
          {profile?.data?.name}
        </Heading>
        <Flex className="nav-links">
          {NAV_LINKS.map(({ name, pathname, subMenus }, key) => {
            return (
              <MobileDrawerMenu
                menuText={name}
                pathname={pathname}
                subMenus={subMenus}
                setShowMobile={setShowMobile}
                key={key}
              />
            );
          })}
        </Flex>
      </Box>
      <Box
        className="outside-click"
        onClick={() => {
          setShowMobile((prev) => !prev);
        }}
      />
    </Box>
  );
};

export default InvoiceMobileDrawer;
