import React from "react";
import { Field } from "react-final-form";
import FormControl from "../../../components/FormControl";
import {
  InputContainer,
  InputField,
} from "../../../components/form-fields/Input";
import * as PropTypes from "prop-types";
import { formattedAddress } from "../../../utils/address";
import i18next from "i18next";

const RFFSelectField = ({ label = null, name, options, disabled = false }) => {
  return (
    <FormControl className="form-control">
      {label && <label>{label}</label>}
      <Field name={name}>
        {({ input, meta }) => (
          <InputContainer>
            <InputField>
              <select
                name={name}
                {...input}
                disabled={disabled}
                className={"select"}
              >
                <option key={"opt123"} value={""}>
                  {i18next.t("please_select")}
                </option>
                {options?.map(
                  ({ address_id, street, no, postal, city, country }, key) => (
                    <option key={key} value={address_id}>
                      {formattedAddress(street, no, postal, city, country)}
                    </option>
                  )
                )}
              </select>
            </InputField>
            {meta.error && meta.touched && (
              <span style={{ color: "red", fontSize: "80%" }}>
                {meta.error}
              </span>
            )}
          </InputContainer>
        )}
      </Field>
    </FormControl>

    // <Field name={name}>
    //   {({ input, meta }) => {
    //     const error = meta.touched && (meta.error || meta.submitError);
    //     return (
    //       <SelectContainer
    //         className={
    //           error ? "select-container has-error" : "select-container"
    //         }
    //       >
    //         <FormControl className="form-control">
    //           {label && <label>{label}</label>}
    //           <select
    //             className="select"
    //             autoComplete="off"
    //             name={name}
    //             {...input}
    //             onChange={(e) => {
    //               input.onChange(e);
    //               onChange(e);
    //             }}
    //           >
    //
    //             <option key={"opt123"}>{i18next.t("please_select")}</option>
    //             {options?.map(
    //               ({ address_id, street, no, postal, city, country }, key) => (
    //                 <option key={key} value={address_id}>
    //                   {formattedAddress(street, no, postal, city, country)}
    //                 </option>
    //               )
    //             )}
    //           </select>
    //         </FormControl>
    //         <span className="error">{error}</span>
    //       </SelectContainer>
    //     );
    //   }}
    // </Field>
  );
};

RFFSelectField.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default RFFSelectField;
