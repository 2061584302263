/* eslint-disable react/prop-types */
import React from "react";

const IconEmail = ({
  fill = "inherit",
  width = "24px",
  height = "24px",
  className = "",
  viewBox = "0 0 24 24",
  onClick,
}) => (
  <svg
    onClick={onClick}
    width={width}
    fill={fill}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M20,4H4C2.897,4,2,4.897,2,6v12c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V6C22,4.897,21.103,4,20,4z M20,6v0.511 l-8,6.223L4,6.512V6H20z M4,18V9.044l7.386,5.745C11.566,14.93,11.783,15,12,15s0.434-0.07,0.614-0.211L20,9.044L20.002,18H4z" />{" "}
  </svg>
);

export default IconEmail;
