import React, {
  Fragment,
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import styled from "@emotion/styled";
import { Badge, Box, Button, Flex, Heading, Text } from "theme-ui";
import Logo from "../../../assets/images/logo.png";
import YPWhite from "../../../assets/images/YP-white.png";
import IconShoppingCart from "../../../components/icons/icon-shopping-cart";
import IconPhone from "../../../components/icons/icon-phone";
import IconEmail from "../../../components/icons/icon-email";
import IconUser from "../../../components/icons/icon-user";
import IconEdit from "../../../components/icons/icon-edit";
import IconLogout from "../../../components/icons/icon-logout";
import Container from "../../../components/Container";
import Menu from "../../../components/Menu";
import { transparentize } from "@theme-ui/color";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useLogout } from "../../../contexts/AuthContext";
import { useQueryCache } from "react-query";
import LanguageSelector from "../../../components/LanguageSelector";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../contexts/ModalContext";
import { useCartActions } from "../../../contexts/CartContext";
import IconHamburger from "../../../components/icons/icon-hamburger";
import MobileDrawer from "./MobileDrawer";
import authenticated from "../../../routes/authenticated";
import { MobileCartContext } from "../../../contexts/MobileCartProvider";
import { useCartState } from "../../../contexts/CartContext";
import IconList from "../../../components/icons/icon-list";
import IconNew from "../../../components/icons/icon-new";
import themes from "../../../themes";
import { useTradeInCartContext } from "../../../contexts/TradeInCartContext";
import IconRightLeft from "../../../components/icons/icon-right-left";
import {
  downloadPriceList,
  getCustomerProfile,
  getTradeInOrder,
} from "../../../apis";
import IconPriceList from "../../../components/icons/icon-price-list";
import IconDownload from "../../../components/icons/icon-download";
import HeaderTradeINLogo from "../../../assets/images/yukatel_tradein_logo_single_480.png";
import IconPlus from "../../../components/icons/icon-plus";
import IconGrid from "../../../components/icons/icon-grid";
import { PageRoutes, TradeIn_Status } from "../../../constants";
import { useQuery } from "react-query";
import IconHome from "../../../components/icons/icon-home";
import { getItem, setItem } from "../../../utils/storage";

const HeaderContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 0.5rem 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.125);

  .main-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mobile-toggle-block {
      display: none;
    }
  }

  .nav-links {
    display: flex;
    font-family: "Raleway", san-sarif;
    font-size: 0.875rem;

    .nav-item {
      padding: 0.5rem;
      margin: 0 0.5rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.colors.white};
      border-radius: 0.25rem;
      position: relative;
      text-decoration: none;

      > span {
        text-transform: uppercase;
      }

      .dropdown-label-block {
        text-transform: uppercase;
      }

      &.icon-link {
        margin: 0;
        padding: 1rem;

        svg {
          fill: ${(props) => props.theme.colors.white};
        }
      }

      &.dropdown-link {
        margin: 0;
        padding: 0;

        .dropdown-menu {
          padding: 1rem;
          height: auto;
        }

        .dropdown-label-block {
          font-size: 0.875rem;
        }

        &.language-dropdown {
          .dropdown-menu {
            padding-right: 1.75rem;
          }
        }
      }

      &:hover {
        background-color: ${(props) =>
          transparentize(props.theme.colors.white, 0.92)};
      }

      &.active {
        &::after {
          display: block;
          content: "";
          position: absolute;
          left: 0;
          bottom: -0.5rem;
          height: 3px;
          width: 100%;
          background: ${(props) => props.theme.colors.secondary};
        }
      }

      .dropdown-menu {
        .dropdown-content {
          color: ${(props) => props.theme.colors.primary};
          font-family: "Roboto", sans-sarif;
        }

        .menu-item-icon {
          fill: ${(props) => props.theme.colors.primary};
          margin-right: 0.75rem;
          height: 1.25rem;
          width: 1.25rem;
        }
      }
    }
  }

  .mobile-nav-container {
    display: none;
  }

  // media query

  @media (max-width: 1024px) {
    padding: 0.75rem 0;

    .main-nav-container {
      .mobile-toggle-block {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page-title {
          color: ${(props) => props.theme.colors.white};
          font-size: 1.25rem;
          margin-left: 1rem;
        }

        .mobile-toggle {
          display: flex;
          padding: 0.25rem 0.5rem;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .svg-icon {
            fill: ${(props) => props.theme.colors.white};
            width: 1.5rem;
            height: 1.5rem;
          }

          &.active {
            background-color: ${(props) =>
              transparentize(props.theme.colors.white, 0.92)};
          }

          &:after {
            display: none;
          }
        }
      }

      .nav-links,
      .brand-image {
        display: none;
      }
    }

    .mobile-nav-wrapper {
      position: fixed;
      left: -100%;
      top: 0;
      z-index: 100;
      height: 100%;
      width: 100%;
      transition: all 0.4s ease;

      display: flex;

      &.opened {
        left: 0;
      }
    }

    .outside-click {
      flex: 1 1 auto;
      background: transparent;
    }

    .mobile-nav-container {
      display: flex;
      flex-direction: column;
      background-color: ${(props) => props.theme.colors.white};
      box-shadow: ${(props) => props.theme.shadows.default};
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      padding-top: 1rem;
      padding-bottom: 2rem;
      max-width: 20rem;
      flex: 1 1 20rem;
      position: relative;

      .icon-btn {
        position: absolute;
        right: 0.5rem;
        top: 1rem;
      }

      .nav-links {
        flex-direction: column;

        .nav-item {
          color: ${(props) => props.theme.colors.primaryText};
          align-items: flex-start;
          font-weight: 600;
          padding: 1rem;
          font-size: 1rem;
          margin: 0;

          > span {
            text-transform: capitalize;
          }

          &:hover {
            background-color: ${(props) =>
              transparentize(props.theme.colors.secondary, 0.92)};
          }

          &.active {
            color: ${(props) => props.theme.colors.secondary};
            background-color: ${(props) =>
              transparentize(props.theme.colors.secondary, 0.92)};

            &::after {
              display: none;
            }
          }
        }

        .accordion-container {
          flex-direction: column;
          padding-bottom: 0.5rem;

          .accordion-title {
            display: flex;
            position: relative;

            .svg-icon {
              transition: all 0.3s ease;
              position: absolute;
              top: 1rem;
              right: 1rem;
            }
          }

          .accordion-content {
            flex-direction: column;
            padding-left: 1rem;
            display: none;

            .nav-sub-link {
              padding: 0.75rem 1rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              display: flex;
              align-items: center;
              text-decoration: none;
              color: currentColor;

              .svg-icon {
                margin-right: 0.5rem;
              }

              &:hover {
                background-color: ${(props) =>
                  transparentize(props.theme.colors.secondary, 0.92)};
              }

              &.active {
                color: ${(props) => props.theme.colors.secondary};

                .svg-icon {
                  fill: currentColor;
                }
              }
            }
          }

          &.opened {
            .accordion-title {
              color: ${(props) => props.theme.colors.secondary};

              .svg-icon {
                transform: rotate(180deg);
              }
            }

            .accordion-content {
              display: flex;
            }
          }
        }
      }
    }
  }

  ${(props) => props.theme.customBreakpoints[1]} {
    .main-nav-container {
      .mobile-toggle-block {
        .page-title {
          font-size: 1.5rem;
        }

        .mobile-toggle {
          .svg-icon {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }

  ${(props) => props.theme.customBreakpoints[0]} {
    position: sticky;
    top: 0;
    z-index: 100;
  }
`;

const Image = styled.img`
  display: block;
  height: 2.5rem;
  width: auto;
`;

const ConfirmationModal = styled(Box)`
  text-align: center;
  max-width: 24rem;

  .modal-title {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;

    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      height: 1.5rem;
      width: 1.5rem;
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 0.5rem;
    }
  }

  .modal-body {
    margin-bottom: 2rem;
  }

  .modal-footer {
    justify-content: center;
    align-items: center;

    button {
      min-width: 10rem;
      margin: 0 1rem;
    }
  }
`;

const NAV_LINKS = [
  {
    name: "home",
    pathname: PageRoutes.HOME,
  },
  {
    name: "shop",
    pathname: PageRoutes.SHOP,
  },
  {
    name: "trade_in",
    pathname: PageRoutes.TRADE_IN_HOME,
    img: HeaderTradeINLogo,
  },
  {
    name: "account",
    pathname: PageRoutes.ACCOUNT,
  },
];

const Header = () => {
  const { t } = useTranslation();
  const { openModal, productCount, isCartEnabled } = useTradeInCartContext();
  const { clearCart } = useCartActions();
  const logout = useLogout();
  const queryCache = useQueryCache();
  let history = useHistory();
  const { pathname } = useLocation();
  const { items } = useCartState();
  const { data: profile } = useQuery("profile", getCustomerProfile);

  useEffect(() => {
    if (profile) {
      setItem("customer_number", profile.data.customer_number);
    }
  }, [profile]);

  const viewProfile = useCallback(() => history.push(PageRoutes.PROFILE), [
    history,
  ]);
  const goToPriceList = useCallback(() => history.push(PageRoutes.PRICE_LIST), [
    history,
  ]);

  const viewRmaList = useCallback(() => history.push(PageRoutes.RMA), [
    history,
  ]);
  const createRma = useCallback(() => history.push(PageRoutes.RMA_CREATE), [
    history,
  ]);
  const newOffer = useCallback(() => history.push(PageRoutes.TRADE_IN), [
    history,
  ]);
  const oldOffer = useCallback(
    () => history.push(PageRoutes.TRADE_IN_OLD_OFFER),
    [history]
  );
  const homeTradeIn = useCallback(
    () => history.push(PageRoutes.HOME_TRADE_IN),
    [history]
  );

  const changePassword = useCallback(
    () => history.push(PageRoutes.CHANGE_PASSWORD),
    [history]
  );

  const { showModal } = useModal();
  const [showMobile, setShowMobile] = useState(false);
  const [, setShowMobileCart] = useContext(MobileCartContext);
  const currentPathname = window.location.pathname;
  const { data } = useQuery(
    [TradeIn_Status.STATUS_COUNTER_OFFER.value],
    getTradeInOrder
  );

  const displayedLength = useMemo(() => {
    const oldOffersLength = data?.data?.length;
    return oldOffersLength > 10 ? "10+" : oldOffersLength;
  }, [data]);

  const logoutConfirmation = useCallback(() => {
    return showModal(({ hideModal }) => (
      <ConfirmationModal>
        <Heading className="modal-title">
          <IconLogout className="menu-item-icon" />
          {t("logout")}
        </Heading>
        <Box className="modal-body">
          <Text>{t("your_orders_have_not_been_processed")}</Text>
        </Box>
        <Flex className="modal-footer">
          <Button
            variant={"secondary"}
            onClick={hideModal}
            sx={{ minWidth: "10rem", textTransform: "uppercase" }}
          >
            {t("back")}
          </Button>
          <Button
            variant={"primary"}
            sx={{ minWidth: "10rem", textTransform: "uppercase" }}
            onClick={() => {
              clearCart();
              logout();
              queryCache.clear();
              hideModal();
            }}
          >
            {t("logout")}
          </Button>
        </Flex>
      </ConfirmationModal>
    ));
  }, [showModal, t, clearCart, logout, queryCache]);
  return (
    <HeaderContainer as="nav" className="header-container">
      {/* Main Navbar */}
      <Container className="main-nav-container">
        {/* Only visible on mobile views */}
        <Box className="mobile-toggle-block">
          <Flex style={{ alignItems: "center" }}>
            <a
              className="nav-item mobile-toggle"
              onClick={(e) => {
                e.preventDefault();
                setShowMobile((prev) => !prev);
              }}
            >
              <IconHamburger />
            </a>
            <Heading className="page-title">
              {
                authenticated.find((item) =>
                  item.isNested
                    ? pathname.startsWith(item.to)
                    : pathname === item.to
                )?.name
              }
            </Heading>
          </Flex>
          {!pathname?.includes("trade-in") && (
            <a
              className={"nav-item icon-link mobile-toggle"}
              onClick={(e) => {
                if (
                  [PageRoutes.SHOP, PageRoutes.REDEEM_YP].includes(pathname)
                ) {
                  e.preventDefault();
                  setShowMobileCart(true);
                  return;
                }

                history.push(PageRoutes.CART);
              }}
            >
              <IconShoppingCart />
              <Badge variant="cartBadge">{items?.length}</Badge>
            </a>
          )}
          {isCartEnabled && (
            <a
              className={"nav-item icon-link mobile-toggle"}
              onClick={openModal}
            >
              <IconRightLeft />
              <Badge variant="cartBadge">{productCount}</Badge>
            </a>
          )}
        </Box>
        <Image
          className="brand-image"
          src={Logo}
          onClick={() => history.push(PageRoutes.HOME)}
        />
        <Flex className="nav-links">
          {NAV_LINKS.map(({ name, pathname, img }, key) => {
            if (
              name === "trade_in" &&
              currentPathname === PageRoutes.TRADE_IN
            ) {
              pathname = PageRoutes.TRADE_IN;
            }
            return (
              <Fragment key={key}>
                <Flex
                  sx={{
                    "@media screen and (max-width: 1269px)": {
                      display:
                        name === "trade_in" &&
                        currentPathname === PageRoutes.TRADE_IN &&
                        "none",
                    },
                  }}
                >
                  {img ? (
                    <Box sx={{ width: "140px" }}>
                      <a className="nav-item dropdown-link">
                        <Menu
                          label={
                            <Flex
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "bold",
                                textTransform: "none",
                                color: themes.colors.white,
                              }}
                            >
                              <Text
                                sx={{
                                  zIndex: 1,
                                }}
                              >
                                TradeIN
                              </Text>
                              <div
                                style={{
                                  marginTop: "-15px",
                                  marginLeft: "-6px",
                                  width: "25px",
                                  height: "25px",
                                  backgroundColor: themes.colors.secondary,
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "white",
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                  zIndex: 0,
                                }}
                              >
                                {displayedLength}
                              </div>
                            </Flex>
                          }
                          showDropdownIcon={false}
                          contentStyle={{ minWidth: "14rem" }}
                          items={[
                            {
                              label: (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconHome className="menu-item-icon" />
                                  {t("home")}
                                </div>
                              ),
                              onClick: homeTradeIn,
                            },
                            {
                              label: (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconPlus className="menu-item-icon" />
                                  {t("new_offer")}
                                </div>
                              ),
                              onClick: newOffer,
                            },
                            {
                              label: (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginRight: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconGrid className="menu-item-icon" />
                                    {t("overview")}
                                  </div>
                                  <div
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      backgroundColor: themes.colors.secondary,
                                      borderRadius: "50%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: themes.colors.white,
                                      fontWeight: "bold",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {displayedLength}
                                  </div>
                                </div>
                              ),
                              onClick: oldOffer,
                            },
                          ]}
                        />
                      </a>
                    </Box>
                  ) : (
                    <NavLink
                      to={pathname}
                      className={"nav-item"}
                      activeClassName={"active"}
                      key={key}
                    >
                      <span>{t(name)}</span>
                    </NavLink>
                  )}
                </Flex>
              </Fragment>
            );
          })}
          <a className="nav-item dropdown-link" href={"#!"}>
            <Menu
              label={t("rma")}
              showDropdownIcon={false}
              contentStyle={{ minWidth: "14rem" }}
              items={[
                {
                  label: (
                    <Fragment>
                      <IconList className="menu-item-icon" /> {t("list_rma")}
                    </Fragment>
                  ),
                  onClick: () => {
                    viewRmaList();
                  },
                },
                {
                  label: (
                    <Fragment>
                      <IconNew className="menu-item-icon" />
                      {t("create_rma")}
                    </Fragment>
                  ),
                  onClick: () => {
                    createRma();
                  },
                },
              ]}
            />
          </a>
          <a className="nav-item dropdown-link" href={"#!"}>
            <Menu
              label={t("contact")}
              showDropdownIcon={false}
              contentStyle={{ minWidth: "14rem" }}
              items={[
                {
                  label: (
                    <Fragment>
                      <IconPhone className="menu-item-icon" /> +49 (0) 69 83 83
                      25 0
                    </Fragment>
                  ),
                  onClick: () => {
                    window.open("tel:+49 (0) 69 83 83 25 0", "_self");
                  },
                },
                {
                  label: (
                    <Fragment>
                      <IconEmail className="menu-item-icon" />
                      info@yukatel.de
                    </Fragment>
                  ),
                  onClick: () => {
                    window.open("mailto:info@yukatel.de", "_self");
                  },
                },
              ]}
            />
          </a>
          <a className="nav-item dropdown-link" href={"#!"}>
            <Menu
              showDropdownIcon={false}
              Icon={IconPriceList}
              items={[
                {
                  label: (
                    <Fragment>
                      <IconList className="menu-item-icon" /> {t("price_list")}
                    </Fragment>
                  ),
                  onClick: () => {
                    goToPriceList();
                  },
                },
                {
                  label: (
                    <Fragment>
                      <IconDownload className="menu-item-icon" />
                      {t("price_list_xls")}
                    </Fragment>
                  ),
                  onClick: async () => {
                    await downloadPriceList();
                  },
                },
              ]}
            />
          </a>
          <NavLink
            to={`${PageRoutes.REDEEM_YP}`}
            className={"nav-item icon-link"}
            activeClassName={"active"}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <Image
              className="brand-image"
              src={YPWhite}
              style={{ width: "1.25rem", height: "auto" }}
            />
          </NavLink>
          {!pathname?.includes("trade-in-home") && (
            <NavLink
              to={`${PageRoutes.CART}`}
              className={"nav-item icon-link"}
              activeClassName={"active"}
            >
              <IconShoppingCart />
              <Badge
                variant="cartBadge"
                style={{ right: "0.125rem", top: "0.375rem" }}
              >
                {items?.length}
              </Badge>
            </NavLink>
          )}

          {isCartEnabled && (
            <Flex
              sx={{
                "@media screen and (min-width: 1270px)": {
                  display: "none",
                },
              }}
            >
              <NavLink
                to={"#!"}
                className={"nav-item icon-link"}
                activeClassName={"active"}
                onClick={openModal}
              >
                <IconRightLeft />
                <Badge
                  variant="cartBadge"
                  style={{ right: "0.125rem", top: "0.375rem" }}
                >
                  {productCount}
                </Badge>
              </NavLink>
            </Flex>
          )}
          <a className="nav-item dropdown-link" href={"#!"}>
            <Menu
              Icon={IconUser}
              showDropdownIcon={false}
              contentStyle={{
                minWidth: "13rem",
                width: "100%",
                _label: { color: "red" },
              }}
              items={[
                {
                  label: (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: `1px solid ${themes.colors.lightGray}`,
                        paddingBottom: "3px",
                        color: "black",
                        width: "100%",
                        paddingLeft: "5px",
                        margin: 0,
                      }}
                    >
                      <Text
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {t("account_number")}
                      </Text>
                      <Text>{getItem("customer_number")}</Text>
                    </Box>
                  ),
                },
                {
                  label: (
                    <Fragment>
                      <IconUser className="menu-item-icon" />
                      {t("view_profile")}
                    </Fragment>
                  ),
                  onClick: () => {
                    viewProfile();
                  },
                },
                {
                  label: (
                    <>
                      <IconEdit className="menu-item-icon" />
                      {t("change_password")}
                    </>
                  ),
                  onClick: () => {
                    changePassword();
                  },
                },
                {
                  label: (
                    <Fragment>
                      <IconLogout className="menu-item-icon" /> {t("logout")}
                    </Fragment>
                  ),
                  onClick: () => {
                    logoutConfirmation();
                  },
                },
              ]}
            />
          </a>
          <a className="nav-item dropdown-link language-dropdown" href={"#!"}>
            <LanguageSelector borderColor={themes.colors.primary} />
          </a>
        </Flex>
      </Container>
      <MobileDrawer
        showMobile={showMobile}
        setShowMobile={setShowMobile}
        logoutConfirmation={logoutConfirmation}
      />
    </HeaderContainer>
  );
};

export default Header;
