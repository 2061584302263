import { Box, Checkbox, Heading, Label, Link } from "theme-ui";
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WithLoader from "../../../components/WithLoader";

const FilterBox = styled(Box)`
  margin-bottom: 1rem;

  .checkbox-label {
    margin-bottom: 0.5rem;

    > div {
      flex: 0 0 2.5rem;
    }

    > span {
      word-break: break-all;
    }

    svg {
      fill: ${(props) => props.theme.colors.primary};
    }

    input:checked {
      & ~ svg {
        fill: ${(props) => props.theme.colors.secondary};
      }
    }
  }

  .more-less-link {
    padding: 0.5rem 0;
    margin-left: 2.5rem;
  }
`;
const FilterHeading = styled(Heading)`
  margin-bottom: 0.75rem;
`;

const MultiCheckboxFilter = ({ isLoading, data, onChange, value = [] }) => {
  const { t } = useTranslation();

  const [more, setMore] = useState(true);

  useEffect(() => {
    setMore(data?.length >= 5);
  }, [data?.length]);

  return (
    <FilterBox>
      <FilterHeading>{t("manufacturer")}</FilterHeading>
      <WithLoader isLoading={isLoading} height={"256"}>
        {data?.map(({ id, name }, index) => {
          return !(more && index >= 5) ? (
            <Label key={id} className="checkbox-label">
              <Checkbox
                checked={value.includes(id)}
                onChange={() => {
                  if (value.includes(id)) {
                    onChange(value.filter((item) => item !== id));
                  } else {
                    onChange([...value, id]);
                  }
                }}
              />
              <span>{name}</span>
            </Label>
          ) : null;
        })}

        <Link
          className="more-less-link"
          variant="secondary"
          href={"#"}
          onClick={(e) => {
            e.preventDefault();
            setMore(!more);
          }}
        >
          {more ? `${t("more")}...` : `${t("less")}`}
        </Link>
      </WithLoader>
    </FilterBox>
  );
};

MultiCheckboxFilter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

export default MultiCheckboxFilter;
