/** @jsx jsx */
import { Box, jsx } from "theme-ui";

// eslint-disable-next-line react/prop-types
const FormControl = ({ sx, ...props }) => (
  <Box
    sx={{
      marginBottom: "1rem",
      ...sx,
    }}
    {...props}
  />
);

export default FormControl;
