import React from "react";
import Banner from "./Banner";
import HotDeal from "./HotDeal";
import HotNews from "./HotNews";

const Home = () => {
  return (
    <section>
      <Banner />
      <HotNews />
      <HotDeal />
    </section>
  );
};

export default Home;
