import styled from "@emotion/styled";
import * as PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Box, Button, Text } from "theme-ui";
import IconCheveronDown from "../../../components/icons/icon-cheveron-down";

const PaginationBlock = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  .page-info {
    margin-right: 1rem;
    display: flex;
  }

  .pagination-btn {
    fill: ${(props) => props.theme.colors.secondary};

    &.next-btn {
      .svg-icon {
        transform: rotate(270deg);
      }
    }

    &.prev-btn {
      .svg-icon {
        transform: rotate(90deg);
      }
    }
  }
`;

const Pagination = ({ last_page, current_page, onChange }) => {
  const ref = useRef({});

  useEffect(() => {
    if (last_page && current_page) ref.current = { last_page, current_page };
  }, [current_page, last_page]);

  return (
    <PaginationBlock className="pagination-block">
      <Box className="page-info">
        <Text sx={{ margin: "0 0.5rem" }}>Page</Text>
        <Text sx={{ color: "secondary" }}>
          {current_page || ref.current.current_page || 0}
        </Text>
        <Text sx={{ margin: "0 0.5rem" }}>of</Text>
        <Text>{last_page || ref.current.last_page || 0} </Text>
      </Box>
      <Button
        variant="iconButton"
        className="pagination-btn prev-btn"
        disabled={current_page === 1}
        onClick={() => onChange(current_page - 1)}
      >
        <IconCheveronDown />
      </Button>{" "}
      <Button
        variant="iconButton"
        className="pagination-btn next-btn"
        disabled={last_page === current_page}
        onClick={() => onChange(current_page + 1)}
      >
        <IconCheveronDown />
      </Button>
    </PaginationBlock>
  );
};

Pagination.propTypes = {
  last_page: PropTypes.number,
  current_page: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pagination;
