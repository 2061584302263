import React from "react";

const IconFileText = ({
  fill = "inherit",
  width = "3rem",
  height = "3rem",
  className = "",
  viewBox = "0 0 24 24",
}) => (
  <svg
    viewBox="0 0 24 24"
    width={width}
    height={height}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 22.938 6.375 C 23.084 6.5 23.229 6.661 23.375 6.857 L 16 6.857 L 16 0.536 C 16.229 0.661 16.417 0.786 16.563 0.911 Z M 15.5 8.572 L 24 8.572 L 24 22.715 C 24 23.072 23.854 23.375 23.563 23.625 C 23.271 23.875 22.917 24 22.5 24 L 1.5 24 C 1.084 24 0.729 23.875 0.438 23.625 C 0.146 23.375 0 23.072 0 22.715 L 0 1.286 C 0 0.929 0.146 0.625 0.438 0.375 C 0.729 0.125 1.084 0 1.5 0 L 14 0 L 14 7.286 C 14 7.643 14.146 7.947 14.438 8.197 C 14.729 8.447 15.084 8.572 15.5 8.572 Z M 18 18.429 L 18 17.572 C 18 17.447 17.953 17.344 17.86 17.264 C 17.766 17.183 17.646 17.143 17.5 17.143 L 6.5 17.143 C 6.354 17.143 6.235 17.183 6.141 17.264 C 6.047 17.344 6 17.447 6 17.572 L 6 18.429 C 6 18.554 6.047 18.657 6.141 18.737 C 6.235 18.817 6.354 18.857 6.5 18.857 L 17.5 18.857 C 17.646 18.857 17.766 18.817 17.86 18.737 C 17.953 18.657 18 18.554 18 18.429 Z M 18 15 L 18 14.143 C 18 14.018 17.953 13.915 17.86 13.835 C 17.766 13.755 17.646 13.715 17.5 13.715 L 6.5 13.715 C 6.354 13.715 6.235 13.755 6.141 13.835 C 6.047 13.915 6 14.018 6 14.143 L 6 15 C 6 15.125 6.047 15.228 6.141 15.308 C 6.235 15.389 6.354 15.429 6.5 15.429 L 17.5 15.429 C 17.646 15.429 17.766 15.389 17.86 15.308 C 17.953 15.228 18 15.125 18 15 Z M 18 11.572 L 18 10.715 C 18 10.59 17.953 10.487 17.86 10.407 C 17.766 10.326 17.646 10.286 17.5 10.286 L 6.5 10.286 C 6.354 10.286 6.235 10.326 6.141 10.407 C 6.047 10.487 6 10.59 6 10.715 L 6 11.572 C 6 11.697 6.047 11.799 6.141 11.88 C 6.235 11.96 6.354 12 6.5 12 L 17.5 12 C 17.646 12 17.766 11.96 17.86 11.88 C 17.953 11.799 18 11.697 18 11.572 Z" />
  </svg>
);

export default IconFileText;
