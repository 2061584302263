import React, { useCallback } from "react";
import { Box, Flex, Button, Text } from "theme-ui";
import themes from "../../../themes";
import { useTranslation } from "react-i18next";
import IconClose from "../../icons/icon-close";
import { Form } from "react-final-form";
import RFFInput from "../../form-fields/rff-fields/RFFInput";
import RFFTextarea from "../../form-fields/rff-fields/RFFTextarea";
import RFFSelectCountry from "../../form-fields/rff-fields/RFFSelectCountry";
import { tradeInCustomerCreateModalValidate } from "../../../utils/validators";
import { useMutation } from "react-query";
import { createCustomerRecourse } from "../../../apis";
import { useToast } from "../../../contexts/AlertProvider";
import PropTypes from "prop-types";
import { submitStatus } from "../../../constants";

const CreateCustomerModal = ({ showState, setShowState }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [mutateCreate, { isLoading }] = useMutation(createCustomerRecourse);

  const onSubmit = useCallback(
    async (values) => {
      try {
        await mutateCreate(values);
        toast(t("create_customer_recourse_successfully"), {
          type: submitStatus.SUCCESS,
        });
        setShowState(false);
      } catch {
        toast(t("create_customer_recourse_error"), {
          type: submitStatus.ERROR,
        });
      }
    },
    [mutateCreate, setShowState, t, toast]
  );

  const validate = useCallback(
    (values) => tradeInCustomerCreateModalValidate(values, t("field_required")),
    [t]
  );

  if (!showState) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: themes.colors.opaqueBlack,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: themes.colors.white,
          borderRadius: "4px",
          width: ["90%", "80%", "60%", "40%"],
          height: "85%",
          overflow: "auto",
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "70px",
            backgroundColor: themes.colors.snowWhite,
            borderBottom: `1px solid ${themes.colors.darkGray}`,
            padding: "20px",
          }}
        >
          <Text sx={{ fontWeight: "bold", fontSize: 18 }}>
            {t("ask_for_customer_confirmation")}
          </Text>
          <Box
            sx={{
              padding: 0,
              margin: 0,
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
            onClick={() => setShowState(false)}
          >
            <IconClose width="100%" height="100%" />
          </Box>
        </Flex>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "20px",
                height: "calc( 100% - 70px )",
              }}
              onSubmit={handleSubmit}
            >
              <Flex
                sx={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                }}
              >
                <RFFInput
                  type="text"
                  name="company_name"
                  label={`${t("company_name")}*`}
                  placeholder={t("company_name")}
                  max={70}
                />
                <RFFInput
                  type="text"
                  name="name"
                  label={`${t("name")}*`}
                  placeholder={t("name")}
                  max={70}
                />
                <RFFSelectCountry
                  label={`${t("country_name")}*`}
                  placeholder="country_id"
                  name="country_id"
                  sx={{
                    height: "82px",
                    borderRadius: "6px",
                    padding: "0px 1px",
                    backgroundColor: themes.colors.transparent,
                    borderColor: themes.colors.stepFourInputBorder,
                    color: themes.colors.titleColor,
                    "::placeholder ": {
                      color: themes.colors.stepFourInputColor,
                    },
                  }}
                />
                <RFFInput
                  type="email"
                  name="company_email"
                  label={`${t("email")}*`}
                  placeholder={t("email")}
                  max={70}
                />
                <RFFInput
                  type="text"
                  name="phone_number"
                  label={`${t("phone_number")}*`}
                  placeholder={t("phone_number")}
                />
                <RFFInput
                  type="text"
                  name="website"
                  label={`${t("website")}*`}
                  placeholder={t("website")}
                  max={70}
                />
                <RFFTextarea
                  type="textarea"
                  name="note"
                  label={t("notes")}
                  placeholder={t("notes")}
                />
              </Flex>
              <Flex
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: ["10px", "20px", "20px", "20px"],
                  padding: "20px 0px",
                  alignSelf: "flex-end",
                  width: "100%",
                }}
              >
                <Box>
                  <Text>{`*${t("required")}`}</Text>
                </Box>
                <Flex sx={{ width: "60%", gap: ["10px", "20px"] }}>
                  <Button
                    sx={{
                      width: "48%",
                      borderRadius: "4px",
                      backgroundColor: themes.colors.grey,
                      color: themes.colors.black,
                      textTransform: "capitalize",
                      "&:hover": {
                        color: themes.colors.white,
                      },
                    }}
                    type="button"
                    onClick={() => setShowState(false)}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    sx={{
                      width: "48%",
                      borderRadius: "4px",
                      backgroundColor: themes.colors.primary,
                      color: themes.colors.white,
                      textTransform: "capitalize",
                      "&:hover": {
                        color: themes.colors.white,
                      },
                    }}
                    type="submit"
                    isLoading={isLoading}
                  >
                    {t("create_customer")}
                  </Button>
                </Flex>
              </Flex>
            </form>
          )}
        />
      </Box>
    </Box>
  );
};

CreateCustomerModal.propTypes = {
  showState: PropTypes.bool,
  setShowState: PropTypes.func,
};

export default CreateCustomerModal;
