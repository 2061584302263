import React from "react";
import { Box, Flex, Text, Grid, Button } from "theme-ui";
import themes from "../../../themes";
import styled from "@emotion/styled";
import IconClose from "../../icons/icon-close";
import { useTranslation } from "react-i18next";
import Money from "../../Money";
import YukatelLogo from "../../../assets/images/logo.png";
import { getTradeInHistory } from "../../../apis";
import { useQuery } from "react-query";
import { parseDate } from "../../../utils/helpers";

export const TradeInHistoryContainer = styled(Flex)`
  margin: 2rem 0 2rem 0;
  .table-container {
    margin-top: 0.2rem
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    border:none;

    .table {
      width: 100%;
      font-size: 0.875rem;
      min-width: 1180px;
      
      .red-text {
        color: ${(props) => props.theme.colors.secondary};
        font-weight:600;
      }
      .green-text {
        color: ${(props) => props.theme.colors.green};
        font-weight:600;
      }

      .gray-text {
        color: ${(props) => props.theme.colors.buttonGray};
      }

      .table-sublist {
        background-color: ${(props) => props.theme.colors.white};
        color:${(props) => props.theme.colors.primary};

        th {
          padding: 0.50rem 0.50rem;
          text-transform: uppercase;
          line-height: 1.8;
          text-align: center;
          font-size: 1rem;
          font-weight: 700;
          color:${(props) => props.theme.colors.tableHeaderTextGray};

          @media (max-width: 1240px) {
            font-size:1rem;
        }
        }
        }

        .table-body {
          background-color: ${(props) => props.theme.colors.lightTableGray};
          color: ${(props) => props.theme.colors.black};

          th {
            padding: 0.75rem 0.75rem;
            text-align: left;
            width: 328px;
            @media (max-width: 1240px) {
              width:355px;
          }
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1.1rem;
          }

          td {
            font-size:0.9rem;
            padding: 0.75rem 0.75rem;
            text-transform: none;
            line-height: 1.6;
            text-align: center;
          }
          td span {
            font-size: 0.9rem;
            font-weight: 400;
          }
        }

        .table-body tr:nth-child(odd) {
          background-color:${(props) => props.theme.colors.lightTableGray}; 
        }
        
        .table-body tr:nth-child(even) {
          background-color: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`;

const TradeInHistoryModal = ({ setShowHistoryModal, selectedItem }) => {
  const { t } = useTranslation();

  const { data } = useQuery(selectedItem?.id, getTradeInHistory, {
    cacheTime: 0,
  });
  let prevItem;

  return (
    <TradeInHistoryContainer>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: themes.colors.opaqueBlack,
          zIndex: 100,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: themes.colors.white,
            borderRadius: "4px",
            width: "80%",
            height: "80%",
            overflow: "auto",
            zIndex: 100,
          }}
        >
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "70px",
              backgroundColor: themes.colors.white,
              padding: "20px",
            }}
          >
            <Text
              sx={{
                fontSize: "1.2rem",
                fontWeight: "700",
                color: themes.colors.primary,
              }}
            >
              {t("history")}
            </Text>
            <Box>
              <Button
                sx={{
                  position: "absolute",
                  right: "1rem",
                  top: "1rem",
                  height: "2rem",
                  width: "2rem",
                  backgroundColor: themes.colors.primary,
                }}
                variant="primaryIconButton"
                onClick={() => setShowHistoryModal(false)}
              >
                <IconClose
                  fill={themes.colors.white}
                  height="1.5rem"
                  width="1.5rem"
                />
              </Button>
            </Box>
          </Flex>
          <Box>
            <Grid columns="1" gap={0}>
              <Box className="table-container">
                <Flex
                  sx={{
                    backgroundColor: themes.colors.lightTableGray,
                    minWidth: "1180px",
                  }}
                >
                  <Text
                    sx={{
                      padding: "0.5rem 0.5rem 0.5rem 1rem",
                      fontSize: "1rem",
                      fontWeight: "500",
                      color: themes.colors.tableHeaderTextGray,
                    }}
                  >
                    {data?.data?.article_name}
                  </Text>
                </Flex>
                <table className="table">
                  <thead className="table-sublist">
                    <tr>
                      <th
                        colSpan="1"
                        style={{ paddingLeft: "1.1rem", textAlign: "left" }}
                      >
                        {t("date")}
                      </th>
                      <th colSpan="1">{t("quantity")}</th>
                      <th colSpan="1">{t("unit_price")}</th>
                      <th colSpan="1">{t("o_quantity")}</th>
                      <th colSpan="1">{t("o_unit_price")}</th>
                      <th colSpan="1" style={{ textAlign: "center" }}>
                        {t("by")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {data?.data?.counter_offers.map(
                      (
                        {
                          quantity,
                          min_order_quantity,
                          created_at,
                          price,
                          is_given_by_seller,
                        },
                        key
                      ) => {
                        const prevQuantity =
                          prevItem?.quantity || data?.data?.min_order_quantity;
                        const prevPrice =
                          prevItem?.price || data?.data?.unit_price;
                        prevItem = {
                          quantity,
                          min_order_quantity,
                          created_at,
                          price,
                          is_given_by_seller,
                        };
                        return (
                          <tr key={key}>
                            <td
                              className="gray-text"
                              style={{
                                textAlign: "left",
                                fontWeight: themes.fontWeights.heading,
                              }}
                            >
                              {parseDate(created_at)}
                            </td>
                            <td className="red-text">{prevQuantity}</td>
                            <td className="red-text">
                              <span
                                style={{
                                  fontWeight: themes.fontWeights.heading,
                                }}
                              >
                                <Money amount={prevPrice} />
                              </span>
                            </td>
                            <td className="green-text">{quantity}</td>
                            <td className="green-text">
                              <span
                                style={{
                                  fontWeight: themes.fontWeights.heading,
                                }}
                              >
                                <Money amount={price} />
                              </span>
                            </td>
                            <td>
                              <Flex
                                sx={{
                                  justifyContent: "center",
                                }}
                              >
                                {!is_given_by_seller ? (
                                  <img
                                    src={YukatelLogo}
                                    width="80px"
                                    height="19px"
                                    alt="direct_buy"
                                    style={{ marginTop: "0.3em" }}
                                  />
                                ) : (
                                  <Text
                                    sx={{
                                      fontSize: "1rem",
                                      fontWeight: "500",
                                      textTransform: "none",
                                    }}
                                  >
                                    {t("seller")}
                                  </Text>
                                )}
                              </Flex>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </TradeInHistoryContainer>
  );
};

export default TradeInHistoryModal;
