/* eslint-disable react/prop-types */
import React from "react";

const IconList = ({
  fill = "inherit",
  width = "24px",
  height = "24px",
  className = "",
  viewBox = "0 0 16 16",
  style,
}) => (
  <svg
    width={width}
    fill={fill}
    height={height}
    viewBox={viewBox}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
    />
  </svg>
);

export default IconList;
