import styled from "@emotion/styled";
import React from "react";
import { Box, Heading } from "theme-ui";
import Container from "../../../components/Container";

export default function index() {
  return (
    <TermsAndConditionContainer>
      <Box className="page-header">
        <Heading className="heading">
          Data Protection{" "}
          <span
            style={{
              display: "inline-block",
              fontSize: "75%",
              padding: "0 0.75rem",
            }}
          >
            (German Version)
          </span>
        </Heading>
      </Box>
      <Box className="page-details">
        <div>
          <p>
            Die nachfolgende Datenschutzerklärung gilt für die Nutzung unseres
            Online-Angebots www.yukatel.de (nachfolgend „Website“).
          </p>
          <p>
            Wir messen dem Datenschutz große Bedeutung bei. Die Erhebung und
            Verarbeitung Ihrer personenbezogenen Daten geschieht unter Beachtung
            der geltenden datenschutzrechtlichen Vorschriften, insbesondere der
            Datenschutzgrundverordnung (DSGVO). Wir erheben und verarbeiten Ihre
            personenbezogenen Daten, um Ihnen das oben genannten Portal anbieten
            zu können. Diese Erklärung beschreibt, wie und zu welchem Zweck Ihre
            Daten erfasst und genutzt werden und welche Wahlmöglichkeiten Sie im
            Zusammenhang mit persönlichen Daten haben. Durch Ihre Verwendung
            dieser Website stimmen Sie der Erfassung, Nutzung und Übertragung
            Ihrer Daten gemäß dieser Datenschutzerklärung
            zu.&nbsp;Verantwortlicher für die Erhebung, Verarbeitung und Nutzung
            Ihrer personenbezogenen Daten im Sinne von Art. 4 Nr. 7 DSGVO ist
            Yukatel GmbH, Yuka-Platz 1, 63303 Dreieich.&nbsp; Sofern Sie der
            Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch uns nach
            Maßgabe dieser Datenschutzbestimmungen insgesamt oder für einzelne
            Maßnahmen widersprechen wollen, können Sie Ihren Widerspruch an den
            Verantwortlichen richten.&nbsp;
          </p>
          <p>
            Sie können diese Datenschutzerklärung jederzeit speichern und
            ausdrucken.
          </p>
        </div>

        <div>
          <Heading className="title">2. Allgemeine Nutzung der Website</Heading>
          <p>
            <b>2.1 Hosting</b>
          </p>
          <p>
            Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
            Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
            Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
            Datenbankdienste, Sicherheitsleistungen sowie technische
            Wartungsleistungen, die wir zum Zwecke des Betriebs der Website
            einsetzen. Hierbei verarbeiten wir, bzw. unser Hostinganbieter
            Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten,
            Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden,
            Interessenten und Besuchern dieses Onlineangebotes auf Grundlage
            unserer berechtigten Interessenan einer effizienten und sicheren
            Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 S. 1
            f) DSGVO i.V.m. Art. 28DSGVO.
          </p>
          <p>
            <b>2.2 Zugriffsdaten</b>
          </p>
          <p>
            Wir sammeln Informationen über Sie, wenn Sie diese Website nutzen.
            Wir erfassen automatisch Informationen über Ihr Nutzungsverhalten
            und Ihre Interaktion mit uns und registrieren Daten zu Ihrem
            Computer oder Mobilgerät. Wir erheben, speichern und nutzen Daten
            über jeden Zugriff auf unser Onlineangebot (sogenannte
            Serverlogfiles). Zu den Zugriffsdaten gehören:
          </p>
          <p>
            – Name und URL der abgerufenen Datei <br />– Datum und Uhrzeit des
            Abrufs <br />– übertragene Datenmenge <br />– Meldung über
            erfolgreichen Abruf (HTTP response code) <br />– Browsertyp und
            Browserversion <br />– Betriebssystem <br />– Referer URL (d.h. die
            zuvor besuchte Seite) <br />– Websites, die vom System des Nutzers
            über unsere Website aufgerufen werden <br />–
            Internet-Service-Provider des Nutzers <br />– IP-Adresse und der
            anfragende Provider
          </p>
          <p>
            Wir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder
            sonstiger Profilerstellung für statistische Auswertungen zum Zweck
            des Betriebs, der Sicherheit und der Optimierung unseres
            Onlineangebotes, aber auch zur anonymen Erfassung der Anzahl der
            Besucher auf unserer Website (traffic) sowie zum Umfang und zur Art
            der Nutzung unserer Website und Dienste, ebenso zu
            Abrechnungszwecken, um die Anzahl der von Kooperationspartnern
            erhaltenen Clicks zu messen. Aufgrund dieser Informationen können
            wir personalisierte und standortbezogene Inhalte zur Verfügung
            stellen und den Datenverkehr analysieren, Fehler suchen und beheben
            und unsere Dienste verbessern. Hierin liegt auch unser berechtigtes
            Interesse gemäß Art 6 Abs. 1 S. 1 f) DSGVO.&nbsp;
          </p>
          <p>
            Wir behalten uns vor, die Protokolldaten nachträglich zu überprüfen,
            wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer
            rechtswidrigen Nutzung besteht. IP-Adressen speichern wir für einen
            begrenzten Zeitraum in den Logfiles, wenn dies für Sicherheitszwecke
            erforderlich oder für die Leistungserbringung oder die Abrechnung
            einer Leistung nötig ist, z. B. wenn Sie eines unserer Angebote
            nutzen. Nach Abbruch des Vorgangs der Bestellung oder nach
            Zahlungseingang löschen wir die IP-Adresse, wenn diese für
            Sicherheitszwecke nicht mehr erforderlich ist.{" "}
            <a name="cookies"></a>IP-Adressen speichern wir auch dann, wenn wir
            den konkreten Verdacht einer Straftat im Zusammenhang mit der
            Nutzung unserer Website haben. Außerdem speichern wir als Teil Ihres
            Accounts das Datum Ihres letzten Besuchs (z.B. bei Registrierung,
            Login, Klicken von Links etc.).
          </p>
          <p>
            <b>2.3 Cookies&nbsp;</b>
          </p>
          <p>
            Wir verwenden sogenannte Session-Cookies, um unser Onlineangebot zu
            optimieren. Ein Session-Cookie ist eine kleine Textdatei, die von
            den jeweiligen Servern beim Besuch einer Internetseite verschickt
            und auf Ihrer Festplatte zwischengespeichert wird. Diese Datei als
            solche enthält eine sogenannte Session-ID, mit welcher sich
            verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
            zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn
            Sie auf unsere Website zurückkehren. Diese Cookies werden gelöscht,
            nachdem Sie Ihren Browser schließen. Sie dienen z. B. dazu, dass Sie
            die Warenkorbfunktion über mehrere Seiten hinweg nutzen
            können.&nbsp;
          </p>
          <p>
            Wir verwenden in geringem Umfang auch persistente Cookies (ebenfalls
            kleine Textdateien, die auf Ihrem Endgerät abgelegt werden), die auf
            Ihrem Endgerät verbleiben und es uns ermöglichen, Ihren Browser beim
            nächsten Besuch wiederzuerkennen. Diese Cookies werden auf Ihrer
            Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit
            von allein. Ihre Lebensdauer beträgt 1 Monat bis 10 Jahre. So können
            wir Ihnen unser Angebot nutzerfreundlicher, effektiver und sicherer
            präsentieren und Ihnen beispielsweise speziell auf Ihre Interessen
            abgestimmte Informationen auf der Seite anzeigen.&nbsp;
          </p>
          <p>
            Unser berechtigtes Interesse an der Nutzung der Cookies gemäß Art 6
            Abs. 1 S. 1 f) DSGVO liegt darin, unsere Website nutzerfreundlicher,
            effektiver und sicherer zu machen.
          </p>
          <p>
            In den Cookies werden etwa folgende Daten und Informationen
            gespeichert:&nbsp;
          </p>
          <p>
            – Log-In-Informationen <br />– Spracheinstellungen <br />–
            eingegebene Suchbegriffe <br />– Informationen über die Anzahl der
            Aufrufe unserer Website sowie Nutzung einzelner Funktionen unseres
            Internetauftritts.
          </p>
          <p>
            Bei Aktivierung des Cookies wird diesem eine Identifikationsnummer
            zugewiesen und eine Zuordnung Ihrer personenbezogenen Daten zu
            dieser Identifikationsnummer wird nicht vorgenommen. Ihr Name, Ihre
            IP-Adresse oder ähnliche Daten, die eine Zuordnung des Cookies zu
            Ihnen ermöglichen würden, werden nicht in den Cookie eingelegt. Auf
            Basis der Cookie-Technologie erhalten wir lediglich pseudonymisierte
            Informationen, beispielsweise darüber, welche Seiten unseres Shops
            besucht wurden, welche Produkte angesehen wurden, etc.&nbsp;Sie
            können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies vorab informiert werden und im Einzelfall entscheiden
            können, ob Sie die Annahme von Cookies für bestimmte Fälle oder
            generell ausschließen, oder dass Cookies komplett verhindert werden.
            Dadurch kann die Funktionalität der Website eingeschränkt werden.
          </p>
          <p>
            <b>2.4 E-Mail Kontakt</b>
          </p>
          <p>
            Wenn Sie mit uns in Kontakt treten (z. B. per Kontaktformular oder
            E-Mail), speichern wir Ihre Angaben zur Bearbeitung der Anfrage
            sowie für den Fall, dass Anschlussfragen entstehen. Hierin liegt
            auch unser berechtigtes Interesse gemäß Art 6 Abs. 1 S. 1 f) DSGVO.
            Weitere personenbezogene Daten speichern und nutzen wir nur, wenn
            Sie dazu einwilligen oder dies ohne besondere Einwilligung
            gesetzlich zulässig ist.
          </p>
          <p>
            <b>2.5 Chat</b>
          </p>
          <p>
            Die Chat-Funktionen werden als Dienstleistung von der Firma Habla
            Inc. Mit Sitz in 205 !/2 N Main St., Ann Arbor, MI 48104 USA unter
            dem Markennamen Olark zur Verfügung gestellt. Dieser Anbieter ist
            für die Datenschutzsicherheit zuständig. Bitte lesen Sie sich
            folgende Datenschutzerklärung auf{" "}
            <a
              href="https://www.olark.com/help/gdpr"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.olark.com/help/gdpr
            </a>{" "}
            durch. Oder kontaktieren Sie das Unternehmen direkt unter{" "}
            <a href="mailto:privacy@olark.com">privacy@olark.com</a>.
          </p>
          <p>
            <b>2.6 Google Analytics</b>
          </p>
          <p>
            Wir benutzen Google Analytics, einen Webanalysedienst der Google
            Inc. („Google“). Google hat sich dem zwischen der Europäischen Union
            und den USA geschlossenen Privacy-Shield-Abkommen unterworfen und
            sich zertifiziert. Dadurch verpflichtet sich Google, die Standards
            und Vorschriften des europäischen Datenschutzrechts einzuhalten.
            Nähere Informationen können Sie dem nachfolgend verlinkten Eintrag
            entnehmen:{" "}
            <a
              href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            .&nbsp;
          </p>
          <p>
            Google Analytics verwendet sog. „Cookies“, Textdateien, die auf
            Ihrem Computer gespeichert werden und die eine Analyse der Benutzung
            der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
            Informationen über Benutzung dieser Website durch die Seitenbesucher
            werden in der Regel an einen Server von Google in den USA übertragen
            und dort gespeichert. Hierin liegt auch unser berechtigtes Interesse
            gemäß Art 6 Abs. 1 S. 1 f) DSGVO. <br />
            Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website,
            wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten
            der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
            über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
            Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
            in den USA übertragen und dort gekürzt. Die IP-Anonymisierung ist
            auf dieser Website aktiv. In unserem Auftrag wird Google diese
            Informationen benutzen, um die Nutzung der Website durch Sie
            auszuwerten, um Reports über die Websiteaktivitäten
            zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen uns gegenüber zu
            erbringen.&nbsp;
          </p>
          <p>
            Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
            IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
            Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie
            können darüber hinaus die Übertragung der durch das Cookie erzeugten
            und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem Sie das unter dem folgenden Link verfügbare
            Browser-Plugin herunterladen und installieren:{" "}
            <a
              href="http://tools.google.com/dlpage/gaoptout?hl=de"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            .
          </p>
          <p>
            <b>2.7 Speicherdauer</b>
          </p>
          <p>
            Sofern nicht spezifisch angegeben speichern wir personenbezogene
            Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke
            notwendig ist.
          </p>
        </div>

        <div>
          <Heading className="title">3. Verarbeitung von Bestandsdaten</Heading>
          <p>Ferner verarbeiten wir nachfolgend beschriebene Bestandsdaten.</p>
          <p>
            <b>3.1 Kundenkonto</b>
          </p>
          <p>
            Um Ihnen eine bequeme Verarbeitung in unserem Händlerbereich bieten
            zu können, können Sie sich durch die Eingabe persönlicher Daten auf
            unserer Website registrieren. Dann müssen Sie nicht bei jeder
            Bestellung Ihre Daten neu eingeben. Für die Neuregistrierung erheben
            wir Stammdaten (z. B. Name, Adresse), Kommunikationsdaten (z. B.
            E-Mail-Adresse) und Zahlungsdaten (Bankverbindung) sowie
            Zugangsdaten (Benutzername u. Passwort). Um Ihre ordnungsgemäße
            Anmeldung sicherzustellen und unautorisierte Anmeldungen durch
            Dritte zu verhindern, erhalten Sie nach Ihrer Registrierung einen
            Aktivierungslink per E-Mail, um Ihren Account zu aktivieren. Erst
            nach erfolgter Registrierung speichern wir die von Ihnen
            übermittelten Daten dauerhaft in unserem System.
          </p>
          <p>
            Sie können ein einmal angelegtes Kundenkonto jederzeit von uns
            löschen lassen, ohne dass hierfür andere als die Übermittlungskosten
            nach den Basistarifen entstehen. Eine Mitteilung in Textform an die
            unter Ziffer 1 genannten Kontaktdaten (z.B. E-Mail, Fax, Brief)
            reicht hierfür aus. Wir werden dann Ihre gespeicherten
            personenbezogenen Daten löschen, soweit wir diese nicht noch zur
            Abwicklung von Bestellungen oder aufgrund gesetzlicher
            Aufbewahrungspflichten speichern müssen.
          </p>
          <p>
            <b>3.2 Newsletter</b>
          </p>
          <p>
            Um Ihnen unseren Newsletter zusenden zu können, verwenden wir das
            sogenannte Double Opt-In-Verfahren. Nur wenn Sie uns zuvor
            ausdrücklich bestätigt haben, dass Sie den Newsletter erhalten
            wollen, werden wir Ihnen eine Aktivierungs-E-Mail zusenden und Sie
            bitten, durch das Anklicken eines in dieser E-Mail enthaltenen Links
            zu bestätigen, dass Sie unseren Newsletter erhalten möchten.&nbsp;
          </p>
          <p>
            Sie können die Anmeldung jederzeit rückgängig machen, ohne dass
            hierfür andere als die Übermittlungskosten nach den Basistarifen
            entstehen. Eine Mitteilung in Textform an die unter Ziffer 1
            genannten Kontaktdaten (z.B. E-Mail, Fax, Brief) reicht hierfür aus.
            Selbstverständlich finden Sie auch in jedem Newsletter einen
            Abmelde-Link.
          </p>
          <p>
            <b>3.3 Rechtsgrundlagen und Speicherdauer&nbsp;</b>
          </p>
          <p>
            Rechtsgrundlage der Datenverarbeitung nach den vorstehenden Ziffern
            ist Art. 6 Abs. 1 S. 1 a), b)und f) DSGVO. Unsere Interessen an der
            Datenverarbeitung sind insbesondere Anbahnung, Abschluss und
            Erfüllung von Verträgen sowie die Direktwerbung und
            Produktinformation. <br />
            Sofern nicht spezifisch angegeben, speichern wir personenbezogene
            Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke
            notwendig bzw. gesetzlich vorgeschrieben ist.
          </p>
        </div>

        <div>
          <Heading className="title">
            4. Ihre Rechte als von der Datenverarbeitung Betroffener
          </Heading>

          <p>
            Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte
            bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte
            geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail oder
            per Post unter eindeutiger Identifizierung Ihrer Person an die in
            Ziffer 1 genannte Adresse.&nbsp;
          </p>
          <p>Nachfolgend finden Sie eine Übersicht über Ihre Rechte.</p>
          <p>
            <b>4.1 Recht auf Bestätigung und Auskunft</b>
          </p>
          <p>
            Sie haben jederzeit das Recht, von uns eine Bestätigung darüber zu
            erhalten, ob Sie betreffende personenbezogene Daten verarbeitet
            werden. Ist dies der Fall, so haben Sie das Recht, von uns eine
            unentgeltliche Auskunft über die zu Ihnen gespeicherten
            personenbezogenen Daten nebst einer Kopie dieser Daten zu verlangen.
            Des Weiteren besteht ein Recht auf folgende Informationen:&nbsp;
          </p>
          <p>
            1. die Verarbeitungszwecke; <br />
            2. die Kategorien personenbezogener Daten, die verarbeitet werden;{" "}
            <br />
            3. die Empfänger oder Kategorien von Empfängern, gegenüber denen die
            personenbezogenen Daten offengelegt worden sind oder noch
            offengelegt werden, insbesondere bei Empfängern in Drittländern oder
            bei internationalen Organisationen; <br />
            4. falls möglich, die geplante Dauer, für die die personenbezogenen
            Daten gespeichert werden, oder, falls dies nicht möglich ist, die
            Kriterien für die Festlegung dieser Dauer; <br />
            5. das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
            betreffenden personenbezogenen Daten oder auf Einschränkung der
            Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung; <br />
            6. das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;{" "}
            <br />
            7. wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden,
            alle verfügbaren Informationen über die Herkunft der Daten; <br />
            8. das Bestehen einer automatisierten Entscheidungsfindung
            einschließlich Profiling gemäß Artikel 22 Absätze 1 und 4 DSGVO und
            – zumindest in diesen Fällen – aussagekräftige Informationen über
            die involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für Sie.
          </p>
          <p>
            Werden personenbezogene Daten an ein Drittland oder an eine
            internationale Organisation übermittelt, so haben Sie das Recht,
            über die geeigneten Garantien gemäß Art. 46 DSGVO im Zusammenhang
            mit der Übermittlung unterrichtet zu werden.
          </p>
          <p>
            <b>4.2 Recht auf Berichtigung</b>
          </p>
          <p>
            Sie haben das Recht, von uns unverzüglich die Berichtigung Sie
            betreffender unrichtiger personenbezogener Daten zu verlangen. Unter
            Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht,
            die Vervollständigung unvollständiger personenbezogener Daten – auch
            mittels einer ergänzenden Erklärung – zu verlangen.
          </p>
          <p>
            <b>4.3 Recht auf Löschung (“Recht auf Vergessenwerden”)</b>
          </p>
          <p>
            Sie haben gemäß Art. 17 Abs. 1 DSGVO das Recht, von uns zu
            verlangen, dass Sie betreffende personenbezogene Daten unverzüglich
            gelöscht werden, und wir sind verpflichtet, personenbezogene Daten
            unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:{" "}
            <br />
            1. Die personenbezogenen Daten sind für die Zwecke, für die sie
            erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
            notwendig. <br />
            2. Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung
            gemäß Art. 6 Abs. 1 S. 1a) DSGVO oder Art. 9 Abs. 2 a) DSGVO
            stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die
            Verarbeitung. <br />
            3. Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die
            Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
            für die Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs. 2 DSGVO
            Widerspruch gegen die Verarbeitung ein. <br />
            4. Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.{" "}
            <br />
            5. Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
            rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten erforderlich, dem wir unterliegen. <br />
            6. Die personenbezogenen Daten wurden in Bezug auf angebotene
            Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO
            erhoben.
          </p>
          <p>
            Haben wir die personenbezogenen Daten öffentlich gemacht und sind
            wir gemäß Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so
            treffen wir unter Berücksichtigung der verfügbaren Technologie und
            der Implementierungskosten angemessene Maßnahmen, auch technischer
            Art, um für die Datenverarbeitung Verantwortliche, die die
            personenbezogenen Daten verarbeiten, darüber zu informieren, dass
            Sie von ihnen die Löschung aller Links zu diesen personenbezogenen
            Daten oder von Kopien oder Replikationen dieser personenbezogenen
            Daten verlangt haben.
          </p>
          <p>
            <b>4.4 Recht auf Einschränkung der Verarbeitung</b>
          </p>
          <p>
            Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu
            verlangen, wenn eine der folgenden Voraussetzungen gegeben
            ist:&nbsp;
          </p>
          <p>
            1. die Richtigkeit der personenbezogenen Daten wird von Ihnen
            bestritten, und zwar für eine Dauer, die es uns ermöglicht, die
            Richtigkeit der personenbezogenen Daten zu überprüfen, <br />
            2. die Verarbeitung unrechtmäßig ist und Sie die Löschung der
            personenbezogenen Daten ablehnten und stattdessen die Einschränkung
            der Nutzung der personenbezogenen Daten verlangt haben; <br />
            3. wir die personenbezogenen Daten für die Zwecke der Verarbeitung
            nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder{" "}
            <br />
            4. Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO
            eingelegt haben, solange noch nicht feststeht, ob die berechtigten
            Gründe unseres Unternehmens gegenüber den Ihren überwiegen.
          </p>
          <p>
            <b>4.5 Recht auf Datenübertragbarkeit</b>
          </p>
          <p>
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
            die Sie uns bereitgestellt haben, in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten, und Sie haben das Recht,
            diese Daten einem anderen Verantwortlichen ohne Behinderung durch
            uns zu übermitteln, sofern <br />
            1. die Verarbeitung auf einer Einwilligung gemäß Art. 6 Abs. 1 S.
            1a) DSGVO oder Art. 9 Abs. 2 a) DSGVO oder auf einem Vertrag gemäß
            Art. 6 Abs. 1 S. 1 b) DSGVO beruht und <br />
            2. die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
          </p>
          <p>
            Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Absatz
            1 haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten
            direkt von uns einem anderen Verantwortlichen übermittelt werden,
            soweit dies technisch machbar ist.
          </p>
          <p>
            <b>4.6 Widerspruchsrecht</b>
          </p>
          <p>
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
            personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1e) oder
            f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
            diese Bestimmungen gestütztes Profiling. Wir verarbeiten die
            personenbezogenen Daten nicht mehr, es sei denn, wir können
            zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
            Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
            Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
            von Rechtsansprüchen.&nbsp;
          </p>
          <p>
            Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung
            zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen
            die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
            derartiger Werbung einzulegen; dies gilt auch für das Profiling,
            soweit es mit solcher Direktwerbung in Verbindung steht.
            <br />
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, gegen die Sie betreffende Verarbeitung Sie
            betreffender personenbezogener Daten, die zu wissenschaftlichen oder
            historischen Forschungszwecken oder zu statistischen Zwecken gemäß
            Art. 89 Abs. 1 DSGVO erfolgt, Widerspruch einzulegen, es sei denn,
            die Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse
            liegenden Aufgabe erforderlich.
          </p>
          <p>
            <b>4.7 Automatisierte Entscheidungen einschließlich Profiling</b>
          </p>
          <p>
            Sie haben das Recht, nicht einer ausschließlich auf einer
            automatisierten Verarbeitung – einschließlich Profiling – beruhenden
            Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
            Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
            beeinträchtigt. Eine automatisierte Entscheidungsfindung auf der
            Grundlage der erhobenen personenbezogenen Daten findet nicht statt.
          </p>
          <p>
            <b>
              4.8 Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
            </b>
          </p>
          <p>
            Sie haben das Recht, eine Einwilligung zur Verarbeitung
            personenbezogener Daten jederzeit zu widerrufen.
          </p>
          <p>
            <b>4.9 Recht auf Beschwerde bei einer Aufsichtsbehörde</b>
          </p>
          <p>
            Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde,
            insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres
            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie
            der Ansicht sind, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten rechtswidrig ist.
          </p>
        </div>

        <div>
          <Heading className="title">5. Datensicherheit</Heading>
          <p>
            Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden
            Datenschutzgesetze und technischen Möglichkeiten maximal bemüht.
            Ihre persönlichen Daten werden bei uns verschlüsselt übertragen.
            Dies gilt für Ihre Bestellungen und auch für das Kundenlogin. Wir
            nutzen das Codierungssystem SSL (Secure Socket Layer), weisen jedoch
            darauf hin, dass die Datenübertragung im Internet (z.B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich. Zur Sicherung Ihrer Daten unterhalten wir technische und
            organisatorische Sicherungsmaßnahmen entsprechend Art. 32 DSGVO, die
            wir immer wieder dem Stand der Technik anpassen. Wir gewährleisten
            außerdem nicht, dass unser Angebot zu bestimmten Zeiten zur
            Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können
            nicht ausgeschlossen werden. Die von uns verwendeten Server werden
            regelmäßig sorgfältig gesichert.
          </p>
        </div>

        <div>
          <Heading className="title">
            6. Weitergabe von Daten an Dritte, keine Datenübertragung ins
            Nicht-EU-Ausland
          </Heading>
          <p>
            Grundsätzlich verwenden wir Ihre personenbezogenen Daten nur
            innerhalb unseres Unternehmens. Wenn und soweit wir Dritte im Rahmen
            der Erfüllung von Verträgen einschalten (etwa
            Logistik-Dienstleister), erhalten diese personenbezogene Daten nur
            in dem Umfang, in welchem die Übermittlung für die entsprechende
            Leistung erforderlich ist. Für den Fall, dass wir bestimmte Teile
            der Datenverarbeitung auslagern („Auftragsverarbeitung“),
            verpflichten wir Auftragsverarbeiter vertraglich dazu,
            personenbezogene Daten nur im Einklang mit den Anforderungen der
            Datenschutzgesetze zu verwenden und den Schutz der Rechte der
            betroffenen Person zu gewährleisten. Eine Datenübertragung an
            Stellen oder Personen außerhalb der EU außerhalb der in dieser
            Erklärung in Ziffer 2 genannten Fällen findet nicht statt und ist
            nicht geplant.
          </p>
        </div>
      </Box>
    </TermsAndConditionContainer>
  );
}

export const TermsAndConditionContainer = styled(Container)`
  margin-top: 3rem;
  margin-bottom: 4rem;
  max-width: 1000px;
  color: ${(props) => props.theme.colors.secondaryText};

  .page-header {
    font-size: 1.75rem;
    margin-bottom: 2rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }
  }

  .page-details {
    > div {
      margin-bottom: 2rem;
    }
  }

  b {
    font-weight: bold;
    color: ${(props) => props.theme.colors.primaryText};
  }

  .title {
    font-size: 1.25rem;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .m-0 {
    margin: 0 !important;
  }

  .text-primary {
    color: ${(props) => props.theme.colors.primary};
  }

  a,
  .text-secondary {
    color: ${(props) => props.theme.colors.secondary};
  }

  strong {
    font-weight: 600;
    display: inline-block;
    margin-right: 0.5rem;
    color: ${(props) => props.theme.colors.primaryText};
  }

  .list-item {
    display: flex;
    margin-bottom: 2rem;

    p {
      margin-bottom: 1rem;
    }

    > div {
      flex: 1 1 auto;
    }
  }
`;
