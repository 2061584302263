import React, { useCallback, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Form, Field } from "react-final-form";
import RFFInputField from "../../unauthenticated/Login/RFFInputField";
import { Box, Button, Flex, Heading, Grid } from "theme-ui";
import Container from "../../../components/Container";
import {
  createRmaReturn,
  searchRmaReturnInvoice,
  getRmaReturnDocument,
} from "../../../apis";
import exceptionHandler from "../../../apis/exceptionHandler";
import {
  isRequired,
  isEmail,
  createRmaReturnValidate,
} from "../../../utils/validators";
import WithLoader from "../../../components/WithLoader";
import { useModal } from "../../../contexts/ModalContext";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../../components/ErrorMessage";
import { useToast } from "../../../contexts/AlertProvider";
import { useHistory } from "react-router-dom";
import FormControl from "../../../components/FormControl";
import IconTrash from "../../../components/icons/icon-trash";
import IconClose from "../../../components/icons/icon-close";
import Header from "./Header";
import Footer from "./Footer";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useQuery } from "react-query";
import themes from "../../../themes";
import { createRmaReturnDeviceType } from "../../../constants";

const RmaSearchContainer = styled(Box)`
  margin: 3rem 0;

  .page-header {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }
    .svg-icon {
      margin-right: 0.5rem;
      height: 1.75rem;
      width: 1.75rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  .container {
    max-width: 40rem;
    float: left;
  }

  .form-control {
    display: flex;
    align-items: flex-start;
    flex-wrap: no-wrap;
    width: 100%;

    label {
      flex: 0 0 15rem;
      margin: 0.75rem 0;
    }

    // for input width
    > * {
      flex: 1 1 auto;

      input {
        background-color: ${(props) => props.theme.colors.lightGray};
      }
    }

    .input {
      padding: 0.75rem 1.25rem;
    }

    &.has-error {
      margin-bottom: 2.5rem;
    }

    ${(props) => props.theme.customBreakpoints[2]} {
      flex-wrap: wrap;
      label {
        width: 100%;
        flex: 1 1 auto;
      }
    }
  }
`;

const RmaInputContainer = styled(Box)`
  margin: 3rem 0;

  .page-header {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }
    .svg-icon {
      margin-right: 0.5rem;
      height: 1.75rem;
      width: 1.75rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  .container {
    max-width: 40rem;
  }

  .form-control {
    display: flex;
    align-items: flex-start;
    flex-wrap: no-wrap;
    width: 100%;

    label {
      flex: 0 0 15rem;
      margin: 0.75rem 0;
    }

    // for input width
    > * {
      flex: 1 1 auto;

      input {
        background-color: ${(props) => props.theme.colors.lightGray};
      }
    }

    .input {
      padding: 0.75rem 1.25rem;
    }

    &.has-error {
      margin-bottom: 2.5rem;
    }

    ${(props) => props.theme.customBreakpoints[2]} {
      flex-wrap: wrap;
      label {
        width: 100%;
        flex: 1 1 auto;
      }
    }
  }
`;

const ConfirmationModal = styled(Box)`
  text-align: center;
  max-width: 24rem;

  .modal-title {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;

    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      height: 1.5rem;
      width: 1.5rem;
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 0.5rem;
    }
  }

  .modal-body {
    margin-bottom: 2rem;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .modal-footer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    button {
      min-width: 10rem;
      margin: 0 1rem;
    }
  }
`;

const CreateRmaReturn = () => {
  const { t } = useTranslation();

  const { showModal } = useModal();
  const [isLoadingByInvoice, setIsLoadingByInvoice] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [createErrorMessage, setCreateErrorMessage] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [chosenArticles, setChosenArticles] = useState([]);
  const [invoiceArticles, setInvoiceArticles] = useState(null);
  const [invalidQuantityArticles, setInvalidQuantityArticles] = useState([]);
  const { authenticated } = useAuthContext();
  const [returnDeviceType, setReturnDeviceType] = useState(null);
  let history = useHistory();
  const { data } = useQuery("profile", { enabled: authenticated });
  const onSearchByInvoice = useCallback(async (values) => {
    try {
      setIsLoadingByInvoice(true);
      setInvoice(null);
      const response = await searchRmaReturnInvoice(values);
      setInvoice(response.data.data);
      setInvoiceArticles(response.data.data.articles);
      setErrorMessage(null);
    } catch (e) {
      setInvoice(null);
      return exceptionHandler(
        e,
        (error) => {
          setErrorMessage(error);
        },
        (errors) => {
          return errors;
        }
      );
    } finally {
      setIsLoadingByInvoice(false);
    }
  }, []);

  const PrintConfirmation = ({ response }) => {
    const [documentPrinted, setDocumentPrinted] = useState(false);
    const [isFetchingDocument, setIsFetchingDocument] = useState(false);
    const toast = useToast();

    const onPrintRma = async (id) => {
      await getRmaReturnDocument(id)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/pdf",
          });
          const objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl, "_blank");
        })
        .catch(() => toast(t("document_not_available"), { type: "error" }))
        .finally(() => {
          setIsFetchingDocument(false);
        });
    };
    return (
      <ConfirmationModal>
        <Heading as="h2" sx={{ marginBottom: "1.5rem", fontSize: "1.25rem" }}>
          {t("rma_successfully_created")}
        </Heading>
        <Flex className="modal-body">
          <Box p={1} columns={1}>
            <p>
              <span style={{ fontWeight: "bolder" }}>
                {t("your_rma_id")}
                {response.data.rma_id}
              </span>
            </p>
          </Box>
          <Box p={1} columns={1}>
            <p>{t("rma_print_message")}</p>
          </Box>
        </Flex>
        <Flex className="modal-footer">
          <WithLoader isLoading={isFetchingDocument}>
            <Button
              sx={{ lineHeight: "1rem", marginBottom: "0.5rem" }}
              variant="secondary"
              className="details-btn"
              onClick={() => {
                setDocumentPrinted(true);
                setIsFetchingDocument(true);
                onPrintRma(response.data.id);
                // setIsFetchingDocument(false);
              }}
            >
              {t("receipt")}
            </Button>
          </WithLoader>
          <Button
            sx={{ lineHeight: "1rem", marginBottom: "0.5rem", height: "3rem" }}
            variant="secondary"
            className="details-btn"
            disabled={!documentPrinted}
            onClick={() => createNewRmaReload()}
          >
            {t("create_new_rma")}
          </Button>
        </Flex>
      </ConfirmationModal>
    );
  };

  const onCreateRmaReturn = async (values) => {
    try {
      values["invoice_number"] = invoice.invoice_id;
      values["invoice_date"] = invoice.invoice_date;
      values["customer_number"] = invoice.customer_id;
      values["articles"] = chosenArticles;
      values["device_type"] = returnDeviceType;

      setIsCreating(true);

      const response = await createRmaReturn(values);
      await showModal(
        () => {
          return <PrintConfirmation response={response} />;
        },
        () => {
          createNewRmaReload();
        }
      );
    } catch (e) {
      return exceptionHandler(
        e,
        (error) => {
          setCreateErrorMessage(error);
        },
        (errors) => {
          return errors;
        }
      );
    } finally {
      setIsCreating(false);
    }
  };
  const onArticleSelect = useCallback(
    (article_no) => {
      const article = invoiceArticles.find(
        (item) => item.article_no === article_no
      );
      article["quantity"] = 1;
      setChosenArticles((prevState) => [...prevState, article]);
      setInvoiceArticles((prev) =>
        prev.filter((item) => item.article_no !== article_no)
      );
    },
    [invoiceArticles]
  );
  const onChooseProduct = useCallback(() => {
    showModal(({ hideModal }) => (
      <ModalContent
        onArticleSelect={onArticleSelect}
        data={invoiceArticles}
        hideModal={hideModal}
      />
    ));
  }, [invoiceArticles, onArticleSelect, showModal]);

  const handleDeviceSelectionChange = useCallback(
    (event) => {
      setReturnDeviceType(event.target.value);
      if (
        parseInt(event.target.value) === createRmaReturnDeviceType?.allDevices
      ) {
        setChosenArticles([]);
        setInvoiceArticles(invoice?.articles);
        setInvalidQuantityArticles([]);
      }
    },
    [invoice?.articles]
  );

  const createNewRmaReload = useCallback(() => {
    history.go(0);
  }, [history]);

  const onArticleQuantityChange = useCallback(
    (event, article_no) => {
      const value = event.target.value;
      const article = chosenArticles.find(
        (item) => item.article_no === article_no
      );
      article.quantity = value;
      const temp = chosenArticles.map((item) => {
        if (item.article_no === article_no) return { ...item, ...article };
        return item;
      });
      setChosenArticles(temp);
      if (value && value > 0) {
        const filtered = invalidQuantityArticles.filter(
          (item) => item !== article_no
        );
        setInvalidQuantityArticles(filtered);
      } else {
        setInvalidQuantityArticles([...invalidQuantityArticles, article_no]);
      }
    },
    [chosenArticles, invalidQuantityArticles]
  );

  const onArticleRemove = useCallback(
    (article_no) => {
      const index = chosenArticles.findIndex(
        (item) => item.article_no === article_no
      );
      if (index !== -1) {
        const temp = chosenArticles;
        temp.splice(index, 1);
        setChosenArticles(temp);
        const article = invoice?.articles.find(
          (item) => item.article_no === article_no
        );
        setInvoiceArticles((prev) => [...prev, article]);
        const filtered = invalidQuantityArticles.filter(
          (item) => item !== article_no
        );
        setInvalidQuantityArticles(filtered);
      }
    },
    [chosenArticles, invalidQuantityArticles, invoice?.articles]
  );
  const validate = useCallback((values) => createRmaReturnValidate(values), []);

  return (
    <>
      {!authenticated && <Header />}
      <Container>
        <Grid columns={1}>
          <RmaSearchContainer className="change-password-container">
            <Container className="container">
              <Box className="page-header">
                <Heading className="heading">{t("search_by_invoice")}</Heading>
              </Box>
              <Form
                onSubmit={onSearchByInvoice}
                validate={validate}
                render={({ handleSubmit, submitting }) => (
                  <Box
                    as="form"
                    className="form-container"
                    onSubmit={handleSubmit}
                  >
                    <ErrorMessage
                      message={errorMessage}
                      sx={{ marginBottom: 4, fontWeight: 400, width: "100%" }}
                    />
                    <RFFInputField
                      placeholder={
                        authenticated
                          ? data?.data?.customer_number
                          : t("customer_number")
                      }
                      value={authenticated ? data?.data?.customer_number : null}
                      label={t("customer_number")}
                      type={"text"}
                      name={"customer_number"}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                      disabled={authenticated || Boolean(invoice)}
                    />
                    <RFFInputField
                      placeholder={t("invoice_number")}
                      label={t("invoice_number")}
                      type={"text"}
                      name={"invoice_number"}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                      disabled={Boolean(invoice)}
                    />
                    <RFFInputField
                      placeholder={t("invoice_date")}
                      label={t("invoice_date")}
                      type={"date"}
                      name={"invoice_date"}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                      disabled={Boolean(invoice)}
                    />
                    <Flex
                      sx={{ justifyContent: "flex-end", marginTop: "2.5rem" }}
                    >
                      <WithLoader isLoading={isLoadingByInvoice}>
                        <Button
                          variant={"secondary"}
                          type={"submit"}
                          sx={{ minWidth: "10rem" }}
                          disabled={Boolean(invoice) || submitting}
                        >
                          {t("search")}
                        </Button>
                      </WithLoader>
                    </Flex>
                  </Box>
                )}
              />
            </Container>
          </RmaSearchContainer>
        </Grid>
      </Container>
      {invoice && (
        <Container sx={{ marginBottom: "20px;" }}>
          <ErrorMessage
            message={createErrorMessage}
            sx={{ marginBottom: 4, fontWeight: 400, width: "100%" }}
          />
          <Form
            onSubmit={onCreateRmaReturn}
            validate={({ phone, email, rma_reason }) => ({
              return_device_type: isRequired(returnDeviceType),
              phone: isRequired(phone),
              email: isEmail(email),
              rma_reason: isRequired(rma_reason),
            })}
            render={({ handleSubmit, submitting }) => (
              <Box as="form" onSubmit={handleSubmit}>
                <RmaInputContainer>
                  <Box className="page-header">
                    <Heading className="heading">
                      {t("contact_details")}
                    </Heading>
                  </Box>
                  <Box className="form-container">
                    <Grid columns={2} gap={1}>
                      <RFFInputField
                        placeholder={t("email")}
                        label={t("email")}
                        type={"text"}
                        name={"email"}
                        onChange={() => setErrorMessage(null)}
                      />
                      <RFFInputField
                        placeholder={t("phone")}
                        label={t("phone")}
                        type={"text"}
                        name={"phone"}
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                    </Grid>
                  </Box>
                </RmaInputContainer>
                <RmaInputContainer>
                  <Box className="page-header">
                    <Heading className="heading">
                      {t("device_information")}
                    </Heading>
                  </Box>
                  <Box className="form-container">
                    <Grid columns={1} gap={0}>
                      <Box>
                        <Field name={"return_device_type"}>
                          {({ input, meta }) => {
                            const { value, ...restInput } = input;
                            return (
                              <FormControl className="form-control">
                                <label>{t("return_all_devices")}</label>
                                <input
                                  type="radio"
                                  error={
                                    meta.touched &&
                                    (meta.error || meta.submitError)
                                  }
                                  value={1}
                                  {...restInput}
                                  onChange={(e) => {
                                    input.onChange(e);
                                    handleDeviceSelectionChange(e);
                                  }}
                                />
                                <small
                                  style={{
                                    display: "flex",
                                    color: themes.colors.alertRed,
                                    fontSize: "80%",
                                  }}
                                  className={"error"}
                                >
                                  {meta.touched &&
                                    (meta.error || meta.submitError)}
                                </small>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <Field name={"return_device_type"}>
                          {({ input, meta }) => {
                            const { value, ...restInput } = input;
                            return (
                              <FormControl className="form-control">
                                <label>{t("return_selected_devices")}</label>
                                <input
                                  type="radio"
                                  error={
                                    meta.touched &&
                                    (meta.error || meta.submitError)
                                  }
                                  value={2}
                                  {...restInput}
                                  onChange={(e) => {
                                    input.onChange(e);
                                    handleDeviceSelectionChange(e);
                                  }}
                                />
                                <small
                                  style={{
                                    display: "flex",
                                    color: themes.colors.alertRed,
                                    fontSize: "80%",
                                  }}
                                  className={"error"}
                                >
                                  {meta.touched &&
                                    (meta.error || meta.submitError)}
                                </small>
                              </FormControl>
                            );
                          }}
                        </Field>
                        {Number(returnDeviceType) ===
                          createRmaReturnDeviceType?.selectedDevice && (
                          <>
                            <Button
                              sx={{
                                lineHeight: "1rem",
                                marginBottom: "0rem",
                                width: "11rem",
                                height: "2rem",
                                marginTop: "0.5rem",
                              }}
                              variant="secondaryOutline"
                              className="details-btn"
                              onClick={() => {
                                onChooseProduct();
                              }}
                              type={"button"}
                            >
                              {t("choose_product")}
                            </Button>
                            <table
                              className={"table"}
                              cellPadding={5}
                              cellSpacing={5}
                              style={{
                                width: "100%",
                                textAlign: "center",
                                marginTop: "1rem",
                              }}
                            >
                              <thead
                                className={"table-head"}
                                style={{
                                  backgroundColor: themes.colors.primary,
                                  color: themes.colors.white,
                                  fontWeight: "bold",
                                }}
                              >
                                <tr>
                                  <th>{t("quantity")}</th>
                                  <th>{t("article_no")}</th>
                                  <th>{t("product")}</th>
                                  <th>{t("action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {chosenArticles.map(
                                  (
                                    { article_no, description, quantity },
                                    key
                                  ) => (
                                    <tr key={key}>
                                      <td style={{ padding: "10px" }}>
                                        <div>
                                          <input
                                            type="number"
                                            name={"quantity"}
                                            defaultValue={1}
                                            onChange={(event) => {
                                              onArticleQuantityChange(
                                                event,
                                                article_no
                                              );
                                            }}
                                            value={quantity}
                                          />
                                          <small
                                            style={{
                                              display: "flex",
                                              color: themes.colors.alertRed,
                                              fontSize: "80%",
                                              flexDirection: "column",
                                            }}
                                            className={"error"}
                                          >
                                            {quantity <= 0
                                              ? t("field_required")
                                              : ""}
                                          </small>
                                        </div>
                                      </td>
                                      <td>{article_no}</td>
                                      <td style={{ padding: "10px" }}>
                                        {description}
                                      </td>
                                      <td style={{ padding: "10px" }}>
                                        <Button
                                          type={"button"}
                                          variant="iconButton"
                                          className="trash-btn"
                                          onClick={() =>
                                            onArticleRemove(article_no)
                                          }
                                        >
                                          <IconTrash onClick />
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </>
                        )}

                        <div style={{ marginTop: "1rem" }}>
                          <Grid columns={1}>
                            <Field name={"rma_reason"}>
                              {({ input, meta }) => {
                                const error =
                                  meta.touched &&
                                  (meta.error || meta.submitError);
                                return (
                                  <>
                                    <label>{t("rma_reason")}</label>
                                    <textarea
                                      label={t("rma_reason")}
                                      type={"textarea"}
                                      rows={5}
                                      error={error}
                                      {...input}
                                      onChange={(e) => {
                                        input.onChange(e);
                                      }}
                                    />
                                    <small
                                      style={{
                                        display: "flex",
                                        color: themes.colors.alertRed,
                                        fontSize: "80%",
                                      }}
                                      className={"error"}
                                    >
                                      {error}
                                    </small>
                                  </>
                                );
                              }}
                            </Field>
                          </Grid>
                        </div>
                      </Box>
                    </Grid>
                  </Box>
                </RmaInputContainer>
                <WithLoader isLoading={isCreating}>
                  {Number(returnDeviceType) ===
                    createRmaReturnDeviceType?.selectedDevice &&
                    !chosenArticles.length && (
                      <small
                        style={{
                          display: "flex",
                          color: themes.colors.alertRed,
                          fontSize: "80%",
                        }}
                        className={"error"}
                      >
                        {t("select_at_least_one_product")}
                      </small>
                    )}
                  <Button
                    variant={"secondary"}
                    type={"submit"}
                    sx={{ minWidth: "10rem" }}
                    disabled={
                      submitting ||
                      invalidQuantityArticles.length ||
                      (Number(returnDeviceType) ===
                        createRmaReturnDeviceType?.selectedDevice &&
                        !chosenArticles.length)
                    }
                  >
                    {t("submit")}
                  </Button>
                </WithLoader>
              </Box>
            )}
          />
        </Container>
      )}
      {!authenticated && <Footer />}
    </>
  );
};

const ModalContent = ({ data, onArticleSelect, hideModal }) => {
  const { t } = useTranslation();
  const [invoiceArticles, setInvoiceArticles] = useState(null);
  useEffect(() => {
    setInvoiceArticles(data);
  }, [data]);
  const handleArticleSelect = useCallback(
    (article_no) => {
      const filteredArticles = invoiceArticles.filter(
        (article) => article.article_no !== article_no
      );
      setInvoiceArticles(filteredArticles);
    },
    [invoiceArticles]
  );
  return (
    <Box style={{ width: "500px" }}>
      <div>
        <Button
          className="icon-btn"
          variant="secondaryIconButton"
          onClick={() => {
            hideModal();
          }}
          sx={{ float: "right" }}
        >
          <IconClose />
        </Button>
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <table
          className={"table"}
          cellPadding={5}
          cellSpacing={5}
          style={{ width: "100%", textAlign: "center" }}
        >
          <thead
            className={"table-head"}
            style={{
              backgroundColor: themes.colors.primary,
              color: themes.colors.white,
              fontWeight: "bold",
            }}
          >
            <tr>
              <th>{t("article_no")}</th>
              <th>{t("product")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody className={"table-body"}>
            {invoiceArticles &&
              invoiceArticles.map(({ article_no, description }, key) => (
                <tr key={key}>
                  <td style={{ padding: "10px" }}>{article_no}</td>
                  <td style={{ padding: "10px" }}>{description}</td>
                  <td style={{ padding: "10px" }}>
                    <Button
                      variant={"primary"}
                      onClick={() => {
                        onArticleSelect(article_no);
                        handleArticleSelect(article_no);
                      }}
                      sx={{ padding: "5px", borderRadius: "2px" }}
                    >
                      {t("choose")}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default CreateRmaReturn;
