import React, { Fragment, useCallback, useState } from "react";
import { Badge, Box, Button, Flex } from "theme-ui";
import IconFilter from "../../../../components/icons/icon-filter";
import Menu from "../../../../components/Menu";
import Pagination from "../../Shop/Pagination";
import {
  downloadInvoice,
  getInvoices,
  downloadDeliveryNote,
  downloadSerialNumber,
} from "../../../../apis/index";
import { useQuery } from "react-query";
import SortTableHead from "../../Shop/SortTableHead";
import { useTranslation } from "react-i18next";
import IconDownload from "../../../../components/icons/icon-download";
import { useModal } from "../../../../contexts/ModalContext";
import OrderDetail from "../OrderDetail";
import {
  downloadBlob,
  downloadBlobCsv,
  getEuropeanDateFormat,
  INVOICE_STATUS_BADGE_VARIANT,
} from "../../../../utils/helpers";
import Money from "../../../../components/Money";
import { useToast } from "../../../../contexts/AlertProvider";
import WithLoader from "../../../../components/WithLoader";
import TrackingDetail from "./TrackingDetail";
import IconTruck from "../../../../components/icons/icon-truck";
import IconSearch from "../../../../components/icons/icon-search";
import Input from "../../../../components/form-fields/Input";
import IconDeliveryNote from "../../../../components/icons/icon-delivery-note";
import IconInvoice from "../../../../components/icons/icon-invoice";
import IconDetail from "../../../../components/icons/icon-detail";

const Invoices = () => {
  const { t } = useTranslation();
  const { showModal } = useModal();

  const toast = useToast();

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [id, setId] = useState(null);

  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({ sort_by: null, sort_direction: null });
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState();
  const [search, setSearch] = useState();

  const { data, isLoading, isFetching } = useQuery(
    ["invoices", page, sort.sort_by, sort.sort_direction, status, searchText],
    getInvoices,
    {
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );

  const invoiceTableData = data?.data || { data: [] };
  const { from, to, total, current_page, last_page } = invoiceTableData;

  const onSortClick = useCallback(
    (value) => {
      setPage(1);
      if (value !== sort.sort_by) {
        setSort({ sort_by: value, sort_direction: "asc" });
        return;
      }
      if (sort.sort_direction === "asc") {
        setSort({ sort_by: value, sort_direction: "desc" });
        return;
      }
      setSort({ sort_by: null, sort_direction: "asc" });
    },
    [sort.sort_by, sort.sort_direction]
  );

  const onInvoiceDownload = async (id, type) => {
    try {
      setId(id);
      setIsDownloadLoading(true);
      let fileResponse = null;
      if (type === "Invoice") {
        fileResponse = await downloadInvoice(id);
      } else if (type === "serial") {
        await downloadSerialNumber(id);
        const fileResponse =
          process.env.REACT_APP_API_URL +
          `/invoices/download-serial-number/${id}`;
        const fileName = `${type}_${id}.csv`;
        downloadBlobCsv(fileResponse, fileName);
        setIsDownloadLoading(false);
        return;
      } else {
        fileResponse = await downloadDeliveryNote(id);
      }

      const fileName = `${type}_${id}.pdf`;
      downloadBlob(fileResponse.data, fileName);
      setIsDownloadLoading(false);
    } catch (error) {
      setIsDownloadLoading(false);
      if (type === "serial") {
        toast.error(t("error.serial_download_failed"));
      } else {
        toast(t("document_not_available"), { type: "error" });
      }
    }
  };

  const onViewDetail = useCallback(
    (id) => {
      showModal(({ hideModal }) => (
        <OrderDetail
          type={"invoice"}
          id={id}
          hideModal={hideModal}
          downloadFile={(id) => downloadInvoice(id)}
          downloadDeliveryNote={(id) => downloadDeliveryNote(id)}
        />
      ));
    },
    [showModal]
  );

  const onTrackClick = (id) => {
    showModal(({ hideModal }) => (
      <TrackingDetail orderId={id} hideModal={hideModal} />
    ));
  };

  return (
    <>
      <Box
        className="pagination-container"
        style={{ justifyContent: "space-between" }}
      >
        <Input
          label={t("search")}
          type="number"
          min="1"
          icon={
            <IconSearch
              onClick={() => {
                setSearchText(search);
                setPage(1);
              }}
            />
          }
          iconPlacement="suffix"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setSearchText(search);
              setPage(1);
            }
          }}
          onBlur={() => {
            if (search === "") {
              setSearchText(search);
              setPage(1);
            }
          }}
        />
        <div>
          <Menu
            className="filter-menu"
            showDropdownIcon={false}
            label={
              <Fragment>
                {status === null || status === undefined
                  ? `${t("filter_by_status")}`
                  : `${t(status)}`}
                <IconFilter sx={{ marginLeft: "0.5rem" }} />
              </Fragment>
            }
            items={[
              {
                label: `${t("paid")}`,
                onClick: () => {
                  setPage(1);
                  setStatus("paid");
                },
              },
              {
                label: `${t("unpaid")}`,
                onClick: () => {
                  setPage(1);
                  setStatus("unpaid");
                },
              },
              {
                label: `${t("all")}`,
                onClick: () => {
                  setPage(1);
                  setStatus(null);
                },
              },
            ]}
          />
          <Pagination
            {...{ from, to, total, current_page, last_page }}
            onChange={(page) => setPage(page)}
          />
        </div>
      </Box>
      <Box className="table-container">
        <table className="table">
          <thead className="table-head">
            <tr>
              <SortTableHead
                label={t("id")}
                value={"id"}
                sort={sort}
                onClick={onSortClick}
              />

              <SortTableHead
                label={t("issued_date")}
                value={"date"}
                sort={sort}
                onClick={onSortClick}
              />
              <th>{t("order_id")}</th>
              <th>{t("quantity")}</th>
              <SortTableHead
                label={t("amount")}
                value={"cost"}
                sort={sort}
                onClick={onSortClick}
              />
              <th className="text-center">{t("status")}</th>
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody className="table-body">
            {!isLoading && invoiceTableData.data.length === 0 && (
              <tr>
                <td colSpan={9}>{t("no_data_in_the_list")}</td>
              </tr>
            )}
            {isLoading || isFetching ? (
              <tr>
                <td colSpan={9} rowSpan={7}>
                  <WithLoader isLoading={true} height={"16rem"} />
                </td>
              </tr>
            ) : (
              invoiceTableData?.data.map(
                (
                  {
                    invoice_id,
                    issued_date,
                    order_id,
                    total_cost,
                    paid_status,
                    total_quantity,
                    tracking_available,
                  },
                  index
                ) => (
                  <tr key={index}>
                    <td>{invoice_id}</td>
                    <td>
                      <span>{getEuropeanDateFormat(issued_date)}</span>
                    </td>
                    <td>{order_id}</td>
                    <td>{total_quantity}</td>
                    <td>
                      <span>
                        <Money amount={total_cost} />
                      </span>
                    </td>
                    <td>
                      <Box className="flex-center">
                        <Badge
                          variant={INVOICE_STATUS_BADGE_VARIANT[paid_status]}
                        >
                          {t(paid_status)}
                        </Badge>
                      </Box>
                    </td>
                    <td>
                      <Box className="flex-center">
                        <Button
                          variant="secondaryOutline"
                          className="details-btn"
                          onClick={() => {
                            onViewDetail(invoice_id);
                          }}
                        >
                          {t("view_details")}
                        </Button>
                      </Box>
                    </td>
                    <td>
                      <Box className="flex-center">
                        <Button
                          variant="primaryOutline"
                          className="delivery-btn"
                          onClick={() => {
                            onTrackClick(invoice_id);
                          }}
                          disabled={!tracking_available}
                        >
                          <IconTruck
                            height="18px"
                            width="18px"
                            style={{ transform: "scaleX(-1)" }}
                          />
                        </Button>
                      </Box>
                    </td>
                    <td>
                      <Flex sx={{ justifyContent: "center" }}>
                        <WithLoader
                          isLoading={id === invoice_id && isDownloadLoading}
                        >
                          <Button
                            sx={{
                              lineHeight: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                            variant="secondaryOutline"
                            onClick={() =>
                              onInvoiceDownload(invoice_id, "Invoice")
                            }
                            title={t("download_invoice")}
                          >
                            <IconDownload />
                            <IconInvoice width={"18px"} height={"18px"} />
                          </Button>
                          <Button
                            sx={{
                              lineHeight: "0.5rem",
                              marginBottom: "0.5rem",
                              marginLeft: "0.5rem",
                            }}
                            variant="secondaryOutline"
                            onClick={() =>
                              onInvoiceDownload(invoice_id, "DeliveryNote")
                            }
                            title={t("download_delivery_note")}
                          >
                            <IconDownload />
                            <IconDeliveryNote width={"18px"} height={"18px"} />
                          </Button>
                          <Button
                            sx={{
                              lineHeight: "0.5rem",
                              marginBottom: "0.5rem",
                              marginLeft: "0.5rem",
                            }}
                            variant="secondaryOutline"
                            onClick={() =>
                              onInvoiceDownload(invoice_id, "serial")
                            }
                            title={"S/N"}
                          >
                            <IconDownload />
                            <IconDetail width={"18px"} height={"18px"} />
                          </Button>
                        </WithLoader>
                      </Flex>
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </Box>
    </>
  );
};

export default Invoices;
