import React from "react";

const IconRoFlag = ({ props, width = "15" }) => (
  <svg
    id="Group_18"
    data-name="Group 18"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 149.161 111.871"
    {...props}
  >
    <path
      id="Path_666"
      data-name="Path 666"
      d="M0,0H49.72V111.871H0Z"
      fill="#00319c"
      fillRule="evenodd"
    />
    <path
      id="Path_667"
      data-name="Path 667"
      d="M213.333,0h49.72V111.871h-49.72Z"
      transform="translate(-163.613)"
      fill="#ffde00"
      fillRule="evenodd"
    />
    <path
      id="Path_668"
      data-name="Path 668"
      d="M426.666,0h49.72V111.871h-49.72Z"
      transform="translate(-327.226)"
      fill="#de2110"
      fillRule="evenodd"
    />
  </svg>
);

export default IconRoFlag;
