import React from "react";
import { Field } from "react-final-form";
import { Box, Label, Textarea } from "theme-ui";
import RFFErrorMessage from "./RFFErrorMessage";
import themes from "../../../themes";
import { inputBorderColor } from "../../../utils/helpers";
import PropTypes from "prop-types";

const RFFTextarea = ({ name, type, label, placeholder, sx }) => {
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <Box
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          {label && <Label sx={{ marginBottom: "2px" }}>{label}</Label>}
          <Textarea
            type={type}
            sx={{
              minWidth: "100%",
              height: "7rem",
              borderRadius: "6px",
              padding: "8px 10px",
              backgroundColor: themes.colors.inputBG,
              ...sx,
              borderColor: inputBorderColor({ meta, sx }),
            }}
            {...input}
            placeholder={placeholder}
          />
          {meta.touched && meta.error && (
            <Label sx={{ marginBottom: "1.5rem", marginTop: "0.5rem" }}>
              <RFFErrorMessage errorMessage={meta.error} />
            </Label>
          )}
        </Box>
      )}
    />
  );
};

RFFTextarea.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
};

export default RFFTextarea;
