import styled from "@emotion/styled";

export default styled.button`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  height: 100%;
  border-radius: 2px;
  text-transform: uppercase;
  color: $theme.colors.white;
  font-size: 1rem;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.primary};
  ":disabled": {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: ${(props) => props.theme.colors.grey};
    color: ${(props) => props.theme.colors.fontColor};
    border-color: ${(props) => props.theme.colors.darkGrey};
  }
`;
